export default {
  labels: {
    clear: "빈",
    refresh: "새로 고침",
    nextStep: "다음 단계",
    prevStep: "이전 단계",
    closeDriver: "출구 부트",
    complete: "완료",
    viewStatistics: "통계 보기",
    colon: "：",
    splitter: "/",
    remark: "비고",
    correction: "오류 수정",
    save: "저장",
    reset: "리셋",
    increase: "추가",
    preview: "미리보기",
    unit: "기사",
    cancel: "취소",
    all: "모두",
    searchPlaceHolder: "키워드 쿼리 입력",
    greaterThan: "더 큰",
    lessThan: "덜",
    second: "초",
    description: "설명",
    column: "열",
    pleaseChoice: "선택하십시오",
    filter: "필터",
    saveAndSubmit: "저장 및 제출",
    submit: "제출",
    appeal: "불만",
    review: "검토",
    choose: "선택",
    manage: "관리",
    delete: "삭제",
    add: "새로운",
    create: "새로운",
    edit: "편집",
    name: "이름",
    describe: "설명",
    next: "다음 단계",
    percent: "%",
    export: "수출",
    exportAll: "수출 모두",
    confirm: "결정",
    back: "반환",
    versionUpdate:
      "저희 웹 사이트가 방금 새 버전을 출시했음을 알려 드리게되어 기쁩니다! 정상적인 사용을 보장하기 위해 현재 작업을 즉시 저장 한 다음 페이지를 새로 고침 (브라우저의 새로 고침 버튼을 클릭하거나 F5 키를 눌러) 새 버전을 적용해야합니다. 당신의 협력에 대단히 감사합니다!",
    more: "더 보기",
    selected: "선정",
    deduct: "버클",
    maxScore: "대부분",
    scoreUnit: "포인트",
    pointUnit: "하나",
    comma: "，",
    view: "보기",
    batchingImport: "수입",
    deleteContent:
      "당신은 그것을 삭제하고 싶습니까? 삭제 후 복원 할 수 없습니다.",
    import: "수입",
    uploadFile: "파일 업로드",
    downloadFile: "파일 내보내기",
    upload: "업로드",
    click: "클릭",
    downloadTemplate: "템플릿 다운로드",
    fillInTheForm: "형식으로 채우기",
    UploadExcelfile: "일괄 가져오기를 완료하려면 Excel 업로드",
    uploadIdentical:
      "API 이름이 있으면 가져오는 동안 시스템의 기존 데이터가 업데이트됩니다.",
    numOne: "1",
    numTwo: "2",
    numThree: "3",
    apply: "적용 가능",
    leftBracket: "(",
    rightBracket: ")",
    question: "질문",
    answer: "답변",
    content: "콘텐츠",
    search: "검색",
    query: "쿼리",
    cancelEdit: "편집 취소",
    noValue: "데이터 없음",
    selectAll: "모두 선택",
    changeAGroup: "그룹 변경",
    arrow: " ---> ",
    saveReview: "제출 검토",
    saveSpotCheck: "샘플링 검사 및 제출",
    rapidSampling: "빠른 샘플링",
    file: "보관",
    download: "다운로드",
    lastPage: "이전 페이지",
    nextPage: "다음 페이지",
    batchActions: "배치 작업",
    cancelDistribution: "할당되지 않음",
    againDistribution: "재 할당",
    deleteRecord: "기록 삭제",
    oneOptionOpertor: "선택 품질 검사관",
    allOptionOpertor: "모든 품질 검사관",
    distributionInfoFirstOne: "확인",
    distributionInfoFirstTwo:
      "레코드를 재 할당하고 싶습니까? 할당 대상을 선택하십시오.",
    distributionInfoSecondOne: "취소 확인",
    distributionInfoSecondTwo:
      "이 레코드의 할당 정보입니까? 취소 후 할당되지 않은 검토 기록이됩니다.",
    distributionInfoSecondThree:
      "이 레코드의 할당 정보는 취소 후 할당되지 않은 샘플링 레코드가됩니다.",
    distributionInfoThirdOne: "선택한 레코드는",
    distributionInfoThirdTwo: "검토 된 기록은 처리되지 않습니다.",
    distributionInfoThirdThree: "샘플링된 레코드는 처리되지 않습니다.",
    exportError: "수출 실패",
    exportSuccess: "수출 성공",
    deleteRecordInfo:
      "{0} 레코드를 삭제하시겠습니까? 삭제 후 데이터를 복구 할 수 없습니다.",
    pleaseSetFilter: "필터 기준을 선택하십시오.",
    noCountAfterFilter: "내보내기 데이터 없음, 필터 기준을 재설정하십시오",
    redo: "재실행",
    batchMove: "배치 이동",
    websiteName: "지능형 품질 검사",
    salesEnable: "판매 권한 부여",
    systemName: "지능형 품질 검사",
    initSystemError:
      "네트워크 예외 또는 페이지 초기화 실패는 고객 서비스에 문의하십시오.",
    day: "날",
    hour: "시간",
    minute: "포인트",
    questionMark: "？",
    personalSettings: "개인 설정",
    logout: "기록",
    ok: "결정",
    saveAs: "저장",
    pleaseEnter: "입장해주세요",
    executeMessage: {
      executeSuccess: "{0} 성공적으로 실행",
      executeError: "{0} 실행에 실패",
    },
    field: "필드",
    operator: "연산자",
    value: "값",
    placeholder: "선택하십시오",
    pleaseSelect: "선택하십시오",
    totalScore: "총 점수",
    hyphen: "-",
    semicolon: "；",
    wfm: "지능형 스케줄링",
    user: "사용자",
    none: "없음",
    plusSign: "+",
    minusSign: "-",
    saveSuccessAndReload: "성공적으로 저장! 관련 직원 새로 고침 후 효과 페이지",
    saveAsName: "{0}(복사)",
    systemInformation: "시스템 데이터 보기",
    billingCenter: "청구 센터",
    warningMessage:
      "나머지 할당량은 충분하지 않습니다. 가능한 한 빨리 재충전하십시오.",
    fileTypeHolder:
      "파일 형식이 잘못되었습니다. {0} 형식의 파일을 업로드하십시오.",
    dataNo: "데이터 없음",
    move: "이동",
    study: "당신은 자아 학습 품질 검사 포인트를 표시하지 않았습니다",
    studySuccess: "라벨 성공",
    studyError: "라벨링 실패",
    editType: "카테고리 레이블 편집",
    copyed: "클립보드에 복사",
    clickToCopy: "복사 클릭",
    addFilterConditions: "필터 상태 추가",
    filterConditions: "필터 상태",
    messageTime: "메시지 시간",
    messageType: "메시지 유형",
    pushType: "메시지 채널",
    messageContent: "메시지 내용",
    messageName: "메시지 이름",
    messageStatus: "메시지 상태",
    configuration: "구성 정보",
    sendTarget: "목적지 보내기",
    guideUrl: "부트 스트랩 URL",
    noticeDetail: "메시지 세부 정보",
    action: "운영",
    seeDetail: "세부 정보 보기",
    theDay: "데이 {0}",
    theLastDayOfMonth: "매월 마지막 날",
    ignore: "무시",
    seeNow: "지금보기",
    exportConfirmText: {
      export: "총 {0} 개의 데이터, 내보내기 확인?",
      exportAll: "모든 콘텐츠를 내보내는 것을 확인 하시겠습니까?",
    },
    samrTagAdd: "태그 추가",
    deletePopconfirmContent: "삭제 하시겠습니까?",
    TheLastThirtyDays: "지난 30 일",
    addNumber: "플러스",
    subNumber: "마이너스",
    addStrategy: "정책 추가",
    minutes: "분",
    status: "사용 상태",
    pushConfig: "푸시 구성",
    category: "분류",
    and: "와",
    interval: "간격",
    switchOn: "오픈",
    switchOff: "닫기",
    batchDel: "일괄 삭제",
    exportTxt: "TXT 텍스트 내보내기",
    playSpeed: "이중 속도",
    messageLogBtn: "알림을 트리거하는 알림/대화 보기",
    dataLink: "데이터 링크",
    yes: "예",
    no: "아니",
    sentenceUnit: "문장",
    appealDetail: "고충 기록 세부 사항",
    detail: "세부 사항",
    default: "기본",
    customer: "고객",
    agent: "고객 서비스",
    sort: "정렬",
    applyNow: "신청",
    index: "일련 번호",
    allColumns: "추가 할 수있는 열",
    selectedColumns: "추가 된 열",
    syn: "동기화",
    saveAndNext: "저장 및 다음 만들기",
    viewReference: "참조 보기",
    referenceDetail: "참조 세부 사항",
    goToQaList: "품질 검사로 이동",
    goToCheck: "현장 확인으로 이동",
    businessCard: "명함",
    weapp: "작은 프로그램",
    redpacket: "빨간 봉투",
    externalRedpacket: "빨간 봉투 교환",
    sphfeed: "비디오 번호",
    exportTaskCreated: "성공적으로 생성 된 내보내기 작업",
    plzGoTo: "점프하세요",
    importTaskCreated: "성공적으로 생성 된 작업 가져오기",
    taskCreated: "성공적으로 생성 된 작업",
    reply: "답장",
    replied: "답장",
    release: "게시",
    expandMore: "다른 {0} 응답 확장",
    comment: "댓글",
  },
  business: {
    notifyMessage: {
      IsNullErrorFormat: "적어도 하나의 {0} 가 있으세요",
      canNotBeEmptyFormat: "{0} 은 비어있을 수 없습니다.",
      repeatErrorTipFormat: "{0} 이 중복됩니다.",
      getInformationFailedFormat:
        "{0} 정보를 얻지 못했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
      deleteSuccessFormat: "{0} 이 성공적으로 삭제되었습니다.",
      deleteFailedFormat:
        "{0} 을 삭제하지 못했습니다. 나중에 다시 시도하십시오.",
      saveSuccessFormat: "{0} 가 성공적으로 저장되었습니다.",
      saveFailedFormat: "{0} 을 저장하지 못했습니다. 나중에 다시 시도하십시오.",
      fileSuccessFormat: "{0} 가 성공적으로 보관되었습니다.",
      fileFailedFormat: "{0} 보관이 실패했습니다. 나중에 다시 시도하십시오.",
      exportSuccessFormat: "{0} 수출 성공",
      exportFailedFormat:
        "{0} 내보내기가 실패했습니다. 나중에 다시 시도하십시오.",
      submitSuccessFormat: "{0} 가 성공적으로 제출되었습니다.",
      submitFailedFormat: "{0} 제출하지 못했습니다. 나중에 다시 시도하십시오.",
      confirmSuccessFormat: "{0} 확인이 성공적이었습니다.",
      confirmFailedFormat: "{0} 확인이 실패했습니다. 나중에 다시 시도하십시오.",
      pleaseUploadFile: "파일을 먼저 업로드하십시오!",
      importFileSuccess: "파일 가져오기 성공!",
      importFildFailed: "파일 가져오기 실패!",
      deleteSuccess: "성공적으로 삭제!",
      deleteFailed: "삭제 실패, 나중에 다시 시도하십시오!",
      illegalTitle: "불충분 한 권한",
      illegalMessage:
        "당신은 다음 단계에 대한 일부 또는 모든 권한이 없습니다, 구성 관리자에게 문의하시기 바랍니다, 확인 버튼을 클릭하여 부팅을 종료합니다",
      illegalMessageCurrent:
        "현재 단계에 대한 일부 또는 모든 권한이 없습니다. 구성하려면 관리자에게 문의하십시오. 확인 버튼을 클릭하면 부팅이 종료됩니다.",
      skipMessage: "이 단계를 지금 건너 뛰고 싶다면 건너 뛰기 를 클릭하십시오.",
      errorMsg500:
        "서버 요청이 실패했습니다. 작업을 확인하거나 새로 고침하고 다시 시도하십시오.",
      errorMsg404: "Ooops... 찾고있는 페이지가 없습니다.",
      errorMsg400:
        "서버 요청이 실패했습니다. 데이터를 확인하고 다시 시도하십시오.",
      pageOverFlow:
        "현재 10000 개의 막대 만 표시 할 수 있습니다. 초과분을 보려면 필터링 기준을 설정하십시오.",
      isHandAddSmartTag: "수동으로 추가 된 스마트 레이블입니다.",
      defaultGradeMustAtLast: "기본 등급은 맨 끝에 있어야합니다.",
      AutomaticAllocationInspectorsIsNull:
        "품질 검사자를 추가하려면 기본 정보 페이지를 입력하십시오.",
      timePickerTips:
        "정상적인 사용에 영향을 미치지 않기 위해 23:00 ~ 2:00 는 시스템 유지 보수 시간이며 예정된 작업을 수행하지 마십시오.",
      batchDeleteInfo:
        "{0} 항목이 삭제되었고 {1} 항목이 데이터가 사용 되었기 때문에 실패했습니다.",
      batchDeleteTips: "{0} 개의 데이터를 확인하고 삭제를 확인합니까?",
      correctionSuccess: "오류 수정 성공",
    },
    pages: {
      qualityInspectionForm: {
        list: { IsMaxFormNumberError: "최대 10 개의 품질 검사 양식 추가" },
        basicInformation: {},
        specialProject: {
          sumOfScoresErrorTip: "미터법 항목 {0} 은 100 까지 추가해야 합니다",
          banProjectMaxErrorTip: "최대 2000 개의 금지 품목 추가",
          deadlyProjectMaxErrorTip: "최대 500 개의 치명적인 항목 추가",
        },
      },
    },
    info: {
      creatSuccess: "성공적으로 생성",
      creatError: "만들지 못함",
      editSuccess: "수정 성공",
      editError: "수정 실패",
      deleteSuccess: "성공적으로 삭제",
      addSuccess: "새로운 성공",
      deleteError: "삭제 실패",
      conditionError: "연산자 논리적 표현 오류",
      customJudgeLogic: "사용자 지정 조건 조합 논리적 표현 오류",
      exportError: "수출 실패",
      searchError: "필터 실패",
      loadError: "{0} 을로드하지 못했습니다.",
      saveSuccess: "성공적으로 저장",
      saveError: "저장하지 못했습니다",
      fileSuccess: "성공 보관",
      fileError: "아카이브 실패",
      cannotBeNull: "{0} 은 비어있을 수 없습니다!",
      pleaseSearchAndSelect: "키워드 검색을 입력하십시오.",
      clickAndToggle: "스위치를 선택하려면 클릭",
      deleteWarning: "{0} 삭제는 복구 할 수 없습니다. 계속 하시겠습니까?",
      getOssAuthFailed:
        "OSS 승인을 얻지 못했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
      getInsightAuthFailed:
        "Insight 승인을받지 못했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
      pictureUploadFailed: "이미지 업로드 실패",
      pictureUploadFailedSize: "이미지 크기 제한 초과, 이미지 업로드 실패",
      wecomTagsSyncInfo: "마지막 업데이트:{0}, {1} 메시지 동기화",
      collectSuccess: "컬렉션 성공.",
    },
  },
  components: {
    common: {
      qcReportEcharts: {
        errorMessage: "보고서 데이터를 가져 오지 못했습니다.",
      },
      waveAudio: {
        noWaveMessage:
          "경험을 유지하기 위해 파형은 {0} 분 이상의 녹화에 표시되지 않습니다. 직접 재생할 수 있습니다.",
      },
      customFilter: {
        del: "삭제",
        edit: "편집",
        new: "맞춤 필터",
        apply: "신청",
        cancel: "취소",
        onlyApply: "적용 가능한 전용",
        ohterSave: "저장",
        save: "저장",
        customFilter: "공통 필터",
        reset: "리셋",
        holderKeyWord: "키워드를 입력하십시오.",
        customFilterRule: "필터 논리 규칙:",
        customFilterName: "필터 이름:",
        holderCustomFilterName: "필터 이름을 입력하십시오.",
        manualConfiguration: "수동 구성",
        holderCustomRule: "사용자 정의 논리 조건을 입력하십시오.",
        example: "예제",
        dragConfiguration: "드래그 앤 드롭 구성",
        condition: "조건",
        operation: "운영",
        infoDel: "삭제할 영역 밖으로 콘텐츠를 드래그",
        toConfigure: "구성",
        holderCustomFilterNameLength: "필터 이름 최대 길이는 10 입니다.",
        customFilterNameNone: "필터 이름을 비울 수 없습니다.",
        and: "과",
        or: "또는",
        not: "비",
        delSuccess: "성공적으로 삭제",
        saveSuccess: "성공적으로 저장",
        updateSuccess: "수정 성공",
        customFilterCondition: "필터 상태",
        conditionRule: "조건 규칙",
        none: "없음",
        filterPlaceholder: "키워드를 입력하십시오.",
      },
      labelTabs: {
        addThis: "여기에 추가",
        goThis: "이것으로 이동",
        saveGo: "저장 및 기차",
      },
      datePicker: {
        today: "오늘",
        yesterday: "어제",
        theDayBeforeYesterday: "어제 전날",
        thisWeek: "이번 주",
        lastWeek: "지난주.",
        thisMonth: "이번 달",
        lastMonth: "지난달",
        lastSevenDay: "지난 7 일",
        lastThirtyDay: "지난 30 일",
        thisQuarter: "이번 분기",
        lastQuarter: "지난 분기",
        thisYear: "올해",
        lastYear: "작년",
        lastYearFromToday: "작년",
      },
      pagination: { page: "{0} 의 {1} 페이지" },
      wechatRecord: {
        loadMoreBtn: { top: "이전 메시지로드", bottom: "더 많은 메시지를로드" },
      },
    },
    pages: {
      menuTitleList: {
        tasksManageTitle: "품질 검사 작업",
        informationCollectTasksManageTitle: "컬렉션 작업",
        customerServiceCenterTitle: "고객 서비스 센터",
        informationTitle: "데이터 센터",
        intelligentAnalysisTitle: "지능형 분석",
        semanticIntelligence: "의미 지능",
        trainingCenterTitle: "훈련 센터",
        adminTitle: "시스템 관리",
      },
      qualityCheckingTask: {
        title: "전화 품질 검사",
        index: {
          tipOne:
            "통화 품질 검사는 직원의 콜센터 작업의 품질을 평가하는 데 사용됩니다. 비즈니스를 기반으로 적절한 품질 검사 양식을 설정할 수 있습니다.",
          tipTwo:
            "필터링 및 샘플링을 포함한 품질 검사 작업에 적합한 규칙을 설정할 수 있습니다. 품질 검사관 점수 후, 고객 서비스는 품질 검사 결과 및 추가 호소를 볼 수 있습니다,",
          addTaskTitle: "품질 검사 작업 추가",
          deleteTipTitle: "품질 검사 작업 삭제",
          deleteTipContent:
            "삭제된 후에는 품질 검사 작업을 복원할 수 없습니다. 당신은 그들을 삭제하고 싶습니까?",
          checkingTask: "품질 검사 작업",
          checkingTaskList: "품질 검사 작업 목록",
          formList: "양식 목록",
          viewHelpDocuments: "도움말 문서 보기",
        },
        manage: {
          taskSetting: {
            title: "작업 설정",
            form: "양식",
            basicInfo: "기본 정보",
            visibleRange: "보이는 범위",
            visibleRangeTip:
              "선택한 고객 서비스 및 고객 서비스 팀은 품질 검사 작업을 볼 수 있습니다. 또한 고객 서비스 팀 리더, 고객 서비스 및 품질 검사관을이 작업에 대한 권한 설정과 결합 할 수 있습니다.",
            automaticSampling: "자동 샘플링",
            automaticSamplingTip:
              "자동 샘플링 규칙을 설정하여 시스템이 설정 한 규칙에 따라 주기적으로 품질 검사 작업을 추가 할 수 있습니다 (예: 매주 월요일 통화의 5% 를 추출).",
            samplingRate: "샘플링 주파수",
            samplingTime: "샘플링 시간",
            samplingRange: "샘플링 범위",
            samplingRule: "샘플링 규칙",
            fillterCondition: "필터 상태",
            workflow: "품질 검사 워크플로",
            appealsTime: "항소 시간 허용",
            appealsTimeTipOne: "점수 제출 후",
            appealsTimeTipTwo: "일 안에",
            appealsTimeTipThree:
              '항소 시간을 초과하면 기록 품질 검사 상태가 자동으로 확인으로 변경됩니다."',
            auditTime: "감사 처리 시간",
            auditTimeTipOne: "불만 제기 후",
            auditTimeTipTwo: "일 안에",
            auditTimeTipThree:
              '승인 처리 시간을 초과하면 기록은 원래 점수를 유지하고 품질 검사 상태는 자동으로 확인"',
            noAppealsMode: "고충 모드 없음",
            appealsMode: "항소 모드 허용",
            noAppealsModeImage: "no-appeal.png",
            appealsModeImage: "appeal.png",
            customerServices: "고객 서비스",
            customerServicesGroup: "고객 서비스 그룹",
            surveyList: "평가 목록",
            customFields: "맞춤 필드",
            fieldConfig: "필드 구성",
            from: "에서",
            owner: "담당자",
            inspectorAndReviewerAuthority: "품질 검사 검토 기관",
            inspector: "품질 검사관",
            reviewer: "리뷰어",
            inspectorTip: "검사관은 작업을 점수를 매길 수 있습니다.",
            reviewerTip: "검토자는 작업을 평가할 수 있습니다.",
            dataStatisticsAuthority: "데이터 통계 권한",
          },
          samplingRecords: {
            title: "샘플링 기록",
            index: {},
            manualSampling: {
              title: "샘플링",
              fillterCondition: "필터 상태",
              samplingRule: "샘플링 규칙",
              agent: "고객 서비스",
              type: "유형",
              result: "결과",
              evaluate: "평가",
              relayNumber: "릴레이 번호",
              source: "소스",
              task: "작업",
              queueState: "큐 상태",
              callHanger: "전화 끊기 파티",
              queueTime: "시간이 많이 걸리는 대기열",
              talkTime: "통화 기간",
              bellTime: "링 기간",
              recordCountTipOne: "합계",
              recordCountTipTwo: "기록",
              customerServices: "고객 서비스",
              customerServicesGroup: "고객 서비스 그룹",
              surveyList: "평가 목록",
              customFields: "맞춤 필드",
              fieldConfig: "필드 구성",
              defaultFields: "기본 필드",
            },
          },
          taskList: {
            title: "작업 목록",
            index: {
              batchDelete: "일괄 삭제",
              batchSubmit: "일괄 제출",
              batchConfirm: "일괄 확인",
              itemFields: "등급 항목 필드",
              categoryFields: "2 차 분류 필드",
              forbidsAndDeadlies: "금지 및 치명적인 항목",
              scoreFieldList: "점수 항목 필드 목록",
              customerName: "고객 이름",
              cellphone: "전화",
              email: "우편함",
              organization: "회사",
              description: "설명",
              tags: "라벨",
              taskUserRole: "현재 작업 사용자 역할",
            },
            workBench: {
              title: "세부 정보 기록",
              checkForm: "품질 검사 양식",
              waitEvaluate: "평가하려면",
              alreadyEvaluate: "평가",
              waitSubmit: "제출하려면",
              alreadySubmit: "제출된",
              waitConfirm: "확인",
              alreadyConfirm: "확인",
              waitReview: "검토 대상",
              alreadyReview: "검토",
              alreadyDone: "완료",
              comment: "평가 비고",
              totalScore: "총 점수",
              score: "포인트",
              remark: "비고",
              changeRecords: "기록 변경",
              subject: "테마",
              secondLevelScore: "등급 II 점수",
              deadlyList: "치명적인 항목",
              forbidList: "금지 된 항목",
              addToCheckTask: "품질 작업에 추가",
              submitCheckRemark: "품질 검사 평가 제출",
              changeCheckRemark: "품질 검사 평가가 수정되었습니다",
              doneCheckRemark: "품질 검사 평가 완료",
              submitCheckReview: "품질 검사 검토를 위해 제출",
              submitCheckAppeal: "품질 검사 항소 제출",
              saveFlowSuccess: "성공을 평가하십시오.",
              saveFlowFailed: "평가 실패, 나중에 다시 시도하십시오.",
              FormDetail: "양식 세부 사항",
              callDetail: "통화 로그 세부 정보",
              sampleCalllogDetail: "세부 정보 기록",
              appealReasonTip: "항소 사유를 입력하십시오 (필수)",
              appeal: "불만",
              appealReason: "불만의 근거",
            },
          },
        },
      },
      qualityInspectionForm: {
        formformat: "{0} 품질 검사 양식",
        basicInformationTitle: "기본 정보",
        indicatorsProjectTitle: "지표 항목",
        specialProjectTitle: "특별 항목",
        totalScore: "총 점수",
        indicatorsName: "표시자 이름",
        score: "분수",
        singleScore: "단일 항목 점수",
        weight: "무게",
        maxDeductScore: "최대 포인트 공제",
        add: "증가",
        detail: "양식 세부 사항",
        list: {
          title: "품질 검사 양식",
          tipOne:
            "품질 검사 양식을 구성하는 것은 품질 검사 작업의 첫 번째 단계입니다. 회사의 비즈니스를 기반으로 가장 적합한 품질 검사 양식을 구성 할 수 있습니다.",
          tipTwo:
            "직접 채점, 가중 채점 및 포인트 공제 시스템과 같은 다양한 형식을 지원합니다. 규칙을 시스템에 추가하기 전에 설정하는 것이 좋습니다.",
        },
        basicInformation: {
          formTemplate: "양식 템플릿",
          directScore: "직접 등급",
          weightedScore: "무게 점수",
          deductScore: "공제",
          directScoreSecondLevel: "직접 등급-레벨 2",
          weightedScoreSecondLevel: "무게 점수-레벨 2",
          deductScoreSecondLevel: "포인트 공제-레벨 2",
          deductScoreImageFileName: "deduct-score.png",
          weightedScoreImageFileName: "weighted-score.png",
          directScoreImageFileName: "direct-score.png",
          deductScoreSecondLevelImageFileName: "deduct-score-second-level.png",
          weightedScoreSecondLevelImageFileName:
            "weighted-score-second-level.png",
          directScoreSecondLevelImageFileName: "direct-score-second-level.png",
        },
        specialProject: {
          banProject: "금지 된 항목",
          banProjectTip:
            "품질 검사 채점 중에 확인하는 데 사용할 수 있습니다. 실제 점수에 영향을 미치지 않고 의견을 위반하거나 보완하는 것으로 간주 될 수 있습니다.",
          deadlyProject: "치명적인 항목",
          deadlyProjectTip:
            "치명적인 항목은 품질 검사 점수 동안 확인하는 데 사용할 수 있습니다. 치명적인 항목을 검사하면 품질 검사 작업이 직접 0 점을 얻습니다.",
        },
      },
      qualityInspectionStatisticalReports: {
        reports: {
          title: "전화 품질 검사",
          entiretyReportTitle: "전체 보고서",
          serviceGroupReportTitle: "고객 서비스 그룹 보고서",
          serviceReportTitle: "고객 서비스 보고서",
          entiretyReport: {
            pandect: "개요",
            contrast: "대비",
            trend: "트렌드",
            analyze: "분석",
            totalScore: "총 점수",
            banProject: "금지 된 항목",
            deadlyProject: "치명적인 항목",
          },
        },
      },
      appealChekingTask: {
        list: {
          title: "품질 검사 결과 목록",
          taskSelectPlaceholder: "품질 작업 선택",
          agentSelectPlaceholder: "고객 서비스 선택",
          refresh: "새로 고침",
          all: "모두",
          unread: "읽지 않음",
          inComplaint: "불만 사항",
          reviewed: "검토",
          totalScore: "일반 댓글",
          detail: "품질 검사 세부 사항",
          audioText: "기록 된 텍스트",
          callInfo: "통화 정보",
          ticketInfo: "작업 순서 정보",
          wechatInfo: "세션 정보",
          dialogText: "대화 텍스트",
          dialogInfo: "대화 정보",
          customer: "고객",
          agent: "고객 서비스",
          none: "품질 검사 데이터 없음",
          appealSuccess: "성공적인 항소!",
          appealFailed: "항소 실패, 나중에 다시 시도하십시오!",
          pointListIsNull: "불만 품질 검사 지점을 비울 수 없습니다!",
          pleaseSelectPoint: "품질 검사 지점을 선택하십시오!",
          unsupportedFormat: "이 형식은 지원되지 않습니다.",
          shardingInformation: "다음은 shard {0} 입니다.",
          smartTags: "스마트 라벨",
          businessRecords: "사업 기록",
          selectedPointsWarning:
            "항소 할 품질 검사 항목을 확인하고 항소 항목에 대한 관련 진술을 지정한 다음 제출 을 클릭하십시오.",
          applyReason: "매력의 근거 (최대 50 단어)",
          chatMessageTypes: {
            all: "모두",
            text: "텍스트",
            image: "사진",
            agree: "동의 세션 내용",
            video: "비디오",
            voice: "음성",
            file: "파일",
            emotion: "표현식",
            link: "링크",
            chatrecord: "채팅 기록",
            mixed: "혼합 메시지",
            default: "메시지가 지원되지 않음",
            deleteFriend: "친구 삭제",
            redpacket: "빨간 봉투",
            card: "명함",
            disAgree: "대화 내용에 동의하지 않음",
            weapp: "작은 프로그램",
            externalRedpacket: "빨간 봉투 교환",
            meetingVoicevCall: "통화 메시지",
            uRL: "웹 페이지",
            replyTimeout: "타임 아웃 응답",
            sphfeed: "비디오 번호",
            chatRecordTitle: "채팅 기록",
          },
        },
        components: {
          qualityCheckScore: {
            totalScore: "총 점수",
            actualScore: "등급",
            actualGrade: "등급",
            appeal: "불만",
            remark: "수동 발언",
            none: "품질 검사를 위한 상세한 자료 없음",
            pointEdit: "품질 검사 포인트 편집 분류",
          },
          changeList: {
            title: "기록 변경",
            user: "사용자",
            point: "품질 검사 포인트",
          },
          relatedRecord: {
            title: "관련 기록",
            qualityList: "품질 검사 작업",
            none: "없음",
            check: "보기",
            loadMore: "더 적재",
          },
        },
      },
      grade: {
        title: "품질 검사 등급",
        subTitle:
          "품질 검사 등급은 품질 검사 점수가 완료된 후 품질 검사 대상을 등급화하는 데 사용됩니다. 예를 들어, 60 보다 낮 으면 무자격, 60 보다 높으면 좋고 80 보다 높으면 우수합니다.",
        list: {
          up: "위로 이동",
          down: "아래로 이동",
          del: "삭제",
          case: "케이스 라이브러리에 자동으로 가입",
          orderCase: "기본 케이스 라이브러리",
          info: "기본 등급에는 일치하는 기준이 없으며 삭제할 수 없습니다. 위의 등급 중 하나라도 충족되지 않으면 기본 등급이 획득됩니다.",
          add: "등급 추가",
          save: "저장",
          close: "취소",
          max: "품질 검사 등급은 20 까지 구성 할 수 있습니다",
          name: "이름없는",
          success: "성공적으로 저장",
        },
      },
      qualityInspectionList: {
        title: "품질 검사 목록",
        fastFilter: "품질 검사 위반",
        fastFilterTip:
          "마이너스 포인트 시스템에 대한 히트는 위반이며 플러스 포인트 시스템에서의 미스는 위반입니다.",
        titleWechat: "분석 목록",
        titleCollect: "취득 목록",
        status: "분석 상태",
        reQualityInspectionConfig: "재질 작업 옵션",
        pleaseSelectRecheckPoints: "다시 계산할 품질 검사 지점을 선택하십시오.",
        beginReQualityInspection: "실행 시작",
        cancelReQualityInspection: "작업 취소",
        reQualityInspection: "재검사",
        reQualityInspectionWechat: "재분석",
        reQualityInspectionUnderWay: "재검사 작업...",
        reQualityInspectionUnderWayTip:
          "창을 닫은 후 페이지를 떠나면 작업에 영향을 미치지 않습니다.",
        reQualityInspectionDone: "재 검사 완료",
        reQualityInspectionDoneTip:
          "재검사가 성공적으로 완료되었으므로 확인 버튼을 클릭하십시오.",
        endReQualityInspection: "작업 종료",
        alreadyDoneTip: "{0} 완료",
        totalTip: "총 {0}",
        endReInspectionSuccess: "재질 작업 종료",
        closeWindow: "닫기 창",
        endReInspectionFailed: "다시 품질 작업 실패, 나중에 다시 시도하십시오!",
        detail: {
          title: "품질 검사 세부 사항",
          wechatTitle: "분석 세부 사항",
          titleCollect: "컬렉션 세부 정보",
          callIndex: "관련 전화",
          textIndex: "관련 대화",
          fileConfirm: "파일 확인?",
          fileConfirmDesc:
            "보관 후에는 품질 검사 점수를 편집할 수 없습니다. 보관 여부를 확인하십시오.",
        },
        sysCloumnsContal: "시스템 열 필드 관리",
        personCloumnsContal: "개인 칼럼 필드 관리",
      },
      spotCheckList: {
        title: "샘플링 목록",
        onlyMyTip: "내 샘플링 만 보기",
        plInspector: "샘플링 직원을 선택하십시오.",
        allInspector: "모두 보기",
        seInspector: "지정된 샘플링 검사자 보기",
        myInspector: "내 만 보기",
        noInspector: "할당되지 않은 샘플링 검사관 확인",
        detail: { title: "샘플링 세부 사항" },
      },
      reviewList: {
        title: "검토 목록",
        onlyMyTip: "내 리뷰 만 보기",
        plInspector: "리뷰어를 선택하십시오.",
        allInspector: "모두 보기",
        seInspector: "지정된 리뷰어보기",
        myInspector: "내 만 보기",
        noInspector: "할당되지 않은 검토자 보기",
        detail: { title: "세부 사항 검토" },
      },
      caseBaseList: {
        title: "케이스 라이브러리",
        del: "삭제",
        delTitle: "삭제 후 모든 내용이 저장되지 않습니다.",
        cancel: "취소",
        actions: "운영",
        seeCaseBase: "케이스 보기",
        seeDetail: "세부 정보 보기",
        edit: "편집",
        save: "저장",
        addCaseBase: "새로운 케이스 라이브러리",
        export: "수출",
        ok: "결정",
        tip: "품질 검사 목록의 일반적인 통화 및 대화 데이터를 쉽게 학습 및 공유를 위해 케이스 라이브러리에 저장할 수 있습니다.",
        detail: {
          choiceCaseBase: "케이스를 선택하십시오",
          delCaseBase: "케이스 삭제",
          addCaseBase: "케이스 추가",
          successed: "성공적으로 저장",
          info: "먼저 편집하는 콘텐츠를 저장하거나 취소하십시오.",
          caseType: "가입 할 케이스 라이브러리를 선택하십시오.",
        },
      },
      testSetList: {
        title: "품질 검사 테스트 세트",
        tip: "품질 검사관이 샘플링 한 데이터는 품질 검사 테스트 세트에 저장되므로 샘플링 검사 후 비교 한 일부 기계 검사 오류 항목에 대한 품질 검사 조건 및 규칙의 구성을 최적화하는 데 편리합니다.",
        refresh: "새로 고침",
        reQualityInspectionUnderWay: "에서 임무를 재평가...",
        reQualityInspectionUnderWayTip:
          "창을 닫은 후 페이지를 떠나면 작업에 영향을 미치지 않습니다.",
        reQualityInspectionDone: "재평가 완료",
        reQualityInspectionDoneTip:
          "재평가가 성공적으로 완료되었으므로 확인 버튼을 클릭하십시오.",
        endReQualityInspection: "작업 종료",
        alreadyDoneTip: "{0} 완료",
        totalTip: "총 {0}",
        endReInspectionSuccess: "재평가 작업 종료",
        closeWindow: "닫기 창",
        endReInspectionFailed:
          "재평가 작업이 실패했습니다. 나중에 다시 시도하십시오!",
        recheckPoint: "품질 검사 포인트 스코어 및 정확도를 다시 계산하십시오.",
        recheckPointTitle: "재평가 옵션",
        delete: {
          reQualityInspectionUnderWay: "일괄 삭제 작업...",
          reQualityInspectionUnderWayTip:
            "창을 닫은 후 페이지를 떠나면 작업에 영향을 미치지 않습니다.",
          reQualityInspectionDone: "일괄 삭제 작업 완료",
          reQualityInspectionDoneTip:
            "대량 삭제가 성공적으로 완료되었으므로 확인 버튼을 클릭하십시오.",
          endReQualityInspection: "작업 종료",
          alreadyDoneTip: "{0} 완료",
          totalTip: "총 {0}",
          endReInspectionSuccess: "일괄 삭제 작업 종료",
          closeWindow: "닫기 창",
          endReInspectionFailed:
            "일괄 삭제가 실패했습니다. 나중에 다시 시도하십시오!",
        },
        reultList: {
          assessmentTime: "마지막 평가 시간",
          assessmentAll: "총 평가",
          strip: "기사",
          spot: "포인트",
          deviation: "편차",
          accuracyRate: "정확도",
          info: "평가 결과 없음, 평가하십시오",
          onlyDeviationInfo: "편차 기록 만 보기",
          againAssessment: "재평가",
          screen: "필터",
          batchDelete: "배치 제거",
          matching: "경기",
          ok: "예",
          noCancel: "아니",
        },
      },
      smartWordsLibrary: {
        title: "지능형 시소러스",
        tip1: "동의어: 의미 라벨의 훈련량을 줄이는 데 사용되는 유사한 동의어 문장은 반복적으로 훈련 할 필요가 없습니다.",
        tip2: "전문 명사: 인식 정확도와 분석 정확성을 향상시키기 위해 정확하게 인식되지 않는 기업 이름, 제품 이름 및 산업 단어와 같은 적절한 명사에 대한 전사 및 자연어 이해를 최적화합니다.",
        tip3: "단어 집중: AI 분석 핫스팟이 현재 비즈니스를 충족시킬 수 있도록 단어 클라우드 분석에 집중하려는 단어에 중점을 둡니다.",
        tip4: "단어 차단: 단어 클라우드 분석에서주의를 기울이지 않으려는 단어를 차단하여 AI가 현재 비즈니스에 맞게 핫스팟을 분석하는 데 도움이됩니다.",
        tip5: "단어 무게: 의미 라벨의 인식을 수정하고 최적화하는 데 사용됩니다. 문장에서 단어의 영향 가중치를 수정함으로써, 특정 시나리오에서의 의미 인식 효과가 필요에 따라 최적화될 수 있다.",
        tip6: "코퍼스 데이터: ASR 트랜스코딩 정확도를 향상시키기 위해 트랜스코딩 오류 정정 및 ASR 교육을 위한 각각의 비즈니스 시나리오에 대해 많은 텍스트 단락을 입력합니다.",
        upload: {
          uploadSynonymWords: "수입 동의어",
          uploadProfessionalWords: "수입 전문 용어",
          uploadAttentionWords: "관심있는 단어 수입",
          uploadIgnoreWords: "수입 무시 단어",
          uploadWordsWeight: "수입 무게 단어",
          uploadTypo: "오타 수입 단어 세트",
          uploadCorpusData: "코퍼스 데이터 가져오기",
          uploadCorpusContent: "코퍼스 데이터 콘텐츠 가져오기",
          firstUploadStep:
            "1. 템플릿을 다운로드하고 템플릿 형식에 따라 채우십시오.",
          downloadTemplateMessage: "템플릿 다운로드:",
          firstUploadJSONButton: "JSON",
          firstUploadExcelButton: "EXCEl",
          firstUploadTxtButton: "TXT",
          secondUploadStep:
            "2. 가져오기 버튼을 클릭하여 템플릿 형식을 준수하는 EXCEl 파일을 업로드합니다.",
          secondUploadCorpusDataStep:
            "2. 가져오기 단추를 클릭하여 템플릿 형식을 따르는 TXT 파일을 업로드합니다.",
          postUploadDataFail: "데이터를 제출하고 업로드하지 못했습니다.",
          pleaseUploadTemplate: "EXCEl/JSON/TXT 파일을 업로드하십시오.",
          templateNeedJSONOrXLSX:
            "파일 템플릿은 JSON 또는 xlsx 형식으로만 가능합니다.",
          templateNeedTxt:
            "Corpus 데이터 파일 템플릿은 TXT 파일 일 수 있습니다.",
          uploadSuccess: "수입 성공",
          uploadFailed: "수입 실패",
          downloadError: "다운로드 실패",
          downLoadTxtTitle: "코퍼스가 주장합니다. Txt",
        },
        navTabNames: {
          synonymWords: "동의어",
          professionalWords: "전문 명사",
          attentionWords: "우려 단어",
          ignoreWords: "단어 무시",
          wordsWeight: "단어 무게",
          corpusData: "코퍼스 데이터",
          typoLibrary: "오타 단어 세트",
        },
        synonymWords: {
          createSynonymWords: "새로운 동의어",
          editSynonymWords: "동의어 편집",
          addSynonymWords: "Synonym 추가",
          pleaseEnterStandardWords: "표준 단어를 입력하십시오.",
          synonymWordsExists: "동의어가 이미 존재하며 추가 할 수 없습니다.",
          synonymWordsCanNotNull: "추가 된 동의어는 비어있을 수 없습니다.",
          pleaseAddSynonymWords: "동의어를 추가하십시오",
          addSuccess: "새로운 성공",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
          required: "*",
        },
        professionalWords: {
          create: "새로운 전문 용어",
          edit: "전문 명사 편집기",
          addTargetWords: "대체 단어 추가",
          targetWordsExists: "대체 단어가 이미 존재하며 추가 할 수 없습니다.",
          targetWordsCanNotNull: "추가 된 대체 단어는 비울 수 없습니다.",
          pleaseAddTargetWords: "대체 단어를 추가하십시오.",
          pleaseEnter: "전문 용어를 입력하십시오.",
          wordsExists: "이 용어는 이미 존재합니다.",
          createSuccess: "성공적으로 추가",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
          saveError: "저장하지 못했습니다",
          addTargetWordsMaxNumberError:
            "최대 100 개의 대체 단어를 추가 할 수 있습니다.",
          addHomophonyWordsMaxNumberError:
            "최대 100 개의 마스크 된 단어를 추가 할 수 있습니다.",
        },
        attentionWords: {
          create: "우려의 새로운 단어",
          edit: "우려 단어 편집기",
          pleaseEnter: "관심있는 단어를 입력하십시오.",
          wordsExists: "관심있는 말은 이미 존재합니다.",
          createSuccess: "성공적으로 추가",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
        },
        ignoreWords: {
          create: "새로운 무시 단어",
          edit: "단어 편집 무시",
          pleaseEnter: "단어를 무시하십시오",
          wordsExists: "무시 단어가 이미 존재합니다.",
          createSuccess: "성공적으로 추가",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
        },
        wordsWeight: {
          createWordsWeight: "새로운 무게 단어",
          editWordsWeight: "무게 단어 편집",
          addWordsWeight: "무게 단어 추가",
          pleaseEnterWeightWords: "무게 단어를 입력하십시오",
          pleaseEnterWeight: "무게를 입력하십시오",
          weightNeedInZeroToHundred: "무게는 0 에서 100 사이 여야합니다.",
          synonymWordsExists: "무게 단어가 이미 존재합니다.",
          weightNeedNumber: "무게는 정수 형식이 필요합니다",
          standardWeightInformation:
            "벤치 마크 가중치는 50 이며 무게가 클수록 분석에 미치는 영향이 강합니다.",
          createSuccess: "성공적으로 추가",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
        },
        corpusData: {
          createCorpusData: "새로운 코퍼스 데이터",
          editCorpusData: "코퍼스 데이터 편집",
          addCorpusData: "코퍼스 데이터 추가",
          pleaseEnterCorpusDescription: "코퍼스 설명을 입력하십시오.",
          pleaseEnterCorpusContent: "말뭉치의 내용을 입력하십시오.",
          synonymWordsExists: "이 코퍼스 데이터는 이미 존재합니다",
          CorpusContentWordsOverSetting:
            "내용의 단어 수는 20000 단어를 초과합니다.",
          download: "다운로드",
          upload: "업로드",
          see: "보기",
          canNotSave:
            "가져 오기 기능을 사용하십시오. 그렇지 않으면 저장할 수 없습니다.",
          createSuccess: "성공적으로 추가",
          editSuccess: "수정 성공",
          deleteSuccess: "성공적으로 삭제",
          deleteError: "삭제 실패",
          required: "*",
        },
        typoLibrary: {
          fields: {
            rightWord: "올바른 단어",
            wrongWord: "잘못된 단어",
            wrongWordCount: "잘못된 단어의 수",
            wrongWordTip: "추가하려면 Enter를 누르십시오",
            status: "효과",
          },
          create: "새로운 오타 단어 세트",
          edit: "오타 편집",
          tip: '오타 워드 세트는 "오타 검출" 의 효과를 최적화하기 위해 사용된다. 예를 들어, "환불" 이 실수로 "폭 환불" 으로 전송 된 경우이 기능을 통해 일반적인 오타와 올바른 단어를 설정할 수 있으며 저장 후 다음날 적용됩니다.',
          onlyChinese: "지원 입력 중국어 단어 만",
          wrongWordLengthErr: "각 잘못된 단어의 길이는 2 ~ 10 단어 여야합니다.",
        },
      },
      semanticTags: {
        title: "시맨틱 라벨",
        current: "현재 시맨틱 라벨",
        reference1: "품질 검사 작업: {0} - {1}",
        reference2: "스마트 라벨: {0}",
        reference3: "데이터 전처리: {0}",
        reference4: "지식 기반: {0}",
        index: { import: "수입 시맨틱 라벨" },
        detail: {
          new: "새로운 시맨틱 라벨",
          edit: "시맨틱 라벨 편집",
          name: "이름",
          standardSentences: "표준 문장",
          effectiveness: "효과",
          similarSentences: "유사한 문장",
          tip: "# # 을 사용하여 엔티티 API를 호출하는 지원, 예: 휴대 전화 번호 # sys_phone 입니까?",
          excludeSimilarSentences: "유사한 문장 제외",
          intelligentRecommend: "유사한 내용의 지능형 추천",
          fastTraining: "빠른 훈련:",
          qualify: "정사이즈",
          recallKeyWord: "키워드 리콜",
          excludeKeywords: "키워드 제외",
          excludeKeywordsInfo1:
            "키워드와 정사이즈 표현을 제외하면 모든 로직보다 우선합니다. 제외되면 시맨틱 레이블이 표시되지 않습니다.",
          keyWordInfo1:
            "여러 리콜 키워드는 영어 쉼표로 구분 된 각 줄에 채울 수 있습니다. 여러 개의 리콜 키워드가 채워지면 모든 히트가 적중된다는 논리가 있습니다.",
          keyWordInfo2:
            "리콜 키워드는 여러 줄로 채울 수 있습니다. 다수의 라인이 채워지면, 로직은 임의의 히트로 설정될 수 있다.",
          recallRegular: "리콜 정사이즈",
          excludeRegular: "정사이즈 제외",
          similaSentences: "유사한 문장 제외",
          simim: "유사성",
          keyWordInfo3:
            "유사한 문장을 제외하면 모든 논리보다 우선합니다. 유사한 문장이 맞으면 시맨틱 레이블이 맞지 않습니다. 기본 유사성은 80% 이며 유사성이 클수록 비슷합니다.",
          keyWordInfo4:
            "규칙은 정사이즈 유사하며 기본 시작 및 종료가 있습니다. 브래킷과 함께 반드시 사용되는 것은 아닙니다. 예를 들어 [XXXX 아님]",
          keyWordInfo5:
            "고급 모듈의 기능은 일반적으로 작업에 익숙한 전문가를위한 실험 기능입니다. 작동 방법을 잘 모르는 경우이 섹션을 변경하지 않는 것이 좋습니다.",
          keyWordInfo6:
            "문장 간 감지 기능은 의미 태그에 문장에서 감지 할 수있는 기능을 제공합니다.",
          keyWordInfo7: "같은 역할에 걸쳐 최대 연속",
          keyWordInfo8: "다른 역할 무시 문장 길이가 크지 않습니다.",
          keyWordInfo9: "문장 간 감지를위한 단어의 문장",
          keyWordInfo10: "가장 긴 교차 문장 제한 문장의 길이는 더 이상",
          keyWordInfo11: "비슷한 비율 제거",
          keyWordInfo12: "유사성이 낮지 않다.",
          keyWordInfo13:
            "비슷한 문장의 % 가 비슷하게 일치합니다 (기본 유사성 80%)",
          keyWordInfo14:
            "유사한 중복 제거는 유사성에 따라 문장과 일치합니다. 탐지 효율을 높이기에는 너무 유사한 문장을 제거하는 것이 좋습니다.",
          keyWord: "키워드",
          entity: "엔티티",
          ruleField: "개념 단어",
          customRule: "음성 규칙 디자인",
          fanhua: "비슷한 질문",
          test: "테스트",
          testOk: "히트 테스트",
          senior: "고급 설정",
          seniorList: "교차 문장 탐지",
          up: "활성화",
          down: "닫기",
          ju: "문장",
          oneWord: "단어",
          simimNo: "무게와 유사함",
          seniorField: "유사성 임계 값",
          save: "저장",
          cancel: "취소",
          specialElements: "특수 요소",
          logicRule: "논리 규칙",
          every: "여러 경기",
          recallOk: "히트 리콜 단어:",
          recallNo: "놓친 리콜 단어",
          ruleOk: "히트 리콜 정사이즈",
          ruleNo: "놓친 정사이즈",
          rularOk: "히트 규칙",
          rularNo: "누락 된 규칙",
          infoOk: "표준 문장 또는 유사한 문장을 명중:",
          infoNo: "누락 된 표준 문장 또는 유사한 문장",
          fieldOk: "유사한 문장을 제외하려면 히트:",
          fieldNo: "유사한 문장을 제외하기 위해 놓친",
          excludeRuleNo: "제외 Regex 실패",
          excludeRuleOk: "히트 제외 정사이즈",
          excludeKeywordNo: "누락 제외 키워드",
          excludeKeywordOk: "히트 제외 키워드",
          update: "업데이트 성공",
          fanhuaList: "일반화 탭 유사 문장 목록에 제출",
          wordList: "교육 목록은 비어 있으며 일반화 목록에 제출할 수 없습니다.",
          start: "시작",
          end: "끝",
          keyWordInfo15:
            "유사한 필드가 일반화 목록에 존재합니다 (유사한 중복 제거 제약 조건의 고급 구성)",
          saveSuccess: "성공적으로 저장",
          errInfo: "일부 필드는 잘못 채워지거나 채워지지 않습니다.",
        },
        table: {
          deepTraining: "딥 트레이닝 {0} 라운드",
          fastTraining: "빠른 훈련",
        },
      },
      intelligentCollection: {
        title: "지능형 인수",
        classification: "그룹 목록",
        new: "새로운 컬렉션",
        table: {
          name: "필드 이름",
          props: "고객 속성",
          type: "필드 유형",
          desc: "필드 설명",
        },
        detail: {
          isCustomerProps: "고객 속성에 저장",
          customerPropsHelper:
            "저장 후 수집 된 정보는 고객 정보로 업데이트됩니다. 저장하지 마십시오, 고객 정보 업데이트 없음",
          name: "필드 이름",
          type: "필드 유형",
          text: "텍스트",
          desc: "필드 설명",
          descPlaceHolder: "정보를 수집하기 위해이 필드의 사용을 설명",
          condition: "조건",
          logic: "규칙",
          method: "획득 모드",
          message: "정보 콘텐츠",
          range: "추출 범위",
          entity: "엔티티 추출",
          base: "기본 정보",
          collect: "정보 수집",
        },
      },
      intelligentTags: {
        title: "스마트 라벨",
        sessionLabelTitle: "세션 시나리오",
        keyEventsTitle: "주요 이벤트",
        customerLabelTitle: "고객 라벨",
        addClassification: "새 레이블 분류 만들기",
        defaultCategoryName: "기본 분류",
        index: {
          createTag: "새 레이블",
          createEvent: "새로운 이벤트",
          editTagClassification: "라벨 분류 편집",
          selectTagClassification: "라벨 분류 선택",
          editTagGrounp: "그룹 편집",
          callTimeError:
            "선택한 통화 시간/대화 시작 시간 범위는 한 달 (31 일) 을 초과 할 수 없습니다",
          callRecordCountError:
            "레코드 수는 0 입니다. 통화/대화 시간을 다시 선택하십시오.",
          selectItmeCountError:
            "대량 작업을 수행 할 스마트 라벨을 선택하십시오.",
          redoSuccess:
            "재실행이 성공적입니다. 작업 센터로 이동하여 확인하십시오.",
          redoError: "다시 실행에 실패했습니다. 나중에 다시 시도하십시오.",
          timeError: "쿼리 시간은 1 개월 (31 일) 을 초과 할 수 없습니다",
          categoryName: "범주 이름",
          categoryColor: "분류 색상",
          newTagClassification: "새 레이블 분류",
          newEventClassification: "새로운 카테고리",
          tagClassification: "라벨 분류",
          eventClassification: "이벤트 분류",
        },
        detail: {
          new: "새로운 스마트 라벨",
          edit: "스마트 라벨 편집",
          tagName: "이름",
          tagDesc: "설명",
          category: "분류",
          tagStatus: "효과",
          operator: "연산자",
          rule: "규칙",
          labelClassAtion: "라벨 유형",
          labelClassAtionTip1: "콜아웃 라벨: 규칙에 따른 라벨링",
          labelClassAtionTip2: "분류 라벨링: 모델 교육에 기반한 라벨링",
          group: "그룹화",
          checkLabel: "카테고리 선택",
          warn: "경고",
          warnTips:
            "경고가 켜진 후, 세션이이 주요 이벤트 규칙을 충족하면 지정된 사람이 프롬프트되고 할 일 목록에서 알림을 수신 할 수 있습니다.",
          warnError: "경고 지정 인원을 선택하십시오.",
        },
        subTitle: {
          common: "스마트 세션 태그",
          wecom: "엔터프라이즈 마이크로 라벨",
        },
      },
      customerPortrait: {
        title: "고객 초상화",
        index: { import: "수입 고객 초상화" },
        searchPlaceHolder: "고객 ID, 별명 또는 전화",
        callCount: "대화 수:",
        callDuration: "총 통화 기간:",
        dialogCount: "총 대화 수:",
        keyWordsStatistics: "핵심 단어 통계 (상위 20 개 및 우려 단어)",
        keyWordsCount: "({0})",
        businessTag: "비즈니스 라벨",
        keyWordsCloudImg: "키워드 클라우드 차트",
        customerEmotionTrend: "고객 감정 동향",
        notFoundCustomer: "검색된 관련 고객 없음",
        noKeyWords: "관련 키워드를 찾을 수 없습니다.",
        noBusinessTags: "관련 비즈니스 태그를 찾을 수 없음",
        getKeyWordsStatisticsDataError: "고객 키워드 통계 쿼리 실패",
        getBusinessTagsStatisticsDataError: "고객 비즈니스 레이블 쿼리 실패",
        personCustome: "고객 정보가 쿼리되지 않음",
        notimeInfo: "감정 정보는 발견되지 않았습니다.",
        timeList: "날짜 범위",
        operator: "스마트 라벨",
        placeHolder: "선택하십시오",
        placeOperator: "스마트 라벨을 선택하세요",
        search: "쿼리",
        reset: "리셋",
        searchHolder: "검색",
        call: "전화",
        text: "대화",
      },
      sentimentAnalysis: { title: "여론 발견" },
      saleSentimentAnalysis: { title: "여론 분석" },
      dataPreprocess: {
        title: "데이터 전처리",
        index: {
          createData: "새로운 데이터",
          selectDataSource: "데이터 소스 선택",
          selectStatus: "유효성 선택",
          callTimeError:
            "선택한 통화 시간/대화 시작 시간 범위는 한 달 (31 일) 을 초과 할 수 없습니다",
          callRecordCountError:
            "레코드 수는 0 입니다. 통화/대화 시간을 다시 선택하십시오.",
          selectItmeCountError: "대량 작업을 수행 할 데이터를 선택하십시오.",
          redoSuccess:
            "재실행이 성공적입니다. 작업 센터로 이동하여 확인하십시오.",
          redoError: "다시 실행에 실패했습니다. 나중에 다시 시도하십시오.",
        },
        detail: {
          new: "새로운 데이터",
          edit: "데이터 편집",
          name: "이름",
          desc: "설명",
          dataSource: "데이터 소스",
          status: "효과",
          entity: "엔티티 추출",
          operator: "연산자",
          rule: "규칙",
        },
      },
      connectedByConversation: {
        title: "통신 대화 협회",
        list: {
          title: "통신 대화 연관 기능",
          info: "대화 연관 기능은 사전 설정된 규칙에 따라 다른 호출 및 대화를 연관시킬 수 있습니다.",
          rule: "대화 협회 규칙",
          source: "데이터 소스를 선택하십시오.",
          classification: "카테고리를 선택하십시오.",
          choose: "선택하십시오",
          input: "입장해주세요",
          save: "저장",
          cancel: "취소",
          fieldwei: "비트",
          fieldNot: "채워지지 않은 필드가 있습니다.",
          fiedldlengthNo: "필드 길이가 한계를 초과합니다",
        },
      },
      components: {
        operatorListGather: {
          conditionTitle: "CK",
          keywordTip: "여러 키워드를 설정할 수 있습니다, 쉼표로 구분",
          applyRole: "역할 감지",
          preOperator: "사전 운영자",
          applyOperatorScope: "탐지 범위",
          addOperator: "연산자 추가",
          noPreOperator: "없음",
          from: "아니.",
          to: "아니오 문장.",
          end: "문장",
          testRegular: "정사이즈 식 테스트",
          test: "테스트",
          matchSuccess: "성공적으로 일치",
          matchError: "경기 실패",
          querySementicTags: "시맨틱 라벨 선택",
          reQuerySementicTags: "의미 레이블 재선택",
          sementicTag: "시맨틱 라벨",
          standardSentence: "표준 문장",
          similarity: "유사성",
          actions: "운영",
          noFitTag: "올바른 레이블을 찾지 못했습니다.",
          addSemanticTag: "새 시맨틱 태그 추가",
          pleaseEnterRegular: "정사이즈 표현을 입력하십시오.",
          pleaseEnterRegularText: "확인할 내용을 입력하십시오.",
          newAssociateComponentTip:
            "값이 숫자 유형 인 경우 레거시 기능을 사용하십시오.",
        },
        filterManage: {
          noValueTip: "데이터 없음",
          allCall: "모든 통화",
          allDialogue: "모든 대화",
          allWechat: "모든 세션",
          getFiltersListFailed:
            "필터 목록을 얻지 못했습니다. 나중에 다시 시도하십시오!",
          getFilterDetailFailed:
            "필터 세부 정보를 얻지 못했습니다. 나중에 다시 시도하십시오!",
          getAllCountFailed:
            "총 통화/대화 수를 얻지 못했습니다. 나중에 다시 시도하십시오!",
        },
        listManage: {
          searchPlaceHolder: "키워드 검색을 입력하십시오.",
          filterConditions: "필터 상태",
          records: "기록",
          getRecordsFailed: "기록을 얻지 못했습니다. 나중에 다시 시도하십시오!",
        },
        miniListManage: {
          getRecordsFailed: "기록을 얻지 못했습니다. 나중에 다시 시도하십시오!",
        },
        applyTemplates: {
          title: "적용 가능한 품질 검사 템플릿",
          collectionTitle: "적용 가능한 취득 템플릿",
        },
        recordDetail: {
          showWaveAudio: "녹음 표시",
          getDetailFailed:
            "세부 사항을 얻지 못했습니다. 나중에 다시 시도하십시오!",
          getSmartTagsFailed:
            "스마트 라벨을 얻지 못했습니다. 나중에 다시 시도하십시오!",
          getTemplatesFailed:
            "해당 템플릿을 얻지 못했습니다. 나중에 다시 시도하십시오!",
        },
        changeList: {
          sampleStatus: "샘플링 상태",
          sampleAssignInspector: "샘플링 검사관 할당",
          reviewAssignInspector: "검토 자 할당",
          read: "읽기",
          distributivePerson: "배정 된 사람",
          totalScore: "총 점수",
        },
        qualityCheckScore: {
          totalScore: "총 점수",
          actualScore: "점수",
          actualGrade: "등급",
          appeal: "불만",
          remark: "비고",
          manualRemark: "수동 발언",
          none: "품질 검사를 위한 상세한 자료 없음",
          interactiveTip:
            "상호 작용 인식을 사용하여 고객 서비스 FAQ의 사용량 감지",
          interactiveIsEmptyTip: "인터랙티브 인식 콘텐츠가 감지되지 않음",
        },
        semanticMark: {
          semanticContent: "마킹 문장",
          markedSimilarTitel: "유사한 시맨틱 라벨",
          markedExcludeTitle: "유사한 의미 태그를 제외하도록 표시",
          taskTagTitle: "작업 관련 시맨틱 라벨",
          similarTagTitle: "유사한 의미 태그 식별 (유사성> 40%, 상위 20 개)",
          delete: "삭제",
          markSimilar: "비슷한 마크",
          markExclude: "유사한 제외",
          none: "없음",
        },
        selectCallTime: {
          callTime: "통화 시간/세션 시작 시간",
          callTimeRemind:
            "지난해에 최대 1 개월 (31 일) 의 대화 기록을 선택할 수 있습니다.",
          callRecordCount: "총 {0} 통화/대화 레코드를 선택했습니다.",
          getCallRecordError:
            "기록을 얻지 못했습니다. 나중에 다시 시도하십시오.",
        },
      },
      informationEntities: {
        title: "정보 엔티티",
        index: { import: "수입 정보 엔티티" },
        detail: {
          new: "새로운 정보 엔티티",
          edit: "정보 엔티티 편집",
          name: "이름",
          apiName: "API 이름",
          activeFlag: "효과",
          remark: "설명",
          logicExp: "엔티티 정사이즈",
          sentenceList: "엔티티 콘텐츠",
          tip: "엔티티 콘텐츠는 동일한 엔티티의 동의어 인 공백으로 구분 된 여러 항목을 지원합니다.",
          remarkLengthError: "설명 길이는 255 를 초과 할 수 없습니다",
          nameLengthError: "이름 길이는 40 을 초과 할 수 없습니다",
          entityDuplicateError: "엔터티 콘텐츠 복제",
        },
      },
      interactiveRecognition: {
        title: "인터랙티브 인식",
        classify: "분류",
        addClassify: "카테고리 추가",
        editClassify: "카테고리 편집",
        index: { import: "수입 상호 작용 인식" },
        detail: {
          new: "새로운 스마트 상호 작용",
          edit: "스마트 상호 작용 편집",
          type: "유형",
          standardQuestion: "표준 문제",
          similarQList: "비슷한 문제",
          exceptSimilarQList: "유사한 문제 제거",
          category: "분류",
          targetType: "문제 탐지 대상",
          status: "효과",
          standardAnswer: "표준 답변",
          similarAList: "비슷한 답변",
          wrongAList: "잘못된 대답",
        },
      },
      businessAnalysis: {
        title: "비즈니스 분석",
        tip1: "비즈니스 동향 분석: 수직 좌표는 대화의 수입니다. E. 조건을 충족하는 대화의 총 수와 수평 좌표는 시간입니다.",
        tip2: "상관 분석: 여러 지표, 마우스 서스펜션 디스플레이 상관 데이터, 데이터 볼륨, 상관 관계, 반복 속도의 상관 관계 분석",
        tip3: "변환 분석: 여러 지표의 분석은 상호 변환 관계, 동일한 통화/대화에 대한 변환 기준을 형성 할 수 있습니다. 두 가지 지표를 칠 수 있다면 두 지표에 변환 관계가 있다고 생각할 수 있습니다.",
      },
      knowledgeBase: {
        title: "지식 기반",
        list: {
          add: "하위 카테고리 추가",
          delete: "분류 삭제",
          categoryName: "범주 이름",
          createSuccess: "성공적으로 생성 된 하위 범주",
          keyWord: "키워드",
        },
        detail: {
          type: "유형",
          status: "상태",
          classification: "분류",
          standardQuestion: "표준 문제",
          answerDetectionRange: "답변 감지 범위",
          standardAnswer: "표준 답변",
        },
      },
      callManage: {
        title: "통화 녹음 필터",
        listTitle: "통화 관리",
        detailTitle: "통화 세부 정보",
      },
      dialogueManage: {
        title: "대화 텍스트 필터",
        listTitle: "대화 관리",
        detailTitle: "대화 세부 사항",
      },
      weChatManage: {
        title: "엔터프라이즈 위챗 세션 필터",
        listTitle: "엔터프라이즈 위챗 세션 관리",
        detailTitle: "엔터프라이즈 위챗 세션 세부 정보",
      },
      speechRecognition: {
        title: "음성 인식",
        asr: "음성 인식",
        asrRemind:
          "이 스위치를 끄면 음성 텍스트 기능과 기계 품질 검사 기능이 비활성화됩니다.",
        asrConfig: "ASR 여과",
        imAsrConfig: "IMASR",
        imAsrConfigRemind: "활성화되면 IM의 ASR 전사가 수행됩니다.",
        asrConfigRemind: "활성화되면 적격 호출만 ASR 콘텐츠를 실행합니다.",
        correction: "지능형 오류 수정",
        correctionRemind:
          "활성화되면 \"<a href = '/site/semantic-intelligence/smart-word-library/professional-words 'target =' _ blank'> Smart Thesaurus-Professional Nouns </a>\" 의 콘텐츠를 사용하여 오류 수정 변환을 강제합니다.",
        correctionConfig: "오류 수정 필터링",
        advancedSettings: "고급 설정",
        advancedSettingsTip:
          "지정된 데이터에 대해 지정된 ASR 모델을 선택합니다.",
        mandarinASRModel: "만다린 ASR 모델",
        cantoneseASRModel: "광둥어 ASR 모델",
        englishASRModel: "영어 ASR 모델",
        correctionConfigRemind:
          "활성화되면 다음 조건을 충족하는 호출만 수정됩니다.",
        customJudgeLogicIsNull: "사용자 지정 논리를 비울 수 없습니다.",
        redoCorrection: "오류 수정 다시 수행",
        callTime: "대화 시간 선택",
        callTimeRemind:
          "지난해에 최대 1 개월 (31 일) 의 통화 기록을 선택할 수 있습니다.",
        callRecordCount: "총 {0} 통화 레코드를 선택했습니다.",
        redoCorrectionSubmit: "실행을 위해 제출",
        callTimeError:
          "선택한 통화 시간 범위는 한 달 (31 일) 을 초과 할 수 없습니다",
        callRecordCountError:
          "통화 기록 수는 0 입니다. 통화 시간을 다시 선택하십시오.",
        redoCorrectionSuccess:
          "재 오류 수정이 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
        redoCorrectionError:
          "다시 오류가 실패했습니다. 나중에 다시 시도하십시오.",
      },
      role: {
        title: "역할 관리",
        create: "새로운 역할",
        detail: {
          title: "역할",
          new: "새로운 역할",
          view: "역할 보기",
          edit: "역할 편집",
          navTabNames: {
            basicInfo: "메뉴 권한",
            taskPermissions: "품질 검사 작업 권한",
            dataPermissions: "데이터 권한",
          },
          basicInfo: { roleName: "이름", permissions: "권한" },
          taskPermissions: {
            allTask: "모든 품질 검사 작업",
            myCreatedTask: "내가 만든 품질 검사 작업",
          },
          dataPermissions: {
            dataPermissionsConfig: "데이터 권한 구성",
            tips: "이 역할은 선택한 분류에서 데이터를 볼 수 있습니다.",
            select: "카테고리 선택",
            selectPlaceholder:
              "데이터 가시성 권한이 필요한 분류를 선택하십시오.",
          },
        },
      },
      dataMigration: {
        title: "데이터 마이그레이션",
        dataUpload: "데이터 마이그레이션",
        dataDowload: "데이터 마이그레이션",
        error: "내보낼 품질 작업을 선택하십시오.",
        list: {
          info: "밖으로 이동할 품질 검사 작업을 선택하십시오",
          listInfo:
            "품질 검사 작업과 관련된 데이터를 마이그레이션할지 여부를 선택하십시오 (예: 지능형 시오사우루스, 시맨틱 태그, 정보 엔티티, 지능형 상호 작용 등)",
          noInfo: "다른 비 QC 데이터를 이동할지 여부를 선택하십시오.",
          ownInfo: "작업 만 이동",
          everyInfo: "관련 이동 작업",
          allInfo: "모두 밖으로 이동",
          callFilter: "통화 필터",
          talkFilter: "대화 필터",
          smartTag: "스마트 라벨",
        },
        upload: {
          info: "1. 업로드 버튼을 클릭하여 형식을 준수하는 JSON 파일을 업로드합니다.",
          uploadSuccess: "성공적인 업로드",
          uploadError: "업로드 실패",
        },
      },
      dataAnalysis: {
        businessStatistics: "비즈니스 통계",
        qualityEffect: "품질 검사 건강",
        qualityAnalysis: "품질 검사 분석",
        generalInspecitionReport: "일반 보고서",
        fullAmountWork: "품질 검사관의 작업량",
        intelligentTagsAnalysis: "라벨 분석",
        saleIntelligentTagsAnalysis: "스마트 라벨 분석",
        generalInspecitionReportTip:
          "총 데이터: 지능형 품질 검사 데이터의 수 및 백분율, 수동 샘플링 데이터의 수 및 백분율, 항소 수, 샘플링 속도 및 시간 추세 차트.",
        groupTest: "그룹 테스트",
      },
      uploadDownloadList: {
        title: "미션 센터",
        tabs: {
          upload: "업로드 수입",
          download: "다운로드 수출",
          taskCenter: "작업 실행",
        },
      },
      uploadDownloadModel: {
        title: "업로드 다운로드",
        list: {
          clear: "빈",
          success: "성공",
          fail: "실패",
          hand: "진행 중",
          noInfo: "데이터 없음",
        },
      },
      billingCenter: {
        title: "청구 센터",
        export: "수출",
        limit: "알림 제한",
        tips: "청구 결과는 반올림으로 인해 편향됩니다.",
        dashboard: {
          remainingQuota: "현재 남은 할당량",
          usedQuota: "누적 사용 할당량",
          voiceQuality: "음성 품질 검사:",
          voice: "음성:",
          textQuality: "텍스트 품질 검사:",
          text: "텍스트:",
          hour: "시간",
          tenThousandHour: "백만 시간.",
          day: "날",
          cutoff: "컷오프",
          warning:
            "(나머지 할당량은 충분하지 않습니다. 가능한 한 빨리 충전하십시오)",
          isClosed: "(폐쇄 상태)",
        },
        table: {
          quotaRecord: "쿼터 레코드",
          timeFilter: "날짜 선택:",
          search: "쿼리",
          reset: "리셋",
          id: "일련 번호",
          type: "유형",
          qualityType: "품질 검사 유형",
          quotaChange: "변경",
          surplus: "균형",
          changeTime: "시간",
          voiceQuality: "음성 품질 검사",
          textQuality: "텍스트 품질 검사",
          sizeChangeText: "페이지 당 표시",
          used: "사용",
          give: "그랜트",
          deduction: "공제",
          callTime: "대화 시간",
          count: "데이터 볼륨 처리",
          page: "{0} 의 {1} 페이지",
        },
        limitModal: {
          call: "음성 품질 검사 할당량이 낮습니다.",
          callFix: "상기시키는 시간",
          dialogue: "텍스트 품질 검사의 나머지 시간은 적습니다.",
          dialogueFix: "기억할 일",
        },
      },
      messageCenter: {
        replyTitle: "내 대답",
        answerTitle: "@ My",
        shareTitle: "내 공유.",
      },
      columnsManage: { title: "품질 검사 현장 관리" },
      recordUpload: {
        list: {
          task: "품질 검사 작업",
          user: "연산자",
          uploadTime: "업로드 시간",
          endTime: "종료 시간",
          total: "총 업로드",
          success: "품질 검사 성공",
          failed: "품질 검사 실패",
          progress: "품질 검사 진행",
          inProgress: "진행 중",
          finished: "완료",
        },
        steps: {
          uploading: "녹음 파일 업로드",
          baseInfo: "기본 정보 채우기",
          start: "품질 검사 시작",
        },
        uploadComponent: {
          dragger: "드래그 앤 드롭하여 파일 추가",
          or: "또는",
          selectFile: "파일 선택",
          des: "지원 wav, mp3 형식, 8000Hz,16 비트 파일의 샘플링 속도",
          limit1: "최대 한 번에 업로드",
          limit2: "파일, 각 파일 크기를 초과하지 않습니다",
          uploading: "업로드",
          finished: "업로드 완료",
          success: "성공",
          failure: "실패",
        },
        baseInfo: {
          task: "품질 작업 선택",
          type: "녹음 양식 선택",
          agentSide: "고객 서비스가있는 채널",
          excel: "길을 따라 데이터 업로드",
          checkFileInfo: "파일 정보 확인",
          excelFileError: "길을 따라 데이터를 업로드하십시오.",
          checking: "교정...",
          reCheck: "재 교정",
          success: "검증 성공",
          excelUoloadFail: "경로를 따라 데이터를 업로드하지 못했습니다.",
          tips: {
            tip1: "1. ecxel에서 녹음 파일의 이름은 업로드 된 레코딩의 이름과 동일해야하며 이름이 같을 수 없습니다.",
            tip2: "2. 시스템은 기록 파일 이름과 호출 ID로 복제본을 확인합니다. 반복 된 데이터는 품질 검사 실패로 이어질 것입니다.",
            tip3: "3. 다운로드 한 엑셀 레코딩 템플릿의 경우 글꼴 색상이 빨간색이며 필수 필드입니다.",
            tip4: "4. 시간 유형의 필드 형식은 다음과 같습니다. yyyy-mm-dd hh:mm:ss, 예: 2021-04-20 17:36:30",
          },
        },
        title: "로컬 녹음 업로드",
        checkTitle: "녹음 품질 검사",
        start: "품질 검사 시작",
        disabledTips: "품질 검사 전에 녹음 파일을 확인하십시오.",
        tips: "같은 이름의 기록 파일은 품질 검사 결과 및 기본 정보를 덮어씁니다.",
        tips1: "여기에서 파일을 드래그 앤 드롭하거나 업로드 를 클릭합니다.",
        tips2: "지원",
        tips3: "형식",
        type: "wav、mp3",
        tips4: "최대 한 번에 업로드",
        tips5: "파일, 각 파일 크기를 초과하지 않습니다",
        tipsOverSize:
          "현재 큐가 가득 찼습니다. 업로드하기 전에 큐의 파일을 제거하십시오.",
        size: "500M",
        count: "20",
        uploaded: "녹화 파일 업로드",
        uploading: "({0}%)",
        uploadFinished: "(업로드 완료)",
        bottomTitle: "기록 정보 수집:",
        recordType: "녹음 양식을 선택하십시오.",
        double: "듀얼 채널 통화 녹음",
        single: "단일 채널 통화 녹음",
        recordBaseInfo: "녹음 기본 정보 업로드:",
        addBtn: "파일 추가",
        downloadTemplate: "템플릿 다운로드. excel",
        checkRecord: "기록 정보 확인",
        checking: "교정...",
        reCheck: "재 교정",
        checkAll: "모두 선택",
        cancelCheckAll: "모두 선택 취소",
        delete: "삭제",
        deleteLotSize: "일괄 삭제",
        agentChannel: "고객 서비스 채널:",
        left: "왼쪽 채널",
        right: "오른쪽 채널",
        donotKonw: "모르겠어",
        mission: "품질 작업 선택:",
        uploadFail: "업로드 실패",
        repeat: "중복 파일",
        overCount: "파일 수가 한도를 초과합니다.",
        overSize: "파일 크기가 제한을 초과합니다.",
        uoloadFail: "클라우드에 업로드하지 못했습니다.",
        formatError: "포맷 오류",
        seccess: "검증 성공",
        fail: "검증 실패",
        recordChecking: "품질 검사에서 녹음, 나중에...",
        recordCheckSuccess: "녹음 품질 검사 완료",
        recordCheckFail:
          "녹음 품질 검사에 실패했습니다. 녹음이 중복인지 유효한지 확인하십시오.",
        backToIndex: "업로드 목록으로 돌아 가기",
        recordCheckIsStarted:
          "품질 검사 작업이 현재 실행 중입니다. 현재 작업이 완료된 후 새 작업을 시작하십시오.",
      },
      customerServicePortrait: {
        title: "고객 서비스 초상화",
        tip1: "문장 길이의 배포 법칙: 문장 길이의 분포는 문장 길이의 단어 수에 따라 세그먼트로 계산되며 고객 서비스",
        tip2: "속도 분배 법: 속도 (단어/분), 세그먼트 통계에 따라 속도 분포, 고객 서비스 만 계산",
        tip3: "응답 시간 분배 법: 응답 시간의 분배, 고객 서비스 응답 상황 만 계산됩니다.",
        tip4: "품질 검사 준수: 품질 검사 포인트의 준수 비율",
        tip5: "평균 품질 검사 작업 점수: 평균 품질 검사 작업 점수",
        contol: {
          date: "날짜 선택:",
          today: "오늘",
          thisMonth: "이번 달",
          lastMonth: "지난달",
          thisQuarter: "이번 분기",
          lastQuarter: "지난 분기",
          thisYear: "올해",
          lastYear: "작년",
          customer: "고객 서비스:",
          search: "쿼리",
          reset: "리셋",
          export: "수출",
          indicator: "지표 관리",
          selectPlaceholder: "비교해야 하는 고객 서비스를 선택하십시오",
          warning: "비교를 위해 최대 5 개의 에이전트 선택",
          warning2: "문의 할 에이전트를 하나 이상 선택하십시오.",
          dataWarning:
            "시작 날짜 이후 최대 366 일의 데이터 만 쿼리 할 수 있습니다.",
          seccuss: "기록적인 수출 성공",
        },
        echarts: {
          distributionLaw: "유통 법칙",
          count: "횟수",
          bout: "타임즈",
        },
        portrait: {
          ability: "고객 서비스 기능 차원",
          option: "구성",
          abilityOption: "기능 차원 구성",
          placeholder: "입력 능력 차원 키워드 검색",
          save: "저장",
          warning:
            "구성 할 지표 3 개 이상을 선택하세요. 최대 10 개 표시기를 선택할 수 있습니다.",
        },
        label: { title: "고객 서비스 라벨" },
      },
      customerServiceRankList: {
        routerTitle: "RankList",
        title: "고객 서비스 순위",
        select: {
          date: "날짜 선택:",
          today: "오늘",
          thisMonth: "이번 달",
          lastMonth: "지난달",
          thisQuarter: "이번 분기",
          lastQuarter: "지난 분기",
          thisYear: "올해",
          lastYear: "작년",
          group: "고객 서비스 그룹:",
          groupPlaceholder: "에이전트 그룹을 선택하십시오.",
          customer: "고객 서비스:",
          customerPlaceholder: "고객 서비스를 선택하십시오.",
          search: "쿼리",
          reset: "리셋",
          compared: "대비",
          export: "수출",
          column: "열",
          warning: "비교를 위해 최대 5 개의 에이전트 선택",
          warning2: "적어도 하나의 에이전트를 선택하십시오.",
          dataWarning:
            "시작 날짜 이후 최대 366 일의 데이터 만 쿼리 할 수 있습니다.",
          selected: "선택된 에이전트:",
          seccuss: "기록적인 수출 성공",
        },
        compared: {
          title: "고객 서비스 선택",
          return: "취소",
          compared: "대비",
          tips: "비교를 위해 2-5 요원을 선택하십시오.",
          warning: "비교를 위해 2-5 에이전트 만 지원됩니다.",
        },
        transfer: { leftTitle: "항목 목록", rightTitle: "항목 표시" },
        table: {
          page: "{0} 의 {1} 페이지",
          actionTitle: "운영",
          action: "보기",
        },
      },
      conceptWord: {
        title: "개념 단어",
        new: "새로운 개념 단어",
        control: {
          search: "검색:",
          searchPlaceholder: "이름 또는 키워드 검색",
          commit: "쿼리",
          reset: "리셋",
          import: "수입",
          export: "수출",
          seccuss: "기록적인 수출 성공",
        },
        modal: { title: "개념 단어 가져 오기" },
        table: {
          sizeChangeText: "페이지 당 표시",
          page: "{0} 의 {1} 페이지",
          edit: "편집",
          delete: "삭제",
          request:
            "삭제 후에는 복구 할 수 없습니다. 당신은 그것을 삭제하고 싶습니까?",
          cancel: "취소",
          title: {
            conceptWord: "개념 단어",
            describeWordsNum: "설명 수",
            updateTime: "업데이트 시간",
            status: "효과",
            effective: "효과적인",
            invalid: "무효",
            action: "운영",
          },
        },
        detail: {
          title: { new: "새로운 개념 단어", edit: "개념 단어 편집" },
          name: "개념 단어 이름",
          namePlaceholder: "개념 단어 이름을 입력하십시오.",
          effectiveness: "효과",
          effective: "효과적인",
          invalid: "무효",
          description: "설명 단어:",
          descriptionPlaceholder: "설명자를 입력하십시오.",
          add: "추가",
          repeat: "중복 콘텐츠",
          empty: "설명 단어를 비울 수 없습니다.",
          recommendEmpty:
            "더 이상 권장되는 설명이 없으므로 수동으로 입력하고 추가하십시오.",
          recommend: "지능형 추천 설명자",
          cancel: "취소",
          change: "그룹 변경",
          descriptionSelected: "추가 설명자:",
          save: "저장",
          success: "개념 단어 성공적으로 저장",
          warning: "개념 단어 이름을 비울 수 없습니다.",
          warning2: "적어도 1 descriptor 추가",
          submitBtnHandle: {
            title:
              "선택한 설명자가 추가되지 않았습니다. 저장하면 추가되지 않은 설명자가 삭제됩니다.",
            content:
              "확인 을 클릭하여 개념 단어 홈 페이지로 돌아가서 계속 확인하십시오.",
          },
        },
      },
      smartTools: {
        btn: "스마트 도구",
        title: {
          similarWords: "발견 된 관련 단어",
          search: "단어로",
          semanticTags: "시맨틱 라벨",
        },
        sentenceSearch: {
          placeholder: "키워드 검색을 입력하고 여러 키워드를 지원하십시오.",
          more: "전체 텍스트 보기",
          pageUp: "이전 페이지",
          pageDown: "다음 페이지",
          total: "총 {0} 기록",
        },
        similarDiscover: {
          placeholder: "키워드를 입력하십시오.",
          copyed: "복사",
          none: "관련 단어 없음, 다른 키워드를 사용해보십시오.",
        },
        smartTags: {
          searchPlaceHolder: "키워드 또는 문장을 입력하십시오.",
          createSmartTag: "새로운",
          createClusteringSmartTag: "{0} 개의 데이터로 새로 만들기",
          addFromKeyword: "유사한 문장에 검색 내용 추가",
          hit: "히트",
          similar: "유사한",
          name: "시맨틱 라벨 이름",
          namePlaceHolder: "의미 레이블 이름을 입력하십시오.",
          recallWords: "단어 리콜",
          recallWordsPlaceHolder: "리콜 키워드를 입력하십시오.",
          recallRegular: "리콜 정사이즈",
          recallRegularPlaceHolder: "리콜 정사이즈 입력하십시오",
          rules: "규칙",
          standardSentences: "표준 문장",
          standardSentencesPlaceHolder: "표준 문장을 입력하십시오.",
          similaSentences: "유사한 문장",
          similaSentencesPlaceHolder: "비슷한 문장을 입력하십시오.",
          notSaveError: "검색하기 전에 시맨틱 라벨을 저장하십시오.",
          add: "추가",
          newPage: "새 페이지에서 열기",
          querryError:
            "유사한 필드가 일반화 목록에 존재합니다 (유사한 중복 제거 제약 조건의 고급 구성)",
          detailTitle: "시맨틱 라벨 세부 정보",
        },
      },
      trainingCenter: {
        title: "훈련 센터",
        edit: "편집",
        delete: "삭제",
        seleteAll: "모두 선택",
        cancelCheckAll: "모두 선택 취소",
        add: "추가",
        remove: "제거",
        save: "저장",
        cancel: "취소",
        fastTraining: {
          title: "빠른 훈련",
          titleDes:
            "빠른 교육은 의미 레이블이 20 개의 유사한 문장으로 빠르게 일반화 할 수 있도록 설계되었습니다.",
          semanticTags: "시맨틱 라벨",
          semanticTagsPlaceholder: "프로세스 키워드 검색을 입력하십시오.",
          standardSentence: "표준 문장:{0}",
          similarSentencesPlaceholder: "유사한 문장을 입력하여 추가 또는 검색",
          similarSentencesAdd: "추가",
          similarSentencesSearch: "검색",
          similarSentences: "유사한 문장 추가",
          trainingCount: "훈련 수:{0}",
          similarSentencesRecommend: "추천 유사한 문장",
          noResp: "관련 시맨틱 레이블이 없으므로 키워드를 변경하십시오.",
          similarSentencesModalTitle: "유사한 문장 검색",
          moreInfo: "컨텍스트 보기",
          moreInfoBtn: "컨텍스트",
          modalSearchType: {
            all: "모두",
            customer: "고객 서비스",
            client: "고객",
          },
          repetWarning:
            "동일한 유사한 문장이 이미 존재합니다. 반복해서 추가하지 마십시오.",
          repetRemoveTip:
            "저장된 유사한 문장에는 중복 된 내용이 있으며 자동으로 중복됩니다.",
        },
        trainingHistory: {
          title: "훈련 역사",
          select: {
            date: "시간 선택:",
            today: "오늘",
            thisMonth: "이번 달",
            lastMonth: "지난달",
            thisQuarter: "이번 분기",
            lastQuarter: "지난 분기",
            thisYear: "올해",
            lastYear: "작년",
            search: "쿼리",
            reset: "리셋",
          },
          table: {
            time: "시간",
            user: "연산자",
            tag: "시맨틱 라벨",
            similarCount: "유사한 문장의 수",
            deleteCount: "제거 된 문장의 수",
            page: "{0} 의 {1} 페이지",
          },
        },
        classifiedTraining: {
          title: "분류 훈련",
          tagsGrounp: "레이블 그룹화",
          placeHolder: "선택하십시오",
          cellTime: "시간 선택",
          search: "쿼리",
          reset: "리셋",
          classifiedFlag: "분류 훈련",
          num: "수치",
          gas: "비율",
          noData: "분류되지 않은 훈련",
          noDataHeight: "분류되지 않은 데이터가 높고 교육이 절실히 필요합니다.",
          noDataSmall:
            "분류되지 않은 데이터는 상대적으로 높은 비율을 차지하며 교육을 권장합니다.",
          noDataDays: "마지막 실행 전 {0} 일",
          training: "훈련",
          start: "시작",
          addClass: "분류 추가",
          custom: "고급 필터링",
          marked: "라벨링된 데이터 Errata",
          markedHeight:
            "Errata는 {0} 일 동안 수행되지 않았으므로 그렇게하는 것이 좋습니다.",
          markedSmall:
            "Errata는 {0} 일 동안 수행되지 않았습니다. Errata는 긴급히 필요합니다.",
          mark: "에라 타",
          dataCheck: "분류된 데이터 검증",
          dataCheckSmall:
            "K-L 발산은 0.5 보다 높기 때문에 훈련하는 것이 좋습니다.",
          dataCheckHeight: "K-L 차이가 0.8 보다 높고 훈련이 시급하다.",
          check: "교정",
          history: "콜아웃 역사",
          historyTime:
            "일일 주석 및 수정은 오전 2 시에 자동으로 모델 교육을 수행합니다.",
          today: "오늘 태그",
          article: "기사",
          none: "없음",
          historyCheck: "역사적 총 콜아웃",
          look: "레코드 보기",
          historyluate: "역사적 데이터 재조정",
          startluate: "크레인 계산",
          classData: "분류된 데이터",
          classStatus: "훈련 진행",
          next: "다음 기사",
          prev: "이전 기사",
          finish: "완전한 훈련",
          stop: "훈련 종료",
          classDetail: "훈련 세부 사항",
          trainingNone: "스마트 라벨에 대한 교육 없음",
        },
        classifiedTrainingHistory: {
          title: "훈련 역사",
          time: "시간 선택:",
          tag: "라벨 유형:",
          user: "연산자:",
          data: "데이터 소스를 선택합니다.",
          search: "쿼리",
          searchBar: "검색:",
          searchBarPlaceholder: "통화 ID/대화 ID 검색을 입력하십시오.",
          reset: "리셋",
          export: "수출",
          all: "모두",
          select: {
            today: "오늘",
            thisMonth: "이번 달",
            lastMonth: "지난달",
            thisQuarter: "이번 분기",
            lastQuarter: "지난 분기",
            thisYear: "올해",
            lastYear: "작년",
            search: "쿼리",
            reset: "리셋",
          },
          table: {
            user: "연산자",
            date: "가동 시간",
            data: "데이터 소스",
            id: "전화 ID/대화 ID",
            tagType: "스마트 라벨 그룹화",
            tagName: "레이블 이름",
            state: "상태",
            sizeChangeText: "페이지 당 표시",
            page: "{0} 의 {1} 페이지",
            call: "전화",
            dialogue: "대화",
          },
        },
        clusteringAnnotation: {
          title: "클러스터링 주석",
          left: { title: "클러스터링 결과" },
          right: {
            title: "클러스터링 문장",
            checkAll: "이 페이지 모두 선택",
            tagging: "콜아웃",
            detail: "보기",
            meaningless: "무시",
            meaninglessTips:
              "무시된 클러스터링 결과는 향후 표시되지 않습니다. 당신은 그들을 무시하고 싶습니까?",
            cancel: "취소",
          },
        },
      },
      clientCenter: {
        clientManage: { title: "고객 관리", tabTitle: "고객 서비스 센터 고객" },
        wechatClientManage: {
          title: "엔터프라이즈 마이크로 고객",
          table: { id: "고객 ID", name: "고객 별명", tag: "고객 라벨" },
        },
      },
      collectionRule: {
        title: "수집 규칙",
        form: {
          info: "지능형 정보 수집",
          infoPlaceholder: "카테고리를 선택하십시오.",
        },
        table: {
          name: "필드 이름",
          props: "고객 속성",
          type: "필드 유형",
          desc: "필드 설명",
        },
      },
      dataAdd: {
        title: "데이터 추가",
        types: { auto: "자동 추가", manual: "수동으로 추가" },
      },
      wechatStaff: {
        table: {
          name: "이름",
          part: "학과",
          phone: "휴대 전화 번호",
          email: "우편함",
          wechartId: "엔터프라이즈 위챗 ID",
          status: "직업 상태",
        },
        info: "먼저 데이터를 동기화해야하는 부서를 선택하십시오.",
        synSuccess: "동기화 성공",
        init: "초기화",
        syn: "동기화",
      },
      appealHistory: {
        appealTime: "항소 시간",
        cancelTime: "취소 시간",
        reviewTime: "검토 시간",
        inspector: "품질 검사관",
        result: "불만의 결과",
        complainResult:
          "총 {0} 품질 검사 항목이 항소되었고 {1} 이 성공적으로 항소되었으며 {2} 가 거부되었습니다.",
        reason: "불만의 이유",
        rejection: "거부 이유",
        cancel: "개정",
        tip: "불만 철회 확인?",
      },
      appealSdk: {
        time: "대화 시간",
        externalIdCall: "통화 로그 ID",
        externalIdText: "대화 기록 ID",
        customerNickName: "고객 별명",
        readStatus: "검토 상태",
        status: "불만 상태",
        isManual: "수동 품질 검사",
        complainButtonInfo:
          "{0} 항소 허용, {1} 항소가 이미 이루어졌으며 {2} 항소가 남아 있습니다. {3} 전에 항소 할 수 있습니다",
        submit: "불만 제출",
        complain: "불만",
        allComplain: "모든 불만",
        pendingSubmit: "제출하려면",
        taskIds: "품질 검사 작업",
        graded: "등급",
        complainSuccess: "성공적으로 제출 된 불만",
        appealSdkIndex: { title: "지능형 품질 검사 결과" },
      },
    },
    lookupRecords: {
      getFiltersListError:
        "필터 목록 정보를 얻지 못했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오!",
      columns: "열",
      filter: "필터",
      noSearchResult: "발견 된 기록이 없습니다.",
      pleaseSearch: "필터를 선택하고 검색을 시도하십시오.",
      sift: "필터",
      create: "새로운",
      search: "검색",
      createTaskTitle: "새로운 작업",
      title: "제목",
      deadline: "마감일",
      principal: "담당자",
      enqueueTime: "입장 시간",
      computeError: "계산 실패, 기다려주세요!",
      sum: "합계",
      max: "최대",
      min: "최소",
      avg: "평균",
      count: "카운트",
      maxDate: "최대 (최신)",
      minDate: "최소 (가장 오래된)",
    },
    mainMenu: {
      insight: {
        name: "Insight",
        getInsightUrlError:
          "Insight 링크 주소를 얻지 못했습니다. 나중에 다시 시도하십시오!",
      },
      template: {
        learningReminder: "학습 알림",
        subscriptionDynamics: "구독 역학",
      },
    },
    tasks: {
      subMenu: {
        manageHome: { text: "작업 관리", chaneg: "스위치" },
        qualityCheckTemplate: {
          text: "품질 검사 템플릿",
          collectText: "취득 템플릿",
          condition: { text: "품질 검사 조건" },
          checkPoint: { text: "규칙 구성" },
          automaticAdd: { text: "프로세스 자동화" },
          manualAdd: { text: "수동으로 추가" },
          roleConfig: { text: "역할 구성" },
          basicInfo: { text: "기본 정보" },
          smartWordsLibrary: { text: "지능형 시소러스" },
          grade: { text: "품질 검사 등급" },
          collectionRule: { text: "수집 규칙" },
          addData: { text: "데이터 추가" },
          collectionList: { text: "취득 목록" },
        },
        qualityCheckWorkbench: {
          text: "품질 검사 작업대",
          wechatText: "분석 작업대",
          informationCollectText: "인수 작업대",
          qualityInspectionList: {
            text: "품질 검사 목록",
            wechatText: "분석 목록",
            informationCollectText: "취득 목록",
          },
          condition: { text: "품질 검사 목록 (폐기 된)" },
          reviewList: { text: "검토 목록" },
          spotCheckList: { text: "샘플링 목록" },
          caseBaseList: { text: "케이스 라이브러리" },
          testSetList: { text: "품질 검사 테스트 세트" },
        },
        statisticsAndReports: {
          text: "통계 및 보고서",
          wholeReport: { text: "전체 보고서" },
          groupReport: { text: "고객 서비스 그룹 보고서" },
          agentReport: { text: "고객 서비스 보고서" },
          customerServiceExecution: { text: "고객 서비스 분석" },
          analysisOverview: { text: "분석 개요" },
          inspectionItemsAnalysis: { text: "품질 검사 항목 분석" },
        },
        wechatQa: {
          text: "엔터프라이즈 WeChat 품질 검사",
          task: { text: "분석 작업" },
          baseInfo: { text: "기본 정보" },
          checkPoint: { text: "분석 모델 구성" },
          setting: { text: "엔터프라이즈 위챗 분석 구성" },
          automaticAdd: { text: "프로세스 자동화" },
          manualAdd: { text: "수동으로 추가" },
        },
        clientCenter: { text: "고객 서비스 센터" },
      },
      conditionDictionaries: {
        title: "모든 조건",
        searchDefaultText: "ID 또는 이름을 입력하십시오.",
        id: "ID",
        name: "이름",
        conditionDetailedShow: "확장",
        conditionDetailedHide: "스토우",
        remark: "설명:",
        checkRole: "역할:",
        operator: "연산자:",
        operatorLogic: "논리:",
      },
      audioTextView: {
        customer: "고객",
        agent: "고객 서비스",
        unsupportedFormat: "이 형식은 지원되지 않습니다.",
      },
      checkPointClassifications: {
        add: "카테고리 추가",
        name: "이름",
        enterToSave: "Enter를 눌러 저장",
        highestScore: "최대 점수 캡",
        minimumScore: "최소 점수 하한 한도",
        defaultMinScore: "기본값은 0 점입니다.",
        minimumScoreTitle: "낮은 점수 제한",
        beginScore: "전체 시작 점수 값",
        beginScoreTitle: "시작 점수 값",
        scoreSetTip:
          "최대 점수는 모든 점수의 합보다 적습니다. 선택할 수 있습니다:",
        ignoreExcessSpillover: "초과 오버플로 무시",
        automaticWeightCalculation: "자동 무게 회계",
      },
    },
    operatorGather: {},
    fieldSelect: { field: "필드" },
    checkRange: {
      numberTipOne: "아니.",
      numberTipTwo: "문장",
      numberTipThree: "아니오 문장.",
      conditionLimit: "조건부 제한",
      roleLimit: "역할 제한",
      contextLimit: "문장 제한",
      notZero: "문장 제한에는 입력 0 이 지원되지 않습니다.",
      startMustLessThanEnd: "시작 문장 위치는 끝 문장 위치보다 작아야합니다.",
    },
    waveAudio: {
      play: "놀이",
      pause: "일시 중지",
      rateOne: "0.5",
      rateTwo: "1.0",
      rateThree: "1.5",
      rateFour: "2.0",
      rateFive: "1.2",
    },
    operatorListGather: {
      pleaseEnter: "입장해주세요",
      pleaseEnterRegular: "정사이즈 표현을 입력하십시오.",
      pleaseEnterGrammar: "문법 규칙을 입력하십시오.",
      pleaseEnterTestText: "확인할 내용을 입력하십시오.",
      pleaseEnterLoudness: "음량 데시벨을 입력하십시오.",
      pleaseEnterRangeAbility: "변경 범위를 입력하십시오.",
      afterHit: "히트 후 확인",
      pleaseEnterContext: "참조 문을 입력하십시오.",
      wordNumber: "단어 간격",
      intervalSecond: "더 큰 시간 간격",
      minute: "매분.",
      wordUnit: "단어",
      test: "테스트",
      testRegular: "정사이즈 식 테스트",
      testGrammar: "테스트 문법",
      testGrammarError: "문법 테스트 실패",
      regularError: "정사이즈 표현 오류, 다시 입력하십시오",
      pleaseEnterSemanticSentence: "의미 적 탐지가 필요한 문장을 입력하십시오.",
      pleaseEnterAssociate:
        "데이터-함께 표현식을 빌드할 수 있는 권한에 대한 지침을 참조하십시오.",
      querySemanticTags: "시맨틱 라벨 선택",
      reQuerySemanticTags: "의미 레이블 재선택",
      semanticTags: "시맨틱 라벨",
      standardSentence: "표준 문장",
      semanticName: "시맨틱 라벨",
      sentence: "표준 문장",
      similarity: "유사성",
      actions: "운영",
      tipOne: "올바른 레이블을 찾지 못했습니다.",
      tipTwo: "새 시맨틱 태그 추가",
      notLessThan: "덜",
      notGreaterThan: "크지 않다.",
      ignoreLessThan: "덜 무시하십시오",
      wordUnitSet: "단어의 문장",
      speedWordNotNull:
        "음성 속도 검출에서 단어의 최소 수와 단어의 최대 수는 모두 비어있을 수 없습니다.",
      speedWordMinValueLessThanMaxValue:
        "음성 속도 검출에서 단어의 최소 수는 단어의 최대 수보다 클 수 없습니다.",
      speechGrabbingIntervalTip: "간격> =",
      speechLengthTip: "문장은 길다> =",
      msUnit: "ms",
      sUnit: "초",
      grabIntervalNotNull: "스내치 간격을 비울 수 없습니다.",
      grabLengthNotNull: "문장을 비울 수 없습니다.",
      pleaseSelectEntity: "정보 엔티티를 선택하십시오.",
      pleaseSelectOperator: "액션을 선택하십시오.",
      pleaseSelectBasicInfo: "기본 정보를 선택하십시오.",
      pleaseEnterFixedValue: "고정 값을 입력하십시오.",
      loudnessUnit: "db",
      percent: "%",
      matchSuccess: "성공적으로 일치",
      matchError: "경기 실패",
      mindetectionStatement: "최소 탐지 문",
      conceptWordholder: "개념 단어를 선택하십시오.",
      elementHolder: "요소를 입력하십시오.",
      associateMedol: { tabs: { data: "데이터", operator: "연산자" } },
      repet: "반복",
      bout: "타임즈",
      ignore: "덜 무시하십시오",
      ignoreAffix: "단어의 문장",
      associateData: "도로 후속 데이터",
      pretreatmentData: "데이터 전처리",
      reviewTaskData: "작업 데이터 검토",
      taskData: "품질 검사 작업 데이터",
      businessData: "사업 기록",
      functionOperator: "기능 연산자",
      operation: "조작 연산자",
      notAnyMatch: "문제가 발생하지 않을 때:",
      times: "발생 횟수",
      newAssociateDataTip: "값이 숫자 유형 인 경우 레거시 기능을 사용하십시오.",
    },
    conditionGather: {
      cycle: "사이클",
      rlue: "규칙",
      delete: "제거",
      sampling: "추출",
      item: "기사",
      percent: "%",
      samplingSame: "에이전트 당 동일한 수의 추출",
      samplingRecheck: "샘플링 된 항목의 반복 추출 허용",
      dateUnit: "아니.",
      samplingTip:
        "다른 규칙은 동일한 호출이 반복적으로 추출되지 않으며 추출되었지만 확인되지 않은 레코드는 다시 추출되지 않습니다.",
      add: "새로운 규칙",
    },
    ruleGather: {
      title: "규칙",
      screeningCondition: "필터 상태",
      addRule: "새로운 규칙",
      inspector: "품질 검사관",
      ruleTip:
        "규칙 우선 할당 모드는 위에서 아래로, 규칙 내에서, 할당을 반복하지 않거나 임의의 규칙 항목들이 랜덤하게 할당될 것이다.",
      moveUp: "위로 이동",
      moveDown: "아래로 이동",
      top: "탑",
    },
    pushRulesGather: {
      title: "규칙",
      delete: "제거",
      newPush: "새로운 푸시",
      newRule: "새로운 규칙",
      sampling: "추출",
      item: "기사",
      percent: "%",
      samplingSame: "에이전트 당 동일한 수의 추출",
      dateUnit: "아니.",
      screeningCondition: "필터 상태",
      email: "우편함",
      emailPlaceholder: "쉼표로 구분 된 사서함 입력",
      updateType: "업데이트 유형",
      pushDetail: "푸시 세부사항",
      pushUrl: "푸시 URL",
      pushWay: "푸시 유형",
      pushMethod: "푸시 모드",
      pushContentType: "데이터 형식",
      pushData: "푸시 데이터",
      additionalData: "추가 데이터",
      inspectionType: "품질 검사 유형",
      all: "모두",
    },
    taskAdditionalData: {
      newData: "새로운 데이터",
      delete: "제거",
      placeholder: "이름을 입력하십시오.",
      dataPlaceholder: "콘텐츠를 입력하세요",
    },
    UdeskQaReact: { loadSdkError: "SDK 로딩 실패, 나중에 다시 시도하십시오!" },
    gearOptionList: { evaluation: "평가" },
    tableComponentTransfer: { leftTitle: "항목 목록", rightTitle: "항목 표시" },
    kmTinymce: {
      link: "링크 삽입/편집",
      modalTitle: "삽입 링크",
      href: "주소",
      text: "텍스트 표시",
      title: "제목",
      target: "오픈 모드",
      none: "없음",
      newWindow: "새 창에서 열기",
      buttonStyle: "버튼 스타일",
      close: "닫기",
      open: "오픈",
      answerMessage: "점프/메시지 보내기",
      answerMessageTitle: "메시지 보내기 버튼",
      answerMessageText: "텍스트 표시",
      answerMessageContent: "메시지 내용",
      answerMessageButtonStyle: "버튼 스타일",
      answerMessageTips:
        "참고: 메시지 보내기 버튼은 웹 채널 만 지원합니다. 신중하게 구성하십시오.",
      answerSwitchStaff: "점프/회전 매뉴얼",
      answerSwitchStaffTitle: "수동 버튼 회전",
      answerSwitchStaffText: "텍스트 표시",
      answerSwitchStaffRule: "버튼 규칙",
      answerSwitchStaffButtonStyle: "버튼 스타일",
      answerSwitchStaffTips:
        "참고: 스위치 수동 버튼은 웹 채널 만 지원합니다. 신중하게 구성하십시오.",
      textButton: "텍스트 버튼",
      borderButton: "프레임 버튼",
      message: "메시지 보내기",
      switchStaff: "수동 회전",
      messageError: "버튼 내용은 10 단어를 초과 할 수 없습니다",
      messageIsEmptyError: "버튼 내용을 비울 수 없습니다.",
      okText: "결정",
      cancelText: "취소",
      video: "비디오",
      uploadVideo: "비디오 업로드",
      uploadText: "MP4 형식, 크기 20M 이하 만 지원",
      videoEmptyOrUploading: "비디오가 비어 있거나 업로드됩니다.",
      customEmoji: "표현식",
    },
    massageConfiguration: {
      pushTarget: "알림 대상",
      customizePushTarget: "맞춤 푸시 대상",
      sysUser: "현재 시스템 사용자",
      sysRole: "현재 시스템 역할",
      emailPushTarget: "목적지 보내기",
      targetEmailsPlaceholder: "쉼표로 구분 된 사서함을 입력하십시오.",
      subject: "메시지 이름",
      SDKID: "SDK ID",
      content: "알림 내용",
      sdkPushType: "푸시 계획",
      sdkPushURL: "푸시 URL",
      pushData: "푸시 데이터",
      sdkPushWay: "푸시 모드",
      dataType: "데이터 형식",
      additionalData: "추가 데이터",
      name: "메시지 이름",
      filter: "필터 상태",
      triggerType: "트리거 메커니즘",
      pushCycle: "타이밍 고정 주파수",
      pushContent: "보고서 내용",
      qualityList: "품질 검사 결과 목록",
      designated: "지정 인사",
      messageType: "메시지 유형",
    },
    taskCard: {
      template: { cancelCollection: "좋아하는 취소", collection: "컬렉션" },
    },
    semanticTagsSelect: { index: { standardSentence: "표준 문장:" } },
    gong: {
      filesOpen: {
        folderItem: {
          collection: "컬렉션",
          calls: "전화",
          createdBy: "만든:",
          createdOn: "창조 시간:",
        },
        fileItem: {
          uIProvidesIcon: "Ui 제공 아이콘",
          minute: "분",
          callClip: "콜 프래그먼트",
          start: "시작",
          End: "끝",
          employees: "직원:",
          Customer: "고객:",
          sessionTime: "세션 시간:",
          reasonForRecommendation: "추천 이유:",
          addedBy: "추가:",
          addTime: "시간 추가:",
        },
        modalAddFile: {
          caseTitle: "케이스 제목",
          addSomeClipsToTheCaseLibrary: "케이스 라이브러리에 부분 스 니펫 추가",
          addToCaseBase: "케이스 라이브러리에 추가",
          storedIn: "에 저장",
          pleaseSelect: "선택하십시오",
          reasonsForRecommendation: "추천 이유",
          pleaseEnter: "입장해주세요",
        },
      },
      shareModal: {
        index: {
          customer: "고객:",
          employees: "직원:",
          timeliness: "적시성:",
          recordingLinkValidWithinDays: "며칠 내에 유효한 녹음 링크",
          sharingInstructions: "공유 설명:",
          shareSuccess: "성공 공유!",
          cancel: "취소",
          share: "공유",
          copySucceeded: "성공적인 복사!",
          copyShareLink: "공유 링크 복사",
          shareWithColleagues: "동료와 공유",
          shareWithCustomers: "고객과 공유",
          staff: "직원",
          selectColleagues: "동료 선택",
          pleaseSelectColleaguesToShare: "공유 할 동료를 선택하십시오.",
          pleaseEnterTheSharingDescription: "공유 지침을 입력하십시오",
          taskName: "작업:",
          recordingShareValidWithinDays: "며칠 내에 유효한 공유 링크",
        },
      },
      waveAudio: {
        index: {
          theFileCannotBeFound: "파일을 찾을 수 없습니다!",
          sale: "판매:",
          customer: "고객:",
          topicDetection: "주제 탐지:",
          keyEvents: "주요 이벤트:",
        },
      },
      helpCommentModal: {
        index: {
          selectColleagues: "동료 선택",
          helpContent: "도움말 콘텐츠",
          visibleRange: "보이는 범위",
          seekCounseling: "상담 받기",
        },
      },
      commentDispalyItem: {
        index: { seekAdvice: "조언을 구하십시오", concatText: "에" },
      },
      customerInsight: {
        index: {
          customerInformation: "고객 정보",
          customerInsight: "고객 통찰력",
          customerLabel: "고객 라벨",
        },
      },
      intelligentRecommend: {
        index: {
          scriptRecommendation: "추천 단어",
          objectionHandlingScripts: "이의 제기 처리",
          productSellingPointScripts: "제품 판매 포인트",
          knowledgePointRecommendation: "지식 포인트 추천",
          relatedKnowledgePoints: "관련 지식 포인트",
          recommendedCases: "권장 사례",
        },
      },
      supervision: {
        index: {
          scriptRecommendation: "추천 단어",
          caseRecommendation: "사례 추천",
          hit: "히트",
          miss: "놓친",
          supervisionItem: "감독 항목",
          implementation: "구현 상태",
          reInspection: "재검사",
          supervisionItemSettings: "감독 항목 설정",
        },
      },
      supervisionClassificationModal: {
        index: {
          telephoneCall: "전화 통화",
          enterpriseWeChatCall: "엔터프라이즈 마이크로 토크",
          enterpriseMicroConversation: "엔터프라이즈 마이크로 세션",
          open: "오픈",
          close: "닫기",
          conversationScenario: "세션 시나리오",
          monitorConversationChannels: "대화 채널 감독",
          permanentlyValid: "영구적으로 효과적인",
          permanentlyInvalid: "영구적으로 무효",
          customValidity: "맞춤 유효 기간",
          termOfValidity: "유효 기간",
          newRating: "새로운 등급",
          whenConversation: "세션 때",
          hit: "히트",
          miss: "놓친",
          time: "언제",
          rated: "등급은",
          qualified: "자격",
          unqualified: "무자격",
          modifiedSuccessfully: "수정 성공",
          classificationName: "범주 이름",
          supervisionObject: "감독의 대상",
          entryintoforceTime: "효과적인 시간",
          supervisionItemRating: "감독 항목 등급",
          pleaseCheckWhetherTheRatingOfSupervisionItemIsCorrect:
            "감독 등급이 올바른지 확인하십시오.",
          ratingRules: "등급 규칙",
          settingOfRatingRulesForSupervisionItems:
            "모니터링 항목 등급 규칙 설정",
        },
      },
      reInspect: {
        index: {
          keyEvents: "주요 이벤트",
          supervisionItem: "감독 항목",
          conversationScenario: "세션 시나리오",
          customerLabel: "고객 라벨",
          reDetectionRules: "규칙 재감지",
        },
      },
      audioPlayerBar: {
        index: {
          downloadRecording: "녹음 다운로드",
          downloadText: "텍스트 다운로드",
        },
      },
    },
    imgUpload: {
      index: {
        uploadPictures: "이미지 업로드",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "크기가 2048kb 를 초과하지 않는 사진을 선택하십시오.",
      },
    },
    chatRecord: {
      chatRecordItem: {
        components: {
          debugInfo: {
            or: "또는",
            and: "과",
            wrong: "비",
            matchTo: "경기",
            rule: "규칙",
            satisfactionOperator: "연산자 만족",
            customerLabel: "고객 라벨",
            keyEvents: "주요 이벤트",
            knowledgePoints: "지식 포인트",
            sessionLabel: "세션 라벨",
            supervisionItem: "감독 항목",
          },
          linkExplorer: { linkInTheText: "기사의 링크:" },
        },
      },
    },
    createForm: {
      field: {
        customComponentNotFound: "사용자 지정 구성 요소를 찾을 수 없습니다:",
      },
    },
    sdkLoaderBase: {
      component: { custom: "맞춤" },
      dropdownMonit: {
        updateSucceeded: "업데이트 성공",
        areYouSureToDelete: "삭제 하시겠습니까?",
        custom: "맞춤",
      },
      modalMonit: {
        saveCurrentReportConfigurationForm: "현재 보고서 구성 === 양식 저장",
        saveCurrentReportConfiguration: "현재 보고서 구성 저장",
        pleaseEnterTheNameOfCommonSettingsTheMaximumLengthIs:
          "공통 설정 이름을 입력하십시오. 최대 길이는 20 입니다!",
        preservation: "저장",
      },
      template: {
        saveCurrentReportConfiguration: "현재 보고서 구성 저장",
        updateCurrentReportConfiguration: "현재 보고서 구성 업데이트",
      },
    },
    audioCallBasicInfoV2: {
      template: {
        workOrderInformation: "작업 순서 정보",
        workOrder: "작업 순서",
        triggerScenario: "트리거 시나리오",
        associatedCallConversation: "준 전화/대화",
      },
    },
    audioCallContentInfoV2: {
      components: {
        testBtn: {
          testWithCurrentCommunicationDialogueText:
            "현재 패스/대화 텍스트로 테스트",
        },
      },
    },
    checkPointOperatorListGather: {
      template: { section: "아니.", day: "날" },
      businessSummary: {
        normal: {
          equalTo: "동등한",
          notEqualTo: "동일하지 않음",
          contain: "포함",
          excluding: "포함되지 않습니다",
        },
      },
    },
    massagePushConfiguration: {
      component: {
        iAmMeItsADifferentFireworksxxxx8827:
          "나는 내가 누구인지, 다른 불꽃 @ 156xxxx8827",
        hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
          "안녕하세요, 10 월 17 일에 그룹에 추가 된 로봇 품질 검사 경고 메시지의 알림입니다.",
      },
      interfacePush: {
        component: {
          iAmMeItsADifferentFireworksxxxx8827:
            "나는 내가 누구인지, 다른 불꽃 @ 156xxxx8827",
          hiThisIsTheNotificationOfRobotQualityInspectionWarningMessageSinceItWasAddedToTheGroupOnOctober:
            "안녕하세요, 10 월 17 일에 그룹에 추가 된 로봇 품질 검사 경고 메시지의 알림입니다.",
        },
      },
      associateSelect: {
        const: {
          communicationTheme: "커뮤니케이션 테마",
          conversationSummary: "세션 요약",
          serviceSuggestions: "서비스 권장 사항",
        },
        template: { smartTags: "스마트 라벨" },
      },
    },
    productMatrix: {
      index: { clickToLearnMore: "자세한 내용을 보려면 클릭하십시오." },
      schema: {
        textRobot: "텍스트 로봇",
        trainerEscortDocumentQATaskDialogueTableQAAtlasQA:
          "트레이너 에스코트, 문서 질문 및 답변, 작업 기반 대화, 테이블 질문 및 답변, 그래프 질문 및 답변.",
        capitalRetentionRobot: "유지 로봇",
        richInquiryAndRetentionStrategiesHighPersonificationCommunicationEnsuringHighRetentionRateAndEfficientAutomaticConversionOfClues:
          "풍부한 후속 유지 전략, 높은 인격화 의사 소통, 높은 유지율, 단서 효율적인 자동 변환.",
        outboundRobot: "아웃 바운드 콜 로봇",
        workOrderCompletionReturnVisitServiceSatisfactionSurveyOperationAndMaintenanceFaultWarningPromptOrderDeliveryReminderEcommerceActivityNoticeCandidateAppointmentAndHelpBusinessAutomationAndBusinessEfficiencyImprovement:
          "작업 주문 완료 반환 방문, 서비스 만족도 조사, 운영 및 유지 보수 실패 경고, 주문 배달 알림, 전자 상거래 활동 알림, 후보 임명, 비즈니스 자동화 및 비즈니스 효율성 개선 지원",
        incomingRobot: "들어오는 로봇",
        flexiblyIntegrateWorkOrdersOrdersAndOtherBusinessSystemsToRealizeProcessAutomationCustomizedRecognitionOfSpeechSemanticModelAndTheBusinessEffectIsImmediateEntitymobilePhoneNumberOrderAmountOrderTimeAddressIdentificationToQuicklySupportComplexBusiness:
          "프로세스 자동화를 달성하기위한 작업 주문, 주문 및 기타 비즈니스 시스템의 유연한 통합; 음성 시맨틱 모델 맞춤형 인식, 비즈니스 결과 즉시; 엔티티 (휴대 전화 번호, 주문 금액, 주문 시간, 주소 등) 식별, 복잡한 비즈니스에 대한 빠른 지원",
        intelligentQualityInspection: "지능형 품질 검사",
        fullQualityInspectionTimelyAndEfficientWithAnAccuracyRateOf:
          "전체 품질 검사, 시기 적절하고 효율적인, 정확도 비율 85%",
        intelligentSessionAnalysis: "지능형 세션 분석",
        insightIntoBusinessWeaknessesListenToTheVoiceOfCustomersAndImproveMarketingTransformation:
          "짧은 비즈니스 보드에 대한 통찰력, 고객의 목소리를 듣고 마케팅 변화를 향상시킵니다.",
        enterpriseWechatConversationAnalysis: "엔터프라이즈 위챗 세션 분석",
        effectivelyIdentifyPotentialBusinessOpportunitiesAndRisksAndIncreaseTheMarketingConversionRateBy:
          "잠재적인 사업 기회 · 리스크 · 마케팅 전환율 효율적 규명 20% 증가",
        assistant: "좌석 조수",
        theAgentAssistantCanGuaranteeTheNoviceCustomerServiceToCompleteTheServiceAndImproveTheCallQualityOfTheSkilledCustomerServiceLetEverySeatBeYourTrumpCard:
          "에이전트 어시스턴트는 초보 고객 서비스의 서비스를 보장하고 숙련 된 고객 서비스의 통화 품질을 향상시킬 수 있습니다. 모든 좌석을 트럼프 카드로 만드십시오.",
        digitalPeople: "디지털 맨",
        FaceToFaceAnthropomorphicCommunicationSupportVoiceTextVideoTouchMultimodalInteractionAchieveImmersiveCommunicationExperienceAndImproveBrandImageAndUserServiceExperience:
          '"대면" 의인화 된 의사 소통, 음성, 텍스트, 비디오, 터치 멀티 모달 상호 작용 지원, 몰입 형 커뮤니케이션 경험 달성, 브랜드 이미지 및 사용자 서비스 경험 향상',
        aIKnowledgeZhongtaiEnterpriseSearch: "AI 지식 중간 단계 기업 검색",
        knowledgeSearchKnowledgeSharingDocumentSearchDatabaseDockingSearch:
          "지식 검색, 지식 공유, 문서 검색, 데이터베이스 도킹 검색",
        aIKnowledgeCenterKCsKnowledgeBase:
          "AI 지식 중간 데스크 · KCS 지식 기지",
        provideFullLifecycleManagementOfKnowledgeUnifiedMaintenanceOfRobotAndImKnowledgeBaseSupportDockingWithThirdpartyKnowledgeBaseKnowledgeTemplateAtomicKnowledgeAndOtherPowerfulFunctionsToBringMoreConvenientKnowledgeManagementExperience:
          "지식의 전체 수명주기 관리, 로봇 및 IM 지식 기반의 통합 유지 관리를 제공하고, 타사 지식 기반, 지식 템플릿, 원자 지식 및 기타 강력한 기능으로 도킹을 지원하여보다 편리한 지식 관리 경험을 제공합니다.",
        aIKnowledgeCenterKnowledgeMap: "AI 지식 플랫폼-지식지도",
        oneStopMapConstructionIntegratesEnterpriseKnowledgeLargescaleMapShowsKnowledgeDetailsDockingAbilityAndCustomizedExperience:
          "원 스톱 매핑 구성은 기업 지식, 큰 그림은 지식 세부 정보, 도킹 기능을 통합하여 맞춤형 경험을 만듭니다.",
        modularDevelopmentPlatformOriginalEngineASR:
          "모듈 형 개발 플랫폼-원래 심장 엔진-ASR",
        theEndtoendAlgorithmIsAdoptedToRealizeTheEffectExperienceOfBusinessCustomizationTheNaturalSceneRecognitionRateExceedsAndThePersonalizedVocabularyRecognitionRateExceeds97:
          "엔드 투 엔드 알고리즘을 채택하여 자연스러운 장면 인식률이 90% 를 초과하고 개인화 된 어휘 인식이 97% 를 초과하는 맞춤형 서비스 효과 경험을 실현하십시오.",
        modularDevelopmentPlatformIntelligentMarketing:
          "모듈 개발 플랫폼 · 지능형 마케팅",
        makeUseOfDeepLearningAlgorithmsAndUserPortraitsToAchieveAccurateRecommendationOfGoodsAndImproveSalesConversion:
          "사용자 초상화와 결합 된 딥 러닝 알고리즘을 사용하여 정확한 제품 권장 사항을 달성하고 판매 전환을 향상시킵니다.",
        modularDevelopmentPlatformUserPortrait:
          "모듈 개발 플랫폼 · 사용자 초상화",
        accuratelyBuildUserPortraitsBasedOnUserDataAndConversationDataToFullyDescribeCustomerCharacteristics:
          "사용자 데이터 및 대화 데이터를 기반으로 사용자 프로필을 정확하게 구축하여 고객 특성을 완전히 설명합니다.",
        modularDevelopmentPlatformIntelligentRecommendation:
          "모듈 형 개발 플랫폼 · 지능형 추천",
        automaticallyRecommendProductsThatUsersMayBeInterestedInAccordingToUserPortraitsAndProductPortraitsAndRealizePersonalizedRecommendationForThousandsOfPeople:
          "사용자 초상화 및 제품 초상화에 따르면 사용자가 관심을 가질 수있는 제품을 자동으로 추천 수천 명의 사람들에게 개인화 된 추천을 실현합니다.",
        modularDevelopmentPlatformNLPPAAS: "모듈 개발 플랫폼 · NLP PaaS",
        provideNLPBasicCapabilitiesWithHighAccuracyCoveringTheUnderlyingCapabilitiesOfDifferentGranularitySuchAsWordsSentencesAndArticles:
          "단어, 문장 및 기사와 같은 다양한 세분성의 낮은 수준의 기능을 다루는 높은 정확도로 기본 NLP 기능을 제공합니다.",
      },
    },
    qualityScore: {
      index: {
        show: "쇼:",
        displayBySecondaryClassification: "2 차 분류로 표시",
        showByRule: "규칙에 따라 표시",
        pleaseEnterClassificationruleName: "분류/규칙 이름을 입력하십시오.",
        clickCopy: "복사 클릭",
        hitDetailsOfOperatorsInRules: "운영자는 규칙의 세부 사항을 명중합니다.",
        hit: "히트",
        miss: "놓친",
        filter: "필터:",
        clickToViewDetails: "세부 정보를 보려면 클릭하십시오.",
      },
      components: {
        flowNodeList: {
          branch: "포인트",
          pleaseSelect: "선택하십시오",
          viewTheCompleteProcess: "전체 프로세스 보기",
          thereIsCurrentlyNoProcessAvailable: "프로세스 없음",
        },
        pointItem: { xiangHit: "아이템 히트" },
        flowNodeListModal: { index: { completeProcess: "완전한 프로세스" } },
        reviewComponent: {
          theProcessQualityInspectionTaskHasChangedPleaseAppealAgain:
            "프로세스 품질 검사 작업이 변경되었습니다, 다시 항소하십시오.",
        },
      },
    },
    recordDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "이 데이터는 주기적으로 삭제되었습니다.",
        subject: "제목:",
        description: "설명:",
      },
      components: {
        addToTask: {
          index: { addToQualityInspectionTask: "품질 작업에 추가" },
        },
      },
    },
    relatedDetail: { index: { associatedCallConversation: "준 전화/대화" } },
    samplingTarget: {
      index: {
        time: "언제",
        samplingQuantityIsLessThan: "샘플링 수량은 더 낮습니다",
        alertWhen: "조기 경고",
        everyday: "매일",
        weekly: "주간",
        monthly: "월간",
        samplingInspectionTargetSavedSuccessfully:
          "샘플링 검사 대상 성공적으로 저장",
        personnel: "인사",
        eachCustomerService: "각 고객 서비스",
        cycle: "사이클",
        samplingInspectionTarget: "샘플링 검사 대상",
        strip: "기사",
        alertSettings: "경고 설정",
      },
    },
    samplingTargetBanner: {
      index: {
        customerService: "고객 서비스",
        complete: "완료",
        Objective: "/대상",
        everyday: "매일",
        weekly: "주간",
        monthly: "월간",
      },
    },
    sessionInsights: {
      components: {
        groupChatInteractionRate: "그룹 채팅 상호 작용 속도",
        maximumResponseIntervalTime: "응답 사이의 최대 간격",
        numberOfPreemptiveCalls: "스내치 수",
        numberOfMessages: "메시지 수",
        strip: "기사",
        proportionOfMessages: "메시지 수 비율",
        speechPercentage: "말하기 비율",
        longestCustomerTalk: "가장 긴 고객 말하기",
        numberOfIndepthDiscussions: "심층 토론 횟수",
        second: "타임즈",
        maximumDurationOfContinuousSpeech: "최대 연속 말하기 기간",
        questionFrequency: "질문 빈도",
        customer: "고객",
        keyEvents: "주요 이벤트:",
        opportunity: {
          index: {
            generationFailed: "빌드 실패",
            generateOpportunityPoints: "기회 포인트 생성",
          },
        },
        sessionSummary: {
          index: {
            generationFailed: "빌드 실패",
            generateSummary: "요약 생성",
          },
        },
      },
      index: {
        areYouSureToDeleteIt: "삭제 하시겠습니까?",
        add: "추가",
        opportunityPoints: "기회 포인트",
        conversationSummary: "세션 요약",
        cancel: "취소",
        determine: "결정",
        edit: "편집",
        establish: "만들기",
        conversationalInsights: "세션 통찰력",
        keyEvents: "주요 이벤트",
        numKeyEvents: "주요 이벤트",
        questionAnalysis: "질문 분석",
        find: "발견",
        questions: "두 번째 질문",
        conversationAction: "세션 동작",
        customer: "고객",
        sale: "판매",
        sessionLabel: "세션 시나리오",
        employeeQuestions: "직원 질문",
        customerQuestions: "고객 질문",
      },
    },
    ticketTextInfo: {
      index: {
        customer: "고객",
        customerService: "고객 서비스",
        replyTo: "답장",
        addressee: "To:",
      },
    },
    listManage: {
      template: {
        reIdentification: "재 식별",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "재 식별은 전송 시간을 소비하므로 조심해서 운영하십시오.",
        determine: "결정",
        executionSuccessful: "성공적인 실행",
        addToQualityInspectionTask: "품질 작업에 추가",
        batchOperation: "배치 작업",
      },
    },
    addToTaskModal: {
      index: {
        successfullyAdded: "성공적으로 추가",
        addToQualityInspectionTask: "품질 작업에 추가",
        qualityInspectionTask: "품질 검사 작업",
      },
    },
    customFilterModal: {
      component: {
        detectRoles: "역할 감지",
        detectionRange: "탐지 범위",
        sentence: "문장",
      },
    },
    inspectionDetail: {
      template: {
        theDataHasBeenClearedPeriodically:
          "이 데이터는 주기적으로 삭제되었습니다.",
      },
    },
    backToSubSystem: {
      index: { returnToBusinessSystem: "비즈니스 시스템으로 돌아 가기" },
    },
    qualityCheckScoreTalk: {
      template: {
        problemExtraction: "문제 추출",
        businessSummary: "비즈니스 요약",
        keyWord: "키워드",
      },
      components: {
        problemExtraction: {
          no: "아니",
          customerServiceDidNotAnswer: "고객 서비스가 응답하지 않음",
          customerServiceHasAnswered: "고객 서비스가 응답했습니다.",
          problemUnresolved: "해결되지 않은 문제",
          theProblemHasBeenResolved: "문제 해결",
          customerIssue: "고객 문제:",
          customerServiceAnswer: "고객 서비스 답변:",
          reExtractQuestions: "재 추출 문제",
          clickToExtractQuestions: "질문을 추출하려면 클릭하십시오.",
        },
      },
    },
    coach: {
      customerPortrait: {
        detail: {
          index: {
            portraitName: "초상화 이름",
            backgroundDescription: "배경 설명",
            customerLabel: "고객 라벨",
            labelManagement: "라벨 관리",
          },
        },
        list: {
          components: {
            addCustomerPortraitItemBtn: { index: { newlyBuild: "새로운" } },
          },
        },
      },
      detailedInquiryConfig: {
        components: {
          detailedInquiryConfigTitle: {
            index: { questioningSettings: "질문 설정" },
          },
          detailedInquiryList: {
            index: {
              increaseQuestioning: "질문 증가",
              remove: "제거",
              addQuestioning: "질문 추가",
              section: "아니.",
              secondInquiry: "두 번째 질문",
              questioningScore: "질문 점수",
            },
          },
        },
        index: {
          accordingToRules: "규칙에 따라",
          byScore: "점수로",
          whenMissRule: "규칙을 놓친 경우",
          whenConductingQuestioning: "질문 할 때",
          pleaseSelectARule: "규칙을 선택하십시오.",
          ruleCannotBeEmpty: "규칙을 비울 수 없습니다.",
          currentScriptScore: "연설 점수 ≤ 때",
          pleaseEnterTheScore: "점수를 입력하십시오",
          scoreCannotBeEmpty: "점수는 비어있을 수 없습니다",
        },
      },
      dialogue: {
        components: {
          narrator: { narrator: "내레이터:" },
          pPT: { slide: "슬라이드" },
          realTimeAnalyzer: {
            index: {
              scriptReview: "댓글",
              script: "단어",
              semantics: "의미",
              violatingWords: "단어 위반",
              speechSpeed: "연설의 속도",
              emotion: "감정",
            },
          },
          scoreDetail: {
            index: {
              scriptReview: "댓글",
              regenerate: "재생",
              generate: "생성",
              hitSituation: "히트 케이스",
              noSemanticTagsMatched: "의미 레이블과 일치하지 않음",
              matchToSemanticTags: "시맨틱 레이블과 일치",
              appealToBeSubmitted: "제출해야 할 불만",
              ratingHasBeenReviewed: "평가 검토",
              ratingAppealInProgress: "점수 고충",
              expressionScore: "표현 점수",
              moodParticlesTimes: "단어 톤, {0} 번",
              changeTheModalParticlesTo: "모달 단어 용",
              second: "타임즈",
              interrogation: "질문",
              questioningWithoutScoring: "점수없이 묻다",
              branch: "포인트",
              matchingRate: "매칭 비율",
              ratingAppeal: "평가 고충",
              changeTo: "변경",
              wordPerMinute: "분당 단어",
              completeScript: "완전한 연설",
              incompleteScript: "불완전한 연설",
              semanticHit: "의미 적 히트",
              semanticMisses: "시맨틱 미스",
              scriptViolation: "언어 위반",
              modified: "수정",
              notChanged: "수정되지 않음",
              keyWord: "키워드",
              semanticLabels: "시맨틱 라벨",
              semanticMatchingRate: "의미 일치 속도",
              violatingWords: '단어 위반"',
              delete: "삭제",
              unmatched: "일치하지 않음",
              matching: "경기",
              matchTo: "경기",
              notMatchedTo: "일치하지 않음",
              scriptScore: "음성 점수",
              standardScript: "표준 연설",
              emotionalScore: "감성 점수",
              speechSpeedScore: "속도 점수",
              viewResolution: "해상도 보기",
              nodeConfiguration: "노드 구성",
            },
          },
          userNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "이 질문의 답변 시간은 초과 근무이며 답변 내용이 자동으로 제출됩니다.",
          },
          knowledgeNode: {
            theAnswerTimeForThisQuestionHasExceededAndTheAnswerContentWillBeAutomaticallySubmitted:
              "이 질문의 답변 시간은 초과 근무이며 답변 내용이 자동으로 제출됩니다.",
          },
          correctBtn: {
            saveSuccessful: "성공적으로 저장",
            errorCorrection: "오류 수정",
            cancel: "취소",
            preserve: "저장",
          },
        },
      },
      scoringRules: {
        components: {
          drawerTitle: {
            scoringRules: "점수 규칙",
            cancel: "취소",
            determine: "결정",
          },
          scoringRuleItem: {
            whole: "모두",
            arbitrarily: "임의",
            pleaseEnterARuleName: "규칙 이름을 입력하십시오.",
            standardScript: "표준 연설",
            if: "If",
            and: "과",
            or: "또는",
            thenScore: "다음 점수",
            pleaseEnterAScore: "점수를 입력하십시오",
            bonusPoints: "보너스 포인트",
            minusPoints: "마이너스 포인트",
            hit: "히트",
            misses: "놓친",
            keyWord: "키워드",
            semantics: "의미",
          },
          title: {
            ruleValidation: "규칙 확인",
            scoringRulestheTotalScoreOfTheCurrentScriptIs:
              "채점 규칙 (현재 연설의 총 점수는",
            points: "포인트)",
            editRules: "규칙 편집",
          },
        },
      },
      userTagsSelect: { index: { noLabelsCurrentlyAvailable: "라벨 없음" } },
      aiGenDialogFrame: {
        components: {
          description: {
            index: {
              communicationObjectives: "통신 목표",
              scoringCriteria: "채점 기준",
            },
          },
        },
        index: {
          generating: "생성",
          generateDialogueFramework: "대화 프레임 워크 생성",
          theme: "테마",
        },
      },
      aiGenPrompt: {
        components: {
          title: {
            hintLanguage: "프롬프트",
            generatePromptLanguage: "프롬프트 생성",
          },
        },
      },
      aiGenScore: {
        index: {
          communicationStage: "통신 단계",
          communicationObjectives: "통신 목표",
          scoringCriteria: "채점 기준",
          suggestionsForImprovement: "프로모션 권장 사항",
        },
      },
      score: {
        overview: {
          index: {
            processScore: "프로세스 점수",
            contentIntegrity: "콘텐츠 무결성:",
            NumberOfCompletedProcesses: "%, 완료된 프로세스의 수",
            keyHitPointsTotal: "주요 안타: 총",
            notReached: "도달하지 않음",
            accurateFollowupAccumulationOfStandardScripts:
              "정확한 후속 조치: 표준 음성 축적",
            theTotalNumberOfWordsHitByTheStudentAfterReading:
              "단어, 학생이 치는 단어의 수",
            aWord: "단어",
            languageNormsYes: "언어 사양: 예",
            emotionsAppearing: "감정: 출연",
            speechSpeedSpeakingTooFast: "속도: 너무 빠른 속도",
            modalParticlesExcessiveOccurrenceOfModalParticles:
              "톤 단어: 과도한 톤 단어 발생",
          },
        },
      },
    },
    smartTags: {
      template: {
        addToIgnoreTags: "태그 무시 에 추가",
        areYouSureYouWantToReExecuteTheSmartLabel:
          "스마트 라벨을 다시 실행하고 싶습니까?",
        reExecute: "재실행",
      },
      component: { successfullyAdded: "성공적으로 추가" },
    },
    reInspectionResultModal: {
      index: {
        turnOffAllNotifications: "모든 알림 끄기",
        reQualityInspectionTask: "재검사 작업",
        requalityInspection: "재검사",
        waiting: "대기 중",
        viewDetails: "세부 정보 보기",
      },
    },
    checkPointConditionListGather: {
      checkPointCondition: {
        components: {
          applyTimeType: { index: { detectionTime: "탐지 시간:" } },
        },
        template: { responseOpeningSentence: "응답 시작 문장" },
      },
    },
    businessSummary: {
      components: {
        fieldItem: {
          index: { generationFailed: "빌드 실패", generate: "생성" },
        },
        empty: { index: { thereIsCurrentlyNoDataAvailable: "데이터 없음" } },
      },
      index: { regenerate: "재생" },
    },
    applyTemplates: {
      component: {
        areYouSureToDeleteIt: "삭제 하시겠습니까?",
        operationSuccessful: "작전 성공",
      },
      template: { delete: "삭제" },
    },
    similarQuestionRecommend: {
      index: {
        pleaseSelectAQuestion: "질문을 선택하십시오!",
        similarQuestionRecommendation: "유사한 권장 사항",
        selectAllOnPage: "모든 페이지를 선택할 때",
        cancel: "취소",
        preserve: "저장",
        changeBatch: "배치 변경",
      },
    },
    listControl: { index: { columnControl: "열 제어" } },
    loginUserAvatar: { index: { switch: "스위치" } },
    autoAddComponent: {
      index: {
        whenAddingDataInRealTimeTheQualityInspectionTaskStillTakesTimeToExecuteTheSpecificTimeDependsOnTheSizeOfTheQualityInspectionDataRangingFromAFewMinutesToHours:
          "실시간으로 데이터를 추가 할 때 품질 검사 작업은 여전히 실행하는 데 시간이 걸립니다. 특정 시간은 몇 분에서 2 시간까지 품질 검사 데이터의 크기에 따라 다릅니다.",
      },
    },
    bEditor: {
      index: {
        delete: "삭제",
        cancel: "취소",
        confirm: "OK",
        insert: "삽입",
        width: "폭",
        height: "높이",
        clearContent: "명확한 내용",
        revoked: "개정",
        redo: "레도",
        fontSize: "글꼴 크기",
        rowHeight: "행 높이",
        wordSpacing: "단어 간격",
        paragraphIndent: "문단 들여 쓰기",
        increaseIndent: "인덴트 증가",
        decreaseIndent: "Indent를 감소",
        border: "국경",
        color: "색상",
        textColor: "텍스트 색상",
        backgroundColor: "배경 색상",
        temporaryColor: "임시 색상",
        bold: "대담한",
        italic: "이탤릭",
        underline: "밑줄",
        strikethrough: "스트라이크 스루",
        font: "글꼴",
        textAlignment: "텍스트 정렬",
        left: "왼쪽",
        centered: "중심",
        right: "오른쪽",
        bothEnds: "양쪽 끝",
        leftFloat: "왼쪽 플로트",
        rightFloat: "오른쪽 플로트",
        subrupt: "수브 뤼프",
        subscript: "하위 스크립트",
        clearStyle: "클리어 스타일",
        title: "제목",
        regular: "정사이즈",
        orderedList: "주문 목록",
        unorderedList: "무질서 목록",
        reference: "참조",
        code: "코드",
        link: "링크",
        clearLink: "지우기 링크",
        horizontalLine: "수평선",
        media: "미디어",
        mediaLibrary: "미디어 라이브러리",
        smallExpression: "작은 표현",
        fullScreen: "전체 화면",
        exitFullScreen: "전체 화면 종료",
        enterLinkText: "링크 텍스트 입력",
        enterLinkAddress: "링크 주소 입력",
        enterTheLinkAddressAndPressEnter:
          "링크 주소를 입력하고 Enter 키를 누릅니다.",
        openInNewWindow: "새 창에서 열기",
        removeLink: "링크 제거",
        playingAudioFiles: "오디오 파일 재생",
        playVideoFiles: "비디오 파일 재생",
        embeddedMedia: "임베디드 미디어",
        image: "이미지",
        video: "비디오",
        audio: "오디오",
      },
    },
  },
  fields: {
    qualityInspectionForm: {
      list: {
        name: "이름",
        description: "설명",
        type: "유형",
        status: "상태",
        actions: "운영",
      },
    },
    qualityCheckingTask: {
      index: {
        name: "작업 이름",
        description: "설명",
        form: "품질 검사 양식",
        actions: "운영",
        createUser: "창조주",
      },
      samplingRecords: {
        createTime: "시간",
        type: "유형",
        operateUser: "연산자",
        number: "수량 추가",
        filterConditionList: "필터 상태",
        sampleRuleStr: "샘플링 조건",
        timeGreaterThan: "시간이 더 큽니다.",
        timeLessThan: "적은 시간",
      },
    },
    customFilters: {
      createdAt: "대화 시간",
      customerId: "고객",
      agentId: "고객 서비스",
      userGroupId: "고객 서비스 그룹",
      state: "상태",
      lastEvaluateUserId: "마지막 래터",
      submitTime: "마지막 제출 시간",
      totalScore: "총 점수",
      formItemForbidIdList: "금지 된 항목",
      formItemDeadlyIdList: "치명적인 항목",
    },
    samplingInspection: {
      callTime: "대화 시간",
      dialogTime: "대화 시간",
      originator: "배정 된 사람",
      createTime: "창조 시간",
      updateTime: "업데이트 시간",
      status: "프로세스 상태",
      score: "분수",
      actions: "운영",
      reviewer: "리뷰어",
      samplingOfficer: "샘플링 검사관",
      id: "통화 로그 ID",
      time: "대화 시간",
      phone: "고객 전화",
      staff: "고객 서비스",
      inspectionStatus: "품질 검사 상태",
      samplingStatus: "샘플링 상태",
      inspectionGrade: "등급",
    },
    smartWordsLibrary: {
      synonymWords: {
        standardWords: "표준 단어",
        synonymWords: "동의어",
        synonymWordsNumber: "동의어의 수",
        useFrequency: "사용 빈도",
        effectiveness: "효과",
        operation: "운영",
      },
      common: {
        useFrequency: "사용 빈도",
        effectiveness: "효과",
        operation: "운영",
      },
      professionalWords: {
        professionalWords: "전문 명사",
        useFrequency: "통계 사용",
        homophonic: "뜨거운 단어 (호모폰 대체)",
        target: "명사 대체",
        targetWords: "단어 대체",
        homophonyWords: "호모폰 마스킹 단어",
        operation: "운영",
      },
      attentionWords: "우려 단어",
      ignoreWords: "단어 무시",
      wordsWeight: {
        selfDefineWeightWords: "사용자 정의 무게 단어",
        weightWords: "무게 단어",
        weight: "무게",
        useFrequency: "사용 빈도",
        effectiveness: "효과",
        operation: "운영",
      },
      corpusData: {
        corpusDescription: "코퍼스 설명",
        updateTime: "업데이트 시간",
        wordsCount: "단어의 수",
        effectiveness: "효과",
        operation: "운영",
        corpusContent: "콘텐츠",
      },
    },
    semanticTags: {
      title: "시맨틱 라벨",
      tags: "표준 문장",
      orgName: "조직",
      tagCount: "훈련의 수",
      usingCount: "참조 수",
      activeFlag: "효과",
      suggestion: "교육 권장 사항",
      actions: "운영",
    },
    intelligentTags: {
      tagName: "이름",
      tagDesc: "설명",
      tagRefCnt: "마크 수",
      categoryName: "라벨 분류",
      eventName: "이벤트 분류",
      tagStatus: "효과",
      actions: "운영",
      tagType: "라벨 유형",
    },
    dataPreprocess: {
      name: "이름",
      desc: "설명",
      dataSource: "데이터 소스 한계",
      type: "유형",
      status: "효과",
      actions: "운영",
    },
    informationEntities: {
      name: "이름",
      apiName: "API 이름",
      type: "유형",
      remark: "설명",
      activeFlag: "효과",
      actions: "운영",
    },
    interactiveRecognition: {
      questionAndAnswer: "표준 질문 및 답변",
      type: "유형",
      status: "상태",
      actions: "운영",
      question: "질문",
    },
    role: { id: "ID", name: "이름", actions: "운영" },
    uploadDownload: {
      taskName: "파일 이름",
      createTime: "날짜",
      size: "크기",
      createUserName: "연산자",
      status: "상태",
      result: "결과",
      actions: "운영",
    },
    taskCenterList: {
      name: "작업 내용",
      createTime: "제출 시간",
      startTime: "시작 시간",
      endTime: "종료 시간",
      createUserName: "연산자",
      status: "상태",
      result: "결과",
      actions: "운영",
    },
    customerInformation: {
      title: "고객 서비스 정보",
      data: "예외 데이터만 보기",
      list: {
        agentNo: "작업 번호/고객 서비스 ID",
        agentName: "고객 서비스",
        agentTel: "전화",
        agentEmail: "우편함",
        updateTime: "업데이트 시간",
        actions: "운영",
      },
      upload: {
        second:
          "2. 가져오기 버튼을 클릭하여 템플릿 형식을 준수하는 EXCEl 파일을 업로드합니다.",
      },
    },
    IndexManagement: {
      title: "지표 관리",
      list: {
        indicatorName: "이름",
        indicatorDesc: "설명",
        activity: "효과",
        inspectDataSource: "데이터 소스",
        sysType: "유형",
        agentGroups: "고객 서비스 그룹",
        actions: "운영",
      },
      detail: {
        nameEmpty: "이름을 비울 수 없습니다.",
        descript: "설명",
        descriptEmpty: "설명을 비울 수 없습니다.",
        active: "효과",
        activeEmpty: "유효성은 비어있을 수 없습니다",
        dataSource: "데이터 소스",
        dataSourceEmpty: "데이터 소스를 비울 수 없습니다.",
        grounp: "고객 서비스 그룹",
        grounpEmpty: "에이전트 그룹을 비울 수 없습니다.",
        grounpHolder: "에이전트 그룹을 선택하십시오.",
        type: "채점 방법",
        typeNone: "채점 방법은 비어있을 수 없습니다.",
        base: "재단 포인트",
        baseNone: "기본 점수는 비울 수 없습니다.",
        positive: "긍정적 인 요인",
        positiveNone: "긍정적 인 요소는 비어있을 수 없습니다",
        customAdd: "필터 추가",
        negative: "부정적인 요인",
        negativeNone: "부정적인 요소는 비어있을 수 없습니다",
        customOK: "필터 기준:",
        judgeStrategy: "조건 규칙:",
        other: "기타 요인",
        otherAdd: "팩터 추가",
        agent: "원시 데이터",
        section: "간격 수",
        sectionNone: "간격 수를 비울 수 없습니다.",
        step: "간격 단계 크기",
        stepNone: "간격 단계 크기",
        save: "저장",
        cancel: "취소",
      },
    },
  },
  enums: {
    gongRolesTypes: {
      all: "모두",
      staff: "나 자신 만",
      department: "부서 및 아래",
    },
    gongStatisticsDate: { week: "주", month: "달", season: "시즌" },
    gongCoachTaskStatus: { wait: "멘토링", already: "코칭", ignore: "무시" },
    callRangeStatus: {
      all: "모든 통화",
      sale: "직원 시작",
      customer: "고객 시작",
    },
    todoListType: {
      later: "나중에 들어라.",
      help: "도움 상담",
      contact: "나중에 연락",
      send: "데이터 보내기",
      warn: "위험 조기 경고",
    },
    frequencyTypes: { everyDay: "매일", everyWeek: "주간", everyMonth: "월간" },
    classifiedTraing: {
      trainTypeList: {
        noneDataTrain: "분류되지 않은 데이터 교육",
        markedDataCorrigenda: "라벨링된 데이터 Errata",
        classifiedDataCheck: "분류된 데이터 검증",
        markedHistoryRecord: "콜아웃 역사",
        historyDataRecalculate: "역사적 데이터 재조정",
      },
      dataExtractMode: {
        random: "완전히 무작위",
        intelligentRecommend: "지능형 추천",
        custom: "맞춤",
        specifiedClass: "분류 지정",
      },
      smartRatesList: {
        markRate: "표시율",
        corrigendaRate: "오류율",
        kl: "K-L 발산",
        signalNoiseRate: "신호 대 잡음 비율",
      },
    },
    visibleRangeTypes: {
      all: "모두",
      customerGroup: "고객 서비스 그룹",
      userDefined: "맞춤",
    },
    samplingRateTypes: { everyDay: "매일 그리기", everyWeek: "주간 추첨" },
    taskRemindTypes: {
      confirm: "품질 검사 작업 기록을 확인해야 할 때 알림을 보냅니다.",
      appeal:
        "품질 검사 작업 기록이 불만 사항을 시작하면 미리 알림을 보냅니다.",
    },
    weekDays: {
      monday: "월요일",
      tuesday: "화요일",
      wednesday: "수요일",
      thursday: "목요일",
      friday: "금요일",
      saturday: "토요일",
      sunday: "일요일",
    },
    reportDateTypes: {
      dailyReport: "일일 보고서",
      weeklyReport: "주간 보고서",
    },
    samplingRangeDayTypes: { today: "오늘", yesterday: "어제" },
    samplingRangeWeekTypes: { thisWeek: "이번 주", lastWeek: "지난주." },
    samplingRuleTypes: { proportion: "비례 샘플링", random: "무작위 샘플링" },
    taskStatusTypes: {
      waitEvaluate: "평가하려면",
      waitSubmit: "제출하려면",
      waitConfirm: "확인",
      waitReview: "검토 대상",
      completed: "완료",
      all: "모두",
    },
    qualityInspectionForm: {
      formTemplateTypes: {
        directScore: "직접 등급",
        weightedScore: "무게 점수",
        deductScore: "공제",
        directScoreSecondLevel: "직접 등급-레벨 2",
        weightedScoreSecondLevel: "무게 점수-레벨 2",
        deductScoreSecondLevel: "포인트 공제-레벨 2",
      },
    },
    fieldOperators: {
      is: "동등한",
      not: "동일하지 않음",
      contains: "포함",
      notContains: "포함되지 않습니다",
      not_contains: "포함되지 않습니다",
      containsAny: "어떤 포함",
      contains_any: "어떤 포함",
      is_any: "어떤 포함",
      not_any: "아무것도 포함하지 않습니다",
      notContainsAny: "아무것도 포함하지 않습니다",
      not_contains_any: "아무것도 포함하지 않습니다",
      containsAll: "모두 포함",
      contains_all: "모두 포함",
      notContainsAll: "모두 포함하지 않습니다",
      nont_contains_all: "모두 포함하지 않습니다",
      isNull: "빈",
      is_null: "빈",
      isNotNull: "비어 있지 않음",
      is_not_null: "비어 있지 않음",
      greaterThan: "더 큰",
      greater_than: "더 큰",
      greaterThanEq: "보다 크거나 동등한",
      greater_than_eq: "보다 크거나 동등한",
      lessThan: "덜",
      less_than: "덜",
      lessThanEq: "보다 작거나 동등한",
      less_than_eq: "보다 작거나 동등한",
      between: "사이",
      notBetween: "아닙니다",
      not_between: "아닙니다",
      relative: "동등한",
      set: "세트",
      belongs: "벨롱",
      notBelongs: "속하지 않는다.",
      not_belongs: "동일하지 않음",
      today: "오늘",
      yesterday: "어제",
      the_day_before_yesterday: "어제 전날",
      tomorrow: "내일.",
      next_seven_day: "다음 7 일",
      last_seven_day: "지난 7 일",
      this_week: "이번 주",
      next_week: "다음 주",
      last_week: "지난주.",
      this_month: "이번 달",
      next_month: "다음 달",
      last_month: "지난달",
      this_year: "올해",
      next_year: "내년",
      last_year: "작년",
      exact_match: "정확한 일치",
      add_value: "새로운 부가가치",
      remove_value: "값 삭제",
      set_null: "빈",
      isAny: "임의로 동일",
      notAny: "임의의 동일하지 않음",
      belongTo: "벨롱",
      notBelongTo: "속하지 않는다.",
      hasAny: "어떤 포함",
      notHasAny: "아무것도 포함하지 않습니다",
      prefixContains: "동등하게 시작",
      prefixNotContains: "시작은 동일하지 않습니다",
      suffixContains: "끝 동등",
      suffixNotContains: "끝이 같지 않다.",
      nextSevenDay: "다음 7 일",
      lastSevenDay: "지난 7 일",
      thisWeek: "이번 주",
      nextWeek: "다음 주",
      lastWeek: "지난주.",
      thisMonth: "이번 달",
      nextMonth: "다음 달",
      lastMonth: "지난달",
      thisYear: "올해",
      nextYear: "내년",
      lastYear: "작년",
      after: "보다 늦게 (현재 일 포함)",
      before: "이전 (현재 포함)",
      allMatching: "모두 일치",
      allNotMatching: "모두 일치하지 않습니다.",
      hit: "히트",
      not_hit: "놓친",
    },
    fieldsOperators: {
      is: "동등한",
      is_null: "또한 빈",
      head_equal: "동등하게 시작",
      tail_equal: "끝 동등",
      is_not_null: "또한 비어 있지 않습니다.",
      not: "동일하지 않음",
      diff_equal: "차이는 같습니다.",
      diff_greater_than: "차이가 더 크다.",
      diff_greater_than_eq: "보다 크거나 같은 차이",
      diff_less_than: "차이가 적습니다.",
      diff_less_than_eq: "보다 작거나 같은 차이",
      hour_equal: "같은 시간.",
      day_equal: "같은 날",
      week_equal: "같은 주",
      month_equal: "같은 달",
      year_equal: "같은 해에",
      day: "날",
      minute: "분",
      hour: "시간",
      part_equal: "부분적으로 동일",
    },
    samplingColumnCaptions: {
      created_at: "대화 시간",
      agent_id: "고객 서비스",
      get_direction: "통화 유형",
      call_result: "통화 결과",
      survey: "평가",
      derived_from_id: "소스",
      quit_queue_why: "큐 상태",
      queuing_duration: "시간이 많이 걸리는 대기열",
      talking_seconds: "통화 기간",
      ringing_seconds: "반지 시간",
      hangup_by: "전화 끊기 파티",
    },
    callTypes: {
      callIn: "들어오는 전화",
      callOut: "숨을 내쉬다",
      threeParty: "(삼자)",
      consultation: "(컨설팅)",
      insert: "(강한 삽입)",
      monitor: "(듣기)",
      transfer: "(전송)",
      intercept: "(요격)",
      transferOutside: "(외부 라인 전송)",
      threePartyOutside: "(3 자 외부 라인)",
      consultingOutside: "(외부 상담)",
    },
    callResults: {
      staffAnswer: "고객 서비스 답변",
      staffNotAnswer: "고객 서비스 누락",
      customerAnswer: "고객 답변",
      customerNotAnswer: "고객이 놓친",
      phoneBusy: "전화 바쁜",
      phoneOffline: "전화 오프라인",
      customerSpeedHangUp: "고객 빠른 교수형",
      customerHangUp: "고객이 전화를 끊습니다.",
      queueTimeout: "큐 타임 아웃",
      queueGiveUp: "포기 대기",
      outlineAnswer: "외부 답변",
      outlineNotAnswer: "외부 라인이 연결되지 않음",
      noChooseQueue: "대기열 선택 없음",
    },
    defaultSurveys: {
      noEvaluation: "평가되지 않음",
      noNeedToEvaluate: "평가할 필요 없음",
    },
    queueStates: {
      queueSuccess: "큐 성공",
      queueTimeout: "큐 타임 아웃",
      queueGiveUp: "포기 대기",
      noStaffOnline: "온라인 고객 서비스 없음",
    },
    callHangers: { customer: "고객", staff: "고객 서비스", outline: "외부" },
    workFlowTypes: {
      noAppealsMode: "고충 모드 없음",
      appealsMode: "항소 모드 허용",
    },
    fieldCategories: {
      standardCondition: "표준 필터 기준",
      customerCondition: "사용자 정의 필터 기준",
    },
    sampleTypes: { automatic: "자동 샘플링", manual: "수동 샘플링" },
    defaultFields: {
      createdAt: "대화 시간",
      agentId: "고객 서비스",
      getDirection: "통화 유형",
      callResult: "통화 결과",
      survey: "평가",
      derivedFrom: "소스",
      quitQueueWhy: "큐 상태",
      queuingDuration: "시간이 많이 걸리는 대기열",
      talkingSeconds: "통화 기간",
      ringingSeconds: "반지 시간",
      hangupBy: "전화 끊기 파티",
    },
    conditionFieldCategories: {
      fixedField: "고정 필드",
      customField: "맞춤 필드",
      secondLevelScore: "2 차 분류",
      formItemScore: "점수 항목",
      forbids: "금지 된 항목",
      deadlies: "치명적인 항목",
    },
    columnFieldCategories: {
      agent: "고객 서비스",
      callLog: "통화 녹음",
      businessFields: "비즈니스 정보",
      customer: "고객",
      customerCustomFields: "고객 맞춤형",
      source: "소스",
      queue: "큐",
      fixed: "고정",
      secondLevelScore: "2 차 분류",
      formItemScore: "점수 항목",
      forbids: "금지 된 항목",
      deadlies: "치명적인 항목",
    },
    taskListDefaultFields: {
      startTime: "대화 시간",
      name: "고객 서비스",
      state: "상태",
      totalScore: "총 점수",
      formItemForbidList: "금지 된 항목",
      formItemDeadlyList: "치명적인 항목",
      comment: "평가 비고",
      inspector: "등급",
      review: "리뷰어",
    },
    evaluationStates: {
      initial: "초기화",
      waitEvaluate: "평가하려면",
      evaluated: "제출하려면",
      waitConfirm: "확인",
      waitCheck: "검토 대상",
      complete: "완료",
    },
    workBenchInfoTypes: {
      callInfo: "통화 정보",
      businessInfo: "비즈니스 정보",
      customerInfo: "고객 정보",
    },
    evaluationEventStates: {
      create: "만들기",
      valuate: "평가",
      edit: "편집",
      submit: "제출",
      confirm: "확인",
      appeal: "불만",
      checkConfirm: "확인 검토",
      check: "검토",
    },
    formItemTypes: {
      direct: "직접 등급",
      weight: "무게",
      deduct: "공제",
      forbid: "금지 된 항목",
      deadly: "치명적인 항목",
    },
    appealCheckCallTypes: {
      callIn: "들어오는 전화",
      callBack: "양방향 콜백",
      callOut: "Outcall 직접 다이얼",
      autoCall: "자동 아웃 바운드 통화",
    },
    appealCheckCallStatus: {
      waitReview: "검토 대상",
      reviewed: "검토",
      filed: "Archived",
    },
    samplingStatus: {
      all: "모두",
      unchecked: "추출이 확인되지 않음",
      checked: "샘플링",
    },
    inspectionStatus: {
      all: "모두",
      unread: "읽지 않음",
      readed: "읽기",
      appeal: "불만",
      review: "검토",
      filed: "Archived",
    },
    gradeChooseHits: { yes: "예", no: "아니" },
    appealCheckStatus: {
      all: "모든 상태",
      unread: "읽지 않음",
      inComplaint: "불만 사항",
      reviewed: "검토",
      readed: "읽기",
      filed: "Archived",
      spotchecked: "샘플링",
    },
    flowStatus: {
      unread: "읽지 않음",
      alreadyRead: "읽기",
      complaining: "불만 사항",
      reviewed: "검토",
      sampling: "추출이 확인되지 않음",
      samplingCompleted: "샘플링",
      complainCancel: "개정",
    },
    complainStatus: {
      success: "성공",
      fail: "실패",
      default: "불만 사항",
      canceled: "개정",
    },
    reviewInspectionStatus: { inComplaint: "불만 사항", reviewed: "검토" },
    gradeChangeTypes: {
      edit: "편집",
      check: "검토",
      file: "보관",
      remark: "비고",
      complain: "불만",
      review: "검토",
      reviewEvaluation: "평가 검토",
      sample: "무작위 검사",
      sampleEvaluation: "샘플링 검사 및 평가",
      sampleSubmit: "샘플링 검사 및 제출",
      sampleAssign: "샘플링 할당",
      reviewAssign: "할당 검토",
      read: "읽기",
      reviewConfirm: "확인 검토",
      sampleConfirm: "샘플링 검사 확인",
      caseBase: "케이스 그룹에 추가",
      sampleCancel: "샘플링 취소 할당",
      reviewCancel: "취소 검토",
      sampleAgain: "샘플링 재할당",
      reviewAgain: "재할당 검토",
      btachDelete: "무작위 검사 기록 삭제",
      rapidSampling: "빠른 샘플링",
      reInspection: "재검사",
      smartTagsChange: "스마트 라벨 변경",
      cancelAppeal: "불만 철회",
    },
    appealTypes: {
      transliterationError: "전사 오류",
      discriminationError: "판단 오류",
      otherError: "기타 오류",
    },
    inspectDataSources: {
      voiceCall: "음성 통화",
      textDialogue: "텍스트 대화",
      realTimeVoiceCall: "실시간 음성 통화",
      realTimeTextDialogue: "실시간 텍스트 대화",
      wechatDialogue: "엔터프라이즈 마이크로 세션",
      taobao: "전자 상거래 텍스트 대화",
      ticket: "작업 순서",
      wechatRadio: "엔터프라이즈 마이크로 음성",
    },
    inspectTaskType: {
      all: "모두",
      common: "일반 품질 검사",
      relate: "관련 품질 검사",
    },
    inspectApproveType: {
      all: "모두",
      resolve: "승인",
      resolving: "검토에서",
      reject: "감사가 통과되지 않음",
    },
    dataSourceFlags: {
      all: "제한 없음",
      voiceCall: "전화",
      textDialogue: "대화",
    },
    smartWordsLibrary: { effective: "효과적인", invalid: "무효" },
    activeFlags: { effiective: "효과적인", invalid: "무효" },
    labelTypes: {
      dimensionLabel: "콜아웃 라벨",
      classificationLabel: "카테고리 라벨",
    },
    pointTypes: {
      automatic: "자동 평가",
      manual: "수동 평가",
      interactiveDetection: "상호 작용 감지",
      smartRules: "스마트 규칙",
      machineLearning: "자기 학습 모드",
      intelligentModel: "지능형 모델",
    },
    pointGradeTypes: {
      radio: "단일 선택",
      input: "입력",
      select: "선택",
      all: "모두 만나다",
      any: "임의의 만남",
      customize: "맞춤",
    },
    pointNaChecked: { check: "N/A" },
    operatorTypes: {
      keyword: "키워드 일치",
      question: "의문 문장",
      regular: "정사이즈 표현식",
      context: "컨텍스트 중복",
      semantics: "시맨틱 매칭",
      word: "비슷한 단어",
      dialogue: "대화 간격",
      speed: "음성 속도 감지",
      grabDetection: "스내치 감지",
      semanticTags: "시맨틱 라벨",
    },
    applyRoles: {
      all: "모두",
      customer: "고객",
      agent: "고객 서비스",
      robot: "로봇",
    },
    saleApplyRoles: { all: "모두", customer: "고객", agent: "직원" },
    applyOperatorScopes: {
      all: "전체 텍스트",
      preCondition: "전제 조건",
      scope: "지정된 범위",
    },
    keywordScopes: { all: "모든 키워드 감지.", any: "임의의 키워드 감지" },
    preOperatorHitTypes: {
      first: "첫 경기",
      every: "모든 경기",
      last: "마지막 경기",
      any: "모든 경기",
      none: "일치하지 않음",
    },
    operatorRuleTypes: {
      all: "모두 만나다",
      any: "임의의 만남",
      custom: "맞춤 논리",
    },
    informationEntityTypes: {
      system: "시스템",
      custom: "맞춤",
      moduleCompany: "모듈",
    },
    organizationTypes: { null: "없음", moduleCompany: "모듈" },
    customType: {
      smartTag: "엔티티 추출",
      scriptTimes: "연설 계산",
      dataCategory: "데이터 분류",
    },
    interactiveQuestionTypes: {
      standard: "표준 Q & A",
      multiElement: "다중 요소 질문 및 답변",
      guideMultiRound: "가이드 멀티 휠 상호 작용",
    },
    targetTypes: {
      all: "모두",
      customerService: "고객 서비스",
      customer: "고객",
    },
    interactiveTypes: {
      similarQList: "비슷한 문제",
      exceptSimilarQList: "유사한 문제 제거",
      similarAList: "비슷한 답변",
      wrongAList: "잘못된 대답",
    },
    filterTypes: {
      call: "통화 녹음",
      dialogue: "대화 텍스트",
      wechat: "엔터프라이즈 위챗",
      wechatAll: "엔터프라이즈 위챗 모든 대화",
      ticket: "작업 주문 세션",
      taobao: "전자 상거래 세션",
      wechatRadio: "엔터프라이즈 마이크로 음성",
    },
    fieldTypes: {
      keyword: "키워드",
      agent: "고객 서비스",
      agentGroup: "고객 서비스 그룹",
      sigleListbox: "단일 선택",
      multiListbox: "객관식",
      text: "텍스트의 단일 라인",
      textArea: "여러 줄의 텍스트",
      dateTime: "시간",
      number: "숫자",
      voice: "",
      customer: "고객",
      fieldWithLink: "링크 필드",
      percentage: "비율",
      intelligentClassification: "지능형 분류",
      testSetList: "테스트 세트 동적 필드",
      inspector: "품질 검사관",
    },
    saveTypes: { save: "저장" },
    knowledgeType: { oneToOne: "하나의 질문과 하나의 대답" },
    updateTypes: { update: "업데이트", saveAs: "저장" },
    recommendItemActionTypes: {
      similar: "유사한",
      exclude: "제외",
      ignore: "무시",
    },
    gradeStatus: {
      unread: "읽지 않음",
      alreadyReview: "검토",
      alreadyFiled: "Archived",
      waitReview: "검토 대상",
      alreadyRead: "읽기",
    },
    qualityCheckDetailTypes: { review: "검토", spotCheck: "무작위 검사" },
    sampleAndReviewStatus: {
      init: "추출되지 않음",
      unread: "읽지 않음",
      alreadyRead: "읽기",
      complaining: "불만 사항",
      reviewed: "검토",
      sampling: "추출이 확인되지 않음",
      samplingCompleted: "샘플링",
    },
    interactiveAnswerTypes: {
      noAnswer: "감지 된 답변 없음",
      answer: "감지 된 답변",
      wrongAnswer: "잘못된 답변 감지",
    },
    interactiveMatchedQuestionTypes: {
      noQuestion: "발견 된 문제 없음",
      question: "발견 된 문제",
      eliminateQuestion: "발견 된 문제 해결",
    },
    isManual: { yes: "수동 품질 검사", no: "수동 품질 검사 없음" },
    readStatus: { yes: "읽기", no: "읽지 않음" },
    reInspectionType: {
      recalcScore: "점수 만 재 계산 (등급 포함)",
      recheckPoint: "품질 검사 포인트 및 점수 재 계산",
    },
    common: {
      eCommerceReview: "전자 상거래 검토",
      socialMediaComments: "소셜 미디어 댓글",
      customer: "고객",
      customerService: "고객 서비스",
      robot: "로봇",
      customerInformation: "고객 정보",
      workOrderFields: "작업 주문 필드",
      intelligentConversationAnalysis: "지능형 세션 분석",
      insightIntoBusinessWeaknessesListenToCustomerVoicesAndEnhanceMarketingTransformation:
        "짧은 비즈니스 보드에 대한 통찰력, 고객의 목소리를 듣고 마케팅 변화를 향상시킵니다.",
      intelligentAccompanyingPractice: "스마트 스파링",
      createImmersiveAIAccompanimentExercisesThatSimulateRealBusinessScenariosEnablingAllEmployeesToMasterGoldMedalScriptsAndQuicklyImproveDialogueSkills:
        "실제 비즈니스 장면을 시뮬레이션하는 몰입 형 AI 스파링을 만들어 금메달 연설 기술을 완벽하게 숙달하고 대화 기술을 빠르게 향상시킵니다.",
      intelligentQualityInspection: "지능형 품질 검사",
      fullQualityInspectionTimelyAndEfficientWithAccuracyRateAbove:
        "전체 품질 검사, 시기 적절하고 효율적인, 85% 이상의 정확도 비율",
      salesEmpowerment: "판매 권한 부여",
      aIReshapesSalesManagementRealizesDigitalAndVisualCommunicationProcessTransparentlyManagesEveryLinkOfSalesIntelligentlyInsightIntoEveryStepOfCommunicationFinelyDiagnosesBusinessProblemsAndPerfectlyReplicatesSalesExperience:
        "AI는 영업 관리를 재구성하고, 커뮤니케이션 프로세스의 디지털화 및 시각화를 실현하고, 모든 판매 링크를 투명하게 관리하고, 모든 커뮤니케이션 단계를 지능적으로 인식하고, 비즈니스 문제를 섬세하게 진단하며, 영업 경험을 완벽하게 재현합니다.",
      algorithmPlatform: "알고리즘 플랫폼",
      basedOnSelfdevelopedOriginalHeartEngineVoiceSemanticsASRAndNLPModelCustomizationGetThroughBusinessScenarioDataRealizeSystemSelflearningAndAchieveTheGoalOfMoreIntelligentAndMoreAccurateWhenUsed:
        "자체 개발 된 오리지널 하트 엔진의 음성 의미론을 기반으로 ASR 및 NLP 모델은보다 지능적이고 정확한 사용의 목적을 달성하기 위해 비즈니스 시나리오 데이터를 통과하고 시스템 자체 학습을 실현하도록 사용자 정의됩니다.",
      generalSettings: "일반 설정",
      unifiedSettingAndManagementOfDataPermissionsAndEmployeeRoles:
        "통합 된 방식으로 데이터 권한 및 직원 역할 설정 및 관리",
      callLog: "통화 녹음",
      intelligentRules: "스마트 규칙",
      businessRecords: "사업 기록",
      customFilter: {
        call: "음성 통화",
        text: "텍스트 대화",
        smartTags: "스마트 라벨",
        pretreatment: "전처리",
        smartTagGroup: "스마트 라벨 그룹",
        currency: "일반 데이터",
        wechat: "엔터프라이즈 위챗",
        spotCheck: "무작위 검사",
        personaField: "고객 목록",
        ticket: "작업 주문 세션",
        taobao: "전자 상거래 세션",
        wechatRadio: "엔터프라이즈 마이크로 음성",
        reviewList: "검토",
      },
      talkerRecognitionType: {
        API: "API, 트랙 역할 식별",
        text: "텍스트 인식",
      },
      operatorTypes: {
        keyword: "키워드 일치",
        question: "의문 문장",
        regular: "정사이즈 표현식",
        context: "컨텍스트 중복",
        semantics: "시맨틱 매칭",
        word: "비슷한 단어",
        dialogue: "대화 간격",
        speed: "음성 속도 감지",
        grabDetection: "스내치 감지",
        semanticTags: "시맨틱 라벨",
        keywordStrict: "키워드 일치 (엄격한)",
        link: "링크 필드",
        entityInfoDetection: "정보 엔티티 탐지",
        basicInfoDetection: "기본 정보 탐지",
        volume: "볼륨 감지",
        emotion: "감정 분석",
        grammar: "문법 규칙",
        sentenceLength: "문장 길이 감지",
        conceptWord: "개념 단어",
        questionsAndAnswers: "지식 기반 솔루션 (이전)",
        element: "필수 질문 및 답변",
        silent: "응답 시간 제한",
        associate: "도로 후속 데이터",
        repeat: "반복 보내기",
        timeInterval: "간격 기간",
        typoDetection: "오타 감지",
        silentOld: "침묵",
        knowledge: "지식 솔루션",
        wecomRemark: "고객 기업 마이크로 발언",
        customerAddTime: "고객 추가 시간",
      },
      keywordOperators: {
        all: "모두 일치",
        allNot: "모두 일치하지 않습니다.",
        any: "모든 경기",
      },
      applyRoles: { all: "모두", customer: "고객", agent: "고객 서비스" },
      sentenceLength: {
        less: "덜",
        greater: "더 큰",
        equal: "동등한",
        greater_or_equal: "보다 크거나 동등한",
        less_or_equal: "보다 작거나 동등한",
      },
      dialogueApplyScopes: {
        differentRoles: "다른 역할 사이",
        agent: "고객 서비스",
        customer: "고객",
        agentResponse: "고객 응답",
        customerResponse: "고객 응답",
        customerNoResponse: "고객이 응답하지 않음",
      },
      applyConditionHitTypes: {
        first: "첫 번째 히트.",
        every: "모든 히트.",
        last: "마지막 히트",
      },
      applyConditionScopes: {
        current: "현재",
        before: "전에",
        after: "후",
        beforeAll: "전에 모두",
        afterAll: "결국",
        around: "주변",
      },
      voiceDemoTypes: {
        singleChannel: "단일 채널 통화 녹음",
        dualChannel: "듀얼 채널 통화 녹음",
      },
      sendStatus: {
        sending: "보내기",
        arrive: "배달",
        fail: "보내지 못함",
        off_sending: "읽지 않은 오프라인 보내기",
        off_arrive: "읽기",
        rollback: "철회",
      },
      collectionMethod: { assignment: "직접 할당", entity: "엔티티 추출" },
      systemTypes: { qa: "지능형 품질 검사", wfm: "지능형 스케줄링" },
      entityOperators: {
        equals: "동등한 정보",
        notEquals: "정보와 같지 않음",
        contains: "정보 포함",
        notContains: "정보가 포함되어 있지 않습니다.",
        equalsFix: "고정 값과 동일",
        notEqualsFix: "고정 값과 같지 않음",
        containsFix: "고정 값 포함",
        notContainsFix: "고정 값을 포함하지 않습니다",
      },
      basicInfoOperators: {
        equals: "동등한",
        notEquals: "동일하지 않음",
        contains: "포함",
        notContains: "포함되지 않습니다",
        isNull: "빈",
        isNotNull: "비어 있지 않음",
      },
      volumeDetectionWays: {
        loudness: "음량 데시벨",
        rangeAbilitySelf: "변화의 범위 (이전 문장에서했던 것보다)",
        rangeAbilityOth: "변경 범위 (위의 문장과 비교하여)",
        rangeAbility3: "변화의 범위 (이 문장에있는 것보다)",
      },
      volumeDetectionOperators: {
        is: "동등한",
        not: "동일하지 않음",
        greaterThan: "더 큰",
        greaterThanEq: "보다 크거나 동등한",
        lessThan: "덜",
        lessThanEq: "보다 작거나 동등한",
      },
      sentimentTypes: {
        positive: "긍정적 인 감정",
        negative: "부정적인 감정",
        neutral: "중립 감정",
        thankful: "감사합니다",
        happy: "행복한",
        complaining: "불만",
        angry: "화난",
        post: "앞",
        negat: "네거티브",
        neut: "중립",
      },
      emotionOperators: { is: "준수", not: "순응하지 않음" },
      propTypes: { system: "시스템", user: "맞춤" },
      valueTypes: { text: "텍스트", number: "숫자" },
    },
    tasks: {
      allowCustomGrievance: "맞춤 불만 허용",
      businessSummary: "비즈니스 요약",
      theDayBeforeYesterday: "어제 전날",
      assessActionTypes: { reassess: "재평가" },
      scoreType: {
        aiScore: "지능형 평가",
        manualScore: "수동 평가",
        interactiveDetection: "상호 작용 감지",
        smartRules: "스마트 규칙",
        machineLearning: "자기 학습 모드",
      },
      inspectType: { inspectTrue: "체크", inspectFalse: "확인되지 않음" },
      operatorType: { keyword: "키워드 일치", notKeyword: "키워드 불일치" },
      applyRole: { all: "모두", customer: "고객", agent: "고객 서비스" },
      applyScope: {
        all: "모두",
        specific: "지정 된 문장",
        scope: "지정된 범위",
      },
      judgeStrategy: {
        all: "모든 조건을 충족",
        arbitrarily: "어떤 조건 충족",
        custom: "맞춤 논리",
      },
      checkPointStatus: {
        waitFor: "검토 대기",
        notDeductScore: "공제 없음",
        deductScore: "공제",
      },
      applyOptions: { hit: "상태 히트", notHit: "지정된 조건 없음" },
      gradeTypes: {
        yesOrNo: "준수",
        input: "수동 입력",
        level: "등급 평가",
        multipleFactors: "여러 요소",
      },
      gradeChooseHits: { yes: "예", no: "아니" },
      gradeChangeTypes: {
        edit: "편집",
        check: "검토",
        file: "보관",
        remark: "비고",
        complain: "불만",
        review: "검토",
        reviewEvaluation: "평가 검토",
        sample: "무작위 검사",
        sampleEvaluation: "샘플링 검사 및 평가",
        sampleSubmit: "샘플링 검사 및 제출",
        sampleAssign: "샘플링 할당",
        reviewAssign: "할당 검토",
        read: "읽기",
        reviewConfirm: "확인 검토",
        sampleConfirm: "샘플링 검사 확인",
      },
      gradeStatus: {
        unread: "읽지 않음",
        alreadyReview: "검토",
        alreadyFild: "Archived",
        waitReview: "검토 대상",
        alreadyRead: "읽기",
      },
      samplingFrequencyTypes: {
        everyDay: "매일",
        everyWeek: "주간",
        everyMonth: "월간",
      },
      samplingEveryDayTimeTypes: { yesterday: "어제", today: "오늘" },
      samplingEveryWeekTimeTypes: { thisWeek: "이번 주", lastWeek: "지난주." },
      samplingEveryMonthTimeTypes: {
        thisMonth: "이번 달",
        lastMonth: "지난달",
      },
      samplingTypes: { random: "무작위 샘플링", proportional: "비례 샘플링" },
      weekdays: {
        Monday: "월요일",
        Tuesday: "화요일",
        Wednesday: "수요일",
        Thursday: "목요일",
        Friday: "금요일",
        Saturday: "토요일",
        Sunday: "일요일",
      },
      distributeTypes: {
        rule: "규칙 우선 순위 할당",
        average: "평균 무작위 분포",
      },
      notMatchAnyRuleTypes: {
        random: "무작위 평균 분포",
        assign: "지정 된 사람에게 할당",
        ignore: "할당되지 않음",
      },
      inspectorTypes: { all: "모든 품질 검사관", some: "지정 품질 검사관" },
      pushFrequencyTypes: {
        everyDay: "매일",
        everyWeek: "주간",
        everyMonth: "월간",
        inspectionUpdate: "품질 검사 업데이트",
        timelyInspection: "실시간 품질 검사",
      },
      pushObjectTypes: { email: "사서함 지정", staff: "고객 서비스" },
      reviewOptions: {
        reviewResult: "검토 중 품질 검사 결과 표시",
        reviewRecord: "검토 중 관련 기록 표시",
        reviewAgentHidden: "검토 중 에이전트 정보 숨기기",
        samplingResult: "무작위 검사 중 품질 검사 결과 표시",
        samplingRecord: "무작위 검사 중 관련 기록 표시",
        sampleAgentHidden: "무작위 검사 중 고객 서비스 정보 숨기기",
        checkExportAddCallInfoIphone: "목록을 내보낼 때 통화 로그 첨부",
        sampleExportAddCallInfoIphone: "목록을 내보낼 때 통화 로그 첨부",
        checkExportAddCallInfoText: "목록을 내보낼 때 대화 레코드 첨부",
        sampleExportAddCallInfoText: "목록을 내보낼 때 대화 레코드 첨부",
        inspectionExportAddCallInfoIphone:
          "목록을 내보낼 때 통화 기록 첨부 (한 번에 최대 10000 을 내보낼 수 있음)",
        inspectionExportAddCallInfoText:
          "목록을 내보낼 때 대화 기록 첨부 (한 번에 최대 10000 을 내보낼 수 있음)",
      },
      fastSampleInDetailList: {
        fastSampleInInspectDetail: "품질 세부 사항에 빠른 현장 검사 허용",
        fastSampleInSampleDetail:
          "무작위 검사 세부 사항에서 무작위 검사의 빠른 실행 허용",
        fastSampleInReviewDetail:
          "검토 세부 사항에서 현장 검사를 신속하게 실행할 수 있습니다.",
      },
      permitCustomReasonList: {
        permitCustomReason: "청구에 대한 사용자 정의 이유 허용",
      },
      autoMateTasks: {
        autoLoad: "자동 추가",
        sample: "자동 샘플링 검사",
        assign: "자동 할당",
        pushes: "자동 푸시",
        assess: "자동 평가",
        check: "고충 검토",
        case: "케이스 라이브러리 동기화",
      },
      qualityCheckDetailTypes: { review: "검토", spotCheck: "무작위 검사" },
      appealTypes: {
        transliterationError: "전사 오류",
        discriminationError: "판단 오류",
        otherError: "기타 오류",
      },
      inspectDataSources: {
        voiceCall: "음성 통화",
        textDialogue: "텍스트 대화",
        realTimeVoiceCall: "실시간 음성 통화",
        realTimeTextDialogue: "실시간 텍스트 대화",
      },
      pointTypes: {
        automatic: "자동 평가",
        manual: "수동 평가",
        interactiveDetection: "상호 작용 감지",
      },
      pointGradeTypes: { radio: "단일 선택", input: "입력", select: "선택" },
      sampleAndReviewStatus: {
        init: "추출되지 않음",
        unread: "읽지 않음",
        alreadyRead: "읽기",
        complaining: "불만 사항",
        reviewed: "검토",
        sampling: "추출이 확인되지 않음",
        samplingCompleted: "샘플링",
      },
      wayOverUpperLimits: {
        ignoreExcessSpillover: "초과 오버플로 무시",
        automaticWeightCalculation: "자동 무게 회계",
      },
      calculateScoreTypes: { add: "보너스 포인트", sub: "마이너스 포인트" },
      naChecked: { check: "이 항목은 허용 된 탐지에 관여하지 않습니다." },
      pointNaChecked: { check: "N/A" },
      interactiveRules: {
        proportional: "백분율 포인트",
        allRight: "모든 올바른 포인트",
        anyRight: "올바른 점수",
      },
      noHitQuestionResults: {
        true: '결과는 "예" 입니다.',
        false: '결과는 "아니오"',
      },
      repetRules: { same: "정확히 같은 내용", similar: "의미 적 유사성" },
      relateConditions: {
        highlightQuestion: "문제 강조",
        highlightAnswer: "강조 답변",
        hightlightFoctors: "강조 요소",
      },
      interactiveQuestionTypes: {
        question: "발견 된 문제",
        eliminateQuestion: "발견 된 문제 해결",
      },
      interactiveAnswerTypes: {
        noAnswer: "감지 된 답변 없음",
        answer: "감지 된 답변",
        wrongAnswer: "잘못된 답변 감지",
      },
      inspectionUpdateTypes: {
        autoInspection: "자동 품질 검사",
        autoInspectionWechat: "자동 분석",
        artificialSampling: "수동 샘플링",
        artificialReview: "수동 검토",
        manualModification: "빠른 샘플링",
        timelyInspection: "실시간 품질 검사",
        samplingAssign: "샘플링 할당",
        artificialComplain: "고충의 제출",
        fastSampling: "빠른 샘플링 검사 및 제출",
        recheckInspection: "재검사",
        recheckInspectionWechat: "재분석",
      },
      timelyInspectionTypes: { timelyInspection: "실시간 품질 검사" },
      pushActionTypes: { email: "메일", interfacePush: "인터페이스 푸시" },
      pushDetailWays: { riskAlert: "위험 경보", subscribe: "메시지 구독" },
      riskAlertPushDataTypes: {
        dialogueMessage: "통화/대화 메시지",
        inspectionResults: "품질 검사 결과",
      },
      subscribePushDataTypes: {
        dialogueMessage: "통화/대화 메시지",
        textContent: "통화/대화의 텍스트 콘텐츠",
        inspectionResults: "품질 검사 결과",
      },
      pushRequestMethods: { post: "POST" },
      pushContentTypes: { json: "JSON" },
      notMatchPreRule: {
        average: "그룹 내 평균 점수",
        addOrSubtract: "직접 플러스 또는 마이너스 포인트",
        noChange: "플러스 또는 마이너스 포인트 없음",
      },
      defaultScore: {
        empty: "빈",
        add: "보너스 포인트",
        notAdd: "추가 포인트 없음",
        sub: "마이너스 포인트",
        notSub: "포인트 감소 없음",
        na: "N/A",
        input: "수동 입력",
        choose: "선택",
      },
      strategyTypes: {
        hitAdd: "보너스 히트",
        hitNotAdd: "타격 점수 없음",
        hitSub: "마이너스 포인트 히트",
        hitNotSub: "히트는 포인트를 줄이지 않습니다",
      },
    },
    download: {
      exportStatus: {
        prepare: "준비 중",
        process: "진행 중",
        faild: "수출 실패",
        success: "성공",
      },
    },
    messageType: {
      all: "모두",
      monitor: "즉각적인 모니터링",
      trigger: "트리거 알림",
      timing: "타이밍 보고서",
    },
    messagePushType: {
      all: "모두",
      system: "시스템 메시지",
      sdk: "메시지 SDK",
      push: "인터페이스 푸시",
      email: "메일",
      udesk: "Udesk 알림",
      udeskWhite: "고객 서비스 시스템 알림",
      weChat: "엔터프라이즈 마이크로 메시지",
    },
    autoAddPushTarget: {
      sampleAssignInspector: "샘플링 검사관 할당",
      sampleInspector: "샘플링 검사관",
      assignReviewer: "검토 자 할당",
      reviewer: "리뷰어",
      taskInspector: "작업 검사관",
    },
    sdkPushType: {
      webHook: "표준 웹후크",
      dingDing: "네일링 로봇",
      weChat: "엔터프라이즈 위챗 로봇",
      customer: "맞춤형 Json 콘텐츠",
    },
    inspectionRemarksType: {
      inspection: "품질 검사",
      check: "무작위 검사",
      review: "검토",
    },
    segmentationStrategyType: { count: "메시지 수", spacing: "메시지 간격" },
    segmentationStrategy: { byTime: "타이밍 분할", customize: "조합 슬라이싱" },
    inspectType: {
      commonInspect: "일반 품질 검사",
      associateInspect: "관련 품질 검사",
    },
    typoDetectionOperators: { less: "덜", greater: "더 큰" },
    autoAddTypes: { realTimeAdd: "실시간으로 추가", timingAdd: "타이밍 추가" },
    messageTypes: { text: "문자 메시지", markdown: "마크 다운 메시지" },
    inServiceStatus: {
      activated: "활성화",
      disabled: "장애인",
      inactivated: "활성화되지 않음",
      resigned: "기업에서 퇴장",
    },
    roles: { agent: "직원", customer: "고객" },
    commentVisibleAuthority: {
      all: "모두에게 가시적",
      self: "자신에게만 보이는",
      designation: "지정된 사람 보이는",
    },
    appealCheckingTask: {
      eCommerceReview: "전자 상거래 검토",
      socialMediaComments: "소셜 미디어 댓글",
      largeModel: "대형 모델",
      customModel: "맞춤 모델",
      universalModel: "일반 모델",
      enterpriseWeChatText: "엔터프라이즈 마이크로 텍스트",
      generalQualityInspection: "일반 품질 검사",
      processQualityInspection: "프로세스 품질 검사",
      voiceCopy: "음성 더블 디스크",
      beEqualTo: "동등한",
      notEqualTo: "동일하지 않음",
      canAppeal: "항소 할 수 있습니다",
      noAppeal: "비 불만",
    },
    wechat: {
      redEnvelopes: "빨간 봉투",
      text: "텍스트",
      picture: "사진",
      voice: "음성",
      voiceCall: "음성 통화",
      video: "비디오",
      businessCard: "명함",
      position: "위치",
      expression: "표현식",
      link: "링크",
      applet: "작은 프로그램",
      chatRecord: "채팅 기록",
      mixedMessage: "혼합 메시지",
      file: "파일",
    },
    gong: {
      sessionActivity: "세션 활동",
      conversationAction: "세션 동작",
      riskSemantics: "위험 의미론",
      daysNotFollowedUp: "따르지 않는 날",
      waysideData: "도로 후속 데이터",
      notStarted: "시작되지 않음",
      completed: "완료",
      haveInHand: "진행 중",
      unpublished: "게시되지 않음",
      published: "게시",
      knowledgePoints: "지식 포인트",
      technologicalProcess: "프로세스",
      notMarked: "레이블이 지정되지 않음",
      inAnnotation: "콜아웃에서",
      annotated: "라벨링",
    },
    qualityChecingTask: {
      appealCanceled: "항소 취소",
      complaintExpired: "불만 만료",
      incomingCall: "들어오는 전화",
      exhale: "숨을 내쉬다",
      incomingCallthirdParty: "들어오는 전화 (3 자)",
      incomingCallconsultation: "들어오는 전화 (컨설팅)",
      incomingCallforcedInsertion: "들어오는 전화 (강한)",
      incomingCallmonitoring: "들어오는 전화 (듣기)",
      incomingCalltransfer: "수신 전화 (전송)",
      incomingCallinterception: "들어오는 전화 (인터셉트)",
      incomingCalltransferToExternalLine: "들어오는 전화 (외부 회선 전송)",
      incomingCallthirdPartyExternalLine: "들어오는 전화 (3 방향 외부 라인)",
      incomingCallexternalLine: "들어오는 전화 (외부 회선 컨설팅)",
      outgoingCallthirdParty: "나가는 (3 방향)",
      outgoingCalltransfer: "발신 (전송)",
      outgoingCallforcedInsertion: "나가는 (강한)",
      outgoingCallmonitoring: "나가는 (듣기)",
      outgoingCallinterception: "발신 전화 (요격)",
      outgoingCalltransferToExternalLine: "나가는 (외부 라인으로 이동)",
      outgoingCallthreePartyExternalLine: "나가는 전화 (3 방향 외부 라인)",
      customerServiceAnswer: "고객 서비스 답변",
      customerServiceMissed: "고객 서비스 누락",
      customerAnswer: "고객 답변",
      customerMissed: "고객이 놓친",
      theTelephoneIsBusy: "전화 바쁜",
      phoneOffline: "전화 오프라인",
      customerQuickHanging: "고객 빠른 교수형",
      clientHangsUp: "고객이 전화를 끊습니다.",
      queueTimeout: "큐 타임 아웃",
      giveUpQueuing: "포기 대기",
      outsideLineAnswering: "외부 답변",
      externalLineIsNotConnected: "외부 라인이 연결되지 않음",
      noQueueSelected: "대기열 선택 없음",
      notEvaluated: "평가되지 않음",
      noEvaluationRequired: "평가할 필요 없음",
      queuingSucceeded: "큐 성공",
      noCustomerServiceOnline: "온라인 고객 서비스 없음",
      customer: "고객",
      customerService: "고객 서비스",
      outsideLines: "외부",
    },
    sessionActivity: {
      single: "평균 단일 통화 시간",
      all: "총 통화",
      allTime: "총 통화 기간",
    },
    sessionAction: {
      salesProportion: "판매 토크 비율",
      salesLongest: "평균 최장 판매 명세서",
      customerLongest: "평균 최장 고객 말하기",
      discussNumber: "심층 토론 횟수",
      frequency: "질문 빈도",
    },
    monitorCriteria: { all: "전후", before: "전에", after: "후" },
    monitorTimeCycle: { day: "날", week: "주", month: "달" },
    monitorGroupTestType: { ab: "두 그룹의 비교", a: "그룹 A", b: "그룹 B" },
    fields: {
      department: "학과",
      customerTags: "고객 라벨",
      member: "회원",
      time: "시간",
      cascade: "캐스케이드",
      anyMatch: "모든 경기",
      keyWord: "키워드",
      keyEvents: "주요 이벤트",
    },
    semanticIntelligence: {
      staff: "직원",
      customer: "고객",
      employeesAndCustomers: "직원 및 고객",
      notStarted: "시작되지 않음",
      haveInHand: "진행 중",
      completed: "완료",
      callRecording: "통화 녹음",
      enterpriseWeChatCall: "엔터프라이즈 마이크로 토크",
      enterpriseWeChatText: "엔터프라이즈 마이크로 텍스트",
      discourseMiningForObjectionHandling: "음성 채광 처리 거부",
      productSellingPointScriptsMining: "제품 판매 포인트 토크 마이닝",
      conversationScenario: "세션 시나리오",
      topicOfConversation: "주제",
      keyEvents: "주요 이벤트",
      customerLabel: "고객 라벨",
      includeAny: "어떤 포함",
      includeAll: "모두 포함",
      and: "과",
      or: "또는",
      question: "의문 문장",
      nonInterrogativeSentence: "비 의문 문장",
    },
    coach: {
      notPassed: "통과되지 않음",
      analogTelephone: "아날로그 전화",
      enterScenarioInformation: "시나리오 정보 입력",
      generateDialogueFramework: "대화 프레임 워크 생성",
      generatePromptLanguage: "프롬프트 생성",
      aIGeneratedDialogue: "AI 생성 대화",
      system: "시스템",
      custom: "맞춤",
      flow: "프로세스",
      script: "단어",
      express: "표현식",
      emotion: "감정",
      rateAccordingToCourseConfiguration: "코스 구성별 점수",
      intelligentModelRating: "스마트 모델 득점",
      allData: "모든 데이터",
      dataFromThisDepartment: "부서의 데이터",
      multidepartmentalData: "다중 섹터 데이터",
      underReview: "검토에서",
      no: "아니",
      yes: "예",
      automaticRating: "자동 득점",
      auditRating: "감사 점수",
      reRating: "재득점",
      inAppeal: "불만 사항",
      reviewed: "검토",
      notViewed: "보이지 않음",
      viewed: "보기",
      uncorrected: "수정되지 않음",
      corrected: "수정",
      practice: "운동",
      examination: "시험",
      other: "기타",
      notMeetingStandards: "표준에 미치지 못함",
      meetingStandards: "표준",
      excellent: "우수",
      invalid: "실패",
      notStarted: "시작되지 않음",
      haveInHand: "진행 중",
      ended: "종료",
      completed: "완료",
      hangInTheAir: "미완성",
      passed: "합격",
      voiceConversation: "음성 대화",
      textDialogue: "텍스트 대화",
      slidePresentation: "슬라이드 프레젠테이션",
      unpublished: "게시되지 않음",
      published: "게시",
      narration: "내레이터",
      studentSpeaking: "생도 말하기",
      robotTalk: "로봇이 이야기합니다.",
      knowledgeBaseQA: "지식 기반 Q & A",
      slide: "슬라이드",
      negativeEmotions: "부정적인 감정",
      emotionallyNormal: "정상적인 기분",
      incompleteScript: "불완전한 연설",
      completeScript: "완전한 연설",
      normalSpeechSpeed: "속도 정상",
      speakTooFast: "너무 빨리 빨리",
      speakTooSlowly: "속도 느린",
      whole: "모두",
      singlePage: "단일 페이지",
      qA: "하나의 질문과 하나의 대답",
      situationalDialogue: "상황 대화",
      misses: "놓친",
      hit: "히트",
      sequentialQA: "순차 Q & A",
      randomQA: "무작위 질문",
      mastered: "마스터링",
      notMastered: "마스터되지 않음",
    },
    algorithm: {
      salesEmpowerment: "판매 권한 부여",
      enterpriseWeChatVoice: "엔터프라이즈 위챗 음성",
      enterpriseWeChatText: "엔터프라이즈 위챗 텍스트",
      waiting: "대기 중",
      extracting: "추출에서",
      success: "성공",
      fail: "실패",
      intelligentQualityInspection: "지능형 품질 검사",
      textDialogue: "텍스트 대화",
      voiceCall: "음성 통화",
      smartTags: "스마트 라벨",
      satisfactionEvaluation: "만족도 평가",
      score: "등급",
      grade: "등급",
      customerServiceTeam: "고객 서비스 그룹",
      conversationAnalysis: "대화 분석",
    },
    operatorListGather: {
      businessSummary: "비즈니스 요약",
      firstSentence: "첫 번째 문장",
      lastSentence: "마지막 문장",
      allTime: "모든 시간",
      nonBusinessWorkingHours: "비 업무 시간",
      businessWorkingHours: "사업 근무 시간",
    },
    callDialogueManage: {
      eCommerceReview: "전자 상거래 검토",
      socialMediaComments: "소셜 미디어 댓글",
    },
  },
  pages: {
    auth: {
      welcome: "",
      copyRight: "",
      login: {
        name: "로그인",
        email: "우편함",
        password: "암호",
        forgetPwd: "비밀번호를 잊었다",
        emailCannotBeEmpty: "사서함이 비어 있으면 안됩니다.",
        passwordCannotByEmpty: "암호가 비어 있으면 안됩니다.",
        mustChangeLogin:
          "죄송합니다. 비밀번호를 재설정해야합니다. 관리자에게 연락하여 비밀번호를 재설정하십시오.",
        dismissionLogin: "죄송합니다. 직장을 그만 두었습니다.",
        forbiddenLogin: "죄송합니다. 계정이 비활성화되었습니다.",
        loginError: "로그인 실패, 사서함 또는 암호 오류.",
        loginPrompt: "시스템에 로그인",
        rememberMe: "기억해",
      },
      logout: {
        logoutError:
          "로그 아웃이 실패했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
      },
      resetPassword: {
        forgetPwd: "비밀번호를 잊었다",
        inputEmailPrompt:
          "아래에 계정 이메일을 입력하면 비밀번호를 재설정하는 이메일과 지침을 보내 드리겠습니다.",
        sendEmailSuccess:
          "비밀번호 수정 확인 문자가 작성한 이메일로 성공적으로 전송되었습니다. 제 시간에 확인하십시오.",
        sendEmailFailed:
          "사서함이 보내지 못했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
        setNewPassword: "새 비밀번호 설정",
        passwordCannotEmpty: "비밀번호가 비어 있으면 안됩니다.",
        passwordFormWrong: "잘못된 암호 형식",
        confirmPasswordWrong: "입력 된 두 개의 암호는 동일하지 않습니다.",
        passwordResetSuccess: "암호 리셋이 성공했습니다.",
        passwordResetFailed:
          "암호 재설정이 실패했습니다. 네트워크 연결을 확인하거나 나중에 다시 시도하십시오.",
        linkTimeover: "만료 된 암호 링크 재설정, 다시 시도하십시오",
      },
      form: { title: "케이스 양식 디스플레이" },
    },
    public: {
      auth: {
        forgotPassword: {
          email: "우편함",
          emailFormatError: "사서함 형식 오류, 다시 입력하십시오!",
          forgotPassword: "비밀번호를 잊었습니까?",
          describe:
            "아래에 계정 이메일을 입력하면 비밀번호를 재설정하는 이메일과 지침을 보내 드리겠습니다.",
          send: "보내기",
          emailCannotBeEmpty: "사서함을 비울 수 없습니다.",
          resetPasswordExpire: "만료 된 암호 링크 재설정, 다시 시도하십시오",
        },
        resetPassword: {
          setPassword: "새 비밀번호 설정",
          newPassword: "새로운 암호",
          repeatPassword: "중복 암호",
          resetPassword: "암호 재설정",
          passwordCannotBeEmpty: "새 암호를 비울 수 없습니다.",
          confirmPasswordCannotBeEmpty: "중복 암호를 비울 수 없습니다.",
          passwordsNotMatch: "두 개의 암호가 일치하지 않습니다!",
          passwordFormWrong:
            "암호는 중국어로있을 수 없으며 번호는 6-14 입니다!",
          linkExpiration: "만료 된 암호 링크 재설정, 다시 시도하십시오",
        },
      },
      errors: {
        haveNoPermissionWarningText: "이 페이지를 볼 수있는 권한이 없습니다.",
      },
    },
    downloadCenter: {
      name: "다운로드 센터",
      saveTime: "(기본적으로 30 일)",
      headerTitle: "파일 다운로드",
      download: "다운로드",
      downloadPromp: "다운로드 작업이 제출되었습니다.",
      viewProgress: "진행 상황 보기",
    },
    uploadCenter: { name: "업로드 센터" },
    tasks: {
      index: {
        name: "이름",
        remark: "설명",
        qualityCheckTasksTitle: "작업대",
        informationCollection: "컬렉션 작업",
        qualityCheckTasks: "품질 검사 작업",
        wechatQaTasks: "분석 작업",
        moduleName: "템플릿 새 사용",
        creatNewQualityCheckTask: "새로운 작업",
        generalInspectionList: "일반 품질 검사 보고서",
        editQualityCheckTask: "품질 검사 작업 편집",
        creatNewQualityCheckTaskError:
          "새로운 품질 검사 작업을 만들지 못했습니다.",
        editQualityCheckTaskError: "품질 검사 작업을 편집하지 못했습니다.",
        type: "형태 유형",
        singleScore: "단일 점수",
        singleScoreNullError: "단일 점수는 비어있을 수 없습니다.",
        inspector: "품질 검사관",
        inspectDataSource: "데이터 소스",
        taskName: "작업 이름",
        inspectionType: "품질 검사 유형",
      },
      manage: {
        screeningCondition: "필터 상태",
        template: {
          condition: {
            index: { name: "조건", existingConditions: "기존 조건" },
            detail: {
              id: "ID",
              name: "조건 이름",
              remark: "설명",
              checkRange: "탐지 범위",
              operator: "연산자",
              operatorLogic: "연산자 논리",
            },
          },
          checkPointTest: {
            title: "규칙 테스트",
            running: "시험 규칙, 나중에 제발...",
            clearSuccess: "시뮬레이션 된 대화가 성공적으로 비워졌습니다.",
            mock: "시뮬레이션 대화",
            save: "선택한 대화 상자 저장",
            saveAll: "모든 대화 저장",
            saveSuccess: "성공적으로 저장된 대화",
            close: "출구",
            delete: "선택한 삭제",
            clear: "빈",
            customer: "고객",
            agent: "고객 서비스",
            test: "테스트",
            request:
              "시뮬레이션 대화 상자가 지워지면 복구 할 수 없습니다. 그것을 지우려면?",
            deletePopconfirmContent: "선택한 대화 상자를 삭제하시겠습니까?",
          },
          checkPoint: {
            index: {
              name: "규칙 구성",
              wechatTitle: "분석 모델 구성",
              existingCheckPoints: "기존 품질 검사 규칙",
              currentCheckPointScore: "현재 품질 검사 규칙 점수는",
              currentCheckPointScoreWechat: "현재 분석 모델 점수는",
              currentCheckPointWeight: "현재 품질 검사 규칙의 총 중량은",
              weightTotalScoreAndItemScore: "총 점수: 100, 단일 점수:",
              template: "품질 검사 규칙 템플릿",
              import: "수입 품질 검사 규칙 템플릿",
              getTemplateError: "품질 검사 규칙 템플릿을 얻지 못했습니다.",
              tabs: { template: "템플릿", demo: "샘플" },
              customize: "빈 템플릿",
              move: "드래그 앤 드롭하여 분류 및 정렬 조정",
              classification: "품질 검사 분류",
              classificationWeChat: "모델 분류",
              first: "레벨 1 분류",
              second: "2 차 분류",
              new: "새로운 품질 검사 규칙",
              weChatNew: "분석 모델 추가",
              createWeChat: "새로운 분석 모델",
              empty: "당분간 품질 검사 규칙이 없습니다.",
              wechatEmpty: "분석 모델 없음,",
              newSecond: "새로운 2 차 분류",
              newFirst: "새로운 1 단계 분류",
              checkPoint: "품질 검사 규칙",
              checkPointWeChat: "분석 모델",
            },
            detail: {
              name: "이름",
              remark: "설명",
              type: "유형",
              deduction: "공제",
              rule: "규칙",
              highlighted: "하이라이트",
              gradeType: "채점 방법",
              interactiveCategory: "상호 작용 분류",
              predeterminedScore: "점수",
              weight: "무게",
              na: "N/A",
              default: "기본 점수",
              preRule: "전제 조건 규칙",
              analysisHighlighted: "구문 분석 규칙",
              categorySelectPrompt: "카테고리를 선택하십시오.",
              checkHighlightArrayError:
                "규칙이 하이라이트와 일치하지 않습니다. 구문 분석 규칙 버튼을 다시 클릭하십시오.",
              foctorsHolder: "요소를 선택하십시오.",
              foctorsErrorNone:
                "다중 요소 규칙이 비어 있으므로 다중 요소 유형을 추가하십시오.",
              naError: "기본 점수가 N/A 일 때 항목을 닫을 수 없습니다",
              templateNew: {
                nAIsTheNotApplicableWhichSetsPreconditionsForTheQualityInspectionRuleIfTheConditionIsMetTheRuleIsNotApplicableIfTheConditionIsNotMetTheRuleIsNotDetectedDescriptionOfPreconditionsMeansandMeansorIndicatesNOTFillInTheExampleRR2R3:
                  'N/A는 품질 검사 규칙에 대한 전제 조건을 설정하는 적용되지 않습니다. 조건이 충족되면 규칙이 적용되지 않습니다. 조건이 충족되지 않으면 규칙이 감지되지 않습니다.\n전제 조건에 대한 설명: "&&" 는 "및", "||" 는 "또는", "!" 를 의미합니다. "NOT" 를 나타내며 "(R1 || R2) && R3" 예제를 작성하십시오.',
                applicationMessageType: "응용 프로그램 메시지 유형",
                selectAll: "모두 선택",
                highlightTags: "하이라이트 라벨:",
                ruleType: "규칙 유형",
                dataSource: "데이터 소스",
                whole: "모두",
                enterpriseMicroConversation: "엔터프라이즈 마이크로 세션",
                enterpriseAndMicroCommunication: "엔터프라이즈 마이크로 토크",
              },
              components: {
                flow: {
                  flow: {
                    determineNodes: "판단 노드",
                    dialogueNode: "대화 노드",
                    editNodes: "노드 편집",
                  },
                  nodeConfig: {
                    nodeName: "노드 이름",
                    nodeRules: "노드 규칙",
                    scoringLogic: "채점 논리",
                    score: "점수:",
                    rule: "규칙",
                    multiBranchLogic: "멀티 브랜치 논리",
                    highlight: "하이라이트",
                    submit: "제출",
                  },
                  ruleList: {
                    branch: "지점",
                    branchName: "지점 이름:",
                    addRules: "규칙 추가",
                  },
                  sidebar: { node: "노드" },
                  hooks: {
                    useControlButtons: {
                      automaticOrganization: "자동 마무리",
                      canvasAdaptation: "캔버스 적응",
                      copy: "복사",
                      delete: "삭제",
                    },
                    useFlow: {
                      determineThatTheChildNodesOfANodeCannotBeUsedAsConnectionTargets:
                        "판단 노드의 자식 노드는 연결 대상으로 사용할 수 없습니다.",
                      theStartingNodeCannotBeUsedAsAConnectionTarget:
                        "시작 노드는 연결 대상이 될 수 없습니다.",
                      startingNodeCannotConnectMultipleNodes:
                        "시작 노드는 여러 노드를 연결할 수 없습니다.",
                      nodeCannotBeConnectedToItself:
                        "노드는 그 자체에 연결할 수 없습니다",
                      startNode: "시작 노드",
                    },
                  },
                },
                msgTypesSelect: {
                  index: {
                    cancelSelectAll: "모두 선택 취소",
                    selectAll: "모두 선택",
                  },
                },
                customModelsSelect: {
                  index: {
                    whetherItMeetsTheRequirements: "준수",
                    gradedEvaluation: "등급 평가",
                  },
                },
              },
            },
            configuration: {
              title: "품질 검사 규칙",
              wechatTitle: "분석 모델",
              neme: "이름",
              errorMessage: "적어도 하나의 조건으로 삭제 실패",
              desc: "설명",
              status: "상태",
              yes: "효과적인",
              no: "무효",
              namePlaceHolder: "레이블 이름을 입력하십시오.",
              desPlaceHolder: "설명을 입력하십시오.",
              nameEmpty: "이름을 비울 수 없습니다.",
              desEmpty: "설명을 비울 수 없습니다.",
              type: "유형",
              ruler: "스마트 규칙",
              machineLearning: "자기 학습 모드",
              autoScore: "스마트 득점 (이전)",
              manualScore: "수동 득점",
              interaction: "상호 작용 감지 (구)",
              typeTips: {
                model:
                  '지능형 모델: 사전 설정 품질 검사 시스템으로 구성 및 유지 보수없이 서비스에 존재하는 문제를 신속하게 확인할 수 있습니다. 이 모델은보다 일반적이며 비즈니스 요구 사항을 충족하지 않으면 "지능형 규칙" 또는 "수동 채점" 을 사용하십시오.',
                rule: "지능형 규칙: 규칙 AI를 기반으로 자동 채점",
                manual: "수동 채점: 수동 품질 검사, 설명서에 의한 수동 채점",
              },
              model: "모형:",
              ruleType: {
                words: "단어",
                interaction: "상호 작용",
                feature: "특징",
                information: "정보",
              },
              testingMode: {
                all: "모든 키워드 감지.",
                any: "임의의 키워드 감지",
              },
              operatorTypesOfInteraction: {
                question: "하나의 질문과 하나의 대답",
                element: "요소 답변",
                repet: "컨텍스트 중복",
                snatch: "Snatch 단어",
                silent: "침묵",
              },
              role: "탐지 역할:",
              preposition: "사전 운영자:",
              range: "탐지 범위:",
              from: "아니.",
              to: "아니오 문장.",
              end: "문장",
              hasOldSilentTip:
                '안녕하세요, 정확성을 향상시키기 위해 "지능형 규칙-상호 작용-침묵" 이 "응답 시간 초과" 로 업그레이드되었습니다. 사용 규칙을 업데이트하는 것이 좋습니다!',
              changeToOld: "레거시로 전환",
              changeToNew: "새 버전으로 전환",
              logic: {
                logic: "채점 논리",
                naTip:
                  "N/A는 품질 검사 규칙에 대한 전제 조건을 설정하는 적용되지 않습니다. 조건이 충족되면 규칙이 적용되지 않습니다. 조건이 충족되지 않으면 규칙이 감지되지 않습니다.",
                score: "점수:",
                na: "N/A：",
                naDes: "이 항목은 허용 된 탐지에 관여하지 않습니다.",
                preRule: "전제 조건 규칙:",
                notCatchPreRule: "전제 조건이 충족되지 않을 때:",
                naChecked: "N/A를 칠 때:",
                preRuleEmpty: "전제 조건 규칙을 비울 수 없습니다.",
                operatorEmpty: "필요한 조건은 연산자에서 null 수 없습니다",
                multipleEmpty:
                  "필요한 조건은 여러 논리 점수에서 비어있을 수 없습니다.",
                average: "그룹 내 평균 점수",
                addOrSubtract: "직접 플러스 또는 마이너스 포인트",
                noChange: "플러스 또는 마이너스 포인트 없음",
                rule: "규칙:",
                all: "모든 연산자 만족",
                any: "임의의 연산자 만족",
                customize: "사용자 지정 연산자 논리",
                multiple: "다중 논리 점수",
                heightLight: "하이라이트:",
                machineLearningTip:
                  "현재 자기 학습 모드는 단일 문장 음성에만 효과적입니다. 복잡한 연설의 경우 지능형 규칙을 사용하십시오.",
                strategy: "전략:",
              },
            },
            component: {
              approvalSubmittedSuccessfully: "성공적으로 제출 된 승인",
            },
            components: {
              pageHeaderExtra: {
                test: "테스트",
                formal: "정식",
                submit: "제출",
              },
              createModal: { index: { myRules: "내 규칙" } },
            },
          },
          automaticAdd: {
            index: {
              name: "프로세스 자동화",
              screeningCondition: "필터 상태",
              aiScreeningCondition: "지능형 필터 상태",
              rule: "규칙",
              automaticAddition: "자동 추가",
              automaticAdditionTip:
                "AI 품질 검사를 위해 실시간 또는 정사이즈 간격으로 작업에 데이터를 자동으로 추가",
              autoAdd: {
                autoAddType: "자동 추가 방법",
                addFrequency: "주파수 추가",
                add: "추가",
              },
              automaticSamplingInspection: "자동 샘플링 검사",
              automaticSamplingInspectionTip:
                "수동 품질 검사가 필요한 데이터를 자동으로 추출합니다. 예를 들어 수동 샘플링을 위해 매일 10% 의 어제 무작위 데이터를 추출합니다.",
              everyAgentCheckLimit: "에이전트 추첨 당 최대",
              automaticAllocation: "자동 할당",
              automaticAllocationTip:
                "샘플링 검사 할당: 추출 된 데이터는 자동으로 품질 검사관에게 할당됩니다. 예를 들어, 어제 무작위 데이터의 10% 가 수동 샘플링 검사를 위해 매일 추출되고, 2 명의 품질 검사관이 평균적으로이 작업에 무작위로 할당됩니다.",
              automaticAllocationTip2:
                "검토 할당: 이의 제기 데이터는 검토를 위해 품질 검사자에게 자동으로 할당됩니다. 예를 들어, 일일 이의 제기 데이터는 검토를 위해이 작업의 품질 검사관 2 명에게 똑같이 무작위로 할당됩니다.",
              automaticPush: "자동 푸시",
              automaticPushTip:
                "트리거 알림: 품질 검사 중 알림/경보 (예: 고객 서비스에서 치명적인 오류가 발생하면 엔터프라이즈 마이크로 그룹으로 알림 메시지가 전송됩니다.",
              automaticPushTip2:
                "시간 보고서: 품질 검사 결과를 메일 형태로 보냅니다. 예를 들어 어제 수동 샘플링의 품질 검사 결과를 매일 9:00 에 고객 서비스 감독자의 사서함으로 보냅니다.",
              automaticAssess: "자동 평가",
              automaticAssessTip:
                "수동 샘플링 결과는 정확도 평가를 위해 AI 품질 검사 결과와 정기적으로 비교됩니다. 예를 들어, 어제의 샘플링 데이터의 정확도는 매일 8 시에 평가된다.",
              reexaminationComplaint: "고충 검토",
              spoktReviewDetail: "비즈니스 구성",
              spoktReviewDetailTip:
                "품질 검사 목록, 무작위 검사 목록, 불만 사업, 검토 목록 및 발언과 같은 기능 구성",
              qaDetailAction: "품질 검사 구성",
              spoktDetailAction: "샘플링 구성",
              reviewDetailAction: "구성 검토",
              appealAction: "고충 구성",
              remarkTemplate: "노트 템플릿",
              automaticArchiving: "자동 보관",
              conditionalRules: "조건 규칙",
              qualityInspectionConditions: "품질 검사 조건",
              unopened: "아직 열리지 않음",
              sampleInspectionDistributionModel: "샘플링 할당",
              reviewAllocationModel: "할당 검토",
              distributionModelTitle: "할당 정책:",
              pushRules: "푸시 규칙",
              push: "푸시",
              assessRules: "평가 규칙",
              on: "on",
              off: "off",
              updateStatusSuccess: "상태 변경 성공!",
              updateStatusFailed:
                "상태 변경이 실패했습니다. 나중에 다시 시도하십시오!",
              sampleNumberOrPercent: "샘플링 수 또는 비율",
              inspectorList: "지정 품질 검사관",
              numberOfSubmission: "제출이 허용되는 표현 수",
              rewivewDays: "날",
              TimeOfSubmission: "표현 제출의 유효 날짜",
              rewivewSecond: "타임즈",
              switchOn: "오픈",
              switchOff: "닫기",
              notMatchAnyRule: "규칙이 일치하지 않을 때:",
              appealOptionsList: {
                hiddenInspector: "불만 숨겨진 검사자 정보",
                hiddenHit:
                  "어필 페이지는 히트를 강조 표시하고 위치를 지정하는 것을 지원합니다.",
              },
              caseLibrary: "케이스 라이브러리 동기화",
              caseLibrarySyncTip:
                "QA 시스템에 의해 추가된 사례를 고객 서비스 시스템에 동기화",
              addCase: "동기화 증가",
              caseLibraryId: "케이스 라이브러리",
              caseLibraryIdTip: "QA 시스템의 사례 기본 그룹",
              udeskCaseLibraryIdTip: "고객 서비스 시스템의 사례 그룹화",
              syncHis: "동기 역사 사례",
              otherSettings: "기타 설정",
              udeskCaseLibraryId: "푸시 케이스 그룹",
              url: "푸시 URL",
              urlTip:
                "http:// 또는 https:// 로 시작하는 인터페이스 주소를 입력하십시오.",
            },
            templateNew: {
              secondaryReview: "2 차 검토",
              numberOfAppealsAllowedToBeSubmitted: "제출이 허용 된 항소 수",
              validityOfSubmissionOfAppeal: "이의 제기 유효성",
              validityOfAppealReview: "항소 검토의 타당성",
              hours: "시간",
              businessSummary: "비즈니스 요약",
              eachCustomerServiceRepresentativeCanSelectALowerLimit:
                "에이전트 당 추출의 하한 한계",
              automaticallyGenerateBusinessSummariesFromLargeModelsAndCustomizeDimensionsBasedOnBusinessSituations:
                "비즈니스 요약은 대형 모델에 의해 자동으로 생성되며 차원은 비즈니스 상황에 따라 사용자 정의 할 수 있습니다.",
              onlyTheFirstLevelReviewerAllocationStrategy:
                "레벨 1 리뷰어 전용 과제 전략",
              secondaryCompounding: "보조 복합:",
              secondLevelReviewersOnlySupportAverageDistribution:
                "2 차 검토자는 동일한 배포만 지원",
              secondLevelReviewer: "두 번째 레벨 리뷰어:",
              samplingInspectionTarget: "샘플링 검사 대상",
              setTheTargetToBeCompletedByManualSamplingInspectionWhenTheTargetIsNotReachedOrExceededAPromptWillBeGivenForExampleEveryCustomerServiceShouldCompleteSamplingInspectionsEveryMonthWhenTheNumberIsLessThan80APromptWillBeGivenOnTheQualityInspectionAndSamplingInspectionPage:
                "수동 샘플링 검사를 통해 목표를 완료하도록 설정하고 목표에 도달하거나 초과하지 않을 때 프롬프트를 제공합니다. 예를 들어 각 고객 서비스는 한 달에 100 개 항목의 샘플링 검사를 완료해야하며 대상이 80 개 미만인 경우 품질 검사 및 샘플링 검사 페이지가 프롬프트를 제공합니다.",
            },
            component: {
              verificationFailed: "검증 실패",
              pleaseAddASecondLevelQualityInspector:
                "2 단계 품질 검사관을 추가하십시오",
            },
            businessSummary: {
              components: {
                field: {
                  index: {
                    hintLanguage: "프롬프트",
                    fieldName: "필드 이름",
                    fieldDescription: "필드 설명",
                    generateContent: "콘텐츠 생성",
                    enabled: "사용",
                    generationMethod: "세대 방법",
                    automaticGeneration: "자동으로 생성",
                    manualTrigger: "수동 트리거",
                    filterCondition: "필터 상태",
                    return: "반환",
                    preserve: "저장",
                  },
                  components: {
                    training: { index: { generateResults: "결과 생성" } },
                  },
                },
                contentScope: {
                  index: {
                    notQualified: "자격 없음",
                    limitedScope: "제한된 범위",
                    add: "추가",
                    thereIsCurrentlyNoDataAvailable: "데이터 없음",
                  },
                },
              },
              index: {
                fieldName: "필드 이름",
                fieldDescription: "필드 설명",
                nothing: "없음",
                generationMethod: "세대 방법",
                generateManually: "수동으로 생성",
                automaticGeneration: "자동으로 생성",
                generateContent: "콘텐츠 생성",
                notQualified: "자격 없음",
                filterCondition: "필터 상태",
                enabled: "사용",
                type: "유형",
                system: "시스템",
                custom: "맞춤",
                operation: "운영",
                edit: "편집",
                areYouSureToDeleteIt: "삭제 하시겠습니까?",
                delete: "삭제",
                addField: "필드 추가",
              },
            },
          },
          roleConfig: {
            index: {
              name: "역할 구성",
              roleConfigTip:
                "가져온 통화 데이터가 모노 일 때 스피커 분리 처리에 대한 인식 규칙을 구성하는 데 사용됩니다. 에이전트 역할에 대한 규칙 준수",
              operator: "연산자",
              operatorLogic: "연산자 논리",
            },
          },
          manualAdd: {
            index: {
              name: "수동으로 추가",
              tip: "수동으로 기준을 구성하여 미리보기 데이터를 필터링하고 신속하게 품질 검사 작업에 추가하는 데 사용됩니다.",
              screeningCondition: "필터 기준으로 추가",
              aiScreeningCondition: "지능형 필터 상태",
              rule: "규칙",
              createTime: "시간 추가",
              filterTitle: "필터 내용",
              filterRule: "필터 상태",
              startTime: "시작 시간",
              endTime: "종료 시간",
              records: "수입 기록",
              createUserName: "연산자",
              successCount: "성공적으로 추가",
              conditionRule: "조건 규칙:",
              preview: "미리보기",
              previewData:
                "현재 {0} 일치하는 필터 기준이 있고 {1} 작업이 추가되었으며 {2} 작업을 추가할 수 있으며 {3} 작업을 추가할 수 없습니다.",
              failCount: "추가 실패",
            },
          },
          basicInfo: {
            index: {
              title: "기본 정보",
              name: "이름",
              taskName: "작업 이름",
              taskNamePlaceholder: "작업 이름을 입력하십시오.",
              requiredName: "작업 이름을 비울 수 없습니다.",
              taskRemarkPlaceholder: "작업 설명을 입력하십시오.",
              requiredRemark: "작업 설명을 비울 수 없습니다.",
              remark: "설명",
              taskRemark: "작업 설명",
              source: "데이터 소스",
              benchmarkScore: "벤치 마크 점수",
              benchmarkScorePlaceholder: "벤치 마크 점수를 입력하십시오.",
              scoreLowerLimit: "최소 점수 하한 한도",
              scoreUpperLimit: "최대 점수 캡",
              inspectType: "품질 검사 유형",
              nlpModel: "NLP 모델",
              commonInspect: "일반 품질 검사",
              associateInspect: "관련 품질 검사",
              associateCycle: "상관 기간",
              associateRegulation: "협회 규칙",
              judgeStrategy: "실행 논리",
              rule: "규칙",
              commonTip:
                "일반 품질 검사: 단일 통신 및 대화 및 단일 통신 내용의 품질 검사에 적용 가능합니다.",
              associateTip:
                "관련 품질 검사: 품질 검사를 위해 여러 통신 내용을 연결하는 여러 통신 및 대화에 적용 할 수 있습니다.",
              cycleTip:
                "여러 개의 관련 통신 및 대화 상자가 있으면 관련 기간의 데이터가 품질 검사를 위해 작업에 들어갑니다.",
              scoreLowerLimitPlaceholder: "최소 점수 제한을 입력하십시오",
              scoreUpperLimitPlaceholder: "최대 점수 상한선을 입력하십시오.",
              call: "음성 통화",
              dialogue: "텍스트 대화",
              user: "품질 검사관",
              wechatUser: "연산자",
              noDesignated: "지정하지 않음",
              type: "형태 유형",
              singleScore: "단일 점수",
              conduct: "다음 정보를 먼저 완료 한 다음 다음을 클릭하십시오.",
              conduct2:
                "품질 검사 규칙 및 품질 검사 포인트의 구성을 완료하십시오. 구성을 완료 한 경우 다음 또는 종료 안내서를 클릭하십시오.",
              conduct3:
                "품질 검사 등급은 품질 검사 지점 및 등급에 따라 구성됩니다. 다른 품질 검사 결과 수준을 정의할 수 있습니다. 등급이 필요하지 않으면이 단계를 건너 뛸 수 있습니다. 구성을 완료한 경우 다음 을 클릭하거나 가이드를 종료할 수 있습니다.",
              conduct4:
                "작업이 자동 추가 위치에서 확인해야 하는 데이터 특성에 따라 작업에 자동으로 추가될 데이터 특성을 구성할 수 있습니다. 당분간 데이터를 자동으로 추가할 필요가 없는 경우 건너뛰기 를 클릭할 수 있습니다. 구성을 완료한 경우 다음 을 클릭합니다.",
              conduct5:
                "모노 녹음을 사용하는 경우이 항목을 구성하고 다음을 클릭하십시오. 고객 서비스의 음성 만 사용하여 시스템이 고객 서비스와 고객 간의 다른 트랙을 식별 할 수 있도록 도와주세요. 2 채널 녹음을 사용하거나 구성을 완료 한 경우 건너 뛰기 를 클릭하십시오.",
              conduct6:
                "수동 추가 기능을 사용하여 품질 검사를 위해 시스템에 기존 통신 데이터를 소개하거나 수동 추가 작업을 제출 한 경우 직접 마무리 를 클릭하십시오.",
              helper: "도움말 문서 보기",
              quit: "출구 부트",
              next: "다음 단계",
              complete: "완료",
              skip: "건너 뛰기",
              cancel: "취소",
              delete: "삭제",
              save: "저장",
            },
            template: {
              samplingInspector: "샘플링 검사관",
              sessionType: "세션 유형",
            },
          },
        },
        workbench: {
          qualityCheckList: {
            index: {
              name: "품질 검사 작업대",
              search: "필터",
              export: "수출",
              condition: "조건",
              logic: "논리",
              reQualityInspection: "재검사",
              reQualityInspectionUnderWay: "재검사 작업...",
              reQualityInspectionUnderWayTip:
                "창을 닫은 후 페이지를 떠나면 작업에 영향을 미치지 않습니다.",
              reQualityInspectionDone: "재 검사 완료",
              reQualityInspectionDoneTip:
                "재검사가 성공적으로 완료되었으므로 확인 버튼을 클릭하십시오.",
              endReQualityInspection: "작업 종료",
              alreadyDoneTip: "완료",
              totalTip: "합계",
              unit: "기사",
              endReInspectionSuccess: "재질 작업 종료",
              closeWindow: "닫기 창",
              endReInspectionFailed:
                "다시 품질 작업 실패, 나중에 다시 시도하십시오!",
            },
            detail: {
              name: "품질 검사 세부 사항",
              reviewName: "세부 사항 검토",
              spotCheckName: "샘플링 세부 사항",
              audioText: "기록 된 텍스트",
              callInfo: "통화 정보",
              satisfactionEvaluation: "만족도 평가:",
              queueTime: "대기 시간:",
              ringingTime: "링 시간:",
              callTime: "전화 이벤트:",
              hangUpIdentity: "전화 끊어진 파티:",
              location: "홈:",
              source: "출처:",
              agent: "고객 서비스:",
              deviceType: "장치 유형:",
              callType: "전화 유형:",
              phoneNumber: "릴레이 번호:",
              task: "작업:",
              commentsTip: "품질 검사 의견을 입력하십시오",
              lastPage: "이전 페이지",
              nextPage: "다음 페이지",
              score: "품질 검사 점수",
              review: "검토",
              file: "보관",
              changeList: "기록 변경",
              fullMarks: "전체 마크",
              most: "대부분",
              saveReview: "제출 검토",
              saveSpotCheck: "샘플링 검사 및 제출",
              point: "품질 검사 포인트",
              sampleAssignInspector: "샘플링 검사관 할당",
              reviewAssignInspector: "검토 자 할당",
              sampleStatus: "샘플링 상태",
              read: "읽기",
              user: "사용자",
              confirmer: "확인된 사람",
              voiceDownloadError:
                "음성 다운로드가 실패했습니다. 나중에 다시 시도하십시오!",
              smartTags: "스마트 라벨",
              interactiveTip:
                "상호 작용 인식을 사용하여 고객 서비스 FAQ의 사용량 감지",
              interactiveIsEmptyTip: "인터랙티브 인식 콘텐츠가 감지되지 않음",
              businessRecords: "사업 기록",
            },
          },
        },
        reports: {
          groupChatAnalysis: {
            index: {
              analysisOfEnterpriseWeChatGroupChat: "기업 WeChat 그룹 채팅 분석",
            },
          },
        },
      },
      cards: {
        health: "품질 검사 건강",
        accuracy: "발견 승인률",
        sessionsTotal: "총 세션",
        enable: "활성화",
        disable: "비활성화",
        none: "아니",
        call: "전화",
        dialogue: "대화",
        taobao: "전자 상거래",
        wecom: "세션",
        ticket: "작업 순서",
      },
      component: {
        backup: "(백업)",
        taskSaveAsSucceeded: "성공적으로 저장된 작업",
        copyContent: "콘텐츠 복사",
        essentialInformation: "기본 정보",
        ruleConfiguration: "규칙 구성",
        basicInformationQualityInspector: "기본 정보-품질 검사관",
        qualityInspectionRating: "품질 검사 등급",
        roleConfiguration: "역할 구성",
        autoAddConfiguration: "자동으로 구성 추가",
        autoPushConfiguration: "자동 푸시 구성",
        automaticSamplingConfiguration: "자동 샘플링 구성",
        automaticAllocationConfiguration: "자동 할당 구성",
        businessConfiguration: "비즈니스 구성",
        caseBaseConfiguration: "케이스 라이브러리",
      },
      template: {
        cancel: "취소",
        complete: "완료",
        sort: "정렬",
        ruleApproval: "규칙 승인",
        enterpriseMicroConversationAnalysisTask:
          "엔터프라이즈 마이크로 세션 분석 작업",
        enterpriseAndMicroVoiceAnalysisTask:
          "엔터프라이즈 마이크로 음성 분석 작업",
        saveTaskAs: "작업 저장",
      },
      approve: {
        checkPoint: { index: { ruleDetails: "규칙 세부 사항" } },
        components: {
          autoApproveSwitch: {
            modificationSucceeded: "수정 성공",
            automaticApproval: "자동 승인",
          },
          pageHeaderExtra: {
            modificationSucceeded: "수정 성공",
            test: "테스트",
            formal: "정식",
            adopt: "에 의해",
          },
          rejectButton: {
            index: {
              areYouSureToReject: "거부 할 것이 확실합니까?",
              pleaseEnterTheRejectionReason: "거부 사유를 입력하십시오.",
              reject: "거부",
            },
          },
          table: {
            index: {
              modificationSucceeded: "수정 성공",
              taskName: "작업 이름",
              dataSource: "데이터 소스",
              qualityInspectionType: "품질 검사 유형",
              state: "상태",
              updateTime: "업데이트 시간",
              updatedBy: "업데이트 사람",
              approver: "접근자",
              reasonForRejection: "거부 이유",
              operation: "운영",
              details: "세부 사항",
              adopt: "에 의해",
              whole: "모두",
              approvalStatus: "승인 상태",
            },
          },
        },
      },
    },
    dashboard: {
      title: "개요",
      hi: "안녕,",
      standardEdition: "표준 판",
      call: "음성 품질 검사 균형",
      text: "텍스트 품질 검사의 유효 기간",
      collectionTasks: "수집 된 품질 검사 작업",
      noCollection: "품질 검사 작업의 수집 없음,",
      goToCollecte: "컬렉션으로 이동",
      yesterdayOverView: "어제의 비즈니스 통계",
      yesterdayAgent: "어제의 고객 서비스 목록",
      yesterdayInspWork: "품질 검사관의 어제의 작업 상황",
      operationalStatistics: {
        title: "비즈니스 통계",
        qualityTesting: "품질 검사",
        spotCheck: "무작위 검사",
        appeal: "불만",
        chartTitles: {
          qualityTestingTrend: "품질 검사 볼륨 추세",
          qualityTestingAnalysis: "품질 검사 작업",
          spotCheckTrend: "샘플링 트렌드",
          spotCheckAnalysis: "샘플링 작업 상황",
          appealTrend: "고충 동향",
          appealAnalysis: "항소 명령의 상태",
        },
        titles: {
          qualityDataTend: "품질 검사 데이터 배포",
          qualityCover: "품질 검사 범위",
          qualityAverageTime: "평균 품질 검사 대기 시간",
          qualityCoverTend: "품질 검사 범위 추세",
          handCover: "수동 처리",
          checkCover: "샘플링 커버리지",
          resAverageTime: "평균 처리 시간",
          handTend: "수동 처리 동향",
          dataTend: "데이터 작업 배포",
        },
        qualityAverage: "평균 품질 검사 점수",
        scoreTrend: "품질 검사 점수 추세",
        qualityAverageTime: "평균 품질 검사 대기 시간",
        callCoverage: "음성 품질 검사 범위",
        textCoverage: "텍스트 품질 검사 범위",
        checkCount: "샘플링 데이터 볼륨",
        siphonCount: "데이터 볼륨 추출",
        reviewCount: "데이터 볼륨 검토",
        appealCount: "불만 데이터 볼륨",
        checkRate: "무작위 검사의 비율",
        reviewRate: "고충의 비율 검토",
        PieTitle: {
          totalCount: "총 대화 수",
          totalCall: "음성 통화 수",
          totalIm: "텍스트 대화 수",
          inspectionCall: "음성 품질 검사 수량",
          inspectionIm: "텍스트 품질 검사 수량",
          checkCall: "음성 샘플링 수량",
          unCheckCall: "음성이 샘플링되지 않음",
          checkIm: "텍스트 샘플링 수량",
          unCheckIm: "샘플링되지 않은 텍스트 수량",
          reviewCall: "음성 리뷰 볼륨",
          reviewIm: "텍스트 검토 수량",
          unReviewCall: "음성 검토되지 않은 수량",
          unReviewIm: "검토되지 않은 텍스트 양",
          unInspectionCall: "음성 수량이 확인되지 않음",
          unInspectionIm: "확인되지 않은 텍스트 수량",
        },
        check_rate: "샘플링 커버리지",
        voice_check_rate: "음성 샘플링 범위",
        text_check_rate: "텍스트 샘플링 범위",
        sample_execute_time: "평균 샘플링 처리 시간",
        review_execute_time: "평균 고충 처리 시간",
      },
      analysisOverview: {
        tableTitle: {
          conditions: "품질 검사 규칙 칸반",
          agent: "고객 서비스 순위 목록",
        },
        tableTips: {
          conditions:
            "<Div> 는 품질 검사 규칙을 위반하고 준수하는 총 대화 수를 정렬합니다. <Br/>\n품질 검사 항목: 품질 검사 지점의 이름 <br />\n불법 대화의 총 수: 이 품질 검사 항목에 대한 불법 대화의 총 수 <br />\n위반 비율: 위반 비율 = 총 위반 건수/총 위반 건수 </div>",
          agent:
            "<Div> 에이전트의 평균 등급 정렬 <br />\n고객 서비스 이름: 고객 서비스의 이름 <br />\n고객 서비스 그룹: 고객 서비스가 위치한 고객 서비스 그룹 (고객 서비스 정보의 조직 정보)<br />\n채점 된 대화의 총 수: 고객 서비스의 총 품질 검사 대화 수 <br />\n평균 품질 검사 점수: 에이전트의 평균 품질 검사 점수 (총 점수/총 대화 수)</div>",
        },
        tips: {
          total: "품질 검사 작업에 입력 된 총 패스/대화 수",
          agentTotal: "품질 검사 작업에 들어간 품질 검사 에이전트의 총 수",
          avg: "품질 검사 점수의 평균 점수",
          violation: "합산 모든 합격 대화 품질 검사 항목의 횟수 및 위반",
          compliance:
            "대화를 통해 품질 검사 과정에서 품질 검사 항목이 타격되는 횟수를 합산합니다.",
          check: "수동 샘플링의 총 수",
          appeal: "총 대화 횟수에 제출 된 총 불만 건수",
          review: "총 통신 대화 수에서 제출 된 총 리뷰 수",
        },
        subTips: {
          violation: "위반율: 총 위반 횟수/품질 검사 항목의 총 수 * 100%",
          compliance: "준수 비율: 총 준수 횟수/품질 검사 항목의 총 수 * 100%",
          check: "샘플링 범위: 총 수동 샘플링/총 품질 검사 * 100%",
          appeal: "항소율: 총 항소 수/총 품질 검사 수 * 100%",
          review: "검토율: 총 리뷰 수/총 품질 검사 수 * 100%",
        },
      },
    },
    admin: {
      system: {
        index: {
          name: "시스템 설정",
          systemName: "시스템 이름",
          speakerRecognize: "스피커 식별",
          massage: "메시지 알림",
          evaluate: "인공인지 평가",
          evaluateTip:
            "개봉 후 품질 검사 세부 사항 및 신속한 수동 평가에서 빠른 현장 검사를 선택하십시오.",
          speakerRecognizeTip:
            "API를 통해 제 3 자 통화 기록에 액세스하려면 왼쪽 채널이 고객 서비스인지 고객인지 확인해야합니다.",
          speakerRecognizeTip2:
            "단일 채널 레코딩은 텍스트 인식 및 역할 구성을 사용하여 에이전트의 역할을 정의합니다.",
          enabledImSendStatusFilter: "텍스트 품질 검사 메시지 필터링",
          enabledImSendStatusFilterTips:
            "품질 검사에서 운영자 음성의 탐지 내용은 [철회], [보내기 실패] 및 [오프라인 메시지 읽지 않음] 상태의 메시지를 포함하지 않습니다.",
          lang: "시스템 언어 설정",
          zh: "중국어 간체",
          en: "English",
          tw: "중국 전통",
        },
        template: {
          largeModelSettings: "대형 모델 설정",
          indonesian: "인도네시아",
          vietnamese: "베트남",
          portuguese: "포르투갈어",
          malay: "말레이",
          french: "프랑스어",
          spanish: "스페인어",
          japanese: "일본어",
          thai: "태국",
          blockSystemSendingMessages: "마스크 시스템 메시지 보내기",
          textDataDesensitization: "텍스트 데이터 감작",
          whenTheSwitchIsTurnedOnTheDesensitizationFieldDropdownListWillBeDisplayedmultipleChoicesAreSupportedTheDesensitizationFieldsPersonNameMobileNumberAddress:
            "스위치가 켜지면 감작 필드 드롭 다운 목록이 표시됩니다 (다중 선택 지원). 탈감작 필드에는 사람의 이름, 휴대폰 번호 및 주소가 포함됩니다.",
          desensitizingField: "탈감작 필드",
          name: "사람의 이름",
          cellphoneNumber: "휴대 전화 번호",
          address: "주소",
          dataCleaning: "데이터 클렌징",
          afterSettingTheConversationDataOfQCAndWeChatWillBeCleanedRegularlyAndCannotBeRecoveredPleaseBeCareful:
            "설정 후 품질 검사 대화 및 엔터프라이즈 WeChat 세션 데이터가 정기적으로 정리되어 복구 할 수 없습니다. 주의 깊게 운영하십시오.",
          newCleaningRules: "새로운 정리 규칙",
          qualityInspectionRuleApproval: "품질 검사 규칙 승인",
          afterOpeningTheModificationOfQualityInspectionRulesCanTakeEffectOnlyAfterBeingApproved:
            "개봉 후 품질 검사 규칙 수정이 적용되기 전에 승인되어야합니다.",
          maskOfflineMessageUnread: "마스크 오프라인 메시지를 읽지 않음",
          privacyNumberProtection: "개인 정보 보호 번호",
          afterOpeningDesensitizeThecustomerPhoneFieldInTheCallWhichIsDisplayedAs1881:
            '개봉 후 통화 중에 "고객 전화" 필드를 탈감세하고 1881 * * * * * * * * * 로 표시하십시오.',
          shieldingWithdrawn: "마스크가 철회되었습니다.",
          maskSendingFailed: "마스크 전송 실패",
        },
        component: {
          conversation: "전화",
          dialogue: "대화",
          weCom: "엔터프라이즈 위챗",
          day: "날",
          week: "주",
          month: "달",
          year: "년",
        },
        components: {
          dataDeleteConfigItem: {
            scopeOfCleaning: "청소 범위",
            dataCleaningTimeSetting: "데이터 클렌징 시간 설정",
            afterCleanUpTheData: "데이터를 청소 한 후",
            dataAfterComplaintCompletion: "불만 제기 후 데이터",
          },
        },
        coachSpeechRecognition: {
          executionSuccessful: "성공적인 실행",
          saveSuccessful: "성공적으로 저장",
          speechRecognition: "음성 인식",
          reExecuteErrorCorrection: "오류 수정 다시 수행",
          confirmExecution: "실행 확인",
          voiceDetection: "음성 감지",
          noiseDetectionPowerLevel: "소음 감지 파워 레벨 ≤",
          pleaseEnterTheThreshold: "임계 값을 입력하십시오.",
          volumeDetectionPowerLevel: "볼륨 감지 파워 레벨 ≥",
          reset: "리셋",
          preserve: "저장",
        },
        coachTemplate: {
          french: "프랑스어",
          spanish: "스페인어",
          japanese: "일본어",
          thai: "태국",
          loginPopupWindow: "로그인 팝업 창",
          afterActivationAPopupPromptWillAppearWhenEnteringTheIntelligentAccompanimentSystem:
            "개봉 후 지능형 스파링 시스템에 들어갈 때 팝업 창이 표시됩니다.",
          title: "제목",
          text: "텍스트",
          checkBoxContent: "체크 박스 내용",
          informedConsentForm: "정보 동의 양식",
          toHelpYouImproveYourCommunicationAndCommunicationSkillsAsWellAsTheApplicationOfProfessionalKnowledgeAllowingYouToPracticeAnytimeAnywhereAndReceiveRealtimeFeedbackWeWillCollectTheFollowingInformationFromYouThroughThisPlatform:
            "언제 어디서나 연습하고 실시간 피드백을받을 수 있도록 의사 소통 및 언어 기술 및 전문 지식 응용 프로그램을 향상시킬 수 있도록 돕기 위해이 플랫폼을 통해 다음 정보를 수집합니다.",
          iHaveFullyReadAndFullyUnderstoodThisInformedConsentFormAndSolemnlyDeclareThatIAgreeToProcessMySensitivePersonalInformationInAccordanceWithThePurposesAndMethodsStatedInThisInformedConsentForm:
            "나는이 정보에 입각 한 동의 양식을 읽고 완전히 이해했으며, 이 정보 동의 양식에 명시된 목적과 방식에 따라 민감한 개인 정보를 처리하는 데 엄숙하게 동의합니다.",
        },
      },
      systemIntegration: {
        title: "시스템 설정",
        ccIntegrationTips: "지능형 품질 검사 도킹 Udesk 콜센터 설명 문서",
        unauthorized: "무허가",
        authorized: "공인",
        udeskDomain: "고객 서비스 시스템 도메인 이름",
        udeskToken: "에이전트 시스템 키",
        udeskAdminEmail: "고객 서비스 시스템 관리자 계정",
        password: "관리자 계정 암호",
        applyAuth: "승인",
        cancelAuth: "허가 취소",
        reApplyAuth: "재승인",
        keyTips: "고객 서비스 시스템의 배경 관리-단일 사인온의 API 인증 토큰",
      },
      customerField: {
        title: "고객 분야",
        table: {
          propName: "필드 이름",
          propDesc: "필드 설명",
          valueType: "필드 유형",
          propType: "창조 방법",
          createTime: "창조 시간",
          canNotEdit: "시스템 필드는 편집을 지원하지 않습니다.",
        },
        edit: "필드 편집",
        new: "새로운 분야",
      },
      wechatDocking: {
        title: "엔터프라이즈 마이크로 도킹",
        formLabels: {
          companyId: "기업 ID",
          communicationKey: "통신 키",
          token: "Token",
          encodingAESKey: "EncodingAESKey",
          url: "이벤트 콜백 URL",
          applicationName: "응용 프로그램 이름",
          applicationId: "웹 응용 프로그램 ID",
          applicationSecret: "비밀의 적용",
        },
        formTitle: {
          initialForm: "초기 설정",
          synchronizeForm: "주소록 동기화",
          settingForm: "응용 프로그램 설정",
        },
        qrCodeUrlTip:
          "관리자에게 아래의 2 차원 코드를 스캔하여 개발 응용 프로그램을 바인딩하도록 요청하십시오.",
      },
      taobao: {
        formLabels: {
          name: "상점 이름",
          account: "계정 번호",
          password: "암호",
          confirm: "비밀번호 확인",
          authorization: "승인",
          switchOn: "오픈",
          switchOff: "닫기",
        },
        tableLabels: {
          name: "상점 이름",
          account: "계정 번호",
          authorization: "승인",
          updateUser: "최근 업데이트 된 사람",
          updateTime: "마지막 업데이트 시간",
        },
      },
      weifengDocking: {
        formLabels: {
          secondaryDomain: "Weifeng 보조 도메인 이름",
          secretKeyTip:
            "세입자 비밀 키는 Weifeng 시스템 배경 시스템의 도킹 장소에서 얻어진다.",
        },
      },
      user: {
        index: {
          name: "직원 관리",
          existingUsers: "이미 직원이 있습니다.",
          deleteSuccess: "성공적으로 삭제",
          deleteFailed: "삭제 실패",
        },
        detail: {
          accountPrompt: "사서함을 입력하십시오.",
          resetPassword: "암호 재설정",
          passwordPrompt: "암호 (문자와 숫자의 6-14 조합)",
          originPasswordPrompt: "초기 암호를 입력하십시오.",
          resumePasswordPrompt: "비밀번호를 다시 입력하십시오.",
          passwordFormWrong: "6-14 글자와 숫자의 조합을 입력하십시오!",
          resumePasswordWrong: "두 개의 암호가 일치하지 않습니다!",
          suppertImageType: "지원 jpg, gif, png, 최대 500Kb",
          namePrompt: "이름을 입력하십시오.",
          nickNamePrompt: "닉네임을 입력하십시오.",
          telephonePrompt: "전화 번호를 입력하십시오",
          roleSelectedPrompt: "역할을 선택하십시오.",
          agentIdPrompt: "작업 번호를 입력하십시오.",
          disabled: "비활성화",
          enabled: "활성화",
          reloadBowerInfo: "사용자 정보 변경, 브라우저를 새로 고침해야합니까?",
          saveUserError:
            "사용자 정보를 저장하지 못했습니다. 나중에 다시 시도하십시오.",
          resetPasswordSuccess: "암호 재설정 성공",
          resetPasswordFailed: "암호 재설정 실패, 나중에 다시 시도하십시오",
        },
      },
      userGroups: {
        index: { name: "직원 그룹 관리", existingUserGroups: "기존 직원 그룹" },
        detail: {},
      },
      role: {
        index: { name: "역할 관리", existingRoles: "기존 역할" },
        edit: {
          editRole: "역할 편집",
          createRole: "역할 만들기",
          basicInfo: "기본 정보",
          taskPermissions: "작업 권한",
          existingTasks: "이미 작업",
        },
      },
      anomalousData: {
        title: "비정상적인 데이터",
        list: {
          selectHolder: "데이터 소스를 선택하십시오.",
          businessTime: "비즈니스 시간",
          businessId: "비즈니스 ID",
          export: "수출",
        },
      },
      templateComponent: {
        list: {
          title: "템플릿 시장",
          name: "템플릿 이름",
          searchHolder: "쿼리할 내용을 입력하십시오.",
          update: "업데이트",
          sort: "정렬",
          moduleType: "템플릿 유형",
          moduleSourceType: "적용 가능한 유형",
          fetchNum: "취득한 횟수",
          newest: "최근 업데이트",
          usedMost: "최대한 활용",
          call: "전화",
          dialogue: "대화",
          waiting: "검토 대상",
          cancel: "게시 취소",
          success: "승인 및 게시",
          failed: "감사 거부",
          no: "업그레이드 불가",
          ok: "업그레이드 가능",
          all: "모두",
          delete: "성공적으로 삭제",
          downOk: "성공적으로 제거",
          withdraw: "철수 성공",
          updateOk: "성공적인 업그레이드",
          get: "얻기",
          width: "철수",
          getOk: "인수 성공",
          orgName: "조직",
          nowVersion: "현재 버전",
          lastedVersion: "최신 버전",
          appStatus: "신청 상태",
          actions: "운영",
          down: "선반에서",
          updateTop: "업그레이드",
          check: "보기",
          deleteWhen: "삭제 확인?",
          yes: "예",
          noo: "아니",
          deleteOk: "삭제",
          permissible: "허용",
          noPermissible: "허용되지 않음",
          openUp: "오픈",
          openDown: "열리지 않음",
          task: "품질 검사 작업",
          point: "품질 검사 포인트",
          tag: "시맨틱 라벨",
          muti: "여러 요소",
          inter: "하나의 질문과 하나의 대답",
          info: "정보 엔티티",
          concept: "개념 단어",
          detail: "모듈 세부 정보",
          infomation: "기본 정보",
          detailConfig: "상세 구성",
          twoEdit: "보조 편집",
          mustUpdate: "지금 업그레이드",
          descition: "템플릿 설명",
          descitionSize: "템플릿 설명은 250 단어를 초과 할 수 없습니다",
          stencilsDetail: "템플릿 세부 정보",
          updatedRecords: "업데이트 기록",
          version: "버전 번호",
          updateDescription: "업데이트 설명",
          myModule: "내 템플릿",
          addModule: "새로운 모듈",
          publishVersion: "릴리스 버전",
          earliestVersion: "가장 초기 버전",
          edit: "편집",
          add: "새로운",
          configNo: "모듈 구성 세부 정보를 비울 수 없습니다.",
          updateNo: "업데이트 설명을 비울 수 없습니다.",
          editSuccess: "수정 성공",
          saveSuccess: "성공적으로 저장",
          newer: "업데이트",
          sumbit: "제출",
          cancelName: "취소",
          nameRequired: "모듈 이름은 필수 필드입니다.",
          nameSize: "모듈 이름은 16 자를 초과 할 수 없습니다",
          publishStatus: "게시 상태",
          submitUpdate: "업데이트 제출",
          updateVersion: "버전 업데이트",
          updateExplain: "업데이트 지침",
        },
      },
      customDataSource: {
        amazonEcommerce: "아마존 전자 상거래",
        youtubeVideo: "유튜브 비디오",
        amazon: {
          component: {
            queuing: "큐잉",
            notStarted: "시작되지 않음",
            inProgress: "진행 중",
            success: "성공",
            failed: "실패",
            successfullyCreated: "성공적으로 생성",
            editedSuccessfully: "성공적으로 편집",
            operationSucceeded: "작전 성공",
            deletedSuccessfully: "성공적으로 삭제",
            link: "링크",
            grabState: "잡아 상태",
            updateTime: "업데이트 시간",
            grab: "잡아",
            edit: "편집",
          },
          template: {
            amazonEcommerce: "아마존 전자 상거래",
            thisProduct: "이 제품",
            competition: "경쟁",
            new: "새로운",
            edit: "편집",
            link: "링크",
          },
        },
        youtube: {
          template: {
            youtubeVideo: "유튜브 비디오",
            thisProduct: "이 제품",
            competition: "경쟁",
            new: "새로운",
            edit: "편집",
            link: "링크",
          },
          component: {
            queuing: "큐잉",
            notStarted: "시작되지 않음",
            inProgress: "진행 중",
            success: "성공",
            failed: "실패",
            successfullyCreated: "성공적으로 생성",
            editedSuccessfully: "성공적으로 편집",
            operationSucceeded: "작전 성공",
            deletedSuccessfully: "성공적으로 삭제",
            youTubeVideoLink: "유튜브 비디오 링크",
            grabState: "잡아 상태",
            updateTime: "업데이트 시간",
            grab: "잡아",
            edit: "편집",
          },
        },
      },
    },
    demo: {
      title: "지능형 품질 검사",
      template: "품질 검사 템플릿 선택",
      radio: "녹음 양식 선택",
      uploadAudio: "녹음 업로드",
      uploadAudioTip:
        "업로드 된 녹음은 wav 및 mp3 형식 만 지원하며 크기는 1G 를 초과하지 않습니다.",
      uploadText: "텍스트 업로드",
      uploadTextTip:
        "업로드 된 텍스트는 srt 및 txt 형식 만 지원하며 크기는 10M 을 초과하지 않습니다. 텍스트를 업로드 한 후 ASR은 더 이상 기록 파일을 구문 분석하는 데 사용되지 않습니다.",
      uploadSuccess: "성공적으로 업로드 된 파일 기록",
      uploadFailed: "녹음 파일을 업로드하지 못했습니다.",
      startQualityInspection: "품질 검사 시작",
      qualityInspectionStarted: "진행중인 품질 검사",
      downloadText: "텍스트 다운로드",
      checking: "품질 검사 진행",
      checkingTip: "몇 분 걸릴 것으로 예상, 인내심을 가지십시오",
      noCheckContent: "아직 품질 검사 결과 없음",
      noTextDownload: "품질 검사 텍스트를 다운로드 할 수 없습니다!",
      pleaseCheckParams:
        "템플릿, 음성 유형을 선택하고 녹음 파일을 업로드할지 확인하십시오.",
      inspectionFailed: "품질 검사 실패, 나중에 다시 시도하십시오!",
    },
    wechatQa: {
      baseInfo: { title: "기본 정보" },
      conversationSetting: {
        title: "세션 설정",
        segmentationStrategy: "슬라이싱 전략",
        rule: "전략",
        time: "절단 시간",
        daily: "매일",
        passScore: "세션 적격 점수",
        ruleLogic: "실행 논리",
      },
      message: {
        title: "푸시 이벤트",
        table: {
          index: "일련 번호",
          name: "이름",
          type: "푸시 모드",
          desc: "설명",
          status: "상태",
        },
        subTitle: "이벤트 목록",
      },
      checkPoint: {
        title: "분석 모델",
        groups: "모델 그룹화",
        newClassification: "새로운 그룹",
        editClassification: "그룹 편집",
        benchmarkScore: "벤치 마크 점수",
        pointCount: "현재 분류에 따른 일반적인 분석 모델",
        openPointCount: "사용 가능한 수는",
        closedPointCount: "비활성화 수량은",
        scoreLowerLimit: "최소 점수 하한 한도",
        scoreUpperLimit: "최대 점수 캡",
        classificationName: "그룹 이름",
        classificationNamePlaceHolder: "그룹 이름을 입력하십시오.",
        classificationRemark: "그룹 설명",
        classificationRemarkPlaceHolder: "그룹 설명을 입력하십시오.",
      },
      chartRecord: {
        title: "엔터프라이즈 위챗 세션",
        table: {
          id: "세션 ID",
          type: "세션 유형",
          startTime: "세션 시작 시간",
          endTime: "세션 마지막 종료 시간",
          groupId: "그룹 ID",
          client: "고객",
          customer: "고객 서비스",
          averageScore: "평균 품질 검사 점수",
        },
        detail: {
          title: "엔터프라이즈 위챗 세션 세부 정보",
          inspectionInfo: "분석 정보",
          chartInfo: "세션 정보",
          tasks: "분석 작업",
          avgScore: "분석의 평균 점수",
          qualified: "자격",
          unqualified: "무자격",
          taskTitle: "적용 가능한 분석 템플릿",
          info: "정보의 실시간 분석",
          today: "오늘의 뉴스",
          history: "역사적 메시지",
          components: {
            filter: {
              index: {
                pleaseEnterACommentNickname: "코멘트/닉네임을 입력하십시오.",
                pleaseEnterTheGroupChatName: "그룹 채팅 이름을 입력하십시오.",
              },
            },
          },
          template: { whole: "모두" },
        },
      },
      realTimeAnalysis: {
        title: "실시간 분석",
        table: {
          name: "장면 이름",
          count: "분석 모델 수",
          baseScore: "벤치 마크 점수",
          passScore: "적격 점수",
          maxScore: "최대 점수 캡",
          minScore: "최소 점수 하한 한도",
          status: "상태",
          actions: "운영",
        },
        detail: {
          title: "시나리오의 실시간 분석",
          form: {
            name: "장면 이름",
            prevCondition: "시나리오 트리거 조건",
            prevConditionToolTip:
              "조건이 비어 있으면 시나리오가 기본적으로 분석됩니다.",
            prevConditionError:
              "시나리오 트리거 조건은 분석 모델과 일치 할 수 없습니다.",
            scoreInputPlaceholder: "점수 입력",
            baseScore: "벤치 마크 점수",
            status: "상태",
            passScore: "적격 점수",
            maxScore: "최대 점수 상한",
            minScore: "최소 점수의 하한 한도",
            analysisModel: "분석 모델",
            message: "트리거 알림",
            pushType: "푸시 모드",
            createAnalysisModel: "모델 추가",
            scoreUpperLimitError:
              "최소 점수의 하한은 최대 점수의 상한보다 클 수 없습니다.",
            table: { name: "모델 이름", rule: "규칙", score: "점수" },
            pushConfigSwitchTip1: "장면이 나타날 때",
            pushConfigSwitchTip2: "시간 이상, 푸시 알림",
          },
        },
      },
      smartCustomerTag: {
        title: "스마트 고객 라벨링",
        table: {
          name: "규칙 이름",
          tags: "엔터프라이즈 마이크로 고객 라벨",
          status: "사용 상태",
          createUser: "창조주",
          createTime: "창조 시간",
        },
        detail: {
          name: "규칙 이름",
          condition: "규칙 설정",
          logic: "논리",
          status: "사용 상태",
          tags: "라벨 선택",
        },
      },
      setting: {
        wechatQaCustomerLabel: {
          template: { numberOfCustomerTags: "고객 표시 수량" },
        },
        messageFiltering: {
          template: {
            edit: "편집",
            messageContent: "메시지 내용",
            messageTypeID: "메시지 유형 ID",
            messageTypeDescription: "메시지 유형 설명",
            messageFiltering: "메시지 필터링",
            suitableForFilteringDataWithoutQualityInspection:
              "품질 검사없이 데이터 필터링에 적합",
            create: "만들기",
            messageDescription: "메시지 설명",
          },
        },
      },
      chartAudioRecord: {
        index: {
          talkTime: "대화 시간",
          sessionType: "세션 유형",
          whole: "모두",
          staff: "직원",
          customer: "고객",
          callKeywords: "전화 키 단어",
        },
        detail: {
          template: {
            enterpriseMicroVoiceCallDetails:
              "엔터프라이즈 마이크로 음성 통화 세부 정보",
          },
        },
      },
      wechatQaChartRecordEntry: {
        sessionArchiving: "세션 아카이브",
        voiceCall: "음성 통화",
        noMoreVoiceCalls: "더 이상 음성 통화 없음",
        enterpriseWechatVoiceCall: "엔터프라이즈 WeChat 음성 통화",
        enterpriseWechatConversationDetails: "엔터프라이즈 위챗 세션 세부 정보",
        previous: "이전 기사",
        next: "다음 기사",
      },
      dashboard: {
        template: {
          enterpriseMicroConversationAnalysisTask:
            "엔터프라이즈 마이크로 세션 분석 작업",
          enterpriseAndMicroVoiceAnalysisTask:
            "엔터프라이즈 마이크로 음성 분석 작업",
        },
      },
      wechatQaTaskManagerEntry: {
        analysisOfEnterpriseWeChatGroupChat: "기업 WeChat 그룹 채팅 분석",
      },
      wechatQaSettingEntry: { messageFiltering: "메시지 필터링" },
    },
    businessAnalysis: {
      keyword: "보고서 이름",
      createUser: "창조주",
      createTime: "창조 시간",
      updateTime: "업데이트 시간",
    },
    semanticIntelligence: {
      applicationMessageType: "응용 프로그램 메시지 유형",
      intelligentModel: {
        components: {
          promptArea: {
            index: {
              pleaseImproveTheConfiguration: "완벽한 구성",
              generatePromptLanguage: "프롬프트 생성",
            },
          },
          typeSelect: {
            index: {
              whetherItMeetsTheRequirements: "준수",
              gradedEvaluation: "등급 평가",
            },
          },
          training: {
            index: {
              pleaseEnter: "입장해주세요",
              pleaseGeneratePrompt: "프롬프트를 생성하십시오",
              pleaseSelectADialogue: "대화를 선택하십시오.",
              training: "훈련",
              dataType: "데이터 유형:",
              voiceCall: "음성 통화",
              textDialogue: "텍스트 대화",
              generate: "생성",
              qualityInspectionResults: "품질 검사 결과:",
              hit: "히트:",
            },
          },
        },
        detail: {
          index: {
            modelName: "모델 이름",
            pleaseEnterTheModelNameForExampleIsThereASituationWhereCustomerServiceIsInactive:
              "예를 들어 에이전트에 작업이 없는지 여부와 같이 모델 이름을 입력하십시오.",
            modelDescription: "모델 설명",
            canYouProvideADetailedDescriptionOfTheContentYouNeedToTestForTheModelsUnderstandingSuchAsWhetherThereIsASituationWhereCustomerServiceIsInactiveThatIsCustomerServiceHasRespondedToCustomerInquiriesButTheCustomersQuestionsHaveNotBeenAddressedThroughoutOrTheResponseContentIsUnrelatedToTheCustomersResponse:
              "테스트해야 할 사항을 자세히 설명 할 수 있으므로 모델을 쉽게 이해할 수 있습니다. 예를 들어, 고객 서비스에 아무런 조치가 없는지, 즉 고객 서비스가 고객의 질문에 응답했지만 고객의 질문이 처리되지 않았거나 답신의 내용이 고객의 답변과 관련이 없습니다.",
            validity: "효과",
            effective: "효과적인",
            invalid: "무효",
            type: "유형",
            hintLanguage: "프롬프트",
            editIntelligentModel: "스마트 모델 편집",
            createANewIntelligentModel: "새로운 스마트 모델",
          },
        },
        index: {
          modelName: "모델 이름",
          modelDescription: "모델 설명",
          type: "유형",
          whetherItMeetsTheRequirements: "준수",
          gradedEvaluation: "등급 평가",
          validity: "효과",
          intelligentModel: "지능형 모델",
          applicableToSubjectiveQualityInspectionsuchAsWhetherTheQualityInspectionServiceAttitudeIsEnthusiasticWhetherTheServiceIsPatientEtc:
            "주관적인 품질 검사에 적용 가능 (예: 품질 검사 서비스 태도가 열성적인지 및 서비스가 환자인지 여부)",
        },
      },
      clusterAnalysis: "클러스터 분석",
      speechMining: "마이닝 단어",
      informationEntities: {
        detail: {
          batchImportOfInformationEntitiesThroughTheAPIInterfaceIsSupportedEditingIsNotSupportedAfterSavingTheAPIName:
            "API 인터페이스를 통해 일괄 적으로 정보 엔티티를 가져올 수 있습니다. API 이름이 저장되면 편집할 수 없습니다.",
        },
        component: { nothing: "없음" },
      },
      knowledgeBase: {
        index: {
          classification: "분류",
          import: "수입",
          importKnowledgeBase: "수입 지식 기반",
          moveKnowledgePointsInBatch: "배치 이동 지식 포인트",
          moveTo: "이동:",
          pleaseSelectTheKnowledgePointsToBeMovedInBatch:
            "일괄 적으로 이동할 지식 포인트를 선택하십시오.",
        },
        detail: {
          index: { intrasentence: "문장 안에", afterProblem: "문제 후" },
        },
      },
      semanticTags: { component: { nothing: "없음" } },
      smartWordsLibrary: {
        template: {
          sensitiveWords: "민감한 단어",
          sensitiveWordsSensitiveWordsCanBeConfiguredWithBusinessFocusWordsForPublicOpinionToDiscoverSensitiveWordClouds:
            "민감한 단어: 민감한 단어는 여론에 민감한 단어 클라우드에 사용되는 비즈니스 우려 단어로 구성 할 수 있습니다.",
        },
        sensitiveWords: {
          component: {
            newSensitiveWord: "새로운 민감한 단어",
            editSensitiveWords: "민감한 단어 편집",
            sensitiveWords: "민감한 단어",
          },
          template: {
            sensitiveWords: "민감한 단어",
            sensitiveWordsCannotBeEmpty: "민감한 단어는 비어있을 수 없습니다.",
            pleaseEnterSensitiveWordsSeparatedBy:
              "중간에 민감한 단어, 여러 민감한 단어를 입력하십시오 ','",
          },
        },
      },
      clusteringAnalysis: {
        add: {
          index: {
            pleaseSelectAnApplicationScenario:
              "응용 프로그램 시나리오를 선택하십시오.",
            keyWord: "키워드",
            pleaseEnterKeywords: "키워드를 입력하십시오.",
            keywordLengthWithinCharacters: "40 자 이내의 키워드 길이",
            dataSources: "데이터 소스",
            pleaseSelectADataSource: "데이터 소스를 선택하십시오.",
            theCurrentClusteringHasBeenCompletedReEditingWillGenerateANewClusteringDataAndWillNotOverwriteTheCompletedClusteringDataPleaseConfirm:
              "현재 클러스터링이 완료되었습니다. 재편집은 새로운 클러스터링 데이터를 생성하고 완료된 클러스터링 데이터를 덮어쓰지 않을 것이다. 확인해 주세요",
            savingSucceeded: "성공적으로 저장",
            clusteringStart: "클러스터링 시작",
            clustering: "클러스터링",
            edit: "편집",
            newlyBuild: "새로운",
            clusterName: "클러스터 이름",
            pleaseEnterAName: "이름을 입력하십시오.",
            withinCharactersOfTheName: "이름 길이 40 자 이내",
            speaker: "스피커",
            pleaseSelectASpeaker: "스피커를 선택하십시오.",
            sessionDate: "세션 날짜",
            pleaseEnterTheSessionDate: "세션 날짜를 입력하십시오.",
            selectUpToMonthOfHistoricalSessionData:
              "과거 세션 데이터의 최대 1 개월 선택",
            automaticallyClusterDailyNewData:
              "매일 새로운 데이터의 자동 클러스터링",
            conversationChannel: "대화 채널",
            pleaseSelectAConversationChannel: "세션 채널을 선택하십시오.",
            applicationScenario: "응용 프로그램 시나리오",
            clusteringRules: "클러스터링 규칙",
            incompleteClusteringRuleSettings:
              "클러스터링 규칙 설정이 불완전합니다.",
            startClustering: "클러스터링 시작",
          },
          components: {
            conditionItem: {
              pleaseSelectAQualityInspectionTask: "품질 작업을 선택하십시오.",
              beEqualTo: "동등한",
            },
          },
        },
        detail: {
          index: {
            theme: "테마",
            numberOfCorpora: "말뭉치의 수",
            corpusCoverage: "코퍼스 커버리지",
            clusterDetails: "클러스터링 세부 사항",
          },
          ranking: {
            hotWords: "뜨거운 단어",
            numberOfCorpora: "말뭉치의 수",
            speaker: "스피커",
            sit: "앉아.",
            passenger: "게스트",
            hotSentence: "뜨거운 문장",
            corpusDetails: "코퍼스 세부 사항",
          },
        },
        index: {
          delete: "삭제",
          deletionSucceeded: "성공적으로 삭제",
          clusterName: "클러스터 이름",
          speaker: "스피커",
          creationTime: "창조 시간",
          state: "상태",
          operation: "운영",
          see: "보기",
          edit: "편집",
          clusterAnalysis: "클러스터 분석",
          newCluster: "새로운 클러스터",
          clusteringStatus: "클러스터링 상태",
        },
      },
      wordsMining: {
        dissent: {
          components: {
            pageLeftTable: {
              enterKeywordsToSearchForObjections:
                "이의 제기 검색을위한 키워드 입력",
            },
            pageRightTable: {
              columnHeader: {
                scriptLibrary: "연설 도서관",
                recommendedScripts: "추천 단어",
                common: "합계",
                strip: "기사",
                scriptFavorites: "단어 즐겨 찾기",
              },
              conditionPostfix: {
                detectRoles: "역할 감지",
                detectionRange: "탐지 범위",
                sentence: "문장",
              },
              customerFilter: {
                scriptFiltering: "음성 필터링",
                cancel: "취소",
              },
              index: {
                viewDetails: "세부 정보 보기",
                areYouSureYouWantToDiscardYouCannotRestoreAfterDiscarding:
                  "당신은 그것을 포기하고 싶습니까? 포기한 후에는 복원 할 수 없습니다.",
                abandonment: "폐기",
                deletionSucceeded: "성공적으로 삭제",
                passenger: "게스트",
                sit: "앉아.",
                adopted: "채택",
                determine: "결정",
                cancel: "취소",
                adopt: "입양",
              },
              pageRightFrom: {
                savingSucceeded: "성공적으로 저장",
                pleaseEnterTheScriptContent: "음성 내용을 입력하십시오.",
                pleaseSelectAFavoriteGroup: "좋아하는 그룹을 선택하십시오.",
              },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                objectionHandlingScriptLibrary: "음성 라이브러리 처리 거부",
              },
              pageRightTable: {
                index: {
                  pleaseChooseToAdoptScript: "연설을 채택하도록 선택하십시오.",
                },
              },
            },
          },
        },
        favoriteView: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "즐겨 찾기 단어 처리 거부",
            },
            pageRightTable: {
              likeCancel: "취소 된 것처럼",
              likeSuccessfully: "나는 성공을 좋아한다.",
              adoptScripts: "연설 채택",
              operation: "운영",
              viewConversationDetails: "대화 세부 정보 보기",
            },
          },
          sellingPoint: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "제품 판매 포인트 토크 라이브러리",
              },
              pageRightTable: { keyPointsOfScript: "연설의 요점" },
            },
          },
        },
        favorite: {
          components: {
            pageLeftTable: {
              objectionHandlingScriptFavorites: "즐겨 찾기 단어 처리 거부",
              newGroup: "새로운 그룹",
              editGroup: "그룹 편집",
              addGroup: "그룹화 추가",
              savingSucceeded: "성공적으로 저장",
              groupName: "그룹 이름",
              pleaseEnterTheGroupName: "그룹 이름을 입력하십시오.",
              groupNameWithinCharacters: "20 자 이내 그룹 이름 길이",
              deletionSucceeded: "성공적으로 삭제",
            },
            pageRightTable: {
              configDrawer: {
                index: { scriptRecommendationSettings: "음성에 권장되는 설정" },
                rightForm: {
                  savingSucceeded: "성공적으로 저장",
                  intelligentRecommendation: "지능형 추천",
                  theAdoptedExcellentScriptsWillBeAutomaticallyDisplayedInTheConversationSidebarOfSimilarObjectionsAndEmployeesCanDirectlyViewTheRecommendedScriptsForLearningOnTheDetailedPageOfConversationReview:
                    "채택 된 우수한 음성 기술은 유사한 이의 제기와 함께 세션의 사이드 바에 자동으로 표시되며 직원은 세션 이력서 세부 정보 페이지에서 학습을 위해 권장되는 음성 기술을 직접 볼 수 있습니다.",
                  objectionsIncludedByDefault: "기본적으로 포함 된 이의 제기",
                  addRecommendationTriggerSemantics: "추천 트리거 시맨틱 추가",
                  remindEmployeesToView: "직원들에게 볼 것을 상기시킨다.",
                  shareWithMoreEmployees: "더 많은 직원과 공유하십시오.",
                  allHands: "모든 직원",
                },
                smartToolbar: { selectSemanticTag: "시맨틱 라벨 선택" },
              },
              index: {
                doYouWantToExportAllDataRecordsUnderTheCurrentGroup:
                  "현재 그룹화로 모든 데이터 레코드를 내보내시겠습니까?",
                newScript: "새로운 연설",
                editingScript: "음성 편집",
                savingSucceeded: "성공적으로 저장",
                objection: "반대",
                pleaseEnterAScriptObjection: "연설 이의를 입력하십시오",
                speechTechnique: "단어",
                pleaseEnterTheScriptContent: "음성 내용을 입력하십시오.",
                grouping: "그룹화",
                pleaseSelectAFavoriteGroup: "좋아하는 그룹을 선택하십시오.",
                adoptScripts: "연설 채택",
                passenger: "게스트",
                sit: "앉아.",
                numberOfViews: "조회 수",
                useful: "유용한",
                operation: "운영",
                edit: "편집",
                delete: "삭제",
                deletionSucceeded: "성공적으로 삭제",
                scriptRecommendationSettings: "음성에 권장되는 설정",
              },
            },
          },
        },
        index: {
          scriptLibrary: "연설 도서관",
          scriptMining: "마이닝 단어",
          objectionHandlingScripts: "이의 제기 처리",
          productSellingPointScripts: "제품 판매 포인트",
        },
        sellingPoint: {
          index: { newCluster: "새로운 클러스터" },
          components: {
            callDetailModal: {
              sessionDetails: "세션 세부 사항",
              cancel: "취소",
              determine: "결정",
              choice: "선택",
            },
            pageCenterTable: { scriptOverview: "연설 개요" },
            pageLeftTable: {
              enterSearchKeywords: "검색 키워드 입력",
              keyWord: "키워드",
            },
            pageRightTable: {
              index: {
                viewDetails: "세부 정보 보기",
                scriptLibrary: "연설 도서관",
                scriptRefinement: "연설 정제",
                adoptScript: "연설 채택",
              },
              tagsEdit: { keyPointsOfScript: "연설의 요점" },
            },
          },
          favorite: {
            components: {
              pageLeftTable: {
                productSellingPointScriptLibrary:
                  "제품 판매 포인트 토크 라이브러리",
              },
              pageRightTable: {
                configDrawer: {
                  rightForm: {
                    defaultIncludedSellingPoints:
                      "기본적으로 포함 된 판매 포인트",
                  },
                },
                index: {
                  keyWord: "키워드",
                  pleaseSelectAKeyword: "키워드를 선택하십시오.",
                  keyPointsOfScript: "연설의 요점",
                  pleaseInputTheKeyPointsOfTheScript:
                    "연설의 요점을 입력하십시오.",
                  viewDetails: "세부 정보 보기",
                  pleaseChooseToAdoptScript: "연설을 채택하도록 선택하십시오.",
                },
              },
            },
          },
        },
      },
      conceptWord: {
        detail: {
          template: {
            matchingLogic: "매칭 로직",
            nLPMatching: "NLP 경기",
            strictlyMatchByWord: "단어에 의한 엄격한 일치",
          },
        },
      },
      applicationMessage: {
        index: {
          messageTypeName: "메시지 유형 이름",
          messageTypeID: "메시지 유형 ID",
          messageTypeDescription: "메시지 유형 설명",
          applicationMessageType: "응용 프로그램 메시지 유형",
          itIsApplicableToAccessDataFromDifferentChannelsForQualityInspectionWhichShouldBeClassifiedAccordingToChannelIdentification:
            "채널 식별에 따라 분류해야하는 품질 검사를 위해 다른 채널의 데이터에 액세스 할 수 있습니다.",
        },
        detail: {
          index: {
            messageTypeName: "메시지 유형 이름",
            pleaseEnterAMessageTypeName: "메시지 유형 이름을 입력하십시오.",
            messageTypeDescription: "메시지 유형 설명",
            describeYourMessageTypeInDetail:
              "메시지 유형을 자세히 설명하십시오.",
            editMessageType: "메시지 유형 편집",
            newMessageType: "새로운 메시지 유형",
          },
        },
      },
    },
    gong: {
      common: {
        pleaseSelectATemMember: "팀 구성원을 선택하십시오.",
        existingFilter: "이미 필터가 있습니다.",
        pleaseSelect: "선택하십시오",
        filterCondition: "여과 조건",
        areYouSureToDeleteTheCurrentFilter: "현재 필터를 삭제하고 싶습니까?",
        sessionDate: "세션 날짜",
        staff: "직원",
        customer: "고객",
        pleaseEnterCustomerName: "고객 이름을 입력하십시오",
        keyWord: "키워드",
        whole: "모두",
        pleaseEnterSessionKeywords: "세션 키워드를 입력하십시오.",
        sessionDuration: "세션 기간",
        sessionLabel: "세션 시나리오",
        pleaseSelectASessionLabel: "세션 시나리오를 선택하십시오.",
        commentTopic: "댓글 주제",
        pleaseSelectACommentTopic: "댓글 주제를 선택하십시오.",
        areYouSureYouWantToOverwriteTheCurrentFilter:
          "현재 필터를 덮어 쓰고 싶습니까?",
        overlayFilter: "커버 필터",
        NewFilter: "새 필터",
        newFilter: "새 필터",
        filterName: "필터 이름",
        pleaseEnter: "입장해주세요",
        allCall: "모든 통화",
        percentage: "비율",
        filterScreen: {
          supportSearchingForMultipleKeywordsWithCommasinBothChineseAndEnglishSeparatingTheORRelationshipAndADashSeparatingTheAndRelationshipSuchAs:
            "여러 키워드를 검색할 수 있습니다. 쉼표 (영어와 중국어) 를 사용하여 OR를 분리하고 일시 정지하여 분리 및 OR. 예:",
          callRecordID: "통화 로그 ID",
          pleaseFillInTheCallRecordID: "통화 기록 ID를 작성하십시오.",
          minute: "분",
          second: "초",
          whole: "모두",
          callRecording: "통화 녹음",
          enterpriseWechat: "엔터프라이즈 위챗",
          conversationChannel: "대화 채널",
          pleaseSelectASessionChannel: "세션 채널을 선택하십시오.",
          learningStatus: "학습 상태",
          pleaseSelectLearningStatus: "학습 상태를 선택하십시오.",
          notLearned: "배우지 않음",
          learned: "귀가",
          Minutes: "분",
          theFilterConditionIsEmptyNewFilterIsNotSupported:
            "필터 상태가 비어 있고 새 필터가 지원되지 않습니다!",
          filterAddedSuccessfully: "성공적으로 추가 된 필터!",
          failedToAddFilter: "필터를 추가하지 못했습니다!",
          filterDeletedSuccessfully: "성공적으로 삭제 된 필터!",
          filterDeletionFailed: "필터 삭제 실패!",
          filterOverwriteSucceeded: "필터 오버라이드 성공!",
          filterOverwriteFailed: "필터 재정의 실패!",
          theme: "테마",
          pleaseSelectATheme: "테마를 선택하십시오",
          keyEvents: "주요 이벤트",
          pleaseSelectAKeyEvent: "키 이벤트를 선택하십시오.",
        },
        filterItem: {
          startRetesting: "재 감지 시작",
          time: "시간",
          talkTime: "통화 기간",
          keyEvents: "주요 이벤트",
          totalImplementationRateOfSupervisionItems:
            "감독 항목의 전체 구현 비율",
          sort: "정렬:",
          seeMore: "더보기",
        },
        filterStatistics: {
          conversation: "전화",
          weChat: "위챗",
          quantity: "수량",
        },
      },
      caseBase: {
        index: {
          copyTo: "복사",
          pleaseSelectTheLocationToMove: "이동할 위치를 선택하십시오!",
          folderCopiedSuccessfully: "폴더 복사본 성공!",
          cancelCollectionSuccessfully: "컬렉션을 성공적으로 취소!",
          collectionSucceeded: "컬렉션 성공!",
          starCaseBase: "스타 케이스 라이브러리",
          move: "이동",
          rename: "이름 바꾸기",
          delete: "삭제",
          folderMovedSuccessfully: "폴더가 성공적으로 움직였습니다!",
          fileMovedSuccessfully: "파일이 성공적으로 이동했습니다!",
          fileMoveFailed: "파일 이동이 실패했습니다!",
          folderAddedSuccessfully: "성공적으로 폴더 추가!",
          folderRenameSucceeded: "폴더 이름이 성공적으로 변경되었습니다!",
          fileRenameSucceeded: "파일 이름이 성공적으로 변경되었습니다!",
          theFolderCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "폴더를 삭제 한 후에는 폴더를 검색 할 수 없습니다. 삭제해야합니까?",
          deleteSucceeded: "성공적으로 삭제!",
          theFileCannotBeRetrievedAfterDeletionAreYouSureToDeleteIt:
            "파일을 삭제 한 후에는 파일을 검색 할 수 없습니다. 삭제해야합니까?",
          share: "합계",
          folders: "폴더,",
          calls: "전화",
          newFolder: "새로운 폴더",
          modifyFolder: "폴더 수정",
          title: "제목",
          pleaseEnter: "입장해주세요",
          selectMoveLocation: "위치 이동 선택",
        },
      },
      msgCenter: {
        index: {
          learningReminder: "학습 알림",
          subscriptionDynamics: "구독 역학",
        },
        subscription: {
          index: {
            theSessionYouSubscribedToID: "구독한 세션 (ID",
            thereAreDynamicUpdates: "동적 업데이트가 있습니다.",
            newCommentsReplies: "댓글 추가/답변",
            strip: "기사",
            newHelp: "도움말 추가",
            goAndWatch: "구경꾼",
          },
        },
        reply: {
          index: {
            respondedToMyComments: "내 의견에 답장",
            replySucceeded: "응답 성공!",
          },
        },
        answer: {
          index: {
            youMeInTheComments: "댓글에 @ me.",
            replySucceeded: "응답 성공!",
          },
        },
        share: {
          index: {
            shareCallsForYou: "공유 요청",
            shareCasesForYou: "사건을 공유하십시오.",
          },
        },
        study: {
          index: {
            learned: "귀가",
            ignored: "무시",
            study: "학습",
            ignore: "무시",
          },
        },
      },
      userManage: {
        list: {
          importSuccessful: "수입 성공",
          account: "계정 번호",
          password: "암호",
          cover: "커버",
          skip: "건너 뛰기",
          import: "수입",
          importEmployees: "수입 직원",
          importPrompt: "수입 팁",
          confirm: "결정",
          theDataContainsDuplicateAccountsOrNames:
            "이 데이터 항목에 중복 계정 또는 이름이 있습니다.",
          allCoverage: "모두 커버",
          skipAll: "모두 건너 뛰기",
          headPortrait: "아바타",
          fullName: "이름",
          nickname: "별명",
          excellentStaff: "우수한 직원",
          yes: "예",
          no: "아니",
          department: "학과",
          accountNumberemail: "계정 번호 (이메일)",
          telephone: "전화",
          jobNumber: "일 번호.",
          role: "역할",
          pleaseEnterANameOrPhoneSearch:
            "이메일, 이름 또는 전화 검색을 입력하십시오.",
          person: "사람들)",
          AddEmployee: "직원 추가",
        },
        edit: {
          jobNo: "일 번호.",
          udeskCustomerServiceSystemIdentity: "Udesk 고객 서비스 시스템 ID",
          enterpriseWechatIdentity: "엔터프라이즈 위챗 신원",
          inputID: "입력 ID",
          newIdentityBinding: "새로운 정체성 바인딩",
          identityBinding: "신원 바인딩",
          avatarGreaterThan500kb: "500Kb 보다 큰 아바타",
          uploadSuccessful: "성공적인 업로드",
          upload: "업로드",
          supportJPGGIFAndPNGUpTo500kb: "지원 jpg, gif, png, 최대 500Kb",
          yes: "예",
          no: "아니",
          accountNumber: "계정 번호",
          pleaseEnterTheAccountNumber: "계좌 번호를 입력하십시오.",
          initialPassword: "초기 암호",
          pleaseInputAPassword: "비밀번호를 입력하십시오.",
          confirmPassword: "비밀번호 확인",
          thePasswordsEnteredTwiceAreDifferent:
            "두 번 입력 한 암호는 동일하지 않습니다!",
          headPortrait: "아바타",
          fullName: "이름",
          pleaseEnterYourName: "이름을 입력하십시오.",
          nickname: "별명",
          department: "학과",
          excellentStaff: "우수한 직원",
          sessionIdentity: "세션 정체성",
          markTheSessionRecordAsBelongingToTheEmployee:
            "태그 세션 레코드는 직원에게 속합니다.",
          telephone: "전화",
          jobNumber: "일 번호.",
          role: "역할",
          pleaseSelectARole: "역할을 선택하십시오.",
          preservation: "저장",
          cancel: "취소",
          addedSuccessfully: "성공적으로 추가!",
          modificationSucceeded: "성공적으로 수정!",
          newEmployees: "신입 사원",
          editEmployee: "직원 편집",
        },
        detail: {
          viewDetails: "세부 정보 보기",
          accountNumber: "계정 번호",
          headPortrait: "아바타",
          fullName: "이름",
          nickname: "별명",
          department: "학과",
          excellentStaff: "우수한 직원",
          yes: "예",
          no: "아니",
          sessionIdentity: "세션 정체성",
          markTheSessionRecordAsBelongingToTheEmployee:
            "태그 세션 레코드는 직원에게 속합니다.",
          telephone: "전화",
          jobNumber: "일 번호.",
          role: "역할",
        },
      },
      roleManage: {
        list: {
          menu: "메뉴",
          functionalCategory: "기능 카테고리",
          functionalSubclass: "기능 하위 클래스",
          deletedSuccessfully: "성공적으로 삭제",
          NewRole: "새로운 역할",
          role: "역할",
          dataPermissions: "데이터 권한:",
          edit: "편집",
          areYouSureYouWantToDeleteTheRole: "역할 삭제 확인",
          determine: "결정",
          cancel: "취소",
          delete: "삭제",
          applicationPermission: "권한 적용",
        },
        edit: {
          youCanOnlySeeYourOwnSessionData:
            "자신의 세션 데이터 만 볼 수 있습니다.",
          youCanViewTheSessionDataOfYourDepartmentAndSubordinateDepartments:
            "부서 및 하위 부서의 세션 데이터를 볼 수 있습니다.",
          menu: "메뉴",
          functionalCategory: "기능 카테고리",
          functionalSubclass: "기능 하위 클래스",
          successfullyAdded: "새로운 성공",
          modifiedSuccessfully: "수정 성공",
          edit: "편집",
          cancel: "취소",
          confirm: "확인",
          roleName: "역할 이름",
          pleaseEnterTheRoleName: "역할 이름을 입력하십시오.",
          dataAuthority: "데이터 권한",
          youCanViewCompanyWideSessionData:
            "회사 전체 세션 데이터를 볼 수 있습니다",
          applicationPermission: "권한 적용",
        },
      },
      callDetail: {
        index: {
          conversationProcess: "세션 프로세스",
          generateProcess: "생성 과정",
          addProcess: "프로세스 추가",
          cancel: "취소",
          complete: "완료",
          edit: "편집",
          theSettingWillBeSuccessfulLater: "나중에 성공을 보도록 설정하십시오!",
          previous: "이전 기사",
          next: "다음 기사",
          debuggingMode: "디버그 모드",
          afterActivationIfTheMessageMatchesTheRulesOfKeyEventsSupervisionItemsCustomerLabelsOrKnowledgePointRulesTheRuleNameThatTheMessageMatchesAndTheKnowledgePointNameOfTheKnowledgeBaseWillBeDisplayed:
            "메시지가 활성화 된 후 주요 이벤트, 모니터링 항목, 고객 태그 또는 지식 지점의 규칙과 일치하는 경우 메시지가 일치하는 규칙의 이름 및 지식 기반의 지식 포인트 이름이 표시됩니다.",
          seeLater: "나중에 봐",
          determine: "결정",
          conversationalInsights: "세션 통찰력",
          customerInsight: "고객 통찰력",
          knowledgePointRecommendation: "지식 포인트 추천",
          comment: "댓글",
          sessionQuality: "세션 품질",
          setToListenLaterSuccessfully: "나중에 성공을 듣기 위해 설정하십시오!",
          listenLater: "나중에 들어라.",
          seekCounselingSuccess: "코칭 성공을 추구하십시오!",
          altogether: "총",
          appearsAt: "등장'",
          commentsPublishedSuccessfully: "성공적으로 게시 된 댓글",
          callRecording: "통화 녹음",
          addedSuccessfully: "성공적으로 추가",
          shareWithColleagues: "동료와 공유",
          shareWithCustomers: "고객과 공유",
          resumptionDetails: "재 제안의 세부 사항",
          addToCaseBase: "케이스 라이브러리에 추가",
          shareCases: "공유 사례",
          seekCounseling: "상담 받기",
          sessionRecord: "세션 기록",
          businessRecord: "사업 기록",
          keywordSearch: "키워드 검색",
          whole: "모두",
          pleaseEnterACommentEnterToNotifyOthersAndEnterToAddATopic:
            "댓글을 입력하고 @ 를 입력하여 다른 사람에게 알리고 # 을 입력하여 스레드를 추가하십시오.",
          subscriptionSession: "세션 구독",
          afterSubscribingYouWillBeNotifiedWhenThereAreNewHelpCommentsAndReplies:
            "구독 후 새로운 도움말, 의견 및 답변이 있으면 알림 및 알림을 받게됩니다.",
        },
      },
      coachTask: {
        index: {
          employeeName: "직원 이름",
          customerName: "고객 이름",
          source: "소스",
          viewSessionRecords: "세션 레코드 보기",
          coachingStatus: "코칭 상태",
          helpTime: "도움을받을 시간",
          counselingTime: "상담 시간",
          helpContent: "도움말 콘텐츠",
          coachingOperation: "코칭 작업",
          areYouSureYouWantToIgnoreIt: "당신은 그것을 무시하고 싶습니까?",
          determine: "결정",
          cancel: "취소",
          ignoreHelp: "도움 무시",
          coaching: "코칭 실시",
          viewCoaching: "코칭 보기",
          areYouSureToReturnToCounseling: "상담으로 돌아가시겠습니까?",
          returnToPendingCoaching: "상담 재개",
          tutor: "카운슬러",
          helpSeekingStatus: "도움말 상태",
          recentCoachingTime: "최근 상담 시간",
          operation: "운영",
          ignoreSuccess: "성공을 무시하십시오!",
          recoverySucceeded: "복구 성공!",
          successfulCoaching: "과외 성공!",
          myMission: "내 임무",
          selectCoachingStatus: "코칭 상태 선택",
          whole: "모두",
          iAskedForHelp: "나는 도움을 요청했다.",
          signOut: "출구",
          submit: "제출",
          helpPerson: "도움 요청",
          customer: "고객",
          toBeCoached: "멘토링",
          counselingSuggestions: "상담 조언",
          pleaseEnterCoachingSuggestions: "코칭 제안을 입력하십시오.",
        },
      },
      coachTaskCenter: {
        title: "미션 센터",
        list: {
          taskName: "작업 이름",
          lessonGroup: "코스 분류",
          lessonName: "코스",
          taskType: "작업 유형",
          taskStatus: "작업 상태",
          taskExecutionInfo: "구현 상태",
          totalCount: "처형 될 사람의 수",
          executeCount: "실행 된 수",
          executeRate: "구현 속도",
          completeCount: "완료의 수",
          completeRate: "완료율",
          commitCount: "획득 횟수",
          commitRate: "규정 준수 비율",
          excellentRate: "우수한 비율",
          taskTime: "작업 시간",
          option: "운영",
          edit: "편집",
          taskDetail: "작업 데이터",
          delete: "삭제",
          new: "새로운 출판",
          taskTypeAll: "모두",
          taskTypePractice: "운동",
          taskTypeTest: "시험",
          taskStatusNotStarted: "시작되지 않음",
          taskStatusRunning: "진행 중",
          taskStatusEnd: "종료",
          taskStatusInvalid: "강제되지 않음",
          unlimitedTime: "무제한 시간",
          to: "에",
          deleteConfirm:
            "삭제 후, 이 작업의 모든 학습 기록이 삭제됩니다. 삭제해야합니까?",
          deleteSuccess: "성공적으로 삭제 된 작업",
        },
        edit: {
          addTask: "새로운 게시 작업",
          editTask: "게시 작업 편집",
          publishSuccess: "성공적으로 게시",
          publishFailed: "게시 실패",
          updateSuccess: "업데이트 성공",
          updateFailed: "업데이트 실패",
          cannotBeNull: "비어있을 수 없습니다.",
          explain: "설명",
          taskDescExtra: "({0} 입구에 표시됩니다)",
          taskTargetObj: "게시 객체",
          isEnable: "유효 상태",
          dialogueMode: "대화 모드",
          voice: "음성",
          text: "텍스트",
          userPortrait: "사용자 초상화",
          difficultyLevel: "난이도",
          limitDuration: "기간 제한",
          tipLabel: "팁",
          tipTooltip: "대의원이 참조 연설을 볼 수 있도록 허용",
          limitTimes: "시험 수",
          time: "시간",
          gradingStandart: "채점 기준",
          gradingStandartDescAffix: "(현재 대화 프로세스의 총 점수는",
          gradingStandartDescSuffix:
            "포인트, 음성 스킬 {0} 포인트, 기분 {1} 포인트, 속도 {2} 포인트)",
          completeStandart: "작업 완료 요구 사항",
          byTimes: "횟수",
          byScore: "점수로",
          completeByTimes: "운동 횟수",
          times: "타임즈",
          score: "포인트",
          anyTimes: "한 번",
          getScore: "점수",
          offLimitDuration: "무제한 기간",
          onLimitDuration: "제한 시간 길이",
          commitScore: "표준, 점수",
          excellentScore: "우수한 표준, 점수",
          offLimitTime: "무제한 시간",
          onLimitTime: "제한 시간",
        },
      },
      homePage: {
        common: {
          useMessage: { hi: "안녕," },
          callDynamic: { today: "오늘", yesterday: "어제.", sessions: "세션" },
          todoList: {
            strip: "기사",
            theSessionIsAddedTolistenLater:
              '세션은 "나중에 듣기" 에 합류합니다.',
            askYouForHelp: "도움을 요청하십시오.",
            iHopeToContactYouLater: "나중에 연락 할 것으로 예상",
            lookingForwardToSendingTheInformationLater:
              "나중에 자료를 보낼 것으로 예상",
            dialogueRisk: "대화에는 위험 지점이 있습니다.",
            tutored: "코칭",
            goToCounseling: "상담하러 가세요",
            contacted: "연락처",
            hasBeenSent: "보낸",
            areYouSureYouWantToIgnoreIt: "당신은 그것을 무시하고 싶습니까?",
            determine: "결정",
            cancel: "취소",
            ignore: "무시",
            checked: "보기",
          },
          callListItem: {
            strip: "기사",
            learned: "귀가",
            keyEvents: "주요 이벤트",
            totalImplementationRateOfSupervisionItems:
              "감독 항목의 전체 구현 비율",
          },
        },
        index: {
          operationSucceeded: "성공적인 운영!",
          sessionDynamics: "세션 역학",
          my: "내",
          department: "학과",
          company: "회사",
          toDoList: "할 일",
        },
      },
      teamAnalysis: {
        index: {
          effectiveCallVolumenumber: "효과적인 통화 볼륨 (단위)",
          bestPractices: "모범 사례",
          averageDurationOfASingleCallminutes: "평균 단일 통화 기간 (분)",
          totalCallVolumePCs: "총 통화 (단위)",
          totalTalkTimehours: "총 통화 기간 (시간)",
          speakingPercentagepercentage: "말하기 비율 (백분율)",
          maximumContinuousSpeakingTimeminutes: "최대 연속 말하기 시간 (분)",
          numberOfIndepthDiscussionsPCs: "심층 토론 수 (단위)",
          maximumCustomerTalkminutes: "최대 고객 말하기 (분)",
          questionFrequencyPCs: "질문 주파수 (단위)",
          averageValue: "평균",
          selectDate: "날짜 선택",
          personnel: "인사",
          pleaseSelect: "선택하십시오",
          callRange: "통화 범위",
          conversationActivity: "세션 활동",
          conversationAction: "세션 동작",
          keyEvents: "주요 이벤트",
          callRecord: "통화 녹음",
          filter: "필터:",
          conversation: "전화",
          minute: "분",
          sale: "직원",
        },
      },
      saleClientCenter: {
        detail: {
          index: {
            january: "1 월",
            numberOfCalls: "통화 수",
            accountInformation: "계정 정보",
            month: "달",
            today: "오늘",
            customerDetails: "고객 세부 사항",
            searchKeywordsInCommunicationContent: "통신 콘텐츠에서 키워드 검색",
            allMembers: "모든 회원",
            reset: "리셋",
            query: "쿼리",
            allTrip: "전체 여행 쇼",
            contactOverview: "연락처 개요",
            lastContact: "마지막 접촉",
            nextContact: "다음 연락처",
            previous: "이전 기사",
            next: "다음 기사",
            viewDetails: "세부 정보 보기",
            contactDynamics: "동적 접촉",
            lastYear: "작년",
            lastCall: "마지막 통화",
            hotTopicsForDiscussion: "토론의 뜨거운 주제",
            totalInTheLastYear: "작년에 모두.",
            callTimes: "2 차 통화",
          },
        },
        list: {
          index: {
            risk: "위험",
            have: "있다",
            nothing: "없음",
            channel: "채널",
            callRecording: "통화 녹음",
            enterpriseWeChatConversation: "엔터프라이즈 마이크로 세션",
            enterpriseWeChatCall: "엔터프라이즈 마이크로 토크",
            followUpSuggestions: "후속 권장 사항",
            customer: "고객 센터",
            followUp: "후속 조치",
            personInCharge: "담당자",
            recentFollowup: "최근에 이어졌습니다.",
            lastContactTime: "마지막 접촉 시간",
            operation: "운영",
            see: "보기",
            exceed: "더 보기",
            noActivityForMoreThanDays: "활동이없는 날",
            lately: "최근",
            risksOccurredInTheLastDays: "위험 의미론은 며칠 내에 발생합니다",
            savedSuccessfully: "성공적으로 저장",
            today: "오늘",
            allDepartments: "모든 부서",
            allMembers: "모든 회원",
            customerName: "고객 이름",
            reset: "리셋",
            query: "쿼리",
            customers: "고객",
            showTheContactInformationBetweenEmployeesAndCustomersInWhichPinkBubblesAreCustomerMessagesAndBlueOnesAreEmployeeMessagesTheLargerTheProportionOfSpeechesTheLargerTheBubbles:
              "핑크 거품은 고객 메시지이고 파란색 거품은 직원 메시지 인 직원과 고객 간의 접촉을 보여줍니다. 연설의 비율이 클수록 거품이 커집니다.",
            riskAllocation: "위험 구성",
            preservation: "저장",
            pleaseEnter: "입장해주세요",
            noActivityForDays: "활동이없는 날",
            riskSemanticsWithinDays: "위험 의미론은 며칠 내에 발생합니다",
            addRiskSemantics: "위험 의미론 추가",
            semanticTags: "시맨틱 라벨:",
            standardSentence: "표준 문장:",
          },
          components: {
            advancedFilter: {
              components: {
                list: { index: { addNewConditions: "새 조건 추가" } },
              },
              index: {
                meetAllOfTheFollowingConditions: "다음 조건을 모두 충족",
              },
            },
            advancedFilterButton: {
              index: {
                screen: "필터",
                pleaseSelectFilteringCriteria: "필터 기준을 선택하십시오.",
              },
            },
          },
        },
        components: {
          businessStage: {
            index: {
              changeOpportunityStage: "기회 단계 변경",
              theCurrentStageIs: "현재 단계는 [",
              ConfirmToChangeItTo: "], 그것을 변경하도록 확인하십시오 【",
              IsIt: "]?",
              StageCoStay: "] 단계 총 체류",
              day: "날",
              start: "시작",
              end: "끝",
              orderInput: "질서 상실",
              restart: "다시 시작",
              pleaseConfigureTheSalesStageInformation:
                "판매 단계 정보를 구성하십시오",
              reasonForDocumentInput: "주문을 잃는 이유:",
              completeOrderInput: "완전한 잃어버린 의사",
              pleaseSelectTheReason: "이유를 선택하십시오.",
            },
            modal: {
              customerDynamics: {
                theManagerSuggests: "경영진은 다음을 권장합니다.",
              },
              index: {
                sessionDetails: "세션 세부 사항",
                customerDynamics: "고객 역학",
                riskPoint: "위험 지점",
                followUpSuggestions: "후속 권장 사항",
              },
              sessionDetails: {
                common: "합계",
                currentlyViewingThe: "현재 No.",
                sessions: "세션",
              },
            },
          },
          businessTitle: {
            index: {
              theCurrentStageHasStayed: "현재 단계가 중지되었습니다.",
              day: "날",
            },
          },
          business: { index: { reEnableOpportunity: "다시 활성화 기회" } },
          customerTag: { index: { label: "라벨" } },
          moreCustomer: {
            index: {
              essentialInformation: "기본 정보",
              cancel: "취소",
              confirm: "확인",
              customer: "고객",
              currentFollowupStage: "현재 후속 단계",
              telephone: "전화",
              followUpDays: "후속 일",
              day: "날",
              remarks: "비고",
              channelPreference: "채널 선호도",
              hello: "안녕하세요.",
              customFields: "맞춤 필드",
              opportunityAmount: "기회 금액",
              focus: "우려",
              customerInformation: "고객 정보",
              viewMoreCustomerInformation: "고객 정보 더보기",
            },
          },
          table: {
            components: {
              row: {
                row0: { keyEvents: "주요 이벤트:" },
                row1: {
                  customerDynamics: "고객 역학",
                  common: "합계",
                  barDynamic: "동적",
                },
                row2: {
                  riskPoint: "위험 지점",
                  common: "합계",
                  risks: "기사 위험",
                },
                row3: {
                  followUpSuggestions: "후속 권장 사항",
                  intelligentRecommendation: "지능형 추천",
                  strip: "기사",
                  managersSuggestATotalOf: "관리자는 합계를 권장합니다.",
                  recommendedByManagers: "관리자 추천",
                  creationTime: "창조 시간",
                  administratorSuggestions: "관리자의 조언",
                  suggestionsForNewManagers: "새로운 관리자의 조언",
                },
              },
            },
            index: { typeTime: "유형/시간" },
          },
          userInfo: {
            index: {
              personInCharge: "담당자",
              recentFollower: "최근에 이어졌습니다.",
              startTime: "처음 도달 시간",
              followUpDays: "후속 일",
              day: "날",
              remarks: "비고",
            },
          },
        },
      },
      studyAnalysis: {
        index: {
          teamMembers: "팀원",
          membersLearnTheTotalNumberOfOtherPeoplesConversationsdeDuplicationLookingUpTheDetailsOfTheConversationAndClickingTheRecordingAndPlayingIsRegardedAsLearningOnce:
            "회원들이 다른 사람들로부터 배운 총 세션 수 (중복 제거). 세션 세부 정보를 찾고 레코딩을 클릭하여 한 번 배우는 것으로 간주됩니다.",
          totalNumberOfStudies: "총 학습 수",
          theTotalNumberOfCommentsAndRepliesFromMembersToOtherPeoplesSessionsdeDuplication:
            "회원들이 다른 사람들의 세션에 시작한 총 의견 및 답변 수 (중복 제거)",
          totalNumberOfInteractions: "상호 작용의 총 수",
          learnTheSumOfConversationCallDuration: "배운 세션 호출 기간의 합계",
          lengthOfStudy: "학습 기간",
          totalNumberOfMembersWhoInitiatedTheConversationToOthers:
            "회원으로부터 다른 사람들의 세션으로의 도움 요청 총 수 (중복 제거)",
          totalNumberOfRequests: "총 도움 요청 수",
          totalNumberOfMembersCounselingOthersForHelpdeDuplication:
            "타인의 도움을 상담하는 총 회원 수 (중복 제거)",
          totalCounseling: "튜토리얼의 총 수",
          totalNumberOfMemberSessionsToOthersAddedToTheCaseBasedeDuplication:
            "사례 라이브러리에 추가 된 다른 회원의 총 세션 수 (중복 제거)",
          addCase: "케이스 추가",
          totalNumberOfSubscriptionsMadeByMembersToOtherPeoplesSessionsdeDuplication:
            "다른 사람들의 세션에 대한 총 회원 구독 수 (중복 제거)",
          subscribe: "구독",
          totalNumberOfSessionsCommunicatedByMembers:
            "회원이 전달한 총 세션 수",
          totalSessions: "총 세션",
          theTotalNumberdeDuplicationOfAMembersOwnSessionItIsDeemedToBeAReplayIfTheMemberHasCheckedTheSessionDetailsAndClickedTheRecordingAndPlayback:
            "세션을 다시 재생하고 (중복 해제) 세션 세부 정보를 찾고 녹음 내용을 클릭하여 재생하는 총 회원 수는 한 번 재생하는 것으로 간주됩니다.",
          totalNumberOfDoubleDisk: "총 리디스크 수",
          totalNumberOfDuplicateDiskTotalNumberOfSessions:
            "총 리디스크 수/총 세션 수 * 100%",
          proportionOfDoubleDisk: "점유율 재개",
          summationOfConversationCallDurationOfDuplicateDisk:
            "이중 디스크의 대화 지속 시간의 합입니다.",
          resumptionDuration: "복원 기간",
          totalNumberOfRequestsForHelpFromMembersForTheirOwnSessionsdeDuplication:
            "자신의 세션에 대한 도움을 시작한 총 회원 수 (중복 제거)",
          theTotalNumberOfCommentsincludingRepliesReceivedByOthersInAMembersSession:
            "회원 세션에 대해 다른 사람이받은 총 의견 수 (답장 포함)",
          theTotalNumberOfSessionsOfMembersAddedToTheCaseBaseByOthersdeDuplication:
            "다른 사람이 사례 라이브러리에 추가 한 총 회원 세션 수 (중복 제거)",
          theTotalNumberOfMemberSessionsSubscribedByOthersdeDuplication:
            "다른 사람이 구독하는 총 회원 세션 수 (중복 제거)",
          askForHelpInTheLastMonth: "도움을위한 지난 달.",
          lastTimeForHelp: "도움을 요청하는 마지막 시간",
          operation: "운영",
          viewAllSessionsInTheLastMonth: "지난 달의 모든 세션 보기",
          allDepartments: "모든 부서",
          reset: "리셋",
          query: "쿼리",
          altogether: "총",
          secondSession: "보조 세션",
        },
      },
      checkPointTest: {
        components: {
          testSelectComponent: {
            currentTestItems: "현재 테스트 프로젝트:",
            supervisionItem: "감독 항목",
            keyEvents: "주요 이벤트",
          },
        },
        index: {
          pleaseCheckTheMonitoringItemsOrKeyEventsToBeTestedAndEnsureThatThereAreCallsInTheSimulatedDialogue:
            "테스트하려는 모니터링 항목 또는 주요 이벤트를 확인하고 시뮬레이션 된 대화에 토크 콘텐츠가 있는지 확인하십시오.",
        },
      },
      executionAnalysis: { index: { executiveForceAnalysis: "실행 분석" } },
      konwledgeBase: {
        detail: {
          components: {
            richTextList: {
              process: "프로세스 1",
              technologicalProcess: "프로세스",
              newProcess: "새로운 프로세스",
            },
          },
          index: {
            upload: "업로드",
            preservation: "저장",
            newlyBuild: "새로운",
            cancel: "취소",
            name: "이름",
            describe: "설명",
            type: "유형",
            classification: "분류",
            content: "콘텐츠",
            enclosure: "별관",
            knowledgePointAssociation: "지식 포인트 협회",
            open: "오픈",
            close: "닫기",
            operator: "연산자",
            logic: "논리",
            see: "보기",
            edit: "편집",
          },
        },
        index: {
          name: "이름",
          describe: "설명",
          createdBy: "창조주",
          type: "유형",
          lastUpdated: "마지막 업데이트 시간",
          numberOfReferences: "참조 수",
          operation: "운영",
          see: "보기",
          edit: "편집",
          delete: "삭제",
          categoryDeletedSuccessfully: "성공적으로 삭제 된 카테고리",
          classificationOfKnowledgePoints: "지식 포인트의 분류",
          newlyBuild: "새로운",
          knowledgeBase: "지식 기반",
          knowledgePoints: "지식 포인트",
          technologicalProcess: "프로세스",
          keyword: "키워드",
          batchExport: "배치 수출",
          batchDelete: "일괄 삭제",
          importProcess: "수입 프로세스",
          importKnowledgePoints: "수입 지식 포인트",
          ifTheKnowledgePointDoesNotExistInTheKnowledgeBaseAddTheKnowledgePointDirectlyIfItExistsSkipAndDoNotUpdate:
            "지식 기반에 지식 포인트가 존재하지 않으면 지식 포인트를 직접 추가하십시오. 존재하는 경우 건너 뛰고 업데이트하지 마십시오.",
          editKnowledgePointClassification: "지식 포인트 분류 편집",
          newKnowledgePointClassification: "새로운 지식 포인트 분류",
          categoryEditingSucceeded: "카테고리 편집 성공",
          newClassificationSucceeded: "새로운 카테고리 성공",
          classificationName: "범주 이름",
        },
      },
      monitoringReport: { index: { executiveSupervision: "집행부 감독" } },
      topicCallout: {
        index: {
          whole: "모두",
          date: "날짜",
          dimensionStatus: "콜아웃 상태",
          callRecordID: "통화 로그 ID",
          callRecordIDMustBeNumeric: "통화 로그 ID는 번호여야 합니다.",
          strip: "기사",
          topicAnnotation: "주제 콜아웃",
          annotated: "라벨링",
          stripNotMarked: "바, 라벨이없는",
          barInDimension: "바, 콜아웃",
          customer: "고객",
          sale: "판매",
          common: "합계",
          sessions: "세션",
          currentTopic: "현재 주제:",
          staff: "직원",
          topicOfConversation: "주제",
          theOperationIsSuccessfulAndTheDimensionHasBeenReset:
            "운영 성공, 콜아웃 재설정",
          afterResetAllTheAnnotationRecordsOfThisCommunicationSessionWillBeClearedPleaseConfirm:
            "재설정 후, 이 세션의 모든 라벨링 레코드가 삭제됩니다. 확인해 주세요",
          resetAll: "모두 재설정",
          pleaseSelectTheTopicToBeMarked:
            "레이블을 지정할 주제를 선택하십시오.",
          pleaseTickTheSentenceToBeMarked: "표시 할 문장을 확인하십시오.",
          theOperationIsSuccessfulAndTheAnnotationHasTakenEffect:
            "작업 성공, 라벨 적용",
          theScopeOfThisAnnotationIncludesMessagesOfOtherTopicsClickOKToCoverThem:
            "이 주석의 범위에는 다른 주제의 메시지가 포함됩니다. 확인을 클릭하여 덮어씁니다.",
          confirmDimensions: "콜아웃 확인",
          talkTime: "대화 시간",
          callID: "전화 ID",
        },
      },
      topicList: {
        index: {
          callID: "전화 ID",
          latestMarkedTime: "최신 마킹 시간",
          topicOfConversation: "주제",
          numberOfSentences: "문장의 수",
          sentence: "문장",
          operation: "운영",
          see: "보기",
          delete: "삭제",
          edit: "편집",
          newSubTopic: "새로운 하위 주제",
          move: "이동",
          whole: "모두",
          topicDeletedSuccessfully: "성공적으로 삭제 된 주제",
          newlyBuild: "새로운",
          topicList: "주제 목록",
          callIDMustBeNumeric: "전화 ID는 번호여야 합니다.",
          leadInTopic: "수입 주제",
          whenTheCategoryNameIsRepeatedTheOriginalTopicCategoryWillNotBeOverwrittenThisItemWillBeSkippedDirectlyAndWillNotBeImported:
            "카테고리 이름이 중복된 경우 원래 주제 범주는 덮어 쓰지 않고 항목을 건너 뛰고 가져 오지 않습니다.",
          mobileClassification: "모바일 분류",
          editTopic: "주제 편집",
          newTopic: "새로운 주제",
          topicName: "주제 이름",
        },
      },
      groupTest: {
        detail: {
          template: {
            modificationSucceeded: "수정 성공",
            createdSuccessfully: "성공적으로 생성",
            modificationFailed: "수정 실패",
            creationFailed: "만들지 못함",
            monitoringName: "모니터링 이름",
            upToCharacters: "최대 40 자",
            pleaseEnterAName: "이름을 입력하십시오.",
            monitoringIndicators: "모니터링 인덱스",
            sessionActivity: "세션 활동",
            conversationAction: "세션 동작",
            keyEvents: "주요 이벤트",
            supervisionItems: "감독 항목",
            monitoringMembers: "회원 모니터링",
            groupReceivingGuidanceTrainingOrManagementIntervention:
              "지도, 교육 또는 관리 개입을받는 그룹",
            groupA: "그룹 A",
            pleaseSelectGroupAData: "그룹 A 데이터를 선택하십시오.",
            thereAreDuplicateUsersInGroupB: "그룹 B의 사용자 복제",
            groupARemarks: "그룹 A 비고",
            controlGroupGroupComparedWithGroupA: "그룹 A와 비교되는 대조군",
            groupB: "그룹 B",
            pleaseSelectGroupBData: "B 그룹 데이터를 선택하십시오.",
            thereAreDuplicateUsersInGroupA:
              "그룹 A에는 중복 사용자가 있습니다.",
            groupBRemarks: "B 조 발언",
            monitoringPeriod: "모니터링주기",
            startNode: "시작 노드",
            monitoringScope: "모니터링 범위",
            pleaseSelectMonitoringConditions: "모니터링 조건을 선택하십시오.",
            pleaseEnterANumber: "번호를 입력하십시오.",
            pleaseSelectATimePeriod: "기간을 선택하십시오",
            preservation: "저장",
            cancel: "취소",
          },
        },
        list: {
          template: {
            deletionSucceeded: "성공적으로 삭제",
            notStarted: "시작되지 않음",
            haveInHand: "진행 중",
            closed: "종료",
            newGroupTest: "새로운 그룹 테스트",
          },
        },
      },
      businessConfiguration: {
        salesStage1: "판매 단계",
        communicationStrategy1: "통신 전략",
        riskAllocation: "위험 구성",
        businessConfiguration: "비즈니스 구성",
        communicationStrategy: {
          detail: {
            components: {
              pushRichText: {
                index: {
                  objectionHandlingScript: "이의 제기 처리",
                  productSellingPointScript: "제품 판매 포인트",
                  caseGrouping: "사례 그룹화",
                  pleaseSelectExcellentCases: "좋은 경우를 선택하십시오.",
                  pleaseSelectKnowledgePoints: "지식 포인트를 선택하십시오",
                  associativeLinks: "관련 링크",
                  jumpTo: "점프",
                  jumpType: "점프 유형",
                  knowledgePoints: "지식 포인트",
                  excellentCases: "우수한 케이스",
                  excellentScript: "훌륭한 연설",
                  pleaseSelectASingleKnowledgePoint:
                    "단일 지식 포인트를 선택하십시오.",
                },
              },
            },
            index: {
              name: "이름",
              pushText: "텍스트 푸시",
              enableStatus: "사용 상태",
              triggerRules: "트리거 규칙",
              communicationStrategy: "통신 전략",
            },
          },
          list: {
            index: {
              communicationStrategyGrouping: "통신 전략 그룹",
              name: "이름",
              pushText: "텍스트 푸시",
              effectiveness: "효과",
              openSuccessfully: "성공적으로 열기",
              closeSuccessfully: "성공적으로 폐쇄",
              operationFailed: "운영 실패",
              operation: "운영",
              edit: "편집",
              delete: "삭제",
              newlyBuild: "새로운",
              communicationStrategy: "통신 전략",
              editGroup: "그룹 편집",
              groupName: "그룹 이름",
            },
          },
        },
        riskStatement: {
          detail: {
            components: {
              riskSettingRule: {
                index: {
                  allSessions: "모든 세션",
                  recentDays: "최근 일",
                  recentSessions: "최근 세션",
                  scopeOfApplication: "적용 범위:",
                  day: "날",
                  through: "패스",
                },
              },
            },
            index: {
              describe: "설명",
              applicableCustomers: "고객에게 적용 가능",
              enableStatus: "사용 상태",
              triggerRules: "트리거 규칙",
              riskRules: "위험 규칙",
            },
          },
          list: {
            index: {
              riskAllocation: "위험 구성",
              keyWord: "키워드",
              newRiskRule: "새로운 위험 규칙",
              riskRuleName: "위험 규칙 이름",
              describe: "설명",
              state: "상태",
              openSuccessfully: "성공적으로 열기",
              closeSuccessfully: "성공적으로 폐쇄",
              operationFailed: "운영 실패",
              operation: "운영",
              edit: "편집",
              delete: "삭제",
              deletionSucceeded: "성공적으로 삭제",
            },
          },
        },
        salesStage: {
          detail: {
            components: {
              alias: { orderInput: "질서 상실" },
              endReasonList: {
                addEndReason: "최종 이유 추가",
                enterEndReason: "종료 이유 입력",
              },
              stageList: {
                keyEvents: "주요 이벤트",
                waysideData: "도로 후속 데이터",
                includeAny: "어떤 포함",
                includeAll: "모두 포함",
                pleaseEnterTheStageName: "위상 이름을 입력하십시오.",
                setStageRules: "스테이지 규칙 설정",
                stageName: "위상 이름",
                operator: "연산자",
                logic: "논리",
              },
            },
            index: {
              theInputFormatOfTheTimeTypeFieldIs:
                "시간 유형 필드 입력 형식: 2022-12-31 00:00:00",
              requestFailedPleaseTryAgainLater:
                "요청이 실패했습니다. 나중에 다시 시도하십시오.",
              salesStage: "판매 단계",
              essentialInformation: "기본 정보",
              name: "이름",
              applicableDepartments: "해당 부서",
              applicableToOthers: "다른 사람에게 적용 가능",
              manualChangePhase: "수동 변경 단계",
              enableStatus: "사용 상태",
              describe: "설명",
              opportunityStageSettings: "기회 단계 설정",
              stageSettings: "위상 설정",
              aliasSettings: "별칭 설정",
              closeOpportunity: "기회 종료",
              newSuccessfully: "새로운 성공",
              newFailed: "새로운 실패",
              savingSucceeded: "성공적으로 저장",
              saveFailed: "저장하지 못했습니다",
            },
          },
          list: {
            index: {
              salesStage: "판매 단계",
              keyWord: "키워드",
              newSalesStage: "새로운 판매 단계",
              salesStageName: "판매 단계 이름",
              describe: "설명",
              state: "상태",
              openSuccessfully: "성공적으로 열기",
              closeSuccessfully: "성공적으로 폐쇄",
              operationFailed: "운영 실패",
              operation: "운영",
              edit: "편집",
              delete: "삭제",
              deletionSucceeded: "성공적으로 삭제",
            },
          },
        },
        largeModelGeneration: {
          index: {
            largeModelGeneration: "대형 모델 생성",
            conversationInsights: "세션 통찰력",
          },
          sessionsInsight: {
            components: {
              autoGenerate: { index: { automaticGeneration: "자동 생성" } },
              hooks: {
                conversationSummary: "세션 요약",
                conversationProcess: "세션 프로세스",
                opportunityPoints: "기회 포인트",
              },
              prompt: {
                index: {
                  edit: "편집",
                  restoreDefault: "기본 복원",
                  cancel: "취소",
                  confirm: "확인",
                },
              },
            },
            index: {
              saveSuccessful: "성공적으로 저장",
              hintLanguage: "프롬프트",
              preserve: "저장",
              cancel: "취소",
            },
          },
        },
      },
      salesBusinessConfigurationEntry: {
        largeModelGeneration: "대형 모델 생성",
        salesStage: "판매 단계",
        communicationStrategy: "통신 전략",
        riskAllocation: "위험 구성",
        businessConfiguration: "비즈니스 구성",
      },
      salesLearningCenter: {
        knowledgeBase: "지식 기반",
        speechCorpus: "연설 도서관",
        learningCenter: "학습 센터",
      },
      customerFields: {
        components: {
          baseFields: { index: { customerInformation: "고객 정보" } },
        },
        index: { otherInformation: "기타 정보" },
      },
    },
    intelligentAnalysis: {
      ruleTemplate: {
        components: { importButton: { index: { import: "수입" } } },
        hooks: {
          index: {
            all: "모두",
            intelligentModel: "지능형 모델",
            smartRules: "스마트 규칙",
            manualScoring: "수동 득점",
            enable: "활성화",
            notEnabled: "사용하지 않음",
            voiceCall: "음성 통화",
            textDialogue: "텍스트 대화",
            ecommerceTextSession: "전자 상거래 텍스트 세션",
            workOrder: "작업 순서",
          },
        },
        index: {
          ruleName: "규칙 이름",
          ruleType: "규칙 유형",
          pleaseSelect: "선택하십시오",
          ruleStatus: "규칙 상태",
          dataSourceType: "데이터 소스 유형",
          voiceCall: "음성 통화",
          textDialogue: "텍스트 대화",
          ecommerceTextSession: "전자 상거래 텍스트 세션",
          workOrder: "작업 순서",
          status: "상태",
          operation: "운영",
          edit: "편집",
          ruleTemplate: "규칙 템플릿",
        },
      },
      analysisReportTitle: "분석 보고서",
      ruleTest: "규칙 테스트",
      intelligentTags: {
        labelDisplayQuantity: "라벨 디스플레이 수량",
        unlimited: "무제한",
        ignoringTagLibraries: "태그 라이브러리 무시",
        automaticallyGenerateLabels: "자동으로 라벨 생성",
        afterActivationLabelsAreAutomaticallyGeneratedForTheSessionBasedOnTheLargeModelAndTheGeneratedLabelsAreClassifiedAsunclassifiedByDefault:
          "세션이 활성화되면 큰 모델에 따라 태그가 자동으로 생성되고 생성된 태그는 기본적으로 분류되지 않은 상태로 분류됩니다.",
        defaultValidity: "기본 효과",
        supervisionItem: "감독 항목",
        customerLabel: "고객 라벨",
        newSupervisionItemRule: "새로운 감독 항목 규칙",
        editSupervisionItemRule: "감독 된 항목 규칙 편집",
        newCustomerLabel: "새로운 고객 라벨",
        editCustomerLabel: "고객 태그 편집",
        newSessionLabel: "새 세션 레이블",
        editSessionLabel: "세션 레이블 편집",
        newKeyEvent: "새로운 키 이벤트",
        editKeyEvents: "키 이벤트 편집",
        detail: {
          template: { nameIsARequiredField: "이름은 필수 필드입니다." },
          formItemComponent: {
            largeModelExtensionLabel: "대형 모델 확장 태그",
            sessionRecordID: "세션 기록 id",
            sessionTime: "세션 시간",
            smartTags: "스마트 라벨",
            viewDetails: "세부 정보 보기",
            afterDeletionTheDataRecordedInThisSessionWillBeClearedAreYouSureYouWantToDeleteIt:
              "삭제 후, 이 세션 레코드의 데이터는 삭제될 것이다. 삭제해야합니까?",
            tagData: "태그 데이터",
            batchDeletion: "일괄 삭제",
          },
        },
        template: {
          advancedFiltering: "고급 필터링",
          dataType: "데이터 유형",
          filterCriteria: "필터 상태",
          edit: "편집",
          createANewSubcategory: "새로운 하위 카테고리",
          delete: "삭제",
          nameLengthCannotExceed: "이름 길이는 40 을 초과 할 수 없습니다",
        },
        component: {
          modelExpansion: "모델 확장",
          enable: "활성화",
          notEnabled: "사용하지 않음",
          standardScreening: "표준 필터",
          advancedFiltering: "고급 필터링",
        },
      },
      businessAnalysis: {
        detail: {
          businessAnalysisFiter: {
            component: {
              keyWord: "키워드",
              sessionLabel: "세션 라벨",
              keyEvents: "주요 이벤트",
              matchAny: "어떤 일치",
              matchAll: "모두 일치",
              beEqualTo: "동등한",
              includeAll: "모두 포함",
              includeAny: "어떤 포함",
              advancedConditions: "고급 조건",
              smartTag: "스마트 라벨",
              smartTagI: "스마트 라벨 하나",
              smartTagII: "스마트 라벨 II",
            },
            template: {
              pleaseSelectSmartTag: "스마트 라벨을 선택하세요",
              pleaseSelectASessionLabel: "세션 레이블을 선택하십시오.",
              pleaseSelectAKeyEvent: "키 이벤트를 선택하십시오.",
              pleaseSelectAdvancedCondition: "고급 기준을 선택하십시오.",
              newAdvancedFilter: "새로운 고급 필터",
              moveUp: "위로 이동",
              moveDown: "아래로 이동",
              moveLeft: "왼쪽으로 이동",
              shiftRight: "오른쪽으로 이동",
            },
          },
          component: {
            businessAnalysispng: "비즈니스 Analytics.png",
            savedSuccessfully: "성공적으로 저장",
          },
        },
      },
      supervision: {
        detail: {
          index: {
            permanentlyValid: "영구적으로 효과적인",
            permanentlyInvalid: "영구적으로 무효",
            customValidity: "맞춤 유효 기간",
            termOfValidity: "유효 기간",
            name: "이름",
            explain: "설명",
            state: "상태",
            classification: "분류",
            smartRecommendation: "지능형 추천",
            preconditions: "전제 조건",
            SetPreconditionsForThisSupervisionItem:
              "1. 이 감독 항목에 대한 전제 조건 설정:",
            whenThePreconditionsAreMetItIsThesupervisionItemToBeImplementedAndThenTheSupervisionItemShallBeTested:
              '전제 조건이 충족되면 "구현 될 감독 항목" 으로 간주되며 감독 항목을 테스트해야합니다.',
            ifThePreconditionsAreNotMetTheSupervisionItemWillNotBeDetected:
              "사전 조건이 충족되지 않으면 감독 항목이 감지되지 않습니다.",
            NoPreconditionsAreSetThatIsAllSessionsAreDetectedByDefault:
              "2. 전제 조건을 설정하지 마십시오. 즉, 모든 세션이 기본적으로 감지됩니다.",
            have: "있다",
            nothing: "없음",
            preconditionRule: "전제 조건 규칙",
            preconditionLogic: "전제 조건 논리",
          },
          recommendation: {
            caseGrouping: "사례 그룹화",
            whenEmployeesFailToPerformWellTheyCanIntelligentlyRecommendExcellentScriptsAndExcellentCases:
              "직원이 제대로 수행하지 않으면 우수한 언어 기술과 우수한 사례를 지능적으로 추천 수 있습니다.",
            smartRecommendation: "지능형 추천",
            open: "오픈",
            close: "닫기",
            whenThisSupervisionItem: "이 감독 항목 때",
            implement: "실행",
            unexecuted: "실행되지 않음",
            when: "때:",
            recommendedKnowledgePoints: "추천 지식 포인트",
            caseRecommendation: "사례 추천",
          },
        },
        index: {
          determine: "결정",
          sort: "정렬",
          sortSucceeded: "성공적으로 정렬",
          supervisionItemClassification: "감독 항목의 분류",
          pressEnterToCreateANew: "새로운 입력 누르기",
          nameCannotBeEmpty: "이름을 비울 수 없습니다.",
          newlyBuild: "새로운",
          name: "이름",
          explain: "설명",
          validStatus: "유효한 상태",
          permanentlyValid: "영구적으로 효과적인",
          permanentlyInvalid: "영구적으로 무효",
          operation: "운영",
          edit: "편집",
          batchDelete: "일괄 삭제",
          newSupervisionItem: "새로운 감독 항목",
          keyWord: "키워드",
          delete: "삭제",
          deleteSucceeded: "성공적으로 삭제",
        },
      },
      analysisReport: {
        components: {
          list: {
            index: {
              theDataCannotBeRecoveredAfterDeletionAreYouSureYouWantToDeleteIt:
                "삭제 된 후에는 데이터를 복원 할 수 없습니다. 당신은 그것을 삭제하고 싶습니까?",
            },
          },
          businessAnalysisFiter: {
            component: {
              smartTag: "스마트 라벨",
              beEqualTo: "동등한",
              includeAll: "모두 포함",
              includeAny: "어떤 포함",
              matchAny: "어떤 일치",
              matchAll: "모두 일치",
              smartTagI: "스마트 라벨 하나",
              smartTagII: "스마트 라벨 II",
            },
            template: {
              pleaseSelectASmartTag: "스마트 라벨을 선택하세요",
              pleaseSelectAdvancedConditions: "고급 기준을 선택하십시오.",
              newAdvancedFilter: "새로운 고급 필터",
              rename: "이름 바꾸기",
              moveUp: "위로 이동",
              moveDown: "아래로 이동",
              shiftLeft: "왼쪽으로 이동",
              shiftRight: "오른쪽으로 이동",
            },
          },
        },
        template: {
          delete: "삭제",
          labelFiltering: "라벨 필터링:",
          satisfyAny: "임의의 만남",
          meetAll: "모두 만나다",
          theNumberOfConversationsOfSecondaryCategoryLabelsPrimaryCategoryAndASessionMayContainMultipleLabels:
            "2 차 분류 레이블 대화 수 ≥ 1 차 분류, 세션에는 여러 레이블이 포함될 수 있습니다",
          calculationLogicOfMonthOnMonthChangeconversationVolumeOfTheSelectedTimePeriodConversationVolumeOfTheImmediatelyPrecedingTimePeriodconversationVolumeOfTheLastTimePeriod:
            "링 변경 계산을위한 논리: (선택한 기간의 대화 볼륨-바로 이전 기간의 대화 볼륨)/이전 기간의 대화 볼륨",
          proportionOfPrimaryClassification: "1 단계 분류 비율",
          saveAs: "저장",
          cover: "커버",
          preservation: "저장",
          qualityInspectionTask: "분석 작업:",
          query: "쿼리",
          changeFromMonthToMonth: "반지 변경",
          tagRanking: "레이블 순위",
          proportion: "백분율 가능한 상황",
        },
        component: {
          numberOfConversations: "대화의 수",
          operation: "운영",
          businessAnalysispng: "비즈니스 Analytics.png",
          savingSucceeded: "성공적으로 저장",
          deletionSucceeded: "성공적으로 삭제",
          sequence: "시퀀스",
          firstLevelClassification: "레벨 1 분류",
          ringRatioChange: "반지 변경",
          classClassification: "클래스 분류",
        },
      },
      intelligentExtractors: {
        components: {
          pageLeftTable: {
            areYouSureToDeleteTheDocumentAndItsExtractedContent:
              "문서와 추출 된 내용을 삭제하는지 확인하십시오.",
            addKnowledgeBase: "지식 기반 추가",
            uploadDocument: "문서 업로드",
            supportsWordPDFPowerPointExcelAndTxtFormatFiles:
              "지원 단어, pdf, ppt, 엑셀, txt 형식 파일",
            taskName: "작업 이름",
            extractConversationDate: "대화 날짜 추출",
            pleaseSelectAConversationDate: "대화 날짜를 선택하십시오.",
            dataSource: "데이터 소스",
            dataType: "데이터 유형",
          },
          pageRightTable: {
            index: {
              exportSuccessful: "수출 성공",
              problem: "질문",
              answer: "답변",
              addToKnowledgeBase: "지식베이스에 추가",
              pleaseConfirmWhetherToDeleteIt: "삭제 여부를 확인하십시오.",
            },
            pageRightFrom: {
              addToKnowledgeBase: "지식베이스에 추가",
              selectClassification: "카테고리 선택",
              pleaseSelectAKnowledgeBaseClassification:
                "지식 기본 분류를 선택하십시오.",
            },
          },
        },
        index: {
          intelligentExtraction: "지능형 추출",
          documentExtraction: "문서 추출",
          dialogueExtraction: "대화 추출",
        },
      },
      ignoreLabel: {
        index: {
          operationSuccessful: "작전 성공",
          labelIgnored: "태그 무시",
          add: "추가",
          areYouSureToDeleteIt: "삭제 하시겠습니까?",
          batchFilterLabels: "배치 필터 라벨",
        },
      },
    },
    logoSite: {
      index: {
        intelligentQualityInspection: "지능형 품질 검사",
        savedSuccessfully: "성공적으로 저장",
        logoSettings: "로고 설정",
        default: "기본",
        custom: "맞춤",
        title: "제목",
        theTitleOfTheSystemDisplayShouldBeWithinWords:
          "시스템에 표시되는 제목 이름은 6 자 이내 인 것이 좋습니다.",
        logoImageUpload: "로고 이미지 업로드",
        pleaseSelectAPictureWithASizeOfNoMoreThankb:
          "크기가 2048kb 를 초과하지 않는 사진을 선택하십시오.",
        inOrderToEnsureTheBrowsingEffectOfLogoUploadPicturesSuggestions:
          "로고 브라우징 효과를 보장하기 위해 사진을 업로드하는 것이 좋습니다.",
        Size5050Pixels: "1. 크기: 50*50 픽셀;",
        FormatPicturesWithTransparentBackgroundPNGOrJPGLogoGraphicsInWhite:
          "2. 형식: 투명한 배경이있는 그림, png 또는 jpg, 흰색을 사용하여 로고 그래픽",
        browserLabelIcon: "브라우저 탭 아이콘",
        toEnsureTheBrowsingEffectOfTagsUploadPicturesSuggestions:
          "태그 브라우징의 효과를 보장하기 위해 사진을 업로드하는 것이 좋습니다.",
        Size3232Pixels: "1. 크기: 32*32 픽셀;",
        FormatPictureWithTransparentBackgroundPNGOrJpg:
          "2. 형식: 투명한 배경, png 또는 jpg 그림",
      },
    },
    sdkTest: {
      index: {
        qualityInspectionInProgressPleaseCheckLater:
          "품질 검사 진행 중, 기다려주십시오...",
      },
      componentIndex: {
        atThatTimeInChinasRealEstateReachedACriticalMomentForItsSurvivalALargeNumberOfHousesWereLeftUnattendedAndThePeopleWereUnwillingToBuyThemHangzhouOnceFellBelow8000AndSomeDevelopersFailedToShipAtThatTimeShenzhenWasStillAbout2w3wConsolidationAndTheHousesInTheFirstTierCitiesWereAlsoInDanger:
          "2015 년에 우리나라의 부동산은 생존의 중요한 순간에 도달했습니다. 많은 수의 집이 무인 상태였으며 사람들은 집을 사려고하지 않았습니다. 항저우는 한때 8000 아래로 떨어졌고, 일부 개발자들은 선적을 위해 시장을 박살냈다. 그 당시 심천은 여전히 2w-3w 정도를 통합하고 있었고 1 층 도시의 주택도 위험에 처했습니다.",
        xiaoAn: "앤.",
        storestoreStoreloadTheseMemoryBarriersAreCalledJSRMemoryBarriersTheyAreImplementedAtTheJVMLevelThereAreTwoWaysToImplementThemAtTheBottomOfTheOperatingSystemLockBus2MemoryBarrierMetaLanguageSuchAsSfancewriteBarrierMfencefullScreenBarrierAndIfencereadBarrierTheBottomLayerOfTheHotspotsVirtualMachineUsesAnAssemblyStatementLockADDLLockIsAnEmptyOperationThatIsLockBusThisIsBecauseSomeOperatingSystemsDoNotSupportMemoryBarrierMetaLanguage:
          "Storestore는 이러한 메모리 장벽을 jvm 수준에있는 jsr 메모리 장벽이라고합니다. 운영 체제의 하단에는 두 가지 구현이 있습니다. 하나는 버스를 잠그는 것이고 다른 하나는 sfance (쓰기 장벽) mdefence (전체 장벽) ifence (읽기 장벽) 와 같은 메모리 장벽 프리미티브입니다. 핫스팟 가상 머신의 하단 레이어는 어셈블리 문을 사용합니다. 잠금 addl, 잠금 버스 인 빈 작업 잠금. 이것은 일부 운영 체제가 메모리 장벽 프리미티브를 지원하지 않기 때문에 수행됩니다.",
        xiaobei: "샤오베이",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheIngredientsAndWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "1. 고기를 여러 번 담그고 와인을 넣은 후 야채를 볶습니다. 고기는 썩은 냄새가 나고 신맛이 난다! 이제부터는 베이컨을위한 와인을 더 이상 요리하지 않습니다.",
        xiaodong: "샤오동",
        xiaoxi: "샤오시",
        TryToMarinateTheMeatForSeveralTimesTheDishesFriedAfterAddingTheWineAreLikeRottenMeatWithASourSmellSinceThenNoCookingWineHasBeenAddedToTheBacon:
          "3. 고기를 여러 번 담그고 와인을 넣은 후 튀긴 야채는 신맛이 나는 버릇없는 고기와 같습니다! 이제부터는 베이컨을위한 와인을 더 이상 요리하지 않습니다.",
      },
    },
    home: {
      index: {
        ecommerceReviewManagement: "전자 상거래 검토 관리",
        socialMediaReviewManagement: "소셜 미디어 검토 관리",
        customDataSource: "사용자 지정 데이터 소스",
        qualityInspectionResults: "품질 검사 결과",
        accountManagement: "계정 관리",
        employeeProfile: "직원 초상화",
        intelligentAnalysis: "지능형 분석",
        customKanban: "커스텀 칸반",
        dataScreening: "데이터 개요",
        learningRecord: "학습 기록",
        dataAnalysis: "데이터 분석",
        scoringManagement: "채점 관리",
        businessConfiguration: "비즈니스 구성",
        manualReview: "수동 검토",
        taskList: "작업 목록",
        videoAnalysis: "비디오 분석",
        overview: "개요",
        personalSignage: "개인 칸반",
        learningCenter: "학습 센터",
        wrongQuestionSet: "잘못된 문제 세트",
        abilityImprovement: "업그레이드 기능",
        close: "닫기",
        switchingSystemApplications: "스위칭 시스템 응용 프로그램",
        moveTheMouseHereToCallUpTheApplicationSwitchingMenuClickTheMenuItemToJumpToDifferentApplications:
          "여기에서 마우스를 이동하여 응용 프로그램 전환 메뉴를 호출하고 메뉴 항목을 클릭하여 다른 응용 프로그램으로 이동합니다.",
        teamAnalysis: "팀 분석",
        semanticIntelligence: "의미 지능",
        permissionManagement: "허가 관리",
        dataConnection: "데이터 도킹",
        courseManagement: "코스 관리",
        labelManagement: "라벨 관리",
        curriculum: "코스 센터",
        courseConfiguration: "코스 구성",
        task: "내 임무",
        historicalLearningData: "역사",
        taskCenter: "작업 관리",
        knowledgeBase: "지식 기반",
        ecommerceSessionManagement: "전자 상거래 세션 관리",
      },
      components: {
        subAppEntries: {
          applicationSwitching: "응용 프로그램 전환",
          descriptionOfIntelligentQualityInspectionSystem:
            "지능형 품질 검사 시스템에 대한 설명",
        },
      },
    },
    eCommerceManage: {
      template: {
        ecommerceSessionFilter: "전자 상거래 세션 필터",
        allEcommerceSessions: "모든 전자 상거래 세션",
        ecommerceSessionManagement: "전자 상거래 세션 관리",
      },
    },
    analysisEnable: {
      bestPractices: "모범 사례",
      behaviorAnalysis: "행동 분석",
      teamAnalysis: "팀 분석",
      executiveSupervision: "집행부 감독",
      executiveForceAnalysis: "실행 분석",
    },
    appealCheckingTask: {
      sdk: {
        index: {
          grade: "등급:",
          startTime: "시작 시간",
          workOrderNo: "작업 주문 번호",
          customerServiceHasReadNotRead: "에이전트 읽기/읽지 않음",
          canYouAppeal: "항소 여부",
        },
      },
    },
    clientCenter: {
      wechatClientManage: {
        template: {
          enterpriseAndMicroNotes: "기업 마이크로 발언",
          addFriendTime: "친구 시간 추가",
        },
      },
    },
    qualityInspectionList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "재검사 시작",
          callID: "전화 ID",
        },
        template: {
          newFeaturesClickThequickSpotCheckButtonAndAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "새로운 기능! 클릭 '빠른 샘플링 체크' 버튼은 성공적으로 제출 후 자동으로 다음 페이지로 이동합니다 ~ ~ ~",
        },
      },
      template: {
        deleteRecord: "기록 삭제",
        areYouSureToDeleteIt: "삭제 하시겠습니까?",
        confirm: "결정",
        reIdentification: "재 식별",
        rerecognitionWillConsumeTranscriptionTimePleaseOperateWithCaution:
          "재 식별은 전송 시간을 소비하므로 조심해서 운영하십시오.",
        determine: "결정",
        executionSuccessful: "성공적인 실행",
      },
      component: {
        pleaseSelectData: "데이터를 선택하십시오",
        operationSuccessful: "작전 성공",
        startPerformingReQualityInspection: "재검사 시작",
      },
    },
    recordUpload: {
      template: {
        uploadFileName: "파일 이름 업로드",
        analysisTask: "분석 작업",
        analyzeProgress: "진행 상황 분석",
        analysisSucceeded: "성공 분석",
        analysisFailed: "분석 실패",
        localDataUpload: "로컬 데이터 업로드",
        recordingUpload: "오디오 녹음 업로드",
        textUpload: "텍스트 업로드",
        uploadTime: "업로드 시간",
        taskName: "작업 이름",
        operator: "연산자",
        qualityInspectionProgress: "품질 검사 진행",
        whole: "모두",
        haveInHand: "진행 중",
        completed: "완료",
        exportAllCompletedDataRecordsUnderTheCurrentFilterCondition:
          "현재 필터 기준에 따라 완료된 모든 데이터 레코드를 내보내기?",
        upload: "업로드",
      },
      detail: {
        info: {
          template: {
            fillInTheName: "이름 채우기",
            fillInTheNameSuchAsTheTypesetpurposeOfTheImportedData:
              "가져온 데이터의 유형/컬렉션/목적과 같은 이름을 입력합니다.",
            default: "기본",
            jDCOM: "징동",
            dataType: "데이터 유형",
            selectAnalysisTask: "분석 작업 선택",
            uploadFiles: "파일 업로드",
            DownloadExcelTemplateAndFillInAccordingToTheFormat:
              "1. 엑셀 템플릿을 다운로드하고 형식에 따라 채우십시오.",
            template: "템플릿",
            SupportUpTo10000PiecesOfData: "2. 데이터의 최대 지원 10000 조각",
            supportTheUploadOfDialogDataExportedByJDLogFileWithAMaximumFileSizeOfM:
              "지원 Jingdong 내보낸 대화 데이터 upload,.txt 파일, 파일 크기 최대 지원 100M",
            alongTheWayDataTemplate: "길을 따라 가기 데이터 템플릿",
          },
        },
        result: {
          template: {
            textUnderInspection:
              "텍스트 품질 검사 중에 참을성있게 기다리십시오.",
            textQualityInspectionCompleted: "텍스트 품질 검사 완료",
          },
        },
        template: {
          startAnalysis: "분석 시작",
          pleaseUploadTheFile: "파일을 업로드하십시오.",
          localDataUpload: "로컬 데이터 업로드",
        },
      },
    },
    reviewList: {
      detail: {
        template: {
          newFeaturesClickThereviewAndSubmitButtonAndThePageWillAutomaticallyJumpToTheNextPageAfterTheSubmissionIsSuccessful:
            "새로운 기능! 클릭 '검토 제출' 버튼, 제출 성공 후 자동으로 다음 페이지로 이동합니다 ~ ~ ~",
        },
      },
    },
    role: {
      detail: {
        taskPermissions: {
          template: {
            qualityInspectionTaskPermission: "품질 검사 작업 권한",
            edit: "편집",
            iParticipated: "나는 관여하고있다.",
          },
        },
        dataPermissions: {
          template: {
            thisRoleCanSeeDataUnderTheSelectedDepartment:
              "이 역할은 선택한 부서의 데이터를 볼 수 있습니다.",
            dataPermissions: "데이터 권한",
            selectDepartment: "선택 부서",
            edit: "편집",
          },
        },
      },
    },
    spotCheckList: {
      detail: {
        component: {
          startPerformingReQualityInspection: "재검사 시작",
          callID: "전화 ID",
        },
        template: {
          newFeaturesClickThesampleInspectionSubmissionAndquickSampleInspectionButtonsAfterTheSubmissionIsSuccessfulItWillAutomaticallyJumpToTheNextPage:
            "새로운 기능! 클릭 '샘플링 서브 미트' 및 '빠른 샘플링 서브 미트' 버튼은 성공적으로 제출 후 자동으로 다음 페이지로 이동합니다 ~ ~ ~",
        },
      },
    },
    templateTest: { semanticTags: { template: { rule: "규칙" } } },
    ticketManage: {
      detail: {
        template: {
          workOrderManagement: "작업 주문 관리",
          workOrderDetails: "작업 주문 세부 정보",
        },
      },
      template: {
        workOrderFilter: "작업 주문 필터",
        allWorkOrders: "모든 작업 주문",
        workOrderManagement: "작업 주문 관리",
      },
    },
    trainingCenter: {
      topicAnnotation: "주제 콜아웃",
      topicList: "주제 목록",
      clusteringAnnotation: {
        components: {
          filter: {
            index: {
              roleType: "역할 유형",
              keyWord: "키워드",
              pleaseEnterKeywordSearch: "키워드 검색을 입력하십시오.",
              query: "쿼리",
            },
          },
        },
      },
      similarQuestionRecommend: {
        index: {
          thisFunctionHasNotBeenOpenedYetPleaseContactTheSystemAdministratorIfNecessary:
            "이 기능은 아직 열리지 않았습니다. 필요한 경우 시스템 관리자에게 문의하십시오.",
          pleaseSelectAQuestion: "질문을 선택하십시오!",
          recommendedSimilarSentencesLargeModel: "권장 유사한 문장 (대형 모델)",
          selectAll: "모두 선택",
          add: "추가",
          changeBatch: "배치 변경",
        },
      },
    },
    adminEntry: {
      businessWorkingHours: "사업 근무 시간",
      localDataUpload: "로컬 데이터 업로드",
      assembleMenuListAccordingToCurrentUserPermissions:
        "현재 사용자 권한에 따라 메뉴 목록 조립",
      sessionSettings: "세션 설정",
      logoSettings: "로고 설정",
    },
    login: {
      template: {
        welcomeBackPleaseEnterYourDetails:
          "다시 환영합니다! 세부 정보를 입력하십시오.",
        mailbox: "우편함",
        password: "암호",
        signIn: "로그인",
      },
      components: {
        layout: {
          theCurrentIPDoesNotAllowLogin:
            "현재 IP는 로그인을 허용하지 않습니다.",
          welcomeToLogin: "로그인에 오신 것을 환영합니다",
          pursuingExcellenceAndLeadingChange: "우수성 추구, 변화를 주도",
          intelligentPlatform: "지능형 플랫폼",
        },
        virtualLogin: { index: { pleaseSelect: "선택하십시오" } },
      },
      oauth: {
        illegalCall: "불법 전화",
        welcomeBackClickTheButtonBelowToJumpToTheIdentityAuthenticationPlatform:
          "다시 환영합니다! 아래 버튼을 클릭하여 인증 플랫폼으로 이동합니다.",
        loggingIn: "로그인...",
        unifiedIdentityAuthenticationLogin: "통합 ID 인증 로그인",
      },
    },
    checkPointTest: {
      index: {
        ruleTestSomeRulesCannotBeTestedUsingRulesSuchAsVoiceSpeedVolumeAndOnTheRoadData:
          "규칙 테스트: 음성 속도 및 볼륨, 데이터 등과 같은 규칙을 사용하여 일부 규칙을 테스트 할 수 없습니다.",
      },
    },
    appEntry: {
      components: {
        subSystemEntryCard: {
          learnMore: "더 알아보기",
          enteringTheSystem: "시스템 입력",
        },
      },
      index: {
        cancel: "취소",
        enteringTheSystem: "시스템 입력",
        close: "닫기",
        enterSystemApplication: "시스템 응용 프로그램 입력",
        clickEnterSystemToJumpToTheEnabledSystemApplication:
          '활성화 된 시스템 앱으로 이동하려면 "시스템 입력" 을 클릭하십시오.',
      },
    },
    permissionManagement: {
      employeeManagement: "직원 관리",
      roleManagement: "역할 관리",
      permissionManagement: "허가 관리",
    },
    generalSetting: {
      docking: {
        components: {
          ruleList: {
            beEqualTo: "동등한",
            contain: "포함",
            doesNotContain: "포함되지 않습니다",
            applicationSystem: "신청 시스템",
            intelligentQualityInspection: "지능형 품질 검사/지능형 세션 분석",
            salesEmpowerment: "판매 권한 부여",
            dataSourceType: "데이터 소스 유형",
            filterCriteria: "필터 상태",
          },
        },
        index: {
          dataConnection: "데이터 도킹",
          savingSucceeded: "성공적으로 저장",
          dataIsolation: "데이터 격리",
          afterOpeningYouCanSetTheDataRangeOfEachApplicationSystemAccessCallAndEnterpriseWeChatSessionIfTheSwitchIsNotTurnedOnAllSystemsAreConnectedToFullData:
            "열면 각 응용 프로그램 시스템 액세스 호출 및 엔터프라이즈 WeChat 세션의 데이터 범위를 설정할 수 있습니다. 스위치가 켜지지 않으면 모든 시스템이 전체 데이터에 연결됩니다.",
          accessRules: "액세스 규칙",
          preservation: "저장",
        },
      },
      permissionManagement: {
        role: {
          index: {
            applyName: "응용 프로그램 이름",
            accessRights: "액세스",
            inAppPermissionSettings: "인앱 권한 설정",
            setUp: "설정",
            applicationAccess: "응용 프로그램 액세스",
            newRole: "새로운 역할",
          },
        },
      },
    },
    wechatNotificationAnalysis: {
      conversationalAnalysis: { index: { sessionDetails: "세션 세부 사항" } },
      messageNotification: {
        index: {
          sessionDetails: "세션 세부 사항",
          caseDetails: "사례 세부 정보",
        },
      },
    },
    coach: {
      courseManagement: {
        baseInfo: {
          index: {
            visibleEmployees: "보이는 직원",
            newCourse: "새로운 코스",
            editCourse: "코스 편집",
            courseName: "코스 이름",
            courseIntroduction: "코스 소개",
            courseType: "코스 유형",
            courseClassification: "코스 분류",
            courseCover: "코스 커버",
            learningContent: "학습 콘텐츠",
            uploadLearningMaterials: "학습 자료 업로드",
          },
        },
        flow: {
          components: {
            configDrawer: {
              test: "테스트",
              preservation: "저장",
              cancel: "취소",
              editNodeName: "노드 이름 편집",
              determine: "결정",
              individual: "하나",
              of: ", 총",
              branch: "포인트",
            },
            configDrawerComponents: {
              pPTParsing: "PPT 분석...",
              uploadSuccessful: "성공적인 업로드",
              uploadAttachments: "첨부 파일 업로드",
              pPTParsingPleaseWait: "PPT 파싱, 기다려주세요",
            },
            customerPortraitConfigDrawer: {
              cancel: "취소",
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisPortrait:
                "삭제 후 복원 할 수 없습니다. 이 초상화를 삭제하고 싶습니까?",
              delete: "삭제",
              determine: "결정",
              customerPortrait: "고객 초상화",
              bindSelectedCharacterPortrait: "선택한 캐릭터 초상화 바인딩",
              customerProfileDetails: "고객 프로필 세부 정보",
              createANewCustomerProfile: "새로운 고객 프로필",
            },
            flow: {
              sidebar: { node: "노드" },
              useControlButtons: {
                cancellingPleaseWait: "실행 취소, 나중에 제발...",
                redoInProgressPleaseWait: "다시 시작하십시오. 나중에 제발...",
                automaticOrganization: "자동 마무리",
                canvasAdaptation: "캔버스 적응",
                delete: "삭제",
              },
              useFlow: {
                nodeCannotBeConnectedToItself:
                  "노드는 그 자체에 연결할 수 없습니다",
              },
            },
            globalConfigDrawer: {
              cancel: "취소",
              determine: "결정",
              globalConfiguration: "글로벌 구성",
              sessionDetection: "세션 감지",
              reciprocalRule: "상호 작용 규칙",
              robotVoice: "로봇 사운드",
            },
            interactionRuleForm: {
              studentNodeTimeoutWithoutAnsweringSetting:
                "학생 노드 시간 제한 미응답 설정",
              maximumAllowedSilenceTimeForStudentsNotAnswerings:
                "최대 허용 침묵 시간 (s)",
              promptScriptForFailureToAnswerOverTime:
                "시간 초과를위한 프롬프트",
              addScript: "단어 추가",
            },
            robotTimbreForm: {
              aixianatural: "아이샤 (자연)",
              zhishaChat: "Zhisha (채팅)",
              zhiqiangentle: "지키 안 (젠틀)",
              zhimiEmotion: "쌀 (감정) 을 알고",
              aiShuoNatural: "아이슈오 (자연)",
              zhishuoChat: "Zhishuo (채팅)",
              aiHaoAffinity: "아이 하오 (애정)",
              zhixiangEmotion: "Zhixiang (감정)",
              robotVoice: "로봇 사운드",
              schoolboy: "소년",
              girlStudent: "여자",
              volume: "볼륨",
              tone: "톤",
              speechSpeed: "연설의 속도",
              test: "테스트",
              helloIAmAnIntelligentTrainingCoachAndLookForwardToCommunicatingWithYou:
                "안녕하세요, 저는 지능형 훈련 코치이며 당신과 의사 소통을 기대합니다.",
            },
            sessionDetectionForm: {
              expressionDetection: "표현 감지",
              excessiveDetectionOfModalParticles: "과도한 모달 단어 감지",
              theNumberOfRepetitionsIs: "반복 횟수는",
              minus: "시간, 마이너스",
              appear: "나타납니다.",
              subtract: "시간, 마이너스",
              branch: "포인트",
              addRules: "규칙 추가",
              includingNegativeAngryComplaining: "(부정, 분노, 불만 포함)",
              scorePointsDisplay: "점수 표시",
              reminderOfSpeakingTooFasttooSlow: "너무 빨리/너무 느린 알림",
              scriptViolationReminder: "연설 위반에 대한 알림",
              emotionalReminder: "정서적 알림",
              speechSpeedDetection: "음성 속도 감지",
              normalSpeechSpeedScore: "속도 정상 점수",
              speakTooFast: "너무 빨리 빨리",
              everyMinuteGreaterThan: "분당 더 큰",
              aWordAppears: "단어, 나타납니다.",
              speakTooSlowly: "속도 느린",
              lessThanPerMinute: "분당 미만",
              ignoreLessThan: "덜 무시하십시오",
              aSentenceOfWords: "단어의 문장",
              emotionalDetection: "감정 감지",
              emotionalNormalScore: "정상적인 기분 점수",
              negativeEmotions: "부정적인 감정",
              illegalWordDetection: "불법 단어 탐지",
              scriptViolationConfiguration: "음성 위반 구성",
              realTimePromptOnTheFrontEnd: "프론트 엔드 실시간 프롬프트",
            },
            const: {
              nodeConfigs: {
                knowledgeNode: {
                  knowledgeBaseQA: "지식 기반 Q & A",
                  qAMethod: "질문 및 답변 방법",
                  numberOfQuestionsAndAnswers: "질문 및 답변 수",
                  scoringRules: "점수 규칙",
                  ifAnyQuestionAndAnswerIsCorrect:
                    "모든 질문과 대답은 정확합니다.",
                  branch: "포인트",
                  successfullySaved: "성공적으로 저장",
                },
                pptNode: {
                  verificationPassed: "검증 통과",
                  upload: "업로드",
                  supportPptPdfFormatFiles: "Ppt 및 pdf 형식 파일 지원",
                  uploadSlide: "슬라이드 업로드",
                  assessmentMethod: "평가 방법",
                  associateSlide: "준 슬라이드",
                  standardScript: "표준 연설",
                  successfullySaved: "성공적으로 저장",
                },
                robotNode: {
                  robotScript: "로봇 연설",
                  randomScript: "무작위 연설",
                  chooseAScriptWithSimilarSemanticsAndTheRobotWillRandomlySayOneSentence:
                    "비슷한 의미를 가진 단어를 선택하면 로봇이 무작위로 문장 중 하나를 말합니다.",
                  customerPortrait: "고객 초상화",
                  whenTheCustomersPortraitIsConsistentTheRobotSaysThisScript:
                    "고객 초상화가 일관되면 로봇이 이것을 말합니다.",
                  setAsDefaultScript: "기본값으로 설정",
                  yes: "예",
                  no: "아니",
                  useThisScriptWhenTheRobotMissesAnyUserTags:
                    "이것은 로봇이 사용자 태그를 놓칠 때라고합니다.",
                  successfullySaved: "성공적으로 저장",
                  thisBranchNodeAlreadyHasADefaultScriptDoYouWantToReplaceTheDefaultScriptWithTheCurrentScript:
                    "이 분기 노드는 이미 기본 음성을 가지고 있습니다. 기본 음성을 현재 음성으로 바꾸시겠습니까?",
                },
                startNode: {
                  paragon: "음성 해설",
                  successfullySaved: "성공적으로 저장",
                },
                userNode: {
                  verificationPassed: "검증 통과",
                  standardScript: "표준 연설",
                  successfullySaved: "성공적으로 저장",
                },
                aiGenDialogueNode: {
                  pleaseImproveTheConfiguration: "완벽한 구성",
                },
                aiGenPromptNode: {
                  pleaseEnterAConversationExample: "샘플 대화를 입력하십시오.",
                  dialogueExample: "대화 예",
                  generateConversationExamples: "대화 예 생성",
                  mattersNeedingAttention: "주의 사항",
                  pleaseEnterThePromptLanguage: "프롬프트를 입력하십시오.",
                },
                aiGenSceneNode: {
                  dialogueScenario: "대화 장면",
                  simulateCharacter: "시뮬레이션 캐릭터",
                  accompanyingTrainees: "동반 객체",
                  selectFromTemplateLibrary: "템플릿 라이브러리에서 선택",
                  templateLibrary: "템플릿 라이브러리",
                  role: "역할",
                  scene: "장면",
                },
              },
            },
            pPTTestDrawer: {
              slideTestWindow: "슬라이드 테스트 창 [",
              clearContent: "명확한 내용",
              close: "닫기",
            },
            aiGenFlowDrawer: {
              index: {
                aIGenerationProcessScript: "AI 생성 프로세스",
                scriptStyle: "스피치 스타일",
                theMostConversationRounds: "대부분의 대화 라운드",
                copyConversation: "복사 대화",
                application: "신청",
                regenerate: "재생",
                startGenerating: "시작 생성",
                pleaseGenerateAProcess: "프로세스를 생성하십시오",
                copySuccessful: "성공 복사",
              },
            },
            aiGenPPTDrawer: {
              components: {
                tipInput: {
                  index: {
                    salesRepresentative: "영업 대표",
                    customerServicePersonnel: "고객 서비스 직원",
                    storeCounter: "상점 카운터",
                  },
                },
              },
              index: {
                generateFullTextSpeechScript: "전체 텍스트 음성 기술 생성",
                speaker: "스피커",
                scriptStyle: "스피치 스타일",
                copyConversation: "복사 대화",
                application: "신청",
                regenerate: "재생",
                startGenerating: "시작 생성",
                pleaseGenerateAProcess: "프로세스를 생성하십시오",
                operationSuccessful: "작전 성공",
                copySuccessful: "성공 복사",
              },
            },
            aiTextArea: { index: { scriptPolishing: "연설 광택" } },
            aiWordsTextArea: {
              theScriptCannotBeEmpty: "단어는 비어있을 수 없습니다!",
              copySuccessful: "성공 복사",
              cancel: "취소",
              copy: "복사",
              replaceTheOriginalText: "원본 교체",
              aiGeneration: "Ai 세대",
              aiPolishing: "Ai 광택",
              currentStyle: "현재 스타일",
              rigorous: "엄격한",
              mild: "젠틀",
              lively: "활기찬",
              simplicity: "간단한",
              pleaseEnterTheSpeakerRole: "스피커 역할을 입력하십시오.",
              startGenerating: "시작 생성",
            },
            auditionTextArea: {
              theScriptCannotBeEmpty: "단어는 비어있을 수 없습니다!",
              copySuccessful: "성공 복사",
              aiRewrite: "Ai 재 작성",
              cancel: "취소",
              copy: "복사",
              replaceTheOriginalText: "원본 교체",
              currentStyle: "현재 스타일",
              rigorous: "엄격한",
              mild: "젠틀",
              lively: "활기찬",
              simplicity: "간단한",
              pleaseEnterTheSpeakerRole: "스피커 역할을 입력하십시오.",
              startGenerating: "시작 생성",
            },
          },
          index: {
            verificationPassed: "검증 통과",
            ruleValidation: "규칙 확인",
            aIGeneration: "AI 발생",
            generateFullTextSpeechScript: "전체 텍스트 음성 기술 생성",
            editCourse: "코스 편집",
            importConversation: "수입 대화",
            uploadExcelToCompleteDialogueImport:
              "Excel 전체 대화 가져오기 업로드",
            editBasicInformation: "기본 정보 편집",
            test: "테스트",
            release: "게시",
          },
        },
      },
      customerTagsManagement: {
        components: {
          addTag: { index: { newLabel: "새 레이블" } },
          tag: {
            index: {
              afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabel:
                "삭제 후 복구 할 수 없습니다. 이 레이블을 삭제하고 싶습니까?",
              delete: "삭제",
              cancel: "취소",
            },
          },
        },
        index: {
          labelManagement: "라벨 관리",
          newGroup: "새로운 그룹",
          edit: "편집",
          afterDeletionItCannotBeRestoredAreYouSureYouWantToDeleteThisLabelGroup:
            "삭제 후 복구 할 수 없습니다. 이 레이블 그룹을 삭제하시겠습니까?",
          delete: "삭제",
          cancel: "취소",
          editGroup: "그룹 편집",
          labelGroupName: "레이블 그룹 이름",
        },
      },
      learningCenter: {
        components: {
          camera: {
            index: {
              cameraDisabled: "카메라 비활성화",
              noCameraDevicesFound: "카메라 장치를 찾을 수 없음",
            },
          },
          cue: {
            index: {
              regenerate: "재생",
              generate: "생성",
              referenceScript: "참조 연설",
              mattersNeedingAttention: "주의 사항",
              examFrequency: "시험 수",
              section: "아니.",
              timestotal: "시간/합계",
              second: "타임즈",
              requirementsForPassingTheExam: "시험 합격 요구 사항",
              examScore: "시험 점수 ≥",
              branch: "포인트",
            },
          },
          customerInfo: {
            index: {
              thereIsCurrentlyNoPortraitAvailable: "초상화 없음",
              customerBackground: "고객 배경",
              customerLabel: "고객 라벨",
            },
          },
          flow: {
            index: {
              processReminder: "프로세스 프롬프트 (",
              score: "점수",
              TotalScore: "/총 점수",
            },
          },
          index: {
            taskName: "작업 이름",
            taskStatus: "작업 상태",
            whole: "모두",
            completionStatus: "완료 상태",
            courseClassification: "코스 분류",
            practiceMode: "연습 모드",
            examMode: "검사 모드",
            practiceTime: "운동 시간",
          },
          ppt: {
            index: {
              nextPage: "다음 페이지",
              tips: "프롬프트",
              continueWithCurrentPage: "현재 페이지 계속",
              goToTheNextPage: "다음 페이지로 이동",
              afterEnteringTheNextPageYouWillNotBeAbleToReturnToThePreviousPagePleaseConfirmThatTheExplanationOnTheCurrentPageIsCompleteBeforeJumpingToTheNextPage:
                "다음 페이지를 입력하면 이전 페이지로 돌아갈 수 없습니다. 점프하기 전에 현재 페이지에 대한 설명이 완료되었는지 확인하십시오.",
              clickingOnTheNextPageWillNoLongerCauseThisPromptToPopUp:
                "다음 페이지를 클릭하면이 프롬프트가 팝업되지 않습니다.",
            },
            list: { index: { slide: "슬라이드" } },
          },
          record: {
            index: {
              unableToRecord: "기록 할 수 없음:",
              creatingARecorderService: "레코더 서비스 만들기",
              turnOffTheRecorderService: "레코더 서비스 끄기",
              theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                "현재 클라이언트가 이미 ASR Websocket 서버에 연결되어 있습니다.",
              afterProcessingTheLastFrameRestoreTheState:
                "마지막 프레임을 처리 한 후 상태를 복원하십시오.",
              aSRConnectionClosed: "ASR 연결 폐쇄...",
              recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                "레코딩이 일시 중지되고 처리되었지만 캐시에 데이터가 남아 있습니다.",
              foundASRChannelClosedRecreateWebsocketLink:
                "발견 ASR 채널 폐쇄, 다시 생성 Websocket 링크",
              foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                "ASR 채널이 열려 있지 않다는 것을 발견하고 Websocket 링크를 다시 만듭니다.",
            },
            ppt: { hook: { index: { serverError: "서버 오류" } } },
            text: {
              index: {
                pleaseEnter: "입장해주세요",
                sendOut: "보내기",
                startPracticing: "연습 시작",
                startTheExam: "시험 시작",
              },
            },
            util: {
              recorded: "기록",
              BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                "16 비트 pcm SendFrameSize는 2 의 정수 배수 여야합니다.",
              pCMSamplingRateShouldNotOccur:
                "Pcm 샘플링 속도가 나타나지 않아야합니다.",
              andTheRequiredSamplingRate: "그리고 필요한 샘플링 속도",
              atypism: "일관되지 않음",
              errorsThatShouldNotOccur: "발생하지 않아야 하는 오류:",
              spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                '<Span style = "color:red"> 요구 사항이 높지 않으면 고장난 데이터를 버릴 수 있습니다. </Span>',
              flower: "꽃",
              TransferStopped: ": 전송 중지",
            },
            video: { index: { camera: "카메라" } },
            voice: {
              hook: {
                bat: {
                  index: {
                    theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                      "현재 클라이언트가 이미 ASR Websocket 서버에 연결되어 있습니다.",
                    aSRConnectionClosed: "ASR 연결 폐쇄...",
                    foundASRChannelClosedRecreateWebsocketLink:
                      "발견 ASR 채널 폐쇄, 다시 생성 Websocket 링크",
                    unableToRecord: "기록 할 수 없음:",
                    aSRChannelClosedReopened: "ASR 채널 폐쇄, 재개",
                    theASRChannelWasNotCreatedStartingNow:
                      "ASR 채널이 생성되지 않음, 지금 만들기 시작",
                    useEffectCallbackToClearTheASRChannel:
                      "ASR 채널을 지우려면 useffect 콜백",
                    creatingARecorderService: "레코더 서비스 만들기",
                    turnOffTheRecorderService: "레코더 서비스 끄기",
                  },
                  util: {
                    recorded: "기록",
                    BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                      "16 비트 pcm SendFrameSize는 2 의 정수 배수 여야합니다.",
                    pCMSamplingRateShouldNotOccur:
                      "Pcm 샘플링 속도가 나타나지 않아야합니다.",
                    andTheRequiredSamplingRate: "그리고 필요한 샘플링 속도",
                    atypism: "일관되지 않음",
                    errorsThatShouldNotOccur: "발생하지 않아야 하는 오류:",
                    spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                      '<Span style = "color:red"> 요구 사항이 높지 않으면 고장난 데이터를 버릴 수 있습니다. </Span>',
                    flower: "꽃",
                    TransferStopped: ": 전송 중지",
                  },
                },
                index: { failInSend: "보내지 못함" },
              },
              index: {
                startRecording: "녹음 시작",
                endRecording: "끝 녹음",
                startPracticing: "연습 시작",
                startTheExam: "시험 시작",
              },
            },
          },
          taskMessageModal: {
            index: {
              theRemainingNumberOfTimesForTheCurrentExamIs:
                "(현재 남아있는 시험 수는",
              totalScriptScore: "총 음성 점수:",
              totalEmotionalScore: "총 감정 점수:",
              totalScoreOfExpression: "총 표현 점수:",
              theCurrentTaskRatingResultNeedsToBeManuallyReviewedAfterTheReviewIsCompletedYouWillBeNotifiedOfTheFinalScorePleaseBePatientAndWait:
                "현재 작업 채점 결과를 수동으로 검토해야합니다. 검토가 완료되면 최종 결과가 통보됩니다. 참을성있게 기다려주세요.",
              areYouSureToSubmit: "제출 확인?",
              theExerciseHasEndedAreYouSureToSubmit:
                "운동이 끝났습니다. 제출하고 싶습니까?",
              theExamHasEndedAreYouSureToSubmit:
                "시험이 끝났습니다. 제출하고 싶습니까?",
              completed: "완료",
              page: "페이지",
              pageCount: "총 페이지",
              topic: "주제",
              totalNumberOfQuestions: "총 질문 수",
              totalScore: "총 점수",
              branch: "포인트",
              accumulatedCompletionProgress: "누적 완료 진행",
              accumulatedPracticeDuration: "누적 연습 기간",
              lastPauseExerciseResults: "마지막 타임 아웃 연습 결과",
              exerciseInstructions: "운동 지침",
              examInstructions: "시험 설명",
              halfwayPausePracticeResults: "중간 일시 중지-연습 결과",
              tips: "프롬프트",
              afterExitingThisExerciseWillNotBeCountedTowardsTheGradeAreYouSureToExit:
                "종료 후, 이 운동은 점수에 포함되지 않습니다. 그만하고 싶니?",
              exitIsNotAllowedMidwayThroughTheExamAreYouSureToSubmit:
                "시험 중간에 종료는 허용되지 않습니다. 제출 확인?",
              pleaseOperateWithCaution: "시간, 주의 깊게 운영하십시오)",
              restart: "다시 시작",
              returnToLearning: "학습으로 돌아 가기",
              continuePracticing: "계속 연습",
              continuingTheExam: "시험 계속",
              return: "반환",
              notSubmittedTemporarily: "제출하지 마십시오",
              iGotIt: "알아요",
              confirmExit: "출구 확인",
              confirmSubmission: "제출 확인",
              signOut: "출구",
            },
          },
          taskSubmitButton: {
            index: {
              suspend: "일시 중지",
              submit: "제출",
              remainingTimeOfThisQuestion: "남은 시간",
              SecondsLeftUntilTheEndTime: "종료 시간까지 10 초",
              remainingTime: "남은 시간",
            },
          },
          util: {
            noUserVoiceContentDetectedPleaseTryAgain:
              "사용자 음성 콘텐츠가 감지되지 않음, 다시 시도하십시오",
          },
          wave: {
            index: {
              continueRecording: "계속 녹음",
              submit: "제출",
              endRecording: "끝 녹음",
              suspendRecording: "일시 정지 녹음",
              startPracticing: "연습 시작",
              startTheExam: "시험 시작",
            },
          },
          deviceModal: {
            index: {
              dialogueMode: "대화 모드",
              excessiveNoisePleaseSwitchToAQuietEnvironment:
                "너무 많은 소음, 조용한 환경으로 전환하십시오",
              normalNoiseDetection: "소음 감지는 정상입니다.",
              theVolumeIsTooLowPleaseIncreaseTheVolume:
                "볼륨이 너무 낮습니다. 볼륨을 높이십시오.",
              volumeDetectionIsNormal: "볼륨 감지 정상",
              noiseDetection: "소음 감지",
              pleaseRemainQuietAndRecordAsAmbientSound:
                "조용히 유지하고 3s 주변 소리를 녹음하십시오.",
              endRecording: "끝 녹음",
              startRecording: "녹음 시작",
              volumeDetection: "볼륨 감지",
              pleaseReadNowPerformVolumeTestAtNormalVolume:
                '정상 볼륨에서 "지금 볼륨 테스트" 를 읽으십시오.',
              playLastDetectedAudio: "마지막 감지 오디오 재생",
              pleaseReadTheRequirementsCarefullyBeforeStarting:
                "시작하기 전에 요구 사항을주의 깊게 읽으십시오.",
              return: "반환",
              iGotIt: "알아요",
              timeLimitForSingleQuestionAnswering: "단일 질문 답변 시간 제한",
              totalTimeLimit: "총 시간 제한",
              unlimitedTime: "무제한 시간",
              stillNeedToCompleteTheTaskInTotal:
                "여전히 작업을 완료해야합니다.",
              practice: "운동",
              second: "타임즈",
              taskScore: "하나의 작업 점수 ≥",
              branch: "포인트",
              or: "또는",
              examScore: "시험 점수 ≥",
              remainingExamTimes: "남은 시험 수",
              totalScore: "총 점수",
              Script: "(토커",
              scoreEmotion: "감정",
              fractionExpression: "하위 표현식",
              points: "포인트)",
              standardScore: "표준 점수",
              excellentScore: "우수한 점수",
              exerciseInstructions: "운동 지침",
              forASmoothAnswerPleaseCompleteTheEquipmentInspectionFirst:
                "질문에 부드럽게 대답하려면 먼저 장비 테스트를 완료하십시오.",
              camera: "카메라",
              beingAbleToSeeOneselfInTheVideoMeansThatTheCameraIsFunctioningProperly:
                "비디오에서 자신을 볼 수 있다는 것은 카메라가 정상임을 의미합니다.",
              microphone: "마이크",
              pleaseSpeakIntoTheMicrophoneSeeingTheWaveformPatternIndicatesThatTheMicrophoneIsFunctioningProperly:
                "마이크에 대고 말하면 파형 패턴이 마이크가 정상임을 의미합니다.",
            },
          },
        },
        course: {
          components: {
            folderTree: {
              modal: {
                index: {
                  empty: "빈",
                  copyTo: "복사",
                  classification: "분류",
                  rename: "이름 바꾸기",
                  newlyBuild: "새로운",
                  categoryParent: "우수한 분류",
                  moveTo: "이동",
                  name: "이름",
                },
              },
              util: {
                rename: "이름 바꾸기",
                moveTo: "이동",
                copyTo: "복사",
                delete: "삭제",
                courseClassification: "코스 분류",
                newlyBuild: "새로운",
              },
            },
          },
          edit: { index: { curriculum: "커리큘럼" } },
          index: {
            learn: "학습",
            practice: "연습",
            examination: "테스트",
            curriculum: "커리큘럼",
            courseIntroduction: "코스 소개:",
            createdOn: "창조 시간:",
            published: "게시",
            tasks: "작업",
            unpublishedTasks: "미공개 작업",
            task: "작업",
            operation: "운영",
            learningMaterials: "학습 자료",
            goPractice: "연습하러 가라.",
            publishTask: "작업 게시",
            taskData: "작업 데이터",
            edit: "편집",
            copy: "복사",
            successfullyCopied: "성공 복사",
            delete: "삭제",
            afterDeletionAllTaskDataUnderThisCourseWillBeClearedAreYouSureToDeleteTheCourse:
              "삭제 후, 이 코스의 모든 작업 데이터가 삭제됩니다. 코스를 삭제해야합니까?",
            courseName: "코스 이름",
            creationTime: "창조 시간",
            releaseStatus: "게시 상태",
            whole: "모두",
          },
        },
        nodeConfigTest: {
          components: {
            input: {
              noSoundDetected: "감지 된 소리 없음",
              voice: "음성",
              text: "텍스트",
              referenceScript: "참조 연설:",
              clickOnTheRightSideToStartRecording:
                "녹음을 시작하려면 오른쪽을 클릭하십시오.",
              sendOut: "보내기",
            },
            hooks: {
              input: {
                theCurrentClientIsAlreadyConnectedToTheASRWebsocketServer:
                  "현재 클라이언트가 이미 ASR Websocket 서버에 연결되어 있습니다.",
                afterProcessingTheLastFrameRestoreTheState:
                  "마지막 프레임을 처리 한 후 상태를 복원하십시오.",
                aSRConnectionClosed: "ASR 연결 폐쇄...",
                recordingPausedAndHasBeenProcessedButThereIsStillDataInTheCache:
                  "레코딩이 일시 중지되고 처리되었지만 캐시에 데이터가 남아 있습니다.",
                foundASRChannelClosedRecreateWebsocketLink:
                  "발견 ASR 채널 폐쇄, 다시 생성 Websocket 링크",
                foundThatTheASRChannelIsNotOpenRecreateTheWebsocketLink:
                  "ASR 채널이 열려 있지 않다는 것을 발견하고 Websocket 링크를 다시 만듭니다.",
                unableToRecord: "기록 할 수 없음:",
                creatingARecorderService: "레코더 서비스 만들기",
                turnOffTheRecorderService: "레코더 서비스 끄기",
              },
              util: {
                recorded: "기록",
                BitPcmSendFrameSizeMustBeAnIntegerMultipleOf2:
                  "16 비트 pcm SendFrameSize는 2 의 정수 배수 여야합니다.",
                pCMSamplingRateShouldNotOccur:
                  "Pcm 샘플링 속도가 나타나지 않아야합니다.",
                andTheRequiredSamplingRate: "그리고 필요한 샘플링 속도",
                atypism: "일관되지 않음",
                errorsThatShouldNotOccur: "발생하지 않아야 하는 오류:",
                spanStylecolorRedDataWithDisorderedOrderCanBeDirectlyDiscardedIfTheRequirementsAreNotHighspan:
                  '<Span style = "color:red"> 요구 사항이 높지 않으면 고장난 데이터를 버릴 수 있습니다. </Span>',
                flower: "꽃",
                TransferStopped: ": 전송 중지",
              },
            },
            finishTest: {
              index: {
                endTest: "엔드 테스트",
                testResult: "테스트 결과",
                returnToCanvas: "캔버스로 돌아 가기",
                retest: "재 테스트",
              },
            },
          },
          index: {
            testFromCurrentNode: "현재 노드에서 테스트",
            listening: "라디오...",
            canvasTesting: "캔버스 테스트",
            endOfProcess: "프로세스의 끝",
            restart: "다시 시작",
            start: "시작",
          },
        },
        record: {
          detail: {
            components: {
              openButton: {
                index: {
                  collapseAnalysis: "붕괴 분석",
                  expandParsing: "파스 확장",
                },
              },
              overView: {
                index: {
                  tooManyModalParticles: "너무 많은 모달 단어",
                  second: "타임즈",
                  ratingOverview: "점수 개요",
                  totalScore: "총 점수",
                  section: "아니.",
                  name: "이름",
                  of: "(총",
                  people: "사람들)",
                  scriptScore: "음성 점수",
                  common: "합계",
                  keyPoints: "요점,",
                  notReached: "도달하지 못했습니다.",
                  have: "있다",
                  violationOfRegulations: "위반",
                  emotionalScore: "감정 점수",
                  appear: "나타납니다.",
                  secondaryNegativeEmotions: "2 차 부정적인 감정",
                  expressionScore: "표현 점수",
                  speakTooFast: "너무 빨리 빨리",
                  times: "시간,",
                  speakTooSlowly: "속도 느린",
                  average: "평균",
                  wordminute: "단어/분",
                },
              },
              score: {
                index: {
                  studentNodeName: "학생 노드 이름",
                  keyPointName: "키 포인트 이름",
                  slideNodeName: "슬라이드 노드 이름",
                  dialogue: "대화",
                  mainPoints: "요점",
                  slide: "슬라이드",
                  detectionResult: "테스트 결과",
                  satisfy: "만남",
                  dissatisfaction: "만족하지 않음",
                  score: "점수",
                  script: "단어",
                  semantics: "의미",
                  scriptViolation: "언어 위반",
                  emotion: "감정",
                  expression: "표현식",
                },
              },
              taskTitle: { index: { branch: "포인트" } },
              comprehensiveEvaluation: {
                const: {
                  thisTime: "이번에는",
                  lastTime: "지난번",
                  comprehensiveEvaluation: "종합 평가",
                },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· 표준 음성 누적',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "단어, 학생이 치는 단어의 수",
                  aWordspan: "단어 </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· 느린 속도',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· 모달 단어가 너무 많습니다.',
                  complete: "전체 콘텐츠",
                  keyHit: "핵심 포인트 히트",
                  languageNorm: "언어 사양",
                  fluentExpression: "부드러운 표현",
                  emotionallyPositive: "감정적으로 긍정적 인",
                  radarChart: "레이더 차트",
                  spanStylemarginLeftpxNumberOfCompleteProcesses:
                    '<Span style = "margin-left: 8px">· 완전한 흐름의 수',
                  spanStylemarginLeftpxNumberOfHitPoints:
                    '<Span style = "margin-left: 8px">· 키 포인트 히트',
                  spanStylemarginLeftpxYes:
                    '<Span style = "margin-left: 8px">· 예',
                  dealingWithIllegalWordsspan: "단어를 위반하여 </span>",
                  spanStylemarginLeftpxSpeakTooFast:
                    '<Span style = "margin-left: 8px">· 너무 빨리 말하십시오.',
                  spanbrspanStylemarginLeftpxTooManyModalParticles:
                    '</Span><br /><span style = "margin-left: 8px"> 너무 많은 모달 단어',
                  spanStylemarginLeftpxNegativeEmotions:
                    '<Span style = "margin-left: 8px">· 부정적인 감정',
                },
              },
              scoreRecord: {
                index: {
                  scoringRecords: "점수 기록",
                  firstRating: "최초 등급",
                  totalScore: "총 점수",
                  changeTo: "변경",
                },
              },
              aiGenScore: {
                index: {
                  communicationStage: "통신 단계",
                  communicationObjectives: "통신 목표",
                  scoringCriteria: "채점 기준",
                  suggestionsForImprovement: "프로모션 권장 사항",
                },
              },
              onceMore: {
                index: {
                  remainingTimes: "남은:",
                  second: "타임즈",
                  doItAgain: "한 번 더.",
                },
              },
              audio: {
                index: {
                  theBrowserDoesNotSupportAudioPlayback:
                    "브라우저가 오디오 재생을 지원하지 않습니다.",
                },
              },
              evaluationSuggestion: {
                index: {
                  scriptCommentsAndSuggestions: "연설에 대한 의견 및 제안",
                  regenerate: "재생",
                  generate: "생성",
                  generationFailed: "빌드 실패",
                },
              },
              resultModal: {
                index: { return: "반환", viewDetails: "세부 정보 보기" },
              },
            },
            index: {
              reRatingSuccessful: "재득점 성공",
              reRatingFailed: "재득점 실패",
              downloadRecording: "녹음 다운로드",
              downloadText: "텍스트 다운로드",
              download: "다운로드",
              reRating: "재득점",
              submitARatingAppeal: "등급 고충 제출",
              reviewSubmission: "제출 검토",
              saveChanges: "변경 사항 저장",
              previousSentence: "이전 문장",
              nextSentence: "다음 문장",
              score: "등급",
              scoringSituation: "점수",
              dialogueAnalysis: "대화 분석",
            },
          },
          index: {
            ratingAppealInProgress: "점수 고충",
            operation: "운영",
            wrongQuestionSet: "잘못된 문제 세트",
            ratingUpdate: "등급 업데이트",
            viewDetails: "세부 정보 보기",
            curriculum: "커리큘럼",
            taskName: "작업 이름",
            taskType: "작업 유형",
            score: "점수",
            scoringResults: "점수 결과",
            time: "시간",
            courseClassification: "코스 분류",
            whole: "모두",
            learningTime: "학습 시간",
            historicalLearningData: "역사 학습 데이터",
          },
        },
        task: {
          exercise: {
            index: {
              practicePause: "연습 일시 중지",
              lastProgress: "마지막 진행",
              continuePracticing: "계속 연습",
              practice: "운동",
              startPracticing: "연습 시작",
              historicalData: "과거 데이터",
              courseIntroduction: "코스 소개:",
              exerciseDifficulty: "연습의 어려움:",
              practiceCompletionRequirements: "운동 완료 요구 사항:",
              practiceFrequency: "연습 수 ≥",
              second: "타임즈",
              anyScore: "모든 점수 ≥",
              branch: "포인트",
              or: "또는",
              taskList: "작업 목록",
              practiceTime: "운동 시간:",
              to: "에",
              unlimitedTime: "무제한 시간",
              completionStatus: "완료 상태",
              numberOfExercises: "운동 횟수",
              maximumScore: "최고 점수",
              operation: "운영",
              enteringLearning: "학습 입력",
              historicalLearningData: "역사 학습 데이터",
              wrongQuestionSet: "잘못된 문제 세트",
            },
          },
          index: {
            practiceTasks: "연습 작업",
            examTasks: "시험 과제",
            task: "작업",
          },
          learn: {
            index: {
              courseName: "코스 이름",
              courseIntroduction: "코스 소개",
              learningContent: "학습 콘텐츠",
              downloadLearningMaterials: "학습 자료 다운로드",
              startPracticing: "연습 시작",
            },
          },
          test: {
            index: {
              taskList: "작업 목록",
              courseIntroduction: "코스 소개:",
              examDifficulty: "시험의 어려움:",
              requirementsForPassingTheExam: "시험 합격 요구 사항:",
              examScore: "시험 점수 ≥",
              branch: "포인트",
              examTime: "검사 시간:",
              to: "에",
              unlimitedTime: "무제한 시간",
              completionStatus: "완료 상태",
              completionTimes: "완료 횟수",
              maximumScore: "최고 점수",
              operation: "운영",
              immediateExam: "즉각적인 검사",
              history: "역사",
            },
          },
        },
        wrongQuestionSet: {
          exercise: {
            components: {
              cue: { index: { referenceScript: "참조 연설" } },
              flow: {
                index: {
                  wrongQuestionItem: "잘못된 항목 (",
                  score: "점수",
                  TotalScore: "/총 점수",
                },
              },
              record: {
                ppt: {
                  hook: { index: { serverError: "서버 오류" } },
                  index: {
                    startRecording: "녹음 시작",
                    endRecording: "끝 녹음",
                    startPracticing: "연습 시작",
                  },
                },
                text: {
                  index: {
                    pleaseEnter: "입장해주세요",
                    sendOut: "보내기",
                    startPracticing: "연습 시작",
                  },
                },
                voice: {
                  index: {
                    startRecording: "녹음 시작",
                    endRecording: "끝 녹음",
                    startPracticing: "연습 시작",
                  },
                  hook: { index: { failInSend: "보내지 못함" } },
                },
              },
              taskMessageModal: {
                index: {
                  scoreForThisPracticeOfIncorrectQuestions:
                    "이 잘못된 운동의 점수",
                  tips: "프롬프트",
                  areYouSureToEnd: "끝을 확인 하시겠습니까?",
                  practiceAgainWithoutMasteringTheWrongQuestions:
                    "다시 연습하고 잘못된 질문을 마스터하지 마십시오.",
                  continue: "계속",
                  returnToTheSetOfIncorrectQuestions:
                    "잘못된 질문 세트로 돌아 가기",
                  end: "끝",
                  wrongQuestionItem: "잘못된 항목",
                  numberOfExercisesThisTime: "이 운동의 수",
                  masteringTheSituation: "상황 파악",
                  mastered: "마스터링",
                  notMastered: "마스터되지 않음",
                },
              },
              taskSubmitButton: {
                index: {
                  practiceThisQuestionAgain: "이 질문을 다시 연습",
                  nextQuestion: "다음 질문",
                },
              },
            },
            ppt: {
              index: {
                mispracticeExercises: "잘못된 운동",
                endExercise: "운동 종료",
              },
            },
            text: {
              index: {
                mispracticeExercises: "잘못된 운동",
                endExercise: "운동 종료",
              },
            },
            voice: {
              index: {
                mispracticeExercises: "잘못된 운동",
                endExercise: "운동 종료",
              },
            },
          },
          list: {
            components: {
              checkScript: {
                index: {
                  checkScript: "연설보기",
                  section: "아니.",
                  secondaryDiscourseTechnique: "2 차 연설",
                },
              },
              tableItem: {
                count: { second: "타임즈" },
                scoreRuleList: {
                  noRelevantInformationFound:
                    "관련 정보가 발견되지 않았습니다.",
                  recallWords: "단어 리콜",
                  branch: "포인트",
                  answerIncorrectly: "잘못된 대답",
                  second: "타임즈",
                  wrongAnswerRate: "오답 비율",
                },
                standardScript: {
                  standardScript: "표준 연설",
                  myScript: "내 말",
                },
                status: {
                  mastered: "마스터링",
                  notMastered: "마스터되지 않음",
                },
                index: {
                  totalNumberOfQuestionsAnswered: "총 답변 수",
                  numberOfTimesIncorrectQuestionsHaveBeenPracticed:
                    "잘못된 질문이 연습 된 횟수",
                },
              },
            },
            index: {
              wrongQuestionList: "잘못된 질문 목록",
              wrongQuestionSet: "잘못된 문제 세트",
              mispracticeExercises: "잘못된 운동",
              taskName: "작업 이름",
              staff: "직원",
              masteringTheSituation: "상황 파악",
              whole: "모두",
            },
          },
        },
      },
      taskCenter: {
        components: {
          completeStandart: {
            index: {
              practiceFrequency: "연습 수 ≥",
              second: "타임즈",
              or: "또는",
              excellentScoreAtAnyOneTime: "어느 하나의 점수 우수 (≥",
              points: "포인트)",
              examScore: "시험 점수 ≥",
              branch: "포인트",
            },
          },
          manualReviewConfig: {
            index: {
              randomAverageDistribution: "무작위 평균 분포",
              assignToDesignatedPersonnel: "지정 된 사람에게 할당",
            },
          },
          examTimesInput: {
            index: {
              successfullyIncreasedTheNumberOfExams: "시험 수를 늘리는 성공",
              failedToIncreaseTheNumberOfExams: "테스트 실패 횟수 증가",
              increaseTheNumberOfExams: "시험 횟수 증가",
              selectObjects: "객체 선택",
            },
          },
          executionFilter: { index: { participationRate: "참여율" } },
          intelligentReview: {
            index: {
              overallReview: "전반적인 검토",
              pleaseEnterTheReviewCriterianotMandatory:
                "주석 기준을 입력하십시오 (필요하지 않음)",
              commentaryOnSingleSentenceRhetoric: "단일 문장에 대한 댓글",
              realTimeReview: "실시간 리뷰",
            },
          },
        },
        detail: {
          resultAnalysis: {
            averageScoreOfTheExam: "평균 시험 점수",
            averageExamDurationmin: "평균 시험 기간 (분)",
            accumulatedPracticeDurationmin: "누적 연습 기간 (min)",
            accumulatedPracticeTimes: "누적 횟수 연습",
            totalScore: "총 점수",
            average: "평균",
            total: "합계",
            keyHitCount: "키 포인트 히트 카운트",
            practiceAverageScore: "연습 평균 점수",
            averagePracticeDurationmin: "평균 운동 기간 (분)",
            topOfTheHighestScore: "최고 점수 Top3",
            branch: "포인트",
            errorProneItemTop: "오류가 발생하기 쉬운 항목 Top5",
            hitRate: "적중률",
            hitRateOfKeyPointsInScript: "음성 핵심 포인트 적중률",
            averageScriptScore: "평균 음성 점수",
            sortByProcess: "프로세스별로 정렬",
            sortByScore: "점수로 정렬",
            averageScoreOfThisPagetotalScoreOfThisPage:
              "이 페이지의 평균 점수/이 페이지의 총 점수",
            averageScoreOfThisItemtotalScoreOfThisItem:
              "이 항목의 평균 점수/이 항목의 총 점수",
            details: "세부 사항",
            viewRules: "규칙 보기",
            standardScript: "표준 연설",
          },
          scoreDetailList: {
            section: "아니.",
            second: "타임즈",
            practice: "운동",
            examination: "시험",
            notMeetingStandards: "표준에 미치지 못함",
            meetingStandards: "표준",
            excellent: "우수",
            frequency: "횟수",
            fraction: "분수",
            result: "결과",
            operation: "운영",
            viewDetails: "세부 정보 보기",
          },
          index: {
            common: "합계",
            people: "사람들",
            staff: "직원",
            notMeetingStandards: "표준에 미치지 못함",
            meetingStandards: "표준",
            excellent: "우수",
            notStarted: "시작되지 않음",
            haveInHand: "진행 중",
            completed: "완료",
            passed: "합격",
            hangInTheAir: "미완성",
            failed: "통과되지 않음",
            unlimitedTime: "무제한 시간",
            to: "에",
            maximumScoringCriteria: "최고 점수 기준",
            completionStatus: "완료 상태",
            employeeName: "직원 이름",
            frequency: "횟수",
            practice: "운동",
            examination: "시험",
            average: "평균",
            speechCraftAvgScore: "평균 음성 점수",
            emotionAvgScore: "평균 감정 점수",
            speedAvgScore: "익스프레스 평균 점수",
            flowAvgScore: "평균 프로세스 점수",
            practiceCostTime: "누적 기간",
            participateCommitRate: "규정 준수 비율",
            participateExcellentRate: "우수한 비율",
            duration: "기간",
            score: "점수",
            scoringResults: "점수 결과",
            flowScore: "프로세스 점수",
            craftScore: "음성 점수",
            emotionScore: "감정 점수",
            speedScore: "표현 점수",
            contentIntegrity: "콘텐츠 무결성",
            pointHitRate: "주요 포인트 적중률",
            emotionNegativeRate: "감정의 부정적인 비율",
            paraPhaSiaRate: "비정상적인 음성 속도의 비율",
            toneOvermuchRate: "모달 단어의 과도한 비율",
            maximumScore: "최고 점수",
            highestScoreResult: "최고 점수 결과",
            state: "상태",
            averageDurationmin: "평균 기간 (min)",
            lately: "최근",
            time: "시간",
            operation: "운영",
            viewDetails: "세부 정보 보기",
            wrongQuestionSet: "잘못된 문제 세트",
            taskName: "작업 이름",
            completeRequirements: "완전한 요구 사항",
            publishTo: "게시 객체",
            taskTime: "작업 시간",
            fractionalComposition: "분수 구성",
            totalScore: "총 점수",
            branch: "포인트",
            Script: "(토커",
            scoreEmotion: "감정",
            fractionExpression: "하위 표현식",
            points: "포인트)",
            implementationRate: "참여율",
            numberOfExecutors: "참가자 수",
            numberOfPeopleNotExecuted: "비 참가자 수",
            completionRate: "완료율",
            numberOfCompletedPersonnel: "완료의 수",
            numberOfUnfinishedPersonnel: "미완성 수",
            complianceRate: "규정 준수 비율",
            numberOfQualifiedPersonnel: "획득 횟수",
            numberOfNonCompliantIndividuals: "표준을 충족하지 않는 사람들의 수",
            excellentRate: "우수한 비율",
            participateCompleteRate: "참여 완료율",
            numberOfOutstandingIndividuals: "뛰어난 숫자",
            numberOfNonOutstandingIndividuals: "비 우수 번호",
            export: "수출",
            taskData: "작업 데이터",
            resultAnalysis: "결과 분석",
            scoreDetails: "점수 세부 사항-",
            dataOverview: "데이터 개요",
            taskDetail: "작업 세부 정보",
            scriptAnalysis: "음성 분석",
            flowAnalysis: "프로세스 분석",
            expressionAnalysis: "표현 분석",
            emotionAnalysis: "감정 분석",
            byTime: "시간 차원 보기",
            byNumber: "학위 차원보기",
            personData: "직원 별 통계",
            numberData: "기록별 세부 사항",
            count: "횟수",
            person: "사람들의 수",
            reachTimes: "읽기",
            outOfReachTimes: "도달하지 못한 시간",
            seeOutOfReach: "클릭하지 않은 보기",
            accumulative: "누적",
            script: "단어",
            times: "타임즈",
            sentence: "문장",
            expressionAverage: "평균 표현 점수",
            modalAnalysis: "모달 입자 분석",
            total: "합계",
            muchModalAnalysis: "너무 많은 모달 단어",
            normal: "정상",
            speedAnalysis: "음성 속도 분석",
            speedDistribution: "음성 비율 분포",
            speakingSpeed: "말하기 속도",
            normalSpeak: "정상적인 음성 속도",
            tooFastSpeak: "빠른 속도",
            tooSlowlySpeak: "느린 속도",
            violationWord: "단어 위반",
            violationWordAnalysis: "위반 단어 분석",
            noData: "아니",
            emotionalScore: "감정 점수",
            emotionalDistribution: "감정의 분포",
            negativeEmotion: "부정적인 감정",
            normalEmotion: "정상적인 감정",
            getDetail: "세부 정보 보기",
            barPattern: "바 전망",
            timeDimension: "시간 위도 보기",
            timesDimension: "학위 차원보기",
          },
          components: {
            dataOverview: {
              hook: {
                useSummary: {
                  averageScore: "평균",
                  averageScoreOfScript: "평균 음성 점수",
                  averageEmotionalScore: "평균 감정 점수",
                  expressionAverageScore: "익스프레스 평균 점수",
                  processAverageScore: "평균 프로세스 점수",
                  accumulatedDuration: "누적 기간",
                  accumulatedTimes: "누적 시간",
                  participationComplianceRate: "참여율",
                  participationRateForAchievingExcellence: "참여율",
                  branch: "포인트",
                  numberOfPeople: "사람들의 수",
                  averageScoreOfExpression: "평균 표현 점수",
                  people: "사람들",
                },
              },
            },
            emotionAnalysis: {
              components: {
                simplePieChart: {
                  index: { negativeEmotions: "부정적인 감정" },
                },
              },
            },
            scriptAnalysis: {
              hook: {
                useSummary: {
                  averageScoreOfScript: "평균 음성 점수",
                  branch: "포인트",
                },
                useSummaryDetail: { keyHitRate: "주요 포인트 적중률" },
              },
            },
          },
        },
        edit: {
          index: {
            intelligentReview: "스마트 리뷰",
            pleaseImproveTheConfiguration: "완벽한 구성",
            examResults: "시험 결과",
            showTheResultsAfterEachExam: "각 시험 후에 결과를 표시합니다.",
            showTheResultsAfterTheLastExam: "마지막 시험 후 결과를 보여줍니다.",
            doNotDisplayResults: "결과를 표시하지 마십시오",
            resultDisplay: "결과 쇼",
            score: "점수",
            scoringResults: "점수 결과",
            allowViewingScoreDetails: "점수 세부 정보를 볼 수 있도록 허용",
            viewCanvasConfiguration: "캔버스 구성 보기",
            synchronizationFailedProcessNodeHasChanged:
              "동기화 실패, 프로세스 노드가 변경 사항 생성",
            successfullySynchronizedPleaseClickOnpublishToUpdateTheCourse:
              "동기화가 성공적입니다. 게시 를 클릭하여 코스를 업데이트하십시오.",
            retrievedTheLatestCanvasConfiguration: "최신 캔버스 구성",
            synchronizeCanvasConfiguration: "캔버스 구성 동기화",
            scoringMethod: "채점 방법",
            pointsProcessPointsScript1PointsEmotion2PointsSpeakingSpeed3Points:
              "포인트, 흐름 {0} 포인트, 음성 스킬 {1} 포인트, 감정 {2} 포인트, 속도 {3} 포인트)",
            excellentStandardsNeedToBeHigherThanStandardStandards:
              "우수성 표준은 규정 준수 표준보다 높아야합니다.",
            theScoringCriteriaNeedToBeGreaterThanAndLessThanOrEqualTo100:
              "채점 기준은 0% 보다 크고 100 이하 여야합니다.",
            theScoringCriteriaNeedToBeGreaterThanPointsAndLessThanOrEqualTo0Points:
              "채점 기준은 0 점보다 크고 {0} 점보다 작거나 같아야 한다.",
            doRatingsRequireManualReview: "점수에 수동 검토가 필요한지 여부",
            allocationStrategy: "할당 정책",
            pleaseSelectTheAssignedPersonnel: "지정된 사람을 선택하십시오.",
            pleaseSelectADesignatedPerson: "지정된 사람을 선택하십시오.",
            timeLimitForSingleQuestionAnswering: "단일 질문 답변 시간 제한",
          },
          autoAddOrganizationList: {
            publishToNewlyAddedEmployeesByDefault:
              "기본적으로 새로 추가된 직원에게 게시",
          },
        },
        list: {
          index: {
            reRatingMeansMatchingWillBeDoneAccordingToTheScoringRulesOfTheLatestCanvasThereIsManuallyReviewedScoringDataForTheCurrentTaskDoYouWantToOverwriteTheScores:
              "다시 채점은 점수가 최신 캔버스의 점수 규칙에 따라 일치한다는 것을 나타냅니다. 현재 작업은 채점 데이터를 수동으로 검토했습니다. 점수를 덮어 쓰고 싶습니까?",
            notCovered: "덮지 않음",
            cover: "커버",
            participationSituation: "참여",
            numberOfParticipants: "참가자 수",
            sumOfTheNumberOfPracticeSubjectsSelectedForThisTask:
              "이 작업에 대해 선택한 운동 객체의 수를 합산하십시오.",
            sumOfTheNumberOfParticipantsInTheTask:
              "작업에 참여한 사람들의 수를 합산하십시오.",
            numberOfCompletedPersonnel: "완료의 수",
            sumOfTheNumberOfPeopleWhoMeetTheTaskCompletionRequirements:
              "작업 완료 요구 사항을 충족하는 사람들의 수를 합산하십시오.",
            numberOfQualifiedPersonnel: "획득 횟수",
            sumUpTheNumberOfPeopleWhoMeetTheTaskScoreRequirements:
              "작업 점수를 충족하는 사람들의 수를 합산하십시오",
            excellentPersonnel: "뛰어난 숫자",
            sumUpTheNumberOfPeopleWithExcellentTaskScores:
              "우수한 점수로 과제를 충족하는 사람들의 수를 합산하십시오.",
            participationRate: "참여율",
            numberOfParticipantsNumberOfParticipants: "참가자 수/참가자 수",
            numberOfCompletedNumberOfParticipants: "완료 횟수/참여해야 할 수",
            numberOfPeopleWhoMeetTheStandardsnumberOfPeopleWhoShouldParticipate:
              "획득 수/참가자 수",
            excellentNumbernumberOfParticipants: "참가자의 뛰어난 수/수",
            participationCompletionRate: "참여 완료율",
            numberOfCompletedParticipated: "완료/참여 수",
            participationComplianceRate: "참여율",
            numberOfQualifiedPersonnelnumberOfParticipants: "달성/참여 수",
            participationRateForAchievingExcellence: "참여율",
            accumulatedDuration: "누적 기간",
            sumOfTheDurationOfStudentParticipationInTheTask:
              "작업 기간 및 합계에 참여",
            averageDuration: "평균 기간",
            sumOfStudentParticipationTimeInTaskstotalNumberOfParticipationTimes:
              "참가자의 총 기간/총 참가자 수",
            accumulatedTimes: "누적 시간",
            sumOfTheNumberOfTimesStudentsParticipateInTasks:
              "작업 참가자 수를 합산하십시오.",
            averageScore: "평균",
            theAverageScoreOfThePersonnelParticipatingInThisTask:
              "작업에 관련된 직원의 평균 점수",
            maximumScore: "최고 점수",
            theHighestScoreForThePersonnelInvolvedInThisTask:
              "작업에 관련된 직원의 최고 점수",
            averageScoreOfScript: "평균 음성 점수",
            sumOfTraineeScriptScorestotalParticipationTimes:
              "학생 연설 점수 합계/총 참여 시간",
            totalScoreOfScript: "총 연설 점수",
            theTotalScoreOfTheScriptSetUnderThisTask:
              "이 작업에 따라 설정된 총 음성 점수",
            processAverageScore: "평균 프로세스 점수",
            sumOfStudentProcessScoresTotalParticipationTimes:
              "학생 프로세스 점수 합계/총 참여 수",
            totalProcessScore: "총 프로세스 점수",
            theTotalScoreOfTheProcessesSetUnderThisTask:
              "이 작업에 따라 설정된 프로세스의 총 점수",
            averageEmotionalScore: "평균 감정 점수",
            sumOfStudentEmotionalScoresTotalParticipationTimes:
              "연수생 감정 점수/총 교전 횟수",
            totalEmotionalScore: "총 감정 점수",
            theTotalScoreOfEmotionsSetUnderThisTask:
              "이 작업에 따라 설정된 총 감정적 점수",
            averageScoreOfExpression: "평균 표현 점수",
            sumOfStudentExpressionScorestotalParticipationTimes:
              "학습자 표현 점수/총 교전 횟수",
            totalScoreOfExpression: "표현 총 점수",
            theTotalScoreOfExpressionsSetUnderThisTask:
              "이 작업에서 설정된 표현식의 총 점수",
            startExecutingReScoringYouCanJumpToTheTaskCenterToViewProgress:
              "다시 채점하기 시작하면 작업 센터로 이동하여 진행 상황을 볼 수 있습니다.",
            reRating: "재득점",
            reRatingMeansThatMatchingWillBeDoneAccordingToTheLatestScoringRulesOnTheCanvasWhichWillOverwriteTheCurrentScoringResults:
              "다시 채점은 점수가 최신 캔버스의 점수 규칙에 따라 일치하고 현재 점수가 덮어 쓰임을 나타냅니다.",
            releaseTime: "출시 시간",
          },
        },
        audit: {
          components: {
            pageLeftTable: {
              reviewed: "검토",
              pendingReview: "검토 대상",
              enterKeywordSearch: "검색 할 키워드 입력",
            },
            pageRightTable: {
              index: {
                employeeName: "직원 이름",
                systemRating: "시스템 점수",
                scoringResults: "점수 결과",
                duration: "기간",
                time: "시간",
                state: "상태",
                whetherToModifyTheRating: "올바른 점수",
                finalScore: "최종 점수",
                reviewedBy: "리뷰어",
                operation: "운영",
                viewScoreDetails: "점수 세부 정보 보기",
                viewStatus: "상태 보기",
                whole: "모두",
                correctionStatus: "수정 상태",
                completeReview: "전체 검토",
                tips: "프롬프트",
                theFinalScoreOfTheStudentIsBasedOnTheCurrentFinalReviewResultPleaseConfirm:
                  "연수생의 최종 결과는 현재 최종 검토 결과의 적용되어야한다. 확인해 주세요",
              },
            },
          },
          index: { manualReview: "수동 검토" },
        },
        review: {
          index: {
            employeeName: "직원 이름",
            score: "점수",
            scoringResults: "점수 결과",
            duration: "기간",
            taskName: "작업 이름",
            numberOfAppealScripts: "고충의 수",
            appealTime: "항소 시간",
            processStatus: "프로세스 상태",
            scoreAfterReview: "검토 후 점수",
            reviewedBy: "리뷰어",
            operation: "운영",
            viewScoreStatus: "점수 보기",
            whole: "모두",
            manualReview: "수동 검토",
          },
        },
      },
      dashboard: {
        components: {
          adminDataCards: {
            exerciseTaskData: "운동 작업 데이터",
            implementationRate: "구현 속도",
            numberOfExecutors: "실행 횟수",
            numberOfPeopleNotExecuted: "실행되지 않은 수",
            completionRate: "완료율",
            numberOfCompletedPersonnel: "완료의 수",
            numberOfUnfinishedPersonnel: "미완성 수",
            complianceRate: "규정 준수 비율",
            numberOfQualifiedPersonnel: "획득 횟수",
            numberOfNonCompliantIndividuals: "표준을 충족하지 않는 사람들의 수",
            excellentRate: "우수한 비율",
            numberOfOutstandingIndividuals: "뛰어난 숫자",
            numberOfNonOutstandingIndividuals: "비 우수 번호",
            examTaskData: "시험 작업 데이터",
            published: "게시",
            courses: "코스",
            courseData: "코스 데이터",
            practiceTasks: "연습 작업",
            examTasks: "시험 과제",
          },
          practiceSummary: {
            practiceSummary: "연습 요약",
            numberOfPracticeTasks: "연습 작업의 수",
            numberOfExercises: "운동 횟수",
            accumulatedPracticeDuration: "누적 연습 기간",
            complianceRate: "규정 준수 비율",
            excellentRate: "우수한 비율",
            ranking: "순위",
          },
          rankingList: {
            numberOfExercises: "운동 횟수",
            exerciseDuration: "운동 기간",
            complianceRate: "규정 준수 비율",
            excellentRate: "우수한 비율",
            diligenceChart: "부지런스 목록",
            excellentList: "우수 목록",
            ranking: "순위",
            fullName: "이름",
            i: "나는...",
          },
          taskCard: {
            end: "마감일:",
            noDeadline: "마감일 없음",
            second: "타임즈",
            branch: "포인트",
          },
          taskProgress: {
            thereAreCurrentlyNoTasksAvailable: "작업 없음",
            completed: "완료",
          },
          filter: { employeeDepartment: "직원 부서" },
          exportButton: {
            index: {
              exportSuccessfulPleaseGoToTheTaskCenterToView:
                "내보내기가 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
              export: "수출",
            },
          },
          filterConfig: {
            dropdown: { custom: "맞춤" },
            modal: {
              updateSuccessful: "업데이트 성공",
              successfullySaved: "성공적으로 저장",
              setAsACommonReport: "공통 보고서로 설정",
              updateCurrentReport: "현재 보고서 업데이트",
            },
          },
          employeeDataCards: {
            components: {
              abilityGrowthTrend: {
                utils: {
                  averageScore: "평균",
                  averageScoreOfScript: "평균 음성 점수",
                  averageEmotionalScore: "평균 감정 점수",
                  expressionAverageScore: "익스프레스 평균 점수",
                  processAverageScore: "평균 프로세스 점수",
                  branch: "포인트",
                },
              },
              comprehensiveEvaluation: { const: { data: "데이터" } },
              doneStatus: {
                index: {
                  practice: "운동",
                  examination: "시험",
                  executed: "실행",
                  unexecuted: "실행되지 않음",
                  accumulatedExamDuration: "누적 시험 기간",
                  examFrequency: "시험 수",
                },
              },
              filter: {
                index: {
                  practice: "운동",
                  examination: "시험",
                  pleaseCompleteTheRequiredFields: "필요한 항목을 완료하십시오",
                  staff: "직원",
                  taskType: "작업 유형",
                  pleaseSelect: "선택하십시오",
                  taskName: "작업 이름",
                },
              },
            },
            index: {
              completionStatus: "완료 상태",
              comprehensiveEvaluation: "종합 평가",
              abilityGrowthTrend: "용량 성장 추세",
              errorProneAnalysis: "오류가 발생하기 쉬운 점 분석",
            },
          },
        },
        hook: {
          useSummary: {
            activePopulation: "활성 번호",
            accumulatedPracticeDuration: "누적 연습 기간",
            averagePracticeDuration: "평균 운동 시간",
            people: "사람들",
            numberOfPracticeTasks: "연습 작업의 수",
            numberOfExercises: "운동 횟수",
            exerciseDuration: "운동 기간",
            second: "타임즈",
            avgScore: "평균",
            speechCraftAvgScore: "평균 음성 점수",
            emotionAvgScore: "평균 감정 점수",
            speedAvgScore: "익스프레스 평균 점수",
            flowAvgScore: "평균 프로세스 점수",
            practiceCostTime: "누적 기간",
            totalPracticeTimes: "누적 시간",
            participateCommitRate: "참여율",
            participateExcellentRate: "참여율",
          },
        },
        index: {
          employeeProfile: "직원 초상화",
          overview: "개요",
          personalSignage: "개인 칸반",
          ongoingExercises: "진행중인 운동",
          ongoingExams: "진행중인 시험",
          practiceTaskProgress: "운동 작업 진행",
          examTaskProgress: "작업 진행 테스트",
        },
      },
      businessConfiguration: {
        scoreManagement: {
          dimensionIndex: {
            index: {
              pleaseSelectAConceptWord: "개념 단어를 선택하십시오.",
              sessionDetectionSettings: "세션 감지 설정",
              successfullySaved: "성공적으로 저장",
              abnormalSpeechSpeed: "비정상적인 음성 속도",
              supportCustomSpeechSpeedDetectionStandardsAndTheResultsWillBeJudgedAsNormalTooFastOrTooSlowWhenThereIsAnAbnormalSpeechSpeedtooFasttooSlowPointsWillBeDeductedAccordingToTheScoringRules:
                "사용자 정의 음성 속도 검출 표준이 지원된다. 결과는 정상적인 음성 속도, 너무 빠른 음성 속도 및 너무 느린 음성 속도입니다. 음성 속도가 비정상일 때 (너무 빠르거나 너무 느리다), 스코어는 스코어링 규칙에 따라 감소될 것이다.",
              speechSpeedTooFastGreaterThanEveryMinute:
                "너무 빠른 속도: 1 분 이상",
              oneWordSpeakingTooSlowlyLessThanPerMinute:
                "너무 느린 단어: 분당 미만",
              charactersIgnoringLessThan: "단어, 덜 무시하십시오.",
              aSentenceOfWords: "단어의 문장",
              excessiveModalParticles: "너무 많은 모달 단어",
              supportCustomModalParticlesAndSetRulesForExcessiveModalParticlesWhenThereAreTooManyModalParticlesPointsWillBeDeductedAccordingToTheScoringRules:
                "사용자 정의 톤 단어를 지원하고 너무 많은 톤 단어의 규칙을 설정하십시오. 모달 단어가 너무 많으면 점수 규칙에 따라 점수가 감소합니다.",
              theNumberOfRepetitionsIs: "반복 횟수는",
              inTheSecondTenseItIsDeterminedThatThereAreTooManyModalParticles:
                "두 번째로, 모달 단어가 너무 많은 것으로 결정된다.",
              definitionOfSensitiveViolations: "민감한 위반 정의",
              supportCustomWordLibrariesToDetectThePresenceOfIllegalWordsInASentenceWhenIllegalWordsAppearPointsWillBeDeductedAccordingToTheScoringRules:
                "불법 단어의 어휘를 사용자 정의하여 문장에 불법 단어가 표시되는지 여부를 감지 할 수 있습니다. 불법 단어가 있으면 점수 규칙에 따라 점수가 감소합니다.",
              violationWords: "위반 단어:",
              emotionalDetection: "감정 감지",
              accordingToTheSystemEmotionModelDialogueTechniqueEmotionDetectionWillBeConductedAndTheResultsWillBeJudgedAsNormalEmotionsAndNegativeEmotionsincludingNegativeAngryAndComplainingWhenNegativeEmotionsOccurPointsWillBeDeductedAccordingToTheScoringRules:
                "시스템 감정 모델 대화에 따르면 감정 테스트는 정상적인 감정과 부정적인 감정 (부정적, 분노 및 불만 포함) 으로 판단됩니다. 부정적인 감정이 있으면 점수 규칙에 따라 점수가 감소합니다.",
              preserve: "저장",
            },
          },
          scoreDimension: {
            index: {
              ratingDimension: "등급 차원",
              ratingPoints: "득점 포인트",
              explain: "설명",
              type: "유형",
            },
          },
          scoreModel: {
            components: {
              comprehensiveEvaluation: {
                const: { randomData: "랜덤 데이터" },
                index: {
                  spanStylemarginLeftpxStandardScriptAccumulation:
                    '<Span style = "margin-left: 8px">· 표준 음성 누적',
                  theTotalNumberOfWordsHitByTheStudentAfterReading:
                    "단어, 학생이 치는 단어의 수",
                  aWordspan: "단어 </span>",
                  timesspanbrspanStylemarginLeftpxSpeakTooSlowly:
                    'Times </span><br /><span style = "margin-left: 8px">· 느린 속도',
                  timesspan: "</Span>",
                  tooManyModalParticles:
                    '<Span style = "margin-left: 8px">· 모달 단어가 너무 많습니다.',
                },
              },
            },
            detail: {
              components: {
                model: {
                  group: { theScoreIs: "점수는" },
                  item: {
                    violationOnceReduced: "1 위반 마이너스",
                    abnormalSpeechSpeedincludingTooFastOrTooSlowDecrease:
                      "비정상적인 속도 (너무 빠른 속도와 너무 느린 속도 포함) 1 감소",
                    excessiveNumberOfModalParticlesReducedOnce:
                      "너무 많은 모달 단어 1 빼기",
                    oneNegativeDecreaseInEmotions: "부정적인 감정 마이너스 1",
                    branch: "포인트",
                  },
                  wrap: { name: "이름", preserve: "저장" },
                },
              },
              index: {
                complete: "전체 콘텐츠",
                keyHit: "핵심 포인트 히트",
                accurateFollowupReading: "정확한 독서",
                languageNorm: "언어 사양",
                speechSpeed: "연설의 속도",
                modalParticles: "모달 단어",
                emotionallyPositive: "감정적으로 긍정적 인",
                scoringModel: "채점 모델",
                successfullyModified: "수정 성공",
                newSuccessfullyCreated: "새로운 성공",
              },
            },
            index: {
              scoringModel: "채점 모델",
              newModel: "새로운 모델",
              model: "모델",
              type: "유형",
              operation: "운영",
              preview: "미리보기",
              edit: "편집",
            },
          },
          index: {
            scoringModel: "채점 모델",
            ratingDimension: "등급 차원",
            sessionDetectionSettings: "세션 감지 설정",
          },
        },
      },
      dataAnalysis: {
        learningRecord: {
          components: {
            keyPointHit: {
              index: {
                pleaseSelect: "선택하십시오",
                hit: "히트",
                misses: "놓친",
              },
            },
          },
          index: {
            duration: "기간",
            processScore: "프로세스 점수",
            branch: "포인트",
            contentCompleteness: "콘텐츠 무결성",
            scriptScore: "음성 점수",
            keyHitRate: "주요 포인트 적중률",
            emotionalScore: "감정 점수",
            negativeProportionOfEmotions: "감정의 부정적인 비율",
            expressionScore: "표현 점수",
            theProportionOfAbnormalSpeechSpeed: "비정상적인 음성 속도의 비율",
            excessiveProportionOfModalParticles: "모달 단어의 과도한 비율",
            learningRecordData: "학습 기록 데이터",
            staff: "직원",
            curriculum: "커리큘럼",
            mainPoints: "요점",
          },
        },
        customKanban: {
          components: {
            chart: {
              components: {
                header: {
                  components: {
                    chartToggle: {
                      hooks: {
                        form: "테이블",
                        lineChart: "라인 차트",
                        barChart: "히스토그램",
                        pieChart: "파이 차트",
                      },
                      index: { switchingTypes: "스위치 유형" },
                    },
                  },
                  index: {
                    saveSuccessful: "성공적으로 저장",
                    editChart: "차트 편집",
                    chartName: "차트 이름",
                    deleteSuccessful: "성공적으로 삭제",
                    refresh: "새로 고침",
                    export: "수출",
                    areYouSureToDeleteIt: "삭제 하시겠습니까?",
                    delete: "삭제",
                    viewFilteringCriteria: "필터 기준보기",
                  },
                },
              },
              index: {
                exportSuccessfulPleaseGoToTheTaskCenterToCheck:
                  "내보내기가 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
              },
            },
            header: {
              index: {
                saveSuccessful: "성공적으로 저장",
                deleteSuccessful: "성공적으로 삭제",
                editReport: "보고서 편집",
                reportName: "보고서 이름",
                refresh: "새로 고침",
                areYouSureToDeleteIt: "삭제 하시겠습니까?",
                delete: "삭제",
                exportToExcel: "엑셀 내보내기",
                exportSnapshot: "내보내기 스냅샷",
                export: "수출",
                authority: "권한",
                permissionSettings: "권한 설정",
                reportVisiblePermissions: "보이는 권한보고",
                visibleToEveryone: "모두에게 보이는",
                onlyVisibleToOneself: "자신에게만 보이는",
                designatedPersonnelVisible: "눈에 보이는 지정된 사람",
                pleaseSelect: "선택하십시오",
                reportDataPermissions: "보고서 데이터 권한",
                restrictedByUserPermissions: "사용자 권한에 의해 제한",
                visiblePeopleAreTheSame: "눈에 보이는 사람들은 동일합니다.",
              },
            },
            titleFooter: {
              index: { addReport: "새 보고서", reportName: "보고서 이름" },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "내보내기가 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
            dataQueryInProgress: "데이터 쿼리...",
            customKanban: "커스텀 칸반",
          },
        },
        intelligentAnalysis: {
          components: {
            header: {
              hooks: {
                form: "테이블",
                lineChart: "라인 차트",
                barChart: "히스토그램",
                pieChart: "파이 차트",
              },
              index: {
                successfullyAdded: "성공적으로 추가",
                viewSQL: "SQL 보기",
                export: "수출",
                addToReport: "보고서에 추가",
              },
            },
            refer: {
              hooks: {
                data: "데이터",
                interrogativeSentence: "질문",
                historicalQuery: "역사 쿼리",
              },
              index: { copySuccessful: "성공 복사", copy: "복사" },
            },
            search: {
              components: {
                latitudeModal: {
                  components: {
                    selector: {
                      const: {
                        notMeetingStandards: "표준에 미치지 못함",
                        meetingStandards: "표준",
                        excellent: "우수",
                        notStartedYet: "시작되지 않음",
                        inProgress: "진행 중",
                        completed: "완료",
                        incomplete: "미완성",
                      },
                    },
                  },
                  const: {
                    taskName: "작업 이름",
                    department: "학과",
                    studentName: "학생 이름",
                    scoringResults: "점수 결과",
                    taskCompletionStatus: "작업 완료 상태",
                  },
                  index: { pleaseSelectADimension: "차원을 선택하십시오" },
                },
              },
              index: {
                enterToOpenThePopupBoxForDimensionSelection:
                  "차원 선택을위한 총알 상자를 열려면 @ 를 입력하십시오.",
                pleaseEnterAQueryStatementToDirectlyQuery:
                  "쿼리 문을 직접 입력하십시오.",
                query: "쿼리",
              },
            },
          },
          index: {
            exportSuccessfulPleaseGoToTheTaskCenterToCheck:
              "내보내기가 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
            dataQueryInProgress: "데이터 쿼리...",
          },
        },
      },
    },
    conversationAnalysis: {
      videoAnalysis: {
        detail: {
          components: {
            dialogue: {
              index: {
                keywordSearch: "키워드 검색",
                previous: "이전",
                next: "다음",
                theObjectThatTriggersTheScrollingEventIs:
                  "스크롤 이벤트를 트리거하는 개체는",
                userScrolling: "사용자 스크롤",
              },
            },
            summary: {
              index: {
                reminder: "알림:",
                edit: "편집",
                name: "이름",
                hintLanguage: "프롬프트",
              },
            },
            viewpoint: { index: { viewpointExtraction: "시점 추출" } },
            outline: {
              index: {
                numberOfKeywords: "키워드 수",
                speaker: "스피커",
                speechDuration: "말하기 기간",
                proportionOfSpeaking: "말하기 비율",
                maximumSpeechDuration: "연설의 최대 길이",
                numberOfQuestions: "질문의 수",
                averageSpeechSpeed: "평균 속도",
                conversationalActions: "세션 동작",
                contentSummary: "내용 요약",
                generateSummary: "요약 생성",
                keywordCloud: "키워드 단어 클라우드",
              },
            },
          },
          index: {
            outline: "요약",
            viewpoint: "의견",
            contentSummary: "내용 요약",
            viewpointExtraction: "시점 추출",
            summaryOfKeyPoints: "요점 요약",
            videoAnalysis: "비디오 분석",
          },
        },
        list: {
          index: {
            delete: "삭제",
            uploadSuccessful: "성공적인 업로드",
            videoList: "비디오 목록",
            uploadVideo: "비디오 업로드",
            videoName: "비디오 이름",
            uploadTime: "업로드 시간",
            endTime: "종료 시간",
            analyzeProgress: "진행 상황 분석",
            haveInHand: "진행 중",
            completed: "완료",
            fail: "실패",
            toBegin: "시작하려면",
            operation: "운영",
            see: "보기",
          },
        },
      },
    },
    businessWorkingHours: {
      components: {
        businessWorkingHoursSetting: {
          index: { addWorkingHours: "작업 시간 추가" },
        },
      },
      index: {
        businessWorkingHours: "사업 근무 시간",
        newlyBuild: "새로운",
        name: "이름",
        describe: "설명",
        operation: "운영",
        edit: "편집",
        delete: "삭제",
        editBusinessWorkingHours: "비즈니스 근무 시간 편집",
        newBusinessWorkingHours: "새로운 비즈니스 근무 시간",
        workingHours: "근무 시간",
      },
    },
    algorithmSemanticIntelligence: { intelligentExtraction: "지능형 추출" },
    taskCenter: {
      downloadList: {
        component: {
          areYouSureYouWantToCancelAfterCancellationItCannotBeRestoredAgain:
            "취소 하시겠습니까? 취소는 불가능합니다.",
        },
      },
    },
    accountManagement: {
      securitySetting: "보안 설정",
      accountManagement: "계정 관리",
    },
    securitySettings: {
      components: {
        iPInput: {
          index: {
            to: "에",
            switchToIPAddressMode: "IP 주소 모드로 전환",
            switchToIPSegmentMode: "IP 세그먼트 모드로 전환",
          },
        },
      },
      index: {
        saveSuccessful: "성공적으로 저장",
        pleaseImproveTheConfiguration: "완벽한 구성",
        securitySetting: "보안 설정",
        employeeLoginIPRestrictions: "직원 로그인 IP 제한",
        restrictTheIPRangeThatEmployeesCanLogInToAndProhibitLoggingInBeyondTheRangeOnlyEffectiveForWebsitesMobileAppsAreNotRestrictedAndAdministratorsAreNotRestricted:
          "직원이 로그인할 수 있는 IP 범위를 제한합니다. 로그인은 범위를 넘어 허용되지 않습니다. 웹 사이트에만 유효하며 모바일 앱은 제한되지 않으며 관리자는 제한되지 않습니다.",
        whenAddingOrModifyingPleaseFillInTheExternalIPSegmentsameCSegmentOrAFixedExternalIPAddress:
          "추가 또는 수정할 때 외부 네트워크 IP 세그먼트 (동일한 C 세그먼트) 또는 고정 된 외부 네트워크 IP 주소를 입력하십시오.",
        allowLoginIPRange: "로그인 할 수있는 IP 범위",
        preserve: "저장",
      },
    },
    coachAdminEntry: { speechRecognition: "음성 인식" },
    billingCenter: {
      template: {
        details: "세부 사항",
        languageType: "언어 유형",
        aSREngine: "ASR 엔진",
        voiceQualityQuota: "음성 품질 할당량",
        realtimeData: "실시간 데이터",
        calculatedByDay: "날로 계산",
      },
      component: {
        aSRName: "ASR 이름",
        language: "언어",
        remainingQuota: "나머지 할당량",
        hours: "시간",
        usedQuota: "중고 할당량",
      },
    },
    speechRecognition: {
      component: {
        operationSavedSuccessfully: "성공적으로 저장된 작업!",
        deletedSuccessfully: "성공적으로 삭제!",
        pleaseSelectLanguage: "언어를 선택하십시오.",
        pleaseConfigureRules: "규칙을 구성하십시오",
        updateSuccessful: "성공적인 업데이트!",
        operationSuccessful: "성공적인 운영!",
      },
      template: {
        aSRLanguage: "ASR 언어",
        pleaseSelect: "선택하십시오",
        model: "모델",
        pleaseSelectLanguage: "언어를 선택하십시오.",
        delete: "삭제",
        newASRModel: "새로운 ASR 모델",
      },
    },
    dataAnalysis: {
      competitiveProductAnalysis: {
        index: { competitionAnalysis: "경쟁 분석" },
      },
    },
    eCommentManage: {
      template: {
        ecommerceReviewFilter: "전자 상거래 검토 필터",
        allComments: "모든 댓글",
        ecommerceReviewManagement: "전자 상거래 검토 관리",
      },
    },
    mCommentManage: {
      template: {
        socialMediaReviewFilter: "소셜 미디어 검토 필터",
        allComments: "모든 댓글",
        socialMediaReviewManagement: "소셜 미디어 검토 관리",
      },
    },
    conversationAnalysisDataAnalysis: { competitionAnalysis: "경쟁 분석" },
  },
  columns: {
    admin: {
      user: {
        id: "ID",
        headPortraitUrl: "아바타",
        realname: "이름",
        nickname: "별명",
        name: "이름",
        email: "계정 번호 (이메일)",
        mobile: "전화",
        agentId: "일 번호.",
        position: "포스트",
        role: "역할",
        actions: "운영",
        createUser: "신입 사원",
        editUser: "직원 편집",
        account: "계정 번호",
        originPassword: "초기 암호",
        newPassword: "새로운 암호",
        resumePassword: "비밀번호 확인",
        userGroup: "직원 그룹",
        authorizationGroup: "권한 그룹",
        resetPassword: "암호 재설정",
      },
      userGroups: {
        id: "ID",
        name: "이름",
        userCount: "직원 수",
        actions: "운영",
        createUserGroup: "직원 그룹 추가",
        editUserGroup: "직원 그룹 편집",
        member: "회원",
      },
      role: {
        id: "ID",
        name: "이름",
        actions: "운영",
        all: "모두",
        taskName: "작업",
        readTask: "작업 보기",
        manageTemplate: "템플릿 관리",
        manualInspection: "수동 검토",
        readResultAll: "결과 보기",
        readResultPersonal: "결과 보기 (개인)",
        readProcess: "품질 검사 프로세스 보기",
        readReport: "통계 및 보고서",
      },
    },
    download: {
      downloadCenter: {
        createTime: "날짜",
        taskName: "이름",
        size: "크기",
        status: "상태",
      },
    },
    tasks: {
      manage: {
        template: {
          condition: {
            idCode: "ID",
            name: "이름",
            remark: "설명",
            actions: "운영",
          },
          checkPoint: {
            name: "이름",
            remark: "설명",
            type: "유형",
            gradeType: "평가 방법",
            conditionLogic: "규칙",
            predeterminedScore: "점수",
            weight: "무게",
            status: "상태",
            actions: "운영",
            switchOn: "활성화",
            switchOff: "비활성화",
          },
        },
        workbench: {
          qualityCheckList: {
            time: "시간",
            customer: "고객",
            customerPhone: "고객 전화",
            callType: "통화 유형",
            source: "소스",
            voiceRecording: "통화 녹음",
            graded: "등급",
            openingGreeting: "오프닝 인사말",
          },
          checkPointList: {
            name: "품질 검사 포인트",
            type: "유형",
            deductScore: "분수",
            isHit: "히트 케이스",
            status: "검토",
            finalDeductScore: "포인트 공제 확인",
            hit: "히트",
            notHit: "놓친",
          },
        },
      },
    },
    custom: { keywords: "대화 핵심 단어" },
  },
  privateEnums: {},
  menu: {
    admin: {
      system: { text: "시스템 설정" },
      systemIntegration: {
        text: "시스템 통합",
        subTitle: {
          udesk: "Udesk 고객 서비스 시스템",
          udeskWhite: "고객 서비스 시스템",
          wechat: "엔터프라이즈 위챗",
          taobao: "Taobao Qianniu",
          weifeng: "웨이 펑",
        },
      },
      columnsManage: { text: "품질 검사 현장 관리" },
      customerField: { text: "고객 분야" },
      speechRecognition: { text: "음성 인식" },
      user: { text: "직원 관리" },
      userGroups: { text: "직원 그룹 관리" },
      role: { text: "역할 관리" },
      dataMigration: { text: "데이터 마이그레이션" },
      taskCenter: { text: "미션 센터" },
      recordUpload: { text: "로컬 녹음 업로드" },
      anomalousData: { text: "비정상적인 데이터" },
      systemLog: { text: "메시지 로그" },
      msgCenter: { text: "메시지 센터" },
      wechatDocking: { text: "엔터프라이즈 마이크로 도킹" },
    },
    tasks: {
      homePage: { text: "홈 페이지" },
      workbench: { text: "작업대" },
      semanticIntelligence: { text: "의미 지능" },
      intelligentAnalysis: { text: "지능형 분석" },
      intelligentLabel: { text: "스마트 라벨" },
      admin: { text: "관리" },
      callManage: { text: "통화 관리" },
      dialogueManage: { text: "대화 관리" },
      ticketManage: { text: "작업 주문 관리" },
      dataAnalysis: { text: "데이터 분석" },
      customerService: { text: "고객 서비스 센터" },
      clientCenter: { text: "고객 센터" },
      insight: { text: "Insight" },
      trainingCenter: { text: "훈련 센터" },
      templates: { text: "템플릿 관리" },
      wechatQaTasks: { text: "엔터프라이즈 위챗 작업대" },
      wechatQaSetting: { text: "엔터프라이즈 위챗 분석 구성" },
      wechatQaChartRecord: { text: "엔터프라이즈 위챗 세션" },
      wechatStaffCenter: { text: "엔터프라이즈 마이크로 직원 센터" },
      callAnalysis: { text: "대화 분석" },
      caseBaseFolder: { text: "케이스 라이브러리" },
      coachTask: { text: "멘토링 작업" },
      teamAnalysis: { text: "팀 분석" },
      customer: { text: "고객 센터" },
      analysisEnable: { text: "분석 권한 부여" },
      analysisHelp: { text: "도움말 분석" },
      analysisStudy: { text: "학습 분석" },
      analysisReview: { text: "화합물 디스크 분석" },
      analysisTags: { text: "스마트 라벨 분석" },
      analysisSentiment: { text: "여론 분석" },
      analysisBusiness: { text: "비즈니스 분석" },
      groupTest: { text: "그룹 테스트" },
    },
    navBar: {
      billingCenter: "도움말 센터",
      uploadDownload: "업로드 및 다운로드 센터",
      setting: "시스템 설정",
    },
  },
  fix: {
    selectType: "유형 선택:",
    all: "모두",
    call: "전화",
    text: "대화",
    wechat: "엔터프라이즈 위챗 세션",
    businessTrendAnalysis: "레벨 1 분류 비즈니스 동향 분석",
    noEchart: "차트에 대한 데이터 없음",
    checkPointisRequired: "규칙 구성이 필요합니다.",
    voiceDownLoad: "녹음 다운로드",
    exportTxt: "TXT 텍스트 내보내기",
    exportWord: "단어 텍스트 내보내기",
    sureReRole: "확인이 연사의 역할을 역전합니까?",
    sureRecover: "재 수정을 확인 하시겠습니까?",
    sureReAsr: "재확인 확인?",
    reRole: "리버스 스피커",
    recover: "재 오류 수정",
    reAsr: "재 식별",
    recordingOperation: "녹음 작업",
    onlySave30Day: "(30 일만)",
    appealError: "그것을 제출하기 전에 품질 검사 지점에 호소하십시오.",
    exportToTaskCenter: "업로드 및 다운로드 센터로 성공적으로 내보내기",
    caseBaseLimit: "케이스 라이브러리는 50 을 초과 할 수 없습니다",
    unnamed: "이름없는",
    wordSpeed: "단어/분",
    hit: "히트",
    checkPoint: "품질 검사 포인트",
    realTimeAdditionTip1:
      "실시간 추가: 데이터가 품질 검사 시스템에 들어가면 품질 검사가 즉시 시작됩니다.",
    realTimeAdditionTip2:
      "타이밍 추가: 타이밍 자동으로 품질 검사를위한 데이터 추가",
    null: "없음",
    collcetedProps: "컬렉션 필드",
    editingNameNow: "이름이 편집되고 있습니다",
    upToFourLevels: "최대 4 단계 분류",
    default: "기본",
    nameCantBeNull: "이름을 비울 수 없습니다.",
    editingNow: "액션 편집 중",
    nameError: "잘못된 이름",
    nameMaxLength8: "최대 이름 길이는 8 입니다.",
    callTime: "대화 시간",
    sentimentValue: "고객 감정 가치",
    agentSentimentValue: "고객 서비스 감정 가치",
    sentimentAnalysis: "감정 분석",
    associatedData: "도로 후속 데이터",
    notifyTheOwner: "그룹 소유자에게 알림",
    notifyTheEmployee: "직원에게 직접 알림",
    notifyTheOwnerTip:
      "대화 유형이 그룹 채팅이면 그룹 소유자에게 알림이 표시됩니다.",
    customNotifyEmployee: "맞춤 알림 직원",
    customNotifyEmployeeTip:
      "트리거링 된 사람이 직원 인 경우 메시지가 속한 직원에게 알림을받습니다.",
    clickToLearnMore: "자세한 내용을 보려면 클릭하십시오.",
    repeatMarkingTip:
      "이 기록은이 품질 검사 지점으로 표시되었으며 마킹을 반복 할 필요가 없습니다.",
    successfulEvaluation: "성공 평가",
    evaluationFailed: "평가 실패",
    qualityInspectionScore: "품질 검사 점수",
    semanticTags: "시맨틱 라벨",
    smartTags: "스마트 라벨",
    collect: "정보 수집",
    appealRecord: "고충의 기록",
    satisfy: "만족",
    notSatisfy: "만족하지 않음",
    appealPlaceHolder: "항소 이유를 입력하십시오.",
    giveUp: "포기",
    rejectReasonPlaceHolder: "거부 사유를 입력하십시오.",
    reject: "거부",
    changeRejectReason: "거부 이유 수정",
    pass: "에 의해",
    revise: "수정",
    machineEvaluation: "기계 평가",
    poor: "불쌍한",
    excellent: "좋아요.",
    moderate: "장군",
    mustMarked: "이 품질 검사 지점을 표시해야합니다.",
    pleaseChoose: "선택하십시오",
    manualEvaluation: "수동 평가",
    qualityInspectionTemplate: "적용 가능한 품질 검사 템플릿",
    collectionTemplate: "적용 가능한 취득 템플릿",
    keywordsWordCloud: "키워드 단어 클라우드",
    editCategoryLabel: "카테고리 레이블 편집",
    justCall: "전화 좀 봐",
    justConversation: "대화를보세요.",
    batchOperation: "배치 작업",
    customerServicesGroup: "고객 서비스 그룹",
    joinGroup: "그룹 가입",
    removeGroup: "그룹 밖으로 이동",
    enterInformationEntity: "정보 엔티티 콘텐츠를 입력하십시오.",
    enterNotBeNull: "입력을 비울 수 없습니다.",
    operationGroup: "운영 그룹",
    customerServiceSelected: "선택된 고객 서비스",
    callAndConversation: "통화/대화",
    conversation: "대화",
    system: "시스템",
    customize: "맞춤",
    effective: "효과적인",
    invalid: "무효",
    successfulAndToTaskCenter:
      "실행이 성공적이므로 작업 센터로 이동하여 확인하십시오.",
    recalculationFailed: "재 계산 실패",
    selectRecalculatedIndicator: "다시 계산할 표시기를 선택하십시오.",
    source: "데이터 소스를 선택하십시오.",
    placeholder: "키워드를 입력하십시오.",
    cumulative: "누적",
    average: "평균 유형",
    distribution: "유통 법칙",
    originalDataCannotEmpty: "원시 데이터를 비울 수 없습니다.",
    inspection: "품질 검사",
    check: "무작위 검사",
    review: "검토",
    draftSavedSuccessfully: "성공적으로 저장된 초안",
    optimization: "최적화",
    agentName: "고객 서비스 이름",
    allTasks: "모든 품질 검사 작업",
    datePickerSelect: "날짜 선택",
    callSelect: "대화 선택",
    taskSelect: "작업 선택",
    genReport: "보고서 생성",
    dataSource: "데이터 소스",
    callsTotal: "총 통화",
    intelligentQualityInspection: "지능형 품질 검사",
    percentage: "비율",
    artificialSampling: "수동 샘플링",
    appealNumber: "불만 금액",
    totalConversation: "총 대화 금액",
    samplingReviewRate: "펌핑 검토율",
    data: "데이터",
    inspectionResults: "품질 검사 결과",
    inspected: "품질 검사",
    totalScore: "총 점수",
    learnMore: "더보기",
    inspectionEffect: "품질 검사 효과",
    allTotal: "합계",
    manualInspection: "수동 검토",
    artificialReview: "수동 검토",
    approvalAmountAndRate: "승인 수량 및 승인 비율",
    customerScore: "고객 서비스 점수",
    inspectionRecommendations: "품질 검사 제안",
    saveDraft: "초안 저장",
    historicalAdvice: "역사적 조언",
    advicePlaceHolder: "제안을 입력하십시오...",
    upload: "파일 업로드",
    customAdd: "필터 추가",
    advancedConditions: "고급 조건",
    keyWord: "키워드",
    containsAll: "모두 포함",
    containsAny: "어떤 포함",
    matchAny: "어떤 일치",
    matchAll: "모두 일치",
    smartTagsOne: "스마트 라벨 하나",
    smartTagsTwo: "스마트 라벨 II",
    season: "시즌",
    month: "달",
    week: "주",
    day: "날",
    hour: "언제",
    notMoreThan366: "366 일을 넘지 않습니다.",
    propsCannotBeNull: "일부 필드는 채워지지 않습니다.",
    other: "기타",
    correlationCoefficient: "상관 계수",
    repetitionRate: "반복률",
    configurationItemsMoreThan20: "최대 20 개의 구성 항목 추가",
    conversionAnalysis: "변환 분석",
    correlationAnalysis: "상관 분석",
    successfulExportAndToTaskCenter:
      "내보내기가 성공적입니다. 작업 센터로 이동하여 볼 수 있습니다.",
    typeSelect: "유형 선택",
    exportExcel: "엑셀 내보내기",
    exportPng: "Png 수출",
    noDataChart: "차트에 대한 데이터 없음",
    placeOperator: "스마트 라벨을 선택하세요",
    placeAdvancedConditions: "고급 기준을 선택하십시오.",
    createAdvancedConditions: "새로운 고급 필터",
    moveUp: "위로 이동",
    moveDown: "아래로 이동",
    moveLeft: "왼쪽으로 이동",
    moveRight: "오른쪽으로 이동",
    statusChangeSuccess: "성공적으로 수정 된 상태",
    editSuccess: "수정 성공",
    saveSuccess: "성공적으로 저장",
    firstPage: "홈 페이지",
    lastPage: "꼬리 페이지",
    customerInfo: "고객 정보",
    type: "유형",
    NumberTypeErrorMessage:
      '숫자 유형의 필드 정보 내용은 숫자 또는 "" 만 포함 할 수 있습니다.',
    taskStartPrompt: "작업 개방 프롬프트",
    startConfirm: "품질 검사 작업을 열려면 확인 버튼을 클릭하십시오.",
    continueToSubmitWithoutEvaluation: "평가없이 제출 계속",
    rejectTip:
      "변경 조치가 감지되지 않았으므로 모든 항소를 직접 거부해야합니까?",
    unmarkedSelfLearningTip:
      "당신은 자아 학습 품질 검사 포인트를 표시하지 않았습니다",
    markedSuccessfully: "라벨 성공",
    markingFailed: "라벨링 실패",
    exceedingLength: "초과 길이",
    uncategorized: "미분류",
    addSuccess: "새로운 성공",
    deleteSuccess: "성공적으로 삭제",
    featureName: "요소 이름",
    informationEntity: "정보 엔티티",
    standardAnswer: "표준 답변",
    keyElementAnswer: "대답 할 핵심 요소",
    generalStatement: "일반 진술 (요청할 필요가 없음)",
    generalQuestionsAndConfirmation: "일반적인 질문 및 확인",
    specialQuestionsAndAnswers: "특별 질문 및 답변",
    agent: "고객 서비스",
    customer: "고객",
    semanticTagsAndInformationEntitiesCannotBeEmpty:
      "키 요소 시맨틱 라벨 및 정보 엔티티는 동시에 비울 수 없습니다.",
    standardSentence: "표준 문장",
    acquaintance: "지인",
    smartUePlaceholder: "스마트 상호 작용 이름을 입력하십시오.",
    typeSelectPlaceholder: "유형을 선택하십시오",
    keyElement: "주요 요소",
    reQuerySementicTags: "의미 레이블 재선택",
    querySementicTags: "시맨틱 라벨 선택",
    QATestForm: "질문 및 답변 감지 양식",
    selectNotBeNull: "선택을 비울 수 없습니다.",
    featureDetectionTarget: "요소 감지 대상",
    categorized: "분류",
    effectiveness: "효과",
    hideAdvancedSettings: "고급 설정 숨기기",
    showAdvancedSettings: "고급 설정 표시",
    generalStatementOnly: "일반 진술",
    generalQuestions: "일반 의심",
    affirmativeExpression: "긍정 표현",
    negativeIntention: "부정적인 의도",
    searchSementicTags: "쿼리 시맨틱 레이블",
    choiceQuestion: "선택적 의문 문장",
    selectiveAnswer: "선택 유형 답변",
    sementicTagsPlaceholder: "시맨틱 라벨 콘텐츠를 입력하십시오.",
    openSpecialQuestion: "특별 질문 열기",
    openAnswer: "공개 답변.",
    customQA: "맞춤 Q & A",
    questions: "질문 방법",
    dataPlaceholder: "콘텐츠를 입력하세요",
    reply: "답변",
    notFindSuitableLabel: "적합한 라벨은 발견되지 않았다.",
    addSemanticTag: "새 시맨틱 태그 추가",
    intelligentInteraction: "지능형 상호 작용",
    matching: "경기",
    bout: "타임즈",
    keywordMatch: "키워드 일치",
    addFilterConditions: "필터 상태 추가",
    buttonStartCollectionTask: "확인 버튼을 클릭하여 수집 작업을 시작하십시오.",
    buttonStartTask: "OK 버튼을 클릭하여 품질 검사 작업을 엽니 다.",
    ruleConfigurationIsRequired: "규칙 구성이 필요합니다.",
    asrRunning: "ASR 실행",
    errorCorrectionExecution: "오류 수정 실행",
    emotionalVolumeRecognition: "감정 볼륨 인식",
    dataPreprocessing: "데이터 전처리",
    inspectionExecution: "품질 검사 실행",
    businessId: "비즈니스 ID",
    businessTime: "비즈니스 시간",
    createTime: "창조 시간",
    abnormalReason: "비정상적인 이유",
    singleChat: "싱글 채팅",
    groupChat: "그룹 채팅",
    taskContent: "작업 내용",
    submissionTime: "제출 시간",
    startTime: "시작 시간",
    endTime: "종료 시간",
    runningTime: "시간이 많이 걸리는 작업",
    totalAmountOfData: "총 데이터 볼륨",
    resultsOfThe: "실행 결과",
    operator: "연산자",
    taskDetails: "작업 세부 정보",
    executiveFunction: "집행 기능",
    qualityCheckTasks: "품질 검사 작업",
    implementationDetails: "실행 세부 사항",
    conditionalRules: "조건 규칙",
    smartCondition: "스마트 조건",
    goThis: "이것으로 이동",
    pleaseMarkClassification: "분류를 표시하십시오",
    advancedScreening: "고급 필터링",
    noDataToManipulate: "조작 할 데이터 없음",
    concept: "개념 단어",
    checkPoint1: "규칙 구성",
    requiredFieldsCannotEmpty: "필요한 항목을 비울 수 없습니다.",
    rule: "규칙",
    afterFixUnit: "단어",
    unmarkedSelfLearningPointsTip:
      "당신은 자아 학습 품질 검사 포인트를 표시하지 않았습니다",
    onlyReservedFor7Days: "(7 일만)",
    downloadError: "다운로드 실패",
    pause: "일시 중지",
    continue: "계속",
    recalcScore: "점수 만 재 계산 (등급 포함)",
    recalculatePointsAndScores: "품질 검사 포인트 및 점수 재 계산",
    recalculatePointsAndScoresWithEmtry:
      "품질 검사 포인트 및 점수 재 계산 (품질 검사 포인트는 비어 있음)",
    inProgress: "진행 중",
    toBeExecuted: "구현하려면",
    executeImmediately: "즉시 실행",
    specialElements: "특수 요소",
    logicRule: "논리 규칙",
    every: "여러 경기",
    hitRecall: "리콜 단어 히트",
    notHitRecall: "놓친 리콜 단어",
    hitRecallRegular: "히트 리콜 정사이즈",
    notHitRecallRegular: "놓친 정사이즈",
    hitRule: "히트 규칙",
    notHitRule: "누락 된 규칙",
    hitSimilarSentence: "유사한 문장을 누르십시오",
    notHitSimilarSentence: "비슷한 문장을 놓쳤다",
    hitExcludeSimilarSentences: "유사한 문장을 제외하기 위해 히트",
    notHitExcludeSimilarSentences: "유사한 문장을 제외하기 위해 놓친",
    updateSuccess: "업데이트 성공",
    qualify: "정사이즈",
    recallKeyWord: "키워드 리콜",
    grammar: "문법 규칙",
  },
  api: {
    putTypoDatasByIdStatusByStatus: "유효성 변경 실패, 다시 시도하십시오",
    postAutoMateTasksCaseByTaskId:
      "케이스 라이브러리 동기화 구성을 저장하지 못했습니다.",
    getAutoMateTasksCaseByTaskId:
      "케이스 라이브러리 동기화 구성을 얻지 못했습니다.",
    postInspectionRemarkModelConfigs: "댓글 템플릿을 저장하지 못했습니다.",
    putInspectionRemarkModelConfigsById: "댓글 템플릿을 저장하지 못했습니다.",
    getInspectionRemarkModelConfigsById:
      "댓글 템플릿 데이터를 얻지 못했습니다.",
    postKnowledgeDatas: "지식 기반 생성 실패, 다시 시도하십시오",
    putKnowledgeDatasById: "저장소 수정 실패, 다시 시도하십시오",
    getLibraryFiles: "가격 폴더 목록을 얻지 못했습니다.",
    getLibraryCalls: "파일을 가져 오지 못했습니다.",
    getLibraryFilesSearch: "검색 실패",
    putLibraryFilesMoveById: "가격 폴더를 이동하지 못했습니다.",
    putLibraryCallsById: "파일 이름 변경 실패!",
    postLibraryFiles: "폴더 추가 실패",
    putLibraryFilesById: "폴더 이름 변경 실패",
    deleteLibraryFilesById: "삭제 실패",
    deleteLibraryCallsById: "삭제 실패",
    postLibraryCalls: "추가 실패",
    getVoiceUploadResultById: "업로드 결과 내보내기 실패",
  },
};
