// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetLibraryCallsByIdParam,
    BaseResponseLibraryCallFoundResponse,
    PutLibraryCallsByIdParam,
    PutLibraryCallsByIdBody,
    BaseResponseVoid,
    DeleteLibraryCallsByIdParam,
} from '../../types';

/**
 * findById
 *
 * @export
 * @tags 案例库通话记录
 * @link [GET] /libraryCalls/{id}
 * @param id
 */
export function getLibraryCallsById(
    options: ApiOptions<GetLibraryCallsByIdParam, never> & { segments: GetLibraryCallsByIdParam }
): Promise<BaseResponseLibraryCallFoundResponse> {
    return request<never, GetLibraryCallsByIdParam, never>('/libraryCalls/{id}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getLibraryCallsById',
    });
}

/**
 * update
 *
 * @export
 * @tags 案例库通话记录
 * @link [PUT] /libraryCalls/{id}
 * @param id
 */
export function putLibraryCallsById(
    data: PutLibraryCallsByIdBody,
    options: ApiOptions<PutLibraryCallsByIdParam, never> & { segments: PutLibraryCallsByIdParam }
): Promise<BaseResponseVoid> {
    return request<PutLibraryCallsByIdBody, PutLibraryCallsByIdParam, never>('/libraryCalls/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putLibraryCallsById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 案例库通话记录
 * @link [DELETE] /libraryCalls/{id}
 * @param id
 */
export function deleteLibraryCallsById(
    options: ApiOptions<DeleteLibraryCallsByIdParam, never> & {
        segments: DeleteLibraryCallsByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteLibraryCallsByIdParam, never>('/libraryCalls/{id}', {
        ...options,
        method: 'delete',
        __$requestCalleeName: 'deleteLibraryCallsById',
    });
}

export const meta = [
    { tags: ['案例库通话记录'], path: '/libraryCalls/{id}', method: 'get' },
    { tags: ['案例库通话记录'], path: '/libraryCalls/{id}', method: 'put' },
    { tags: ['案例库通话记录'], path: '/libraryCalls/{id}', method: 'delete' },
];
