// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type { GetUsersByUuidParam, BaseResponseUserFoundResponse } from '../../types';

/**
 * findById
 *
 * @export
 * @tags 用户
 * @link [GET] /users/{uuid}
 * @param uuid
 */
export function getUsersByUuid(
    options: ApiOptions<GetUsersByUuidParam, never> & { segments: GetUsersByUuidParam }
): Promise<BaseResponseUserFoundResponse> {
    return request<never, GetUsersByUuidParam, never>('/users/{uuid}', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getUsersByUuid',
    });
}

export const meta = [{ tags: ['用户'], path: '/users/{uuid}', method: 'get' }];
