import Locales from '../locales/index';

let valueTypes = [
    { id: 1, key: "text", name: () => Locales.current.enums.common.valueTypes.text },
    { id: 2, key: "number", name: () => Locales.current.enums.common.valueTypes.number }
];

let propTypes = [
    { id: 1, key: "system", name: () => Locales.current.enums.common.propTypes.system },
    { id: 2, key: "user", name: () => Locales.current.enums.common.propTypes.user }
];

export default {
    valueTypes,
    propTypes,
};