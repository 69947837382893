import UdeskLocalesManager from '../locales';

let requestMethods = [
    { id: 1, name: "GET", key: "get" },
    { id: 2, name: "POST", key: "create" },
    { id: 3, name: "DELETE", key: "del" },
    { id: 4, name: "PUT", key: "update" }
];

let intBoolean = [
    { id: 1, name: () => UdeskLocalesManager.get("labels.yes"), key: "True" },
    { id: 0, name: () => UdeskLocalesManager.get("labels.no"), key: "False" }
];

let websocketStates = [
    { id: 0, key: "connecting" },
    { id: 1, key: "open" },
    { id: 2, key: "closeing" },
    { id: 3, key: "closed" },
];

export default {
    requestMethods,
    intBoolean,
    websocketStates
};