// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    GetReviewWordsFavoriteByTypeQuery,
    GetReviewWordsFavoriteByTypeParam,
    BaseResponseListWordsFavoriteGroupFoundResponse,
    PostReviewWordsFavoriteByTypeParam,
    PostReviewWordsFavoriteByTypeBody,
    BaseResponseWordsFavoriteGroupFoundResponse,
} from '../../../../types';

/**
 * 查询话术收藏夹分组
 *
 * @export
 * @tags 话书库
 * @link [GET] /review/words/favorite/{type}
 * @param type
 */
export function getReviewWordsFavoriteByType(
    options: ApiOptions<GetReviewWordsFavoriteByTypeParam, GetReviewWordsFavoriteByTypeQuery> & {
        segments: GetReviewWordsFavoriteByTypeParam;
    }
): Promise<BaseResponseListWordsFavoriteGroupFoundResponse> {
    return request<never, GetReviewWordsFavoriteByTypeParam, GetReviewWordsFavoriteByTypeQuery>(
        '/review/words/favorite/{type}',
        { ...options, method: 'get', __$requestCalleeName: 'getReviewWordsFavoriteByType' }
    );
}

/**
 * 创建话术收藏夹分组
 *
 * @export
 * @tags 话书库
 * @link [POST] /review/words/favorite/{type}
 * @param type
 */
export function postReviewWordsFavoriteByType(
    data: PostReviewWordsFavoriteByTypeBody,
    options: ApiOptions<PostReviewWordsFavoriteByTypeParam, never> & {
        segments: PostReviewWordsFavoriteByTypeParam;
    }
): Promise<BaseResponseWordsFavoriteGroupFoundResponse> {
    return request<PostReviewWordsFavoriteByTypeBody, PostReviewWordsFavoriteByTypeParam, never>(
        '/review/words/favorite/{type}',
        { ...options, data, method: 'post', __$requestCalleeName: 'postReviewWordsFavoriteByType' }
    );
}

export const meta = [
    { tags: ['话书库'], path: '/review/words/favorite/{type}', method: 'get' },
    { tags: ['话书库'], path: '/review/words/favorite/{type}', method: 'post' },
];
