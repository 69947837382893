import Udesk from 'Udesk';
import classifiedTrainingComponent from './component';

class classifiedTrainingRoute extends classifiedTrainingComponent {
    model(){
        const company = Udesk.data.init.company;
        if (company.enabledCallInspect !== 0 && company.enabledImInspect !== 0) {
            this.privates.dataSourceType = 0;
        } else if (company.enabledCallInspect !== 0) {
            this.privates.dataSourceType = 1;
        } else if (company.enabledImInspect !== 0) {
            this.privates.dataSourceType = 2;
        }
    }
    asyncModel(changedParams) {
        let { sdkOptions } = this.props;
        let url2 = Udesk.business.apiPath.concatApiPath(`smartTagCategorys`, sdkOptions);
        let smartTagCategorysPromise = new Promise((resolve, reject) => {
            Udesk.ajax.get(url2).then(
                resp => {
                    resolve(resp);
                },
                reason => {
                    Udesk.ui.notify.error(reason.errorMsg);
                    reject(reason);
                }
            );
        });
        return {
            smartTagCate: smartTagCategorysPromise
        };
    }
    parseAsyncModel(asyncModel, { asyncKey }) {
        return asyncModel;
    }
}
export default classifiedTrainingRoute;
