import React, { useState, useRef } from 'react';
import Udesk from 'Udesk';
import Page from 'Component/common/udesk-qa-page-layout';
import { CommonPage, StandardizeTable } from 'udesk_gm_ui';
import { getSaleSettingBaseInfos } from 'src/api/saleSettingBaseInfos';
// @ts-ignore
import { SaleSettingBaseInfoFoundResponse } from 'src/api/types';
import {
    deleteSaleSettingBaseInfosById,
    putSaleSettingBaseInfosById,
} from 'src/api/saleSettingBaseInfos/{id}';
import UdeskLocales from 'UdeskLocales';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';

const default_pagination = {
    current: 1,
    pageSize: 20,
    total: 0,
};

const Template = React.memo((props: any) => {
    const { pageInfo = {}, storePageInfo } = CommonPage.usePageStateMemo();
    const [dataSource, setDataSource] = useState<SaleSettingBaseInfoFoundResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [mounted, setMounted] = useState<boolean>(false);

    const onRequestValues = useRef(pageInfo);
    // const [pagination, setPagination] = useState<StandardizeTableProps['table']['pagination']>(default_pagination);

    return (
        <Page
            pageBodyClassName="udesk-qa-sales-stage-page-index"
            title={
                /* 销售阶段 */ UdeskLocales['current'].pages.gong.businessConfiguration.salesStage
                    .list.index.salesStage
            }
            padding={false}
            backGroundGhost={true}
        >
            <StandardizeTable.RequestTable
                onRequest={(values) => {
                    setLoading(true);
                    setMounted(true);
                    getSaleSettingBaseInfos({
                        params: {
                            ...values.filter,
                            pageNum: values.pagination.current || values.pagination.defaultCurrent,
                            pageSize:
                                values.pagination.pageSize || values.pagination.defaultPageSize,
                        },
                    })
                        .then((resp) => {
                            setDataSource(resp.data ?? []);
                            // storePageInfo(values);
                            onRequestValues.current = values;
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }}
                filter={{
                    initialValues: mounted ? {} : pageInfo.filter,
                    fields: [
                        {
                            type: 'Input',
                            label: /* 关键词 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.list.index.keyWord,
                            name: 'name',
                            props: {
                                placeholder: UdeskLocales['current'].labels.pleaseEnter,
                            },
                        },
                    ],
                }}
                toolBarRightButtons={[
                    {
                        label: /* 新建销售阶段 */ UdeskLocales['current'].pages.gong
                            .businessConfiguration.salesStage.list.index.newSalesStage,
                        mainBtn: true,
                        onClick(item) {
                            let routeOptions = {
                                history: props.history,
                                routeName: 'salesStageCreate',
                            };
                            Udesk.ui.routing.transitionTo(routeOptions);
                        },
                    },
                ]}
                table={{
                    loading,
                    dataSource,
                    columns: [
                        {
                            title: /* 销售阶段名称 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.list.index.salesStageName,
                            dataIndex: 'name',
                        },
                        {
                            title: /* 描述 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.list.index.describe,
                            dataIndex: 'desc',
                        },
                        {
                            title: /* 状态 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.list.index.state,
                            dataIndex: 'isEnable',
                            renderType: 'switch',
                            onChange: (checked, record, index) => {
                                putSaleSettingBaseInfosById(
                                    {
                                        ...record,
                                        isEnable: checked ? 1 : 0,
                                    },
                                    {
                                        segments: {
                                            id: record.id,
                                        },
                                        successMsg: checked
                                            ? /* 开启成功 */ UdeskLocales['current'].pages.gong
                                                  .businessConfiguration.salesStage.list.index
                                                  .openSuccessfully
                                            : /* 关闭成功 */ UdeskLocales['current'].pages.gong
                                                  .businessConfiguration.salesStage.list.index
                                                  .closeSuccessfully,
                                        errorMsg:
                                            /* 操作失败 */ UdeskLocales['current'].pages.gong
                                                .businessConfiguration.salesStage.list.index
                                                .operationFailed,
                                    }
                                ).then(() => {
                                    record.isEnable = checked ? 1 : 0;
                                    setDataSource((prev) => [...prev]);
                                });
                            },
                        },
                        {
                            title: /* 操作 */ UdeskLocales['current'].pages.gong
                                .businessConfiguration.salesStage.list.index.operation,
                            dataIndex: 'actions',
                            renderType: 'actions',
                            buttons: [
                                {
                                    label: /* 编辑 */ UdeskLocales['current'].pages.gong
                                        .businessConfiguration.salesStage.list.index.edit,
                                    onClick(item) {
                                        storePageInfo(onRequestValues.current);
                                        let routeOptions = {
                                            history: props.history,
                                            routeName: 'salesStageDetail',
                                            pathParams: { id: item.id },
                                        };
                                        Udesk.ui.routing.transitionTo(routeOptions);
                                    },
                                },
                                {
                                    label: /* 删除 */ UdeskLocales['current'].pages.gong
                                        .businessConfiguration.salesStage.list.index.delete,
                                    danger: true,
                                    onClick(item) {
                                        return deleteSaleSettingBaseInfosById({
                                            segments: {
                                                id: item.id,
                                            },
                                            successMsg:
                                                /* 删除成功 */ UdeskLocales['current'].pages.gong
                                                    .businessConfiguration.salesStage.list.index
                                                    .deletionSucceeded,
                                            errorMsg:
                                                /* 操作失败 */ UdeskLocales['current'].pages.gong
                                                    .businessConfiguration.salesStage.list.index
                                                    .operationFailed,
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                    pagination: {
                        defaultCurrent: pageInfo.pagination?.current ?? default_pagination.current,
                        defaultPageSize:
                            pageInfo.pagination?.pageSize ?? default_pagination.pageSize,
                    },
                }}
            />
        </Page>
    );
});

class Component extends React.Component {
    render() {
        return <Template {...this.props} />;
    }
}
export default Udesk.react.udeskify(Component);
