// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    GetAutoMateTasksSampleTargetByTaskIdParam,
    BaseResponseListAutoMateSampleTargetFoundResponse,
} from '../../../types';

/**
 * findSampleTargetConf
 *
 * @export
 * @tags 自动化配置
 * @link [GET] /autoMateTasks/sampleTarget/{taskId}
 * @param taskId
 */
export function getAutoMateTasksSampleTargetByTaskId(
    options: ApiOptions<GetAutoMateTasksSampleTargetByTaskIdParam, never> & {
        segments: GetAutoMateTasksSampleTargetByTaskIdParam;
    }
): Promise<BaseResponseListAutoMateSampleTargetFoundResponse> {
    return request<never, GetAutoMateTasksSampleTargetByTaskIdParam, never>(
        '/autoMateTasks/sampleTarget/{taskId}',
        { ...options, method: 'get', __$requestCalleeName: 'getAutoMateTasksSampleTargetByTaskId' }
    );
}

export const meta = [
    { tags: ['自动化配置'], path: '/autoMateTasks/sampleTarget/{taskId}', method: 'get' },
];
