import React, { useState } from 'react';
import { Space } from 'udesk-ui';
import CustomerTooltip, { formatTime } from '../table/components/customer-tooltip';
import { getIcon } from '../table/components/row/row0';
import './index.scss';

const MAX_WIDTH = 30;
const CircleTitle = React.memo((props: any) => {
    const {children, icon} = props;
    return (
        <Space>
            <span style={{
                fontSize: 24,
                color: '#fff',
            }}>
                {icon}
            </span>

            <span>{children}</span>
        </Space>
    );
});

const Circle = React.memo((props:any) => {
    const {data, type, visible, icon} = props;    
    const callTotal = data.agentSpeechDuration + data.customerSpeechDuration;
    const agentWidth = data.agentSpeechDuration / callTotal * MAX_WIDTH || 0;
    const customWidth = data.customerSpeechDuration / callTotal * MAX_WIDTH || 0;

    const width = type === 'customer' ? customWidth : agentWidth;
    const className = type === 'customer' ? 'bg-custom' : 'bg-user';
    const style:any = {
        width,
        height: width,
        lineHeight: width,
        fontSize: width * 60 / 14  + '%'
    };

    if (type === 'agent') {
        if (customWidth < 1) {
            style.transform = 'translateX(0%)';
        }
    }

    return width ? (
        <i
            style={style}
            className={className}
        >
            <div>
                {
                    visible ? icon : null
                }
            </div>
        </i>
    ): null;
});


export default React.memo((props:any) => {
    const {data} = props;    
    const [visible, setVisible] = useState(false);
    const icon = getIcon(data);
    
    return (
        <CustomerTooltip
            onVisibleChange={visible => setVisible(visible)}
            title={[
                <CircleTitle icon={icon}>{formatTime(data.callTime)}</CircleTitle>
            ]} 
            getPopupContainer={triggerNode => {
                return $(triggerNode).parents('.carousel-detail-container').get(0);
            }}
        >
            <Circle data={data} visible={visible} icon={icon} type='customer' />
            <Circle data={data} visible={visible} icon={icon} type='agent' />
        </CustomerTooltip>
    );
});