import React, { useMemo } from 'react';
import { Form, Space, TimePicker, Button, Icon } from 'udesk-ui';
import Udesk from 'Udesk';
import moment from 'moment';
import styled from 'styled-components';
import UdeskLocales from 'UdeskLocales';

const WorkDayWrap = styled.div`
    white-space: nowrap;
`;

const WorkDay = (props) => {
    return <WorkDayWrap>{Udesk.enums.weekDays.getName(props.value)}</WorkDayWrap>;
};

const Wrap = styled.div`
    overflow: auto;
    height: 500px;

    .udesk-qa-ui-space {
        background-color: #f5f5f5;
        border-radius: 3px;
        padding: 8px 16px;
        .udesk-qa-ui-form-item {
            margin-bottom: 0;
        }
    }
    .udesk-qa-ui-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
`;

const FORMAT = 'HH:mm';

const RangePicker = (props) => {
    const { value, onChange } = props;

    const valueMoment = useMemo(() => value?.map((t) => moment(t, FORMAT)), [value]);

    return (
        <TimePicker.RangePicker
            format={FORMAT}
            {...props}
            value={valueMoment}
            onChange={(values) => {
                onChange?.(values?.map((t) => t?.format(FORMAT)));
            }}
            allowClear={true}
        />
    );
};

export const BusinessWorkingHoursSetting = React.memo((props: any) => {
    return (
        <Wrap>
            <Form.List name="workTimeList">
                {(fields, { remove, add, move }) => {
                    return (
                        <>
                            {fields.map((field, index) => (
                                <Space key={index} style={{ marginBottom: 8, display: 'flex' }}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'workDay']}
                                        fieldKey={[field.fieldKey, 'workDay']}
                                    >
                                        <WorkDay />
                                    </Form.Item>
                                    <Form.List name={[field.name, 'workDayTimeList']}>
                                        {(fields, { remove, add, move }) => {
                                            return (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Space>
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'time']}
                                                                fieldKey={[field.fieldKey, 'time']}
                                                            >
                                                                <RangePicker />
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Button
                                                                    onClick={() => remove(index)}
                                                                    danger
                                                                    type="text"
                                                                    icon={
                                                                        <Icon
                                                                            type="MinusCircleOutlined"
                                                                            antdIcon={true}
                                                                        />
                                                                    }
                                                                ></Button>
                                                            </Form.Item>
                                                        </Space>
                                                    ))}
                                                    <div>
                                                        <Button
                                                            onClick={() => {
                                                                add({
                                                                    time: null,
                                                                });
                                                            }}
                                                            type="link"
                                                            icon={
                                                                <Icon
                                                                    type="PlusCircleOutlined"
                                                                    antdIcon={true}
                                                                />
                                                            }
                                                        >{/* 添加工作时间 */}{UdeskLocales['current'].pages.businessWorkingHours.components.businessWorkingHoursSetting.index.addWorkingHours}</Button>
                                                    </div>
                                                </>
                                            );
                                        }}
                                    </Form.List>
                                </Space>
                            ))}
                        </>
                    );
                }}
            </Form.List>
        </Wrap>
    );
});
