import React from 'react';
import { UserTreeSelect } from 'src/util/hook/user';
import Locales from 'UdeskLocales';
import './index.less';

const OrganizationMemberSelect = (props) => {
    const locales = Locales['current'];
    const { isView, value, onChange, width, treeData } = props;

    return (
        <React.Fragment>
            {isView ? (
                <UserTreeSelect {...{
                    className: 'organization-member-select',
                    disabled: true,
                    bordered:false,
                    multiple: true,
                    treeData,
                    value,
                    tagRender: (val) => {
                        return <span className="view-label" title={val?.label}>{val?.label}</span>;
                    }
                }}/>
            ) : (
                <UserTreeSelect {...{
                    width,
                    multiple: true,
                    treeData,
                    onChange,
                    value,
                    showSearch: true,
                    allowClear: true,
                    showArrow: true,
                    maxTagCount: 3,
                    placeholder: locales.labels.pleaseSelect,
                    showCheckedStrategy: "SHOW_PARENT",
                    treeDefaultExpandAll: false,
                    treeCheckable: true,
                }}/>
            )}
        </React.Fragment>
    );
};

export default OrganizationMemberSelect;
