// @ts-nocheck

import { request } from '../../../../../../context';
import type { ApiOptions } from '../../../../../../context';
import type {
    GetReviewCallAnalysisOrganizationByIdKeyEventsQuery,
    GetReviewCallAnalysisOrganizationByIdKeyEventsParam,
    BaseResponseListDataCallAnalysisOrgTagResponse,
} from '../../../../../../types';

/**
 * keyEventsByOrganization
 *
 * @export
 * @tags 团队分析
 * @link [GET] /review/call/analysis/organization/{id}/keyEvents
 * @param id
 */
export function getReviewCallAnalysisOrganizationByIdKeyEvents(
    options: ApiOptions<
        GetReviewCallAnalysisOrganizationByIdKeyEventsParam,
        GetReviewCallAnalysisOrganizationByIdKeyEventsQuery
    > & { segments: GetReviewCallAnalysisOrganizationByIdKeyEventsParam } & {
        params: GetReviewCallAnalysisOrganizationByIdKeyEventsQuery;
    }
): Promise<BaseResponseListDataCallAnalysisOrgTagResponse> {
    return request<
        never,
        GetReviewCallAnalysisOrganizationByIdKeyEventsParam,
        GetReviewCallAnalysisOrganizationByIdKeyEventsQuery
    >('/review/call/analysis/organization/{id}/keyEvents', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getReviewCallAnalysisOrganizationByIdKeyEvents',
    });
}

export const meta = [
    {
        tags: ['团队分析'],
        path: '/review/call/analysis/organization/{id}/keyEvents',
        method: 'get',
    },
];
