import { postReviewIntelligentPartnerNodeRecoverById } from 'src/api/review/intelligentPartner/node/recover/{id}';
import {
    deleteReviewIntelligentPartnerNodeRelation,
    postReviewIntelligentPartnerNodeRelation,
} from 'src/api/review/intelligentPartner/node/relation';
import {
    deleteReviewIntelligentPartnerNodeById,
    putReviewIntelligentPartnerNodeById,
} from 'src/api/review/intelligentPartner/node/{id}';

export const deleteNodeById = (
    id: number
): ReturnType<typeof deleteReviewIntelligentPartnerNodeById> => {
    return deleteReviewIntelligentPartnerNodeById({
        segments: {
            id,
        },
    });
};

export const updateNodePositionById = (
    id: number,
    nodeLocation: string
): ReturnType<typeof putReviewIntelligentPartnerNodeById> => {
    return putReviewIntelligentPartnerNodeById(
        { nodeLocation },
        {
            segments: {
                id,
            },
        }
    );
};

export const recoverNodeDeletedStatusById = (
    id: number
): ReturnType<typeof postReviewIntelligentPartnerNodeRecoverById> => {
    return postReviewIntelligentPartnerNodeRecoverById({
        segments: {
            id,
        },
    });
};

export const connectNodes = (
    source: number,
    target: number
): ReturnType<typeof postReviewIntelligentPartnerNodeRelation> => {
    return postReviewIntelligentPartnerNodeRelation({
        prevBindNodeId: source,
        targetNodeId: target,
    });
};

export const deleteNodesConnection = (
    source: number,
    target: number
): ReturnType<typeof deleteReviewIntelligentPartnerNodeRelation> => {
    return deleteReviewIntelligentPartnerNodeRelation({
        prevBindNodeId: source,
        targetNodeId: target,
    });
};
