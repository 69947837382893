import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const usePPTData = (list) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const current = useMemo(() => list?.[currentIndex], [currentIndex, list]);

    const indexValidate = useCallback((index) => {
        if (list) {
            if (index >= list.length) {
                return list.length - 1;
            }
            if (index < 0) {
                return 0;
            }
            return index;
        }
        return 0;
    }, [list]);

    const goIndex = useCallback((index) => {
        const $content = $('.ppt-thumbnail-list').get(0);
        index = indexValidate(index);
        if ($content) {
            $('tr:eq(' + (index + 2) + ')', $content).get(0)?.scrollIntoView({
                block: 'nearest',
                inline: 'start',
                behavior: 'smooth',
            });
        }
        setCurrentIndex(index);
    }, [indexValidate]);

    const isLastPage = useMemo(() => {
        if (list) {
            return currentIndex === list.length - 1;
        }
        return false;
    }, [currentIndex, list]);

    return [isLastPage, list, current, currentIndex, goIndex];
};


export const useFullscreen = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreen);

    const requestFullscreen = useCallback(() => {
        if (!document.fullscreen) {
            // contentRef.current?.requestFullscreen();
            contentRef.current && openFullscreen(contentRef.current);
        }
    }, []);

    const exitFullscreen = useCallback(() => {
        if (document.fullscreen) {
            document.exitFullscreen();
        }
    }, []);

    useEffect(() => {
        contentRef.current?.addEventListener(
            'fullscreenchange',
            function () {
                setIsFullscreen(document.fullscreen);
            },
            false
        );
    }, []);

    return [contentRef, isFullscreen, requestFullscreen, exitFullscreen];
};

function openFullscreen(elem) {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
    }
}