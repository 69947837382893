// @ts-nocheck

import { request } from '../../../context';
import type { ApiOptions } from '../../../context';
import type {
    PostReviewCallSearchBody,
    BaseResponseReviewDataCallFieldListWrapper,
} from '../../../types';

/**
 * search
 *
 * @export
 * @tags 通话记录
 * @link [POST] /review/call/search
 */
export function postReviewCallSearch(
    data: PostReviewCallSearchBody
): Promise<BaseResponseReviewDataCallFieldListWrapper>;
export function postReviewCallSearch(
    data: PostReviewCallSearchBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseReviewDataCallFieldListWrapper>;
export function postReviewCallSearch(
    data: PostReviewCallSearchBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseReviewDataCallFieldListWrapper> {
    return request<PostReviewCallSearchBody, never, never>('/review/call/search', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postReviewCallSearch',
    });
}

export const meta = [{ tags: ['通话记录'], path: '/review/call/search', method: 'post' }];
