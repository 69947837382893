/*
 * @Author: 唐毓斌
 * @Description: 结果分析
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { postIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId } from 'src/api/intelligentPartnerTaskInfos/result/analysis/overview/{taskId}';
import {
    FinishRecordResultAnalysisOverview,
} from 'src/api/types';
import { Space } from 'udesk-ui';
import CustomCard from '../../../../components/CutomCard';
import './ResultAnalysis.less';
import UdeskLocales from 'UdeskLocales';
// import { useRequest } from 'src/util/hook';



const ResultAnalysis: React.FC<any> = (props) => {
    const { taskId, isExercise, showTop = false,
        startTime,
        endTime,
        userSelectObj
    } = props;
    // const request = useRequest();
    const [overview, setOverview] = useState<FinishRecordResultAnalysisOverview>();
    const contentRef = useRef<HTMLDivElement>(null);

    const fetchOverviewData = useCallback(() => {
        let params = {
            endTime: endTime.split(' ')[0],
            startTime: startTime.split(' ')[0],
            ...userSelectObj
        };
        postIntelligentPartnerTaskInfosResultAnalysisOverviewByTaskId(params, {segments: {taskId:taskId}})
            .then((resp) => {
                setOverview(resp.data);
            });
    }, [taskId,userSelectObj,startTime, endTime]);

    useEffect(() => {
        fetchOverviewData();
    }, [fetchOverviewData]);

    return (
        <Space className="result-analysis-container" direction="vertical" size={'middle'}>
            {showTop ? (
                <div className="result-analysis-top-wrapper" ref={contentRef}>
                    <CustomCard
                        containerStyle={{ marginRight: 16, width: 260 }}
                        title={
                            isExercise
                                ? /* 练习平均分 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                    .resultAnalysis.practiceAverageScore
                                : /* 考试平均分 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averageScoreOfTheExam
                        }
                        content={
                            <div
                                className="result-card-content-wrapper"
                                style={{ alignSelf: 'center', justifyContent: 'center' }}
                            >
                                <span className="score-content">
                                    {overview?.avgScore?.avgScore || 0}
                                </span>
                            </div>
                        }
                    />
                    <CustomCard
                        containerStyle={{ marginRight: 16, width: 260 }}
                        title={
                            isExercise
                                ? /* 平均练习时长（min） */ UdeskLocales['current'].pages.coach
                                    .taskCenter.detail.resultAnalysis.averagePracticeDurationmin
                                : /* 平均考试时长（min） */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.averageExamDurationmin
                        }
                        content={
                            <div
                                className="result-card-content-wrapper"
                                style={{ alignSelf: 'center', justifyContent: 'center' }}
                            >
                                <span className="score-content">{overview?.avgCostTime}</span>
                            </div>
                        }
                    />
                    {isExercise ? (
                        <CustomCard
                            containerStyle={{ marginRight: 16, width: 260 }}
                            title={/* 累计练习时长（min） */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.accumulatedPracticeDurationmin}
                            content={
                                <div
                                    className="result-card-content-wrapper"
                                    style={{ alignSelf: 'center', justifyContent: 'center' }}
                                >
                                    <span className="score-content">
                                        {overview?.totalPracticeCostTime}
                                    </span>
                                </div>
                            }
                        />
                    ) : null}
                    {isExercise ? (
                        <CustomCard
                            containerStyle={{ marginRight: 16, width: 260 }}
                            title={/* 累计练习次数 */UdeskLocales['current'].pages.coach.taskCenter.detail.resultAnalysis.accumulatedPracticeTimes}
                            content={
                                <div
                                    className="result-card-content-wrapper"
                                    style={{ alignSelf: 'center', justifyContent: 'center' }}
                                >
                                    <span className="score-content">
                                        {overview?.totalPracticeTimes}
                                    </span>
                                </div>
                            }
                        />
                    ) : null}
                    <CustomCard
                        containerStyle={{ marginRight: 16, width: 260 }}
                        title={
                            /* 最高分Top3 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .resultAnalysis.topOfTheHighestScore
                        }
                        content={
                            <div className="result-card-content-wrapper">
                                {(overview?.top3HighestScoreUser || [])
                                    .slice(0, 3)
                                    .map((item, index) => (
                                        <div key={index} className="top-list-item">
                                            <span>{`Top${index + 1}`}</span>
                                            <span
                                                style={{
                                                    marginLeft: 8,
                                                    color: '#257aed',
                                                    overflow: 'hidden',
                                                    flex: 1,
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {item.userName}
                                            </span>
                                            <span style={{ marginLeft: 18, color: '#257aed' }}>{`${
                                                item.score
                                            }${
                                                /* 分 */ UdeskLocales['current'].pages.coach.taskCenter
                                                    .detail.resultAnalysis.branch
                                            }`}</span>
                                        </div>
                                    ))}
                            </div>
                        }
                    />
                    <CustomCard
                        containerStyle={{ width: 400 }}
                        title={
                            /* 易错项Top5 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .resultAnalysis.errorProneItemTop
                        }
                        content={
                            <div className="result-card-content-wrapper">
                                {(overview?.top5FallibleWords || []).slice(0, 5).map((item, index) => (
                                    <div key={index} className="top-list-item">
                                        <span>{`Top${index + 1}`}</span>
                                        <span
                                            title={item.ruleName}
                                            style={{
                                                marginLeft: '8px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                flex: 1,
                                            }}
                                        >
                                            {item.ruleName}
                                        </span>
                                        <span style={{ marginLeft: 10 }}>{`${
                                            /* 命中率 */ UdeskLocales['current'].pages.coach.taskCenter
                                                .detail.resultAnalysis.hitRate
                                        }`}</span>
                                        <span style={{ marginLeft: 0, color: 'red' }}>
                                            {((item.hitRatio || 0) * 100).toFixed(2) + '%'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        }
                    />
                </div>
            ) : ''}
        </Space>
    );
};

export default ResultAnalysis;
