// @ts-nocheck

import { request } from '../context';
import type { ApiOptions } from '../context';
import type {
    GetTypoDatasQuery,
    BaseResponseListTypoDataFoundResponse,
    PostTypoDatasBody,
    BaseResponseTypoDataFoundResponse,
} from '../types';

/**
 * findAll
 *
 * @export
 * @tags typo-data-controller
 * @link [GET] /typoDatas
 */
export function getTypoDatas(): Promise<BaseResponseListTypoDataFoundResponse>;
export function getTypoDatas(
    options: ApiOptions<never, GetTypoDatasQuery>
): Promise<BaseResponseListTypoDataFoundResponse>;
export function getTypoDatas(
    options?: ApiOptions<never, GetTypoDatasQuery>
): Promise<BaseResponseListTypoDataFoundResponse> {
    return request<never, never, GetTypoDatasQuery>('/typoDatas', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getTypoDatas',
    });
}

/**
 * save
 *
 * @export
 * @tags typo-data-controller
 * @link [POST] /typoDatas
 */
export function postTypoDatas(data: PostTypoDatasBody): Promise<BaseResponseTypoDataFoundResponse>;
export function postTypoDatas(
    data: PostTypoDatasBody,
    options: ApiOptions<never, never>
): Promise<BaseResponseTypoDataFoundResponse>;
export function postTypoDatas(
    data: PostTypoDatasBody,
    options?: ApiOptions<never, never>
): Promise<BaseResponseTypoDataFoundResponse> {
    return request<PostTypoDatasBody, never, never>('/typoDatas', {
        ...options,
        data,
        method: 'post',
        __$requestCalleeName: 'postTypoDatas',
    });
}

export const meta = [
    { tags: ['typo-data-controller'], path: '/typoDatas', method: 'get' },
    { tags: ['typo-data-controller'], path: '/typoDatas', method: 'post' },
];
