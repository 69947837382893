import optionsBuilderClass from '../options-builder';
import echarts from 'echarts';

class HasLegendBuilderClass extends optionsBuilderClass {
    getLegendOptions(optionsOwner) {

        let {
            legendLimit,
            seriesColumns,
        } = optionsOwner.props;
        if (!seriesColumns) {
            return false;
        }

        let legendData = seriesColumns.map(item => item.name);
        legendData = legendData.slice(0, legendLimit);

        let legend = super.getLegendOptions(...arguments);

        legend.data = legendData;
        legend.formatter = function (name) {
            return echarts.format.truncateText(name, 90, "Microsoft Yahei", '...');
        };

        legend.tooltip = {
            show: true
        };

        return legend;
    }
}

export default HasLegendBuilderClass;
