import React, { useCallback, useState } from 'react';
import { Button, Icon, Tooltip } from 'udesk-ui';
import moment from 'moment';
import './index.less';
import { fire } from 'src/util/core';

export const formatTime = (time) => {
    return moment(time).format('MM-DD HH:mm');
};

export const getPlacement = (index, list) => {
    if (index < 3) {
        return 'topLeft';
    }
    if (index > list.length - 3) {
        return 'topRight';
    }
    return 'top';
};

const Title = React.memo((props: any) => {
    const {fixed, setFixed, title} = props;

    const clickHandle = useCallback(() => {
        setFixed(fixed => !fixed);
    }, [
        setFixed
    ]);

    return (
        <>
            <div className='custom-tooltip-title'>
                {title[0]}
                <Button 
                    type='link'
                    onClick={clickHandle}
                    icon={<Icon antdIcon={true} type={fixed? 'PushpinFilled' : 'PushpinOutlined'}/>}
                />
            </div>
            <div className='custom-tooltip-content'>{title[1]}</div>
        </>
    );
});

export default React.memo((props: any) => {
    const {
        color, 
        children, 
        title, 
        placement = 'top',
        zIndex = 1,
        defaultFixed = false, 
        defaultVisible = false,
        onVisibleChange,
        getPopupContainer = (triggerNode) => triggerNode.parentElement!,
    } = props;
    const [visible, setVisible] = useState(defaultVisible);
    const [fixed, setFixed] = useState(defaultFixed);

    const openHandle = useCallback((visible) => {
        if (!fixed) {
            setVisible(visible);
        }
        fire(onVisibleChange, visible);
    }, [fixed, onVisibleChange]);

    return (
        <Tooltip
            overlayClassName="custom-tooltip"
            zIndex={zIndex}
            placement={placement}
            visible={visible}
            onVisibleChange={openHandle}
            color={color}
            title={
                <Title fixed={fixed} setFixed={setFixed} title={title} />
            } 
            trigger="hover"
            getPopupContainer={getPopupContainer}
            destroyTooltipOnHide={true}
        >
            {children}
        </Tooltip>
    );
});
