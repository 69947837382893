/*
 * @Author: 唐毓斌
 * @Description: 任务详情
 */
import React, {useCallback, useEffect, useState } from 'react';
import Page from 'Component/common/udesk-qa-page-layout'; 
import Udesk from 'Udesk';
import moment from 'moment';
import UdeskLocales from 'UdeskLocales';
import hookProps from 'udesk-react/src/udesk/react/hook-props';
import ResultAnalysis from './components/ResultAnalysis';
import { Tabs,Button } from 'udesk-ui';

import OrganizationMemberSelect from '../../../components/OrganizationMemberSelect';
import { useCoachUserTreeDataWithPermit } from 'src/util/hook/user';

import { getIntelligentPartnerTaskInfosById } from 'src/api/intelligentPartnerTaskInfos/{id}';

import { isExercise } from '../../../../learning-center/components/util';



const TaskCenterDetail: React.FC<any> = (props) => {
    const locales = UdeskLocales['current'];
    const { history, match, routes } = props;
    console.log('task-center:routes', routes);
    const [taskInfo, setTaskInfo] = useState<any>();
    const [currentTab, setCurrentTab] = useState('count');

    // --筛选start
    const [userSelectObj, setUserSelectObj] = useState<any>({
        userIds: [],
        organizationIds: []
    });
    const { treeData } = useCoachUserTreeDataWithPermit();
    const treeDataChange = (data) => {
        // console.log(data);
        setUserSelectObj(data);
    };
    const [ confirmFilterParams, setConfirmFilterParams] = useState<any>({
        userSelectObj: {
            userIds: [],
            organizationIds: []
        }
    });
    const filterConfirm = () => {
        // console.log('confirm',userSelectObj);
        setConfirmFilterParams({
            userSelectObj
        });
    };
    const filterReset = () => {
        setConfirmFilterParams({
            userSelectObj: {
                userIds: [],
                organizationIds: []
            }
        });
        setUserSelectObj({
            userIds: [],
            organizationIds: []
        });
    };
    // --筛选end

    const onBack = () => {
        const routeName = 'coachTaskCenterList';
        Udesk.ui.routing.transitionTo({
            history,
            routeName,
        });
    };

    const isPPT = useCallback(
        (wholeCallback, singleCallback, errorCallback?) => {
            return singleCallback.call(taskInfo);
            // taskInfo?.evaluationMode 等于null:非ppt， 1：整体， 2：单页
            if (taskInfo?.evaluationMode === 1) {
                return wholeCallback.call(taskInfo);
            }
            if (taskInfo?.evaluationMode === 2) {
                return singleCallback.call(taskInfo);
            }
            return (errorCallback || singleCallback).call(taskInfo);
        },
        [taskInfo]
    );

    const init = () => {
        const id = match?.params?.id || '0';
        if (id !== '0') {
            // setLoading(true);
            getIntelligentPartnerTaskInfosById({
                segments: { id },
            })
                .then((resp) => {
                    let values: any = {
                        ...resp?.data,
                    };
                    //画像
                    values.imageIdInfo = {
                        status: 2,
                    };
                    if (values.imageId != null) {
                        values.imageIdInfo.status = 1;
                        values.imageIdInfo.selectedItemId = values.imageId;
                    }
                    // 时长限制
                    values.limitDurationInfo = {
                        status: 1,
                    };
                    if (values.limitDuration != null) {
                        values.limitDurationInfo.status = 2;
                        values.limitDurationInfo.inputValue = values.limitDuration;
                    }
                    // 时间
                    values.taskTimeInfo = {
                        status: 1,
                        viewText:
                            /* 不限时间 */ UdeskLocales['current'].pages.coach.taskCenter.detail
                                .index.unlimitedTime,
                    };
                    if (values.taskStartTime && values.taskStartTime) {
                        values.taskTimeInfo.status = 2;
                        values.taskTimeInfo.viewText =
                            moment(values.taskStartTime).format('YYYY-MM-DD HH:mm') +
                            /* 至 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                                .to +
                            moment(values.taskEndTime).format('YYYY-MM-DD HH:mm');
                    }
                    // 评分标准
                    values.gradingStandartInfo = {
                        commitScore: values.commitScore,
                        excellentScore: values.excellentScore,
                    };
                    // 完成要求
                    values.completeStandartInfo = {};
                    if (values.completeByTimes != null) {
                        values.completeStandartInfo.timesStatus = true;
                        values.completeStandartInfo.completeByTimes = values.completeByTimes;
                    }
                    if (values.completeByScore != null) {
                        values.completeStandartInfo.scoreStatus = true;
                        values.completeStandartInfo.completeByScore = values.completeByScore;
                    }
                    setTaskInfo(values);
                    // getTaskScore(values);
                    // setLoading(false);
                })
                .catch(() => {
                    // setLoading(false);
                });
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Page
            pageBodyClassName="task-center-detail-container"
            backGroundGhost={true}
            title={`${taskInfo?.taskName || ''}-${
                taskInfo?.taskType === 1
                    ? /* 练习 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .practice
                    : /* 考试 */ UdeskLocales['current'].pages.coach.taskCenter.detail.index
                          .examination
            }`}
            // loading={loading}
            onBack={onBack}
        >
            <div className="tcd-header-wrapper">
                    <div className="tcd-header-filter-box" style={{background: '#fff'}}>
                        <div className="tcd-header-filter-box-left">
                            {UdeskLocales['current'].pages.coach.taskCenter.detail.index.staff}：
                            <OrganizationMemberSelect 
                                placeholder={locales.labels.pleaseSelect}
                                width={200}
                                value={userSelectObj}
                                onChange={treeDataChange}
                                treeData={treeData}
                            />
                        </div>
                        <div className="tcd-header-filter-box-right">
                            <Button onClick={filterReset}>{locales.labels.reset}</Button>
                            <Button type="primary" onClick={filterConfirm}>{locales.labels.confirm}</Button>
                        </div>
                    </div>
                
                <div className="tcd-header-echarts">
                <Tabs
                    style={{ padding: '4px 16px', backgroundColor: '#fff' }}
                    activeKey={currentTab}
                    onChange={(activeKey) => setCurrentTab(activeKey)}
                >
                    <Tabs.TabPane
                        tab={
                            /* 按次数 */
                            UdeskLocales['current'].pages.coach
                            .taskCenter.detail.index.count
                        }
                        key="count"
                    >
                    {currentTab === 'count' ? (
                        <ResultAnalysis
                            taskId={match?.params?.id}
                            lessonId={taskInfo?.lessonId}
                            isExercise={isExercise(taskInfo?.taskType)}
                            chartType={'count'}
                            isPPT={isPPT}
                            userSelectObj={confirmFilterParams.userSelectObj}
                            showBottom={true}
                            history={props.history}
                        />
                    ): ''}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            /* 按人数 */
                            UdeskLocales['current'].pages.coach
                            .taskCenter.detail.index.person
                        }
                        key="person"
                    >
                    {currentTab === 'person' ? (
                        <ResultAnalysis
                            taskId={match?.params?.id}
                            lessonId={taskInfo?.lessonId}
                            isExercise={isExercise(taskInfo?.taskType)}
                            chartType={'person'}
                            userSelectObj={confirmFilterParams.userSelectObj}
                            isPPT={isPPT}
                            showBottom={true}
                            history={props.history}
                        />
                    ) : ''}
                    </Tabs.TabPane>
                </Tabs>
                </div>
            </div>

        </Page>
    );
};

export default hookProps(TaskCenterDetail);
