import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, InputNumber, Radio, Select } from 'udesk-ui';
import Udesk from 'Udesk';
import { useReactFlow } from 'reactflow';
import UdeskLocales from 'UdeskLocales';
import { ConditionListGatherForm } from 'Udesk/config/form-config/cus-component-map';
import { RuleList } from './RuleList';
import styled from 'styled-components';
import { findAllSelectedBranch } from './utils/node';
// import { CheckboxValueType } from 'antd/lib/checkbox/Group';
// import CommonTablePage from 'Component/common/udesk-qa-table-page';
// import { Button, Icon } from 'udesk-ui';
// import './style.scss';

type CheckboxValueType = string | number | boolean;

const initialValues = {
    conditionList: [
        {
            conditionType: 1, //1话术2交互3特征4信息
            taskConditionId: null,
            orderId: 1,
            operatorType: 10,
            idCode: 'R1',
            operatorList: [
                {
                    applyConditionHitType: 1,
                    applyOperatorScope: {
                        key: 'all',
                        id: 1,
                    },
                    type: 10,
                    operator: 'all',
                    applyRole: 'all',
                },
            ],
            applyConditionId: null,
            applyConditionIdCode: null,
            operator: 'all',
            applyRole: 'all',
            applyScope: null,
            similarityScore: null,
            differWordNumber: null,
            wordPerMinite: null,
            maxWordPerMinite: null,
            grabIntervalTime: null,
            grabMaxSpeakTime: null,
            intervalSecond: null,
            keyword: '',
            semanticSentence: null,
            applyConditionScope: null,
            applyConditionHitType: 1,
            // applyOperatorScope: {
            //     key: 'all',
            //     id: 1
            // },
            param: {},
        },
    ],
    ruleList: [{ formula: '', judgeStrategy: 1 }],
};

const Wrap = styled.div`
    .udesk-qa-ui-input-group {
        display: flex !important;
        align-items: center;
    }
`;

const BaseNodeConfig = (props: any) => {
    const locales = UdeskLocales['current'];
    const { getNodes, getEdges } = useReactFlow();
    const [showInput, setShowInput] = useState(false);
    const [multiBranch, setMultiBranch] = useState(false);
    const [scoreType, setScoreType] = useState(Udesk.enums.calculateScoreTypes.add.id);
    // const [allBranch, setAllBranch] = useState<any[]>([]);
    const [conditionList, setConditionList] = useState<any[]>([]);
    const [form] = Form.useForm();
    const { currentNode, _type } = props;

    useEffect(() => {
        if (!currentNode) return;
        const nodes = getNodes();

        const { data } = currentNode;
        let { judgeNode: dialogNode, allBranch } = getAllBranch(
            currentNode,
            nodes,
            getEdges(),
            _type
        );

        
        let initValues = {
            ruleList: allBranch.length ? allBranch.reduce<any[]>((res, b: any) => {
                res.push(...(b.data.ruleList ?? []).map((r) => ({ ...r, nodeId: b.id })));
                return res;
            }, []) : dialogNode.data.ruleList,
            judgeStrategy: allBranch.length
                ? 31
                : dialogNode.data.ruleList?.[0]?.judgeStrategy || 1,
            conditionList: data.conditionList || initialValues.conditionList,
            name: data.name,
            scoreType: data.scoreType,
            predeterminedScore: data.predeterminedScore,
            relateConditionList: data.relateConditionList,
        };
        
        _type === 'DIALOG_NODE' && setScoreType(initValues.scoreType);
        setShowInput(initValues.judgeStrategy >= 3);
        setMultiBranch(initValues.judgeStrategy > 3);

        setConditionList(dialogNode.data.conditionList || initialValues.conditionList);
        form.setFieldsValue(initValues);
    }, [currentNode, _type]);

    return (
        <Wrap>
            <Form
                initialValues={initialValues}
                layout="vertical"
                form={form}
                onFinish={(values) => {
                    const relateConditionListMap = (values.relateConditionList || []).reduce((p, c) => {
                        p[c.idCode] = true;
                        return p;
                    }, {});
                    props.onSubmit?.({
                        ...values,
                        relateConditionList: conditionList.map(c => {
                            let highlight = 0;
                            if(relateConditionListMap[c.idCode]) {
                                highlight = 1;
                            }
                            return {
                                idCode: c.idCode,
                                highlight,
                            };
                        }),
                        ruleList: values.ruleList?.map((r) => ({
                            ...r,
                            judgeStrategy: Math.min(3, values.judgeStrategy),
                        })),
                        // preNodeType: 'DIALOG_NODE'
                    });
                }}
            >
                <Form.Item name="name" label={/* 节点名称 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.nodeName} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="conditionList" label={/* 节点规则 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.nodeRules} rules={[{ required: true }]}>
                    <ConditionListGatherForm
                        type={[props.inspectDataSource]}
                        isWechatQa={false}
                        isSales={false}
                        onChange={setConditionList}
                    />
                </Form.Item>
                {_type === 'DIALOG_NODE' && (
                    <Form.Item label={/* 评分逻辑 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.scoringLogic} rules={[{ required: true }]}>
                        <Input.Group compact>
                            <div>{/* 分值： */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.score}</div>
                            <Form.Item name="scoreType" noStyle rules={[{ required: true }]}>
                                <Select
                                    style={{ width: 80 }}
                                    options={Udesk.enums.calculateScoreTypes.map((i) => ({
                                        label: i.name,
                                        value: i.id,
                                    }))}
                                    onChange={setScoreType}
                                />
                            </Form.Item>
                            <Form.Item
                                name="predeterminedScore"
                                noStyle
                                rules={[{ required: true }]}
                            >
                                <InputNumber style={{ width: 140 }} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                )}
                <Form.Item name="judgeStrategy" label={/* 规则 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.rule} rules={[{ required: true }]}>
                    <Radio.Group
                        onChange={(e) => {
                            setShowInput(e.target.value >= 3);
                            setMultiBranch(e.target.value > 3);
                            if (e.target.value >= 3) {
                                form.setFieldsValue({
                                    ruleList: [{}],
                                });
                            }
                        }}
                    >
                        <Radio value={1}>
                            {locales.pages.tasks.manage.template.checkPoint.configuration.logic.all}
                        </Radio>
                        <Radio value={2}>
                            {locales.pages.tasks.manage.template.checkPoint.configuration.logic.any}
                        </Radio>
                        <Radio value={3}>
                            {
                                locales.pages.tasks.manage.template.checkPoint.configuration.logic
                                    .customize
                            }
                        </Radio>
                        <Radio value={31}>{/* 多分支逻辑 */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.multiBranchLogic}</Radio>
                    </Radio.Group>
                </Form.Item>
                {showInput && (
                    <Form.Item name="ruleList" rules={[{ required: true }]}>
                        <RuleList
                            conditions={conditionList}
                            multiBranch={multiBranch}
                            scoreType={_type === 'DIALOG_NODE' ? scoreType : undefined}
                        />
                    </Form.Item>
                )}
                <Form.Item name="relateConditionList" label={/* 高亮 */UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.highlight}>
                    <Highlight conditions={conditionList}></Highlight>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                onClick={() => {
                    form.submit();
                }}
            >{/* 提交 */}{UdeskLocales['current'].pages.tasks.manage.template.checkPoint.detail.components.flow.nodeConfig.submit}</Button>
        </Wrap>
    );
};

function getNodeConfigWithType(WrapComponent, type) {
    return function DialogNodeConfig(props) {
        const [state] = useState({ _type: type });
        return <WrapComponent {...props} {...state} />;
    };
}

export const DialogNodeConfig = getNodeConfigWithType(BaseNodeConfig, 'DIALOG_NODE');
export const JudgeNodeConfig = getNodeConfigWithType(BaseNodeConfig, 'JUDGE_NODE');

function Highlight(props) {
    const { conditions, onChange, value } = props;

    return (
        <Checkbox.Group
            options={conditions?.map((c) => c.idCode) ?? []}
            value={value?.filter((v) => v.highlight)?.map((v) => v.idCode) ?? []}
            onChange={(list: CheckboxValueType[]) => {
                onChange?.(
                    conditions.map((c) => {
                        const res = {
                            idCode: c.idCode,
                            highlight: 0,
                            name: null,
                        };
                        if (list.includes(c.idCode)) {
                            res.highlight = 1;
                        }
                        return res;
                    })
                );
            }}
        ></Checkbox.Group>
    );
}

function findAllSelectedEdges(edges: any, currentSelectedNode: any, allBranch: any) {
    return edges.filter((e) => {
        return e.source === currentSelectedNode.id && allBranch.find((b) => b.id === e.target);
    });
}

function getAllBranch(currentNode, nodes, edges, type) {
    const { data } = currentNode;

    let judgeNode,
        allBranch: any[] = [],
        allEdges: any[] = [];

    if (data.type === 'BRANCH' || data.type === type) {
        if (data.type === 'BRANCH') {
            judgeNode = nodes.find((n) => n.data.id === data.preId && n.data.type === type);
        }
        if (data.type === type) {
            judgeNode = currentNode;
        }
        if (judgeNode) {
            allBranch = nodes.filter(
                (n) => n.data.preId === judgeNode.id && n.data.type === 'BRANCH'
            );
            // setAllBranch(allBranch);
        }
    }

    allEdges = findAllSelectedEdges(edges, currentNode, findAllSelectedBranch(nodes, currentNode));

    return {
        judgeNode,
        allBranch,
        allEdges,
    };
}
