import React, { useEffect, useState } from 'react';
import Locales from 'UdeskLocales';
import { List, Space } from 'udesk-ui';
import CallListItem from './call-list-item';
import moment from 'moment';
import './index.scss';

const locales = Locales['current'];

const CallDynamic = (props) => {
    const [listData, setListData] = useState<any[]>([]);

    const timeFormatter = (time?) => {
        let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let today = moment().format('YYYY-MM-DD');
        let defaultTime = moment(time).format('YYYY-MM-DD');
        switch(defaultTime) {
            case today :
                return locales.pages.gong.homePage.common.callDynamic.today;
            case yesterday :
                return locales.pages.gong.homePage.common.callDynamic.yesterday;
            default: {
                return time;
            }
        }
    };
    useEffect(() => {
        setListData(props.callStatusData);
    }, [props.callStatusData]);
    useEffect(() => {
        const dom = document.getElementsByClassName('pages-home-page-call-list-content')?.[0];
        dom && (dom.scrollTop = 0);
    }, [props.page]);

    return (
        <List
            className='pages-home-page-call-list-content'
            size='large'
            header={null}
            footer={null}
            dataSource={listData}
            loading={props.loading}
            pagination={{
                ...props.page,
                onChange: props.onPageChange,
            }}
            renderItem={(item) => (
                <List.Item>
                    <Space direction="vertical" style={{width: '100%'}}>
                        <Space>
                            <h3 style={{fontSize: 14, fontWeight: 700}}>{timeFormatter(item?.callTime)}</h3>
                            <span>{item?.children?.length??0}{locales.pages.gong.homePage.common.callDynamic.sessions}</span>
                        </Space>
                        {
                            item.children.map(record => {
                                return <CallListItem isHome={true} {...props} key={record.date} {...record} tag={record.tagList??[]}></CallListItem>;
                            })
                        }
                    </Space>
                </List.Item>
            )}
        />
    );
};

export default CallDynamic;
