import Locales from '../locales/index';

let wordsEffectiveness = [
    { id: 1, name: () => Locales.current.enums.smartWordsLibrary.effective, key: "effective" },
    { id: 0, name: () => Locales.current.enums.smartWordsLibrary.invalid, key: "invalid" }
];

let corpusContentWordsLengthStatus = [
    { id: 2, key: "over" },
    { id: 1, key: "suitable" }
];

let smartWordsLibraryMenus = [
    { key: 'synonymWords', id: 1, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.synonymWords },
    { key: 'professionalWords', id: 2, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.professionalWords },
    { key: 'attentionWords', id: 3, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.attentionWords },
    { key: 'ignoreWords', id: 4, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.ignoreWords },
    { key: 'wordsWeight', id: 5, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.wordsWeight },
    { key: 'corpusData', id: 6, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.corpusData },
    { key: 'typoLibrary', id: 7, name: () => Locales.current.components.pages.smartWordsLibrary.navTabNames.typoLibrary },
];

let smartWordsLibraryBoard = [
    { key: 'synonymWords', id: 'synonym-words' },
    { key: 'professionalWords', id: 'professional-words' },
    { key: 'attentionWords', id: 'attention-words' },
    { key: 'ignoreWords', id: 'ignore-words' },
    { key: 'wordsWeight', id: 'words-weight' },
    { key: 'corpusData', id: 'corpus-data' },
    { key: 'typoLibrary', id: 'typo-data' },
];
export default {
    wordsEffectiveness,
    corpusContentWordsLengthStatus,
    smartWordsLibraryMenus,
    smartWordsLibraryBoard
};

