// @ts-nocheck

import { request } from '../../../../context';
import type { ApiOptions } from '../../../../context';
import type {
    PutInspectionPointsByIdStatusByStatusQuery,
    PutInspectionPointsByIdStatusByStatusParam,
    BaseResponseVoid,
} from '../../../../types';

/**
 * status
 *
 * @export
 * @tags 质检点
 * @link [PUT] /inspectionPoints/{id}/status/{status}
 * @param id
 * @param status
 */
export function putInspectionPointsByIdStatusByStatus(
    options: ApiOptions<
        PutInspectionPointsByIdStatusByStatusParam,
        PutInspectionPointsByIdStatusByStatusQuery
    > & { segments: PutInspectionPointsByIdStatusByStatusParam } & {
        params: PutInspectionPointsByIdStatusByStatusQuery;
    }
): Promise<BaseResponseVoid> {
    return request<
        never,
        PutInspectionPointsByIdStatusByStatusParam,
        PutInspectionPointsByIdStatusByStatusQuery
    >('/inspectionPoints/{id}/status/{status}', {
        ...options,
        method: 'put',
        __$requestCalleeName: 'putInspectionPointsByIdStatusByStatus',
    });
}

export const meta = [
    { tags: ['质检点'], path: '/inspectionPoints/{id}/status/{status}', method: 'put' },
];
