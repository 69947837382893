// @ts-nocheck

import { request } from '../../../../../context';
import type { ApiOptions } from '../../../../../context';
import type {
    GetReviewIntelligentPartnerNodeKnowledgeConfigByIdParam,
    BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse,
    PutReviewIntelligentPartnerNodeKnowledgeConfigByIdParam,
    PutReviewIntelligentPartnerNodeKnowledgeConfigByIdBody,
    BaseResponseVoid,
    DeleteReviewIntelligentPartnerNodeKnowledgeConfigByIdParam,
} from '../../../../../types';

/**
 * findById
 *
 * @export
 * @tags 智能陪练-知识库问答配置
 * @link [GET] /review/intelligentPartner/node/knowledgeConfig/{id}
 * @param id
 */
export function getReviewIntelligentPartnerNodeKnowledgeConfigById(
    options: ApiOptions<GetReviewIntelligentPartnerNodeKnowledgeConfigByIdParam, never> & {
        segments: GetReviewIntelligentPartnerNodeKnowledgeConfigByIdParam;
    }
): Promise<BaseResponseIntelligentPartnerDialogNodeKnowledgeConfigFoundResponse> {
    return request<never, GetReviewIntelligentPartnerNodeKnowledgeConfigByIdParam, never>(
        '/review/intelligentPartner/node/knowledgeConfig/{id}',
        {
            ...options,
            method: 'get',
            __$requestCalleeName: 'getReviewIntelligentPartnerNodeKnowledgeConfigById',
        }
    );
}

/**
 * 知识库问答节点编辑
 *
 * @export
 * @tags 智能陪练-知识库问答配置
 * @link [PUT] /review/intelligentPartner/node/knowledgeConfig/{id}
 * @param id
 */
export function putReviewIntelligentPartnerNodeKnowledgeConfigById(
    data: PutReviewIntelligentPartnerNodeKnowledgeConfigByIdBody,
    options: ApiOptions<PutReviewIntelligentPartnerNodeKnowledgeConfigByIdParam, never> & {
        segments: PutReviewIntelligentPartnerNodeKnowledgeConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<
        PutReviewIntelligentPartnerNodeKnowledgeConfigByIdBody,
        PutReviewIntelligentPartnerNodeKnowledgeConfigByIdParam,
        never
    >('/review/intelligentPartner/node/knowledgeConfig/{id}', {
        ...options,
        data,
        method: 'put',
        __$requestCalleeName: 'putReviewIntelligentPartnerNodeKnowledgeConfigById',
    });
}

/**
 * deleteById
 *
 * @export
 * @tags 智能陪练-知识库问答配置
 * @link [DELETE] /review/intelligentPartner/node/knowledgeConfig/{id}
 * @param id
 */
export function deleteReviewIntelligentPartnerNodeKnowledgeConfigById(
    options: ApiOptions<DeleteReviewIntelligentPartnerNodeKnowledgeConfigByIdParam, never> & {
        segments: DeleteReviewIntelligentPartnerNodeKnowledgeConfigByIdParam;
    }
): Promise<BaseResponseVoid> {
    return request<never, DeleteReviewIntelligentPartnerNodeKnowledgeConfigByIdParam, never>(
        '/review/intelligentPartner/node/knowledgeConfig/{id}',
        {
            ...options,
            method: 'delete',
            __$requestCalleeName: 'deleteReviewIntelligentPartnerNodeKnowledgeConfigById',
        }
    );
}

export const meta = [
    {
        tags: ['智能陪练-知识库问答配置'],
        path: '/review/intelligentPartner/node/knowledgeConfig/{id}',
        method: 'get',
    },
    {
        tags: ['智能陪练-知识库问答配置'],
        path: '/review/intelligentPartner/node/knowledgeConfig/{id}',
        method: 'put',
    },
    {
        tags: ['智能陪练-知识库问答配置'],
        path: '/review/intelligentPartner/node/knowledgeConfig/{id}',
        method: 'delete',
    },
];
