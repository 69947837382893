// @ts-nocheck

import { request } from '../../context';
import type { ApiOptions } from '../../context';
import type {
    GetInspectionTasksExpandDataQuery,
    BaseResponseListInspectionTaskFoundExpandDto,
} from '../../types';

/**
 * 质检任务列表UI改版
 *
 * @export
 * @tags 质检任务
 * @link [GET] /inspectionTasks/expandData
 */
export function getInspectionTasksExpandData(): Promise<BaseResponseListInspectionTaskFoundExpandDto>;
export function getInspectionTasksExpandData(
    options: ApiOptions<never, GetInspectionTasksExpandDataQuery>
): Promise<BaseResponseListInspectionTaskFoundExpandDto>;
export function getInspectionTasksExpandData(
    options?: ApiOptions<never, GetInspectionTasksExpandDataQuery>
): Promise<BaseResponseListInspectionTaskFoundExpandDto> {
    return request<never, never, GetInspectionTasksExpandDataQuery>('/inspectionTasks/expandData', {
        ...options,
        method: 'get',
        __$requestCalleeName: 'getInspectionTasksExpandData',
    });
}

export const meta = [{ tags: ['质检任务'], path: '/inspectionTasks/expandData', method: 'get' }];
